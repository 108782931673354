import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Paper, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Status } from '../../../common/constants';
import Colours from '../../../styles/colours';
import postStyle from '../../../styles/post';
import reviewAction from '../../../actions/review';

const Launch = (props) => {
  const [isPending, setIsPending] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    setIsLive(props.review.status === Status.Live);
    setIsDraft(props.review.status === Status.Draft);
    setIsPending(props.review.status === Status.Pending);
  }, [props.review]);

  return (
    <Paper className="pb-4 px-5" style={postStyle.paper}>
      <Typography variant="subtitle1" color="inherit" className="mt-3" style={postStyle.textHeader}>
        Select status:
      </Typography>

      <div className="px-3 mt-4">
        {[Status.Draft, Status.Pending, Status.Live].includes(props.review.currentReviewStatus) && (
          <div className="mb-1 mt-3">
            <FormControl component="fieldset">
              <RadioGroup aria-label="Save as Draft" name="customized-radios">
                <FormControlLabel
                  control={<Radio checked={isDraft} color="primary" />}
                  label={(
                    <div className="ml-2">
                      <Typography variant="body1" color="inherit" style={{ fontWeight: isDraft ? 'bold' : 'normal' }}>
                        Save as Draft
                      </Typography>
                      <Typography variant="body2" color="inherit" style={{ color: Colours.Gray1 }}>
                        Continue editing later
                      </Typography>
                    </div>
                  )}
                  onClick={() => {
                    props.dispatchReviewUpdate({ status: Status.Draft });
                  }}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}

        {[Status.Pending].includes(props.review.currentReviewStatus) && (
          <>
            <hr className="my-4" />
            <div className="mt-4 mb-4 pt-2">
              <FormControl component="fieldset">
                <RadioGroup aria-label="Pending" name="customized-radios">
                  <FormControlLabel
                    control={<Radio checked={isPending} color="primary" />}
                    label={(
                      <div className="ml-2">
                        <Typography variant="body1" color="inherit" className="" style={{ fontWeight: isPending ? 'bold' : 'normal' }}>
                          Continue as Pending
                        </Typography>
                        <Typography variant="body2" color="inherit" className="" style={{ color: Colours.Gray1 }}>
                          Review wont be live and searchable in the app until the Page's status is approved.
                        </Typography>
                      </div>
                    )}
                    onClick={() => {
                      props.dispatchReviewUpdate({ status: Status.Pending  });
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}

        {[Status.Draft, Status.Live].includes(props.review.currentReviewStatus) && (
          <>
            <hr className="my-4" />
            <div className="mt-4 mb-4 pt-2">
              <FormControl component="fieldset">
                <RadioGroup aria-label="Launch Now" name="customized-radios">
                  <FormControlLabel
                    control={<Radio checked={isLive} color="primary" />}
                    label={(
                      <div className="ml-2">
                        <Typography variant="body1" color="inherit" className="" style={{ fontWeight: isLive ? 'bold' : 'normal' }}>
                          {
                            props.review.currentReviewStatus === Status.Draft
                              ? `Launch to Live`
                              : (
                                props.review.currentReviewStatus === Status.Live
                                  ? 'Continue as Live'
                                  : 'Update back to Live'
                              )
                          }
                        </Typography>
                        <Typography variant="body2" color="inherit" className="" style={{ color: Colours.Gray1 }}>
                          Set/Update the review as Live and searchable in the app
                        </Typography>
                      </div>
                    )}
                    onClick={() => {
                      props.dispatchReviewUpdate({ status: Status.Live  });
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}
      </div>
    </Paper>
  );
};

Launch.propTypes = {
  dispatchReviewUpdate: PropTypes.func.isRequired,

  review: PropTypes.shape({
    status:              PropTypes.oneOf([Status.Draft, Status.Pending, Status.Live, Status.Rejected]),
    currentReviewStatus: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  review: state.review.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewUpdate: (review) => reviewAction.reviewDataUpdate(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(Launch);
