/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Photos from './ReviewMedia/photo/Photo';
import ReviewAction from '../../../actions/review';
import postStyle from '../../../styles/post';
import { Status } from '../../../common/constants';

const Media = (props) => {
  const [disableMedia, setDisableMedia] = useState(false);

  useEffect(() => {
    if (props && props.review) {
      setDisableMedia([Status.Live, Status.Deleted].includes(props.review.currentReviewStatus));
    }
  }, [props.review]);

  return (
    <Paper variant="elevation" className={`${postStyle.paper} pb-4 px-5 pt-4 mt-2`}>
      <Photos
        {...props}
        disabled={disableMedia}
        match={props.match}
        images={props.review.images || []}
        onSetReviewImages={(images) => props.dispatchReviewUpdate({ images })}
        onSort={(images) => props.dispatchReviewUpdate({ images })}
        showSaveImgPosBtn={false}
        disabledMsg={`Cannot be updated because review is ${props.review.currentReviewStatus}`}
      />
      <br />
    </Paper>
  );
};

Media.defaultProps = {
  match: {},
};

Media.propTypes = {
  dispatchReviewUpdate: PropTypes.func.isRequired,

  match: PropTypes.shape(),

  review: PropTypes.shape({
    id:                  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentReviewStatus: PropTypes.string,
    images:              PropTypes.arrayOf(
      PropTypes.shape({
        display:  PropTypes.string,
        original: PropTypes.string,
      }),
    ),

    videos: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  review: state.review.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewUpdate: (review) => ReviewAction.reviewDataUpdate(dispatch, review),
});
export default connect(mapStateToProps, mapDispatchToProps)(Media);
