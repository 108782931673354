/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { makeStyles, IconButton, Paper, Typography, TextField, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog }
from '@material-ui/core';
import MealsAvailableTag from './Details/MealsAvailableTagMobile';
import { IsEmpty, IfEmptyThrowError, IfFalseThrowError, IfTrueThrowError, IsAdminOrContributor } from '../../../common/checks';
import { Trim } from '../../../common/util';
import Constants, { PageType, Status, Error } from '../../../common/constants';

import MobilePostPhotos from './PromoMedia/photo/PhotoMobile';
import MobilePostVideos from './PromoMedia/video/VideoMobile';
import Tooltip from '../../../components/Common/ToolTip';
import MaterialDatePicker from '../../../components/Common/form/MaterialDatePicker';
import layoutAction from '../../../actions/layout';
import MButton from '../../../components/MButton';
import Colours from '../../../styles/colours';
import Loading from '../../../components/Loading';
import PreviewMobile from './PreviewMobile';
import PostAction from '../../../actions/post';
import ApiService from '../../../services/apiService';
import Icons from '../../../components/Common/Icons';
import PostCategories from './Details/PostCategories';
import SubscriptionLimitModal from '../../../components/SubscriptionLimitModal';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding:  '20px 0px',
    margin:   'auto',
    width:    '100%',
    maxWidth: '100%',
    flexGrow: 1,
  },
}));

const MobileUpdate = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [viewMediaVideo, setViewMediaVideo] = useState(true);
  const [viewMediaPhoto, setViewMediaPhoto] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [postToDelete, setPostToDelete] = useState();
  const [status, setStatus] = useState('');
  const [title, setTitle] = useState('');
  const [promoFromDate, setPromoFromDate] = useState('');
  const [promoTillDate, setPromoTillDate] = useState('');
  const [planModal, setPlanModal] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [flagMealsAvailNA, setFlagMealsAvailNA] = useState(false);
  const [disableInputFields, setDisableInputFields] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState(null);
  const [disableMedia, setDisableMedia] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title:   'Save to Live',
    message: 'Post cannot be updated back to Draft once it is Live, proceed?',
  });

  useEffect(() => {
    if (props && props.post) {
      const p = props.post;
      setStatus(p.status);
      setTitle(p.title || '');
      setPromoFromDate(p.promoFromDate || '');
      setPromoTillDate(p.promoTillDate || '');

      // set the media view.
      if ((!IsEmpty(p.videos) && !IsEmpty(p.images)) || (!IsEmpty(p.videos) && IsEmpty(p.images))) {
        setViewMediaVideo(true);
        setViewMediaPhoto(false);
      } else {
        setViewMediaPhoto(true);
        setViewMediaVideo(false);
      }
      setDisableInputFields([Status.Live, Status.Paused].includes(props.post.currentPostStatus));
      setDisableMedia([Status.Live, Status.Paused, Status.Deleted].includes(props.post.currentPostStatus));
    }

    setAccountType(props.page.plan);
  }, [props.post]);

  const deletePost = async () => {
    try {
      if (!IsEmpty(postToDelete)) {
        const softDeleteStatus = [Status.Live, Status.Paused];
        if (softDeleteStatus.includes(postToDelete.status)) {
          await ApiService.updatePost(postToDelete.id, { status: Status.Deleted });
        } else {
          await ApiService.deletePost(postToDelete.id);
        }
        props.dispatchPostUpdate({ status: Status.Deleted });
        toast.success('Post deleted');
        props.getPost();
        props.history.push(Constants.Url.Posts.replace(':pageId', props.match.params.pageId));
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const validate = (p) => {
    p.title = Trim(p.title);
    p.promoFromDate = Trim(p.promoFromDate);
    p.promoTillDate = Trim(p.promoTillDate);

    IfEmptyThrowError(p.title, 'Title is required');
    IfTrueThrowError((IsEmpty(p.images) && IsEmpty(p.videos)), 'Upload a photo/video');

    if (!IsEmpty(p.promoFromDate) && !IsEmpty(p.promoTillDate)) {
      const datePromo = moment(p.promoTillDate).isAfter(p.promoFromDate, 'day');
      if (!datePromo) {
        IfFalseThrowError(datePromo, 'Promo end date cannot be earlier than start date');
      }
    }

    props.dispatchPostUpdate({ ...p });
  };

  const handleUpdate = async (updatedStatus) => {
    try {
      setConfirmPopup(false);
      const p = props.post;
      validate(p);

      setLoading(true);
      const categoryIds = p.categories.map((category) => category.id);

      // Standardize to pass Singapore timezone to the server
      const startDate = p.promoFromDate ? new Date(p.promoFromDate) : null;
      const promoStartDate = startDate ? moment.tz(startDate, 'Asia/Singapore').startOf('day').format('YYYY-MM-DD HH:mm:ss Z') : null;
      const endDate = p.promoTillDate ? new Date(p.promoTillDate) : null;
      const promoEndDate = endDate ? moment.tz(endDate, 'Asia/Singapore').endOf('day').format('YYYY-MM-DD HH:mm:ss Z') : null;

      const mealTagsOnly = _.compact(_.map(p.userTags, (tag) => (Constants.MealTags.includes(tag) ? tag : null))) || [];
      const mealsAvailable =  mealTagsOnly.filter((tag) => tag !== Constants.NotApplicable);

      let mealsAvailTags = [Constants.NotApplicable];
      if (!IsEmpty(mealsAvailable) && !mealsAvailable.includes(Constants.NotApplicable)) {
        mealsAvailTags = mealsAvailable;
      } else if (IsEmpty(mealsAvailable) && flagMealsAvailNA === false) {
        mealsAvailTags = [];
      }

      let postStatus = updatedStatus;
      if (props.page.status === Status.Pending && updatedStatus === Status.Pending) {
        postStatus = Status.Pending;
      }

      const payload = {
        title:              p.title,
        description:        p.description,
        status:             postStatus,
        images:             p.images,
        videos:             p.videos,
        categories:         categoryIds,
        isMallHighlight:    p.mallHighlight,
        promoCodes:         p.promoCodes,
        promoType:          p.promoType,
        promoTerms:         p.promoTerms,
        promoFromDate:      promoStartDate,
        promoTillDate:      promoEndDate,
        promoAvailOnlineAt: p.promoAvailOnlineAt ? String(p.promoAvailOnlineAt).trim() : '',
        promoLocations:     p.promoLocations,
        isAvailOnline:      !!p.promoAvailOnlineAt,
        promoIsNationwide:  p.promoIsNationwide,
        promoPartners:      p.promoPartners,
        promoSubsidiaries:  p.promoSubsidiaries,
        promoCreditCards:   p.promoCreditCards,
        promoUrl:           p.promoUrl ? String(p.promoUrl).trim() : '',
        userTags:           p.userTags,
        mealsAvailable:     mealsAvailTags,
      };

      if (![PageType.FNB, PageType.Retailer, PageType.ECommerce].includes(props.page.type)) {
        delete payload.mealsAvailable;
      }

      // eslint-disable-next-line react/prop-types
      await ApiService.updatePost(props.match.params.postId, payload)
        .then((post) => {
          props.dispatchPostUpdate({ status: post.status, currentPostStatus: post.status });
          toast.success('Post updated');
          setLoading(false);
          props.getPost();
        })
        .catch((err) => {
          if (err.message === Error.MaxNumberPublishedPost) {
            setPlanModal(true);
          } else {
            toast.error(err.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (loading ? <Loading />
    : (
      <>
        <div className="container-fluid" style={{ background: Colours.LightGray }}>
          <div className="row pt-3" style={{ backgroundColor: Colours.White }}>
            <div className="col-12 d-flex flex-row justify-content-between align-items-center">
              <IconButton
                style={{ width: 50, height: 50 }}
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <Icons.ArrowBack colour={Colours.Black} />
              </IconButton>

              <div className="d-flex flex-column justify-content-center">
                <Typography variant="h6" align="center">Edit Post</Typography>
              </div>

              { props.post.status !== Status.Deleted ? (
                <Tooltip title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}>
                  <MButton
                    text="Delete"
                    textSize="subtitle1"
                    textColour={Colours.Red}
                    disabled={!IsAdminOrContributor(props.page.staffType)}
                    onClick={() => {
                      setOpenDeleteDialog(true);
                      setPostToDelete(props.post);
                    }}
                  />
                </Tooltip>
              ) : <div style={{ width: 35 }} />}
            </div>

            <div className="col-12 pb-2">
              <div className="row">
                <div className="col-3">
                  <Typography className="ml-2" variant="subtitle1">
                    ID:
                    {' '}
                    {props.post.id}
                  </Typography>
                </div>

                <div className="col-6">
                  <div className="d-flex flex-row justify-content-center flex-nowrap">
                    <Typography className="mr-2" variant="subtitle1">Status</Typography>
                    <Typography variant="subtitle1" style={{ color: (status !== Status.Draft) ? Colours.Primary : Colours.Orange }}>
                      {status || ''}
                    </Typography>
                    <Tooltip title={`Post's status`}>
                      <Typography className="mr-2" variant="subtitle1">
                        <Icons.InfoOutlined colour={Colours.LightGray} fontSize="1.3rem" style={{ marginLeft: '5px' }} />
                      </Typography>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3">
            <div className="col-12 mb-2 d-flex flex-column">
              <Paper className={`${classes.paper}`}>
                <div className="px-3">

                  <div className="mb-2">
                    <Typography
                      variant="body1"
                      color="inherit"
                      className="d-inline-block mr-2"
                      style={{
                        fontWeight:   'bold',
                        padding:      '5px 10px',
                        border:       `1px solid ${(viewMediaVideo) ? ((disableMedia === false) ? Colours.Primary : Colours.Gray2) : Colours.Gray}`,
                        borderRadius: 100,
                        background:   (viewMediaVideo) ? ((disableMedia === false) ? Colours.Primary : Colours.Gray2) : Colours.White,
                        color:        (viewMediaVideo) ? Colours.White : Colours.Gray,
                      }}
                      onClick={() => {
                        setViewMediaVideo(true);
                        setViewMediaPhoto(false);
                      }}
                    >
                      Videos
                    </Typography>

                    <Typography
                      variant="body1"
                      color="inherit"
                      className="d-inline-block"
                      style={{
                        fontWeight:   'bold',
                        padding:      '5px 10px',
                        border:       `1px solid ${(viewMediaPhoto) ? ((disableMedia === false) ? Colours.Primary : Colours.Gray2) : Colours.Gray}`,
                        borderRadius: 100,
                        background:   (viewMediaPhoto) ? ((disableMedia === false) ? Colours.Primary : Colours.Gray2) : Colours.White,
                        color:        (viewMediaPhoto) ? Colours.White : Colours.Gray,
                      }}
                      onClick={() => {
                        setViewMediaPhoto(true);
                        setViewMediaVideo(false);
                      }}
                    >
                      Photos
                    </Typography>
                  </div>

                  <div className="container-fluid p-0 m-0">
                    <div className="row">
                      <div className="col-md-12">
                        {
                          ((!IsEmpty(props.post.videos) && viewMediaVideo) || (IsEmpty(props.post.videos) && viewMediaVideo))
                            ? (
                              <MobilePostVideos
                                {...props}
                                postId={props.post.id}
                                videos={props.post.videos || []}
                                onVideoSort={(vid) => props.dispatchPostUpdate({ vid })}
                                onVideoChanged={(vid) => props.dispatchPostUpdate({ vid })}
                                disabled={!IsAdminOrContributor(props.page.staffType) || (disableMedia)}
                              />
                            )
                            : (
                              <MobilePostPhotos
                                {...props}
                                match={props.match}
                                images={props.post.images || []}
                                onSetPostImages={(imgs) => props.dispatchPostUpdate({ imgs })}
                                onSort={(imgs) => props.dispatchPostUpdate({ imgs })}
                                showSaveImgPosBtn={false}
                                disabled={!IsAdminOrContributor(props.page.staffType) || (disableMedia)}
                              />
                            )
                        }
                      </div>
                    </div>
                  </div>

                </div>
              </Paper>
            </div>

            <div className="col-12 d-flex flex-column">
              <Paper className={`${classes.paper}`}>
                <div className="px-3">
                  <Typography variant="body1" color="inherit" className="mb-2" style={{ color: Colours.Black, fontWeight: 'bold' }}>
                    Title
                    <sup style={{ color: Colours.Red }}>*</sup>
                  </Typography>

                  <TextField
                    id="standard-multiline-flexible"
                    value={title}
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={(e) => props.dispatchPostUpdate({ title: e.currentTarget.value })}
                  />
                </div>

                <hr />

                <div className="px-3">
                  <Typography variant="body1" color="inherit" className="mb-2" style={{ color: Colours.Black, fontWeight: 'bold' }}>
                    Promotion Period
                  </Typography>

                  <div className="container px-0 mx-0">
                    { props.post.status !== Status.Live ? (
                      <div className="row">
                        <div className="col-5 pr-0">
                          <MaterialDatePicker
                            disabled={disableInputFields}
                            minDate={false}
                            onChange={(date = new Date()) => {
                              if (!disableInputFields) {
                                const from = date.toString('yyyy-MM-dd');
                                props.dispatchPostUpdate({ promoFromDate: from });
                              }
                            }}
                            value={!promoFromDate ? null : new Date(promoFromDate)}
                            label="From"
                            size="small"
                            variant="outlined"
                          />
                        </div>

                        <div className="col-2 text-center">
                          <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Gray2, marginTop: 30 }}>
                            to
                          </Typography>
                        </div>

                        <div className="col-5 pl-0">
                          <MaterialDatePicker
                            disabled={disableInputFields}
                            minDate={!promoFromDate ? false : new Date(promoFromDate)}
                            onChange={(date = new Date()) => {
                              if (!disableInputFields) {
                                const to = date.toString('yyyy-MM-dd');
                                props.dispatchPostUpdate({ promoTillDate: to });
                              }
                            }}
                            value={!promoTillDate ? null : new Date(promoTillDate)}
                            label="To"
                            size="small"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="alert alert-warning" role="alert">
                          Promo dates cannot be changed once the post goes live.
                          <br />
                          <hr />
                          { props.post.promoFromDate && props.post.promoTillDate ? (
                            <div>
                              <h5><strong>{`Promo starts: ${moment(new Date(props.post.promoFromDate)).utcOffset(+8, false).format('YYYY-MM-DD')}`}</strong></h5>
                              <h5><strong>{`Promo ends: ${moment(new Date(props.post.promoTillDate)).utcOffset(+8, false).format('YYYY-MM-DD')}`}</strong></h5>
                            </div>
                          ) : <h6 style={{ fontWeight: 'bold' }}>Promo period not specified</h6>}
                        </div>
                      </>
                    ) }
                  </div>
                </div>

                <hr />

                <div className="px-3">
                  <Typography variant="body1" color="inherit" className="mb-2" style={{ color: Colours.Black, fontWeight: 'bold' }}>
                    Categories
                    <sup style={{ color: Colours.Red }}>*</sup>
                  </Typography>
                  <Tooltip title={disableInputFields ? 'Cannot be updated' : ''}>
                    <PostCategories disabled={disableInputFields} />
                  </Tooltip>
                </div>

                { [PageType.FNB, PageType.Retailer, PageType.ECommerce].includes(props.page.type) && (
                  <>
                    <hr />
                    <div className="px-3">
                      <Typography variant="body1" color="inherit" className="mb-2" style={{ color: Colours.Black, fontWeight: 'bold' }}>
                        Meals Available
                        <sup style={{ color: Colours.Red }}>*</sup>
                      </Typography>

                      <Tooltip title={disableInputFields ? 'Cannot be updated' : ''}>
                        <div className="container px-0 mx-0" style={{ background: (disableInputFields ? Colours.LightGray : Colours.White) }}>
                          <MealsAvailableTag tags={props.post.userTags} onSkipMealsTag={(bool) => setFlagMealsAvailNA(bool)} disabled={disableInputFields} />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                )}
              </Paper>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-12 px-3">
              <Button
                variant="outlined"
                size="large"
                className="btn-block"
                onClick={() => {
                  props.dispatchSlidingPanelMobile({
                    open:    true,
                    content: <PreviewMobile {...props} slidePreview />,
                    title:   'App Preview',
                  });
                }}
              >
                <Typography>Preview</Typography>
              </Button>
            </div>

            {
              // *Note: If the status array is modified, pls rmb to modify the dialog msg below accordingly
              [Status.Draft, Status.Live, Status.Paused].includes(props.post.currentPostStatus) && (
                <>
                  <div className="col-12 px-3 my-3">
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      className="btn-block"
                      disabled={!IsAdminOrContributor(props.page.staffType)}
                      onClick={() => {
                        if (props.post.currentPostStatus === Status.Live) {
                          handleUpdate(Status.Live);
                        } else {
                          setConfirmationStatus(Status.Live);
                          setDialogContent({
                            title:   'Save to Live',
                            message: props.post.currentPostStatus === Status.Draft
                              ? `Post cannot be updated back to Draft once it is Live, proceed?`
                              : `Update post's status from ${props.post.currentPostStatus} to Live, proceed?`,
                          });
                          setConfirmPopup(true);
                        }
                      }}
                    >
                      <Typography>Save</Typography>
                    </Button>
                  </div>

                  { [Status.Live, Status.Paused].includes(props.post.currentPostStatus) && (
                    <div className="col-12 px-3 mb-3">
                      <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        className="btn-block"
                        disabled={!IsAdminOrContributor(props.page.staffType)}
                        onClick={() => {
                          if (props.post.currentPostStatus === Status.Live) {
                            setConfirmationStatus(Status.Paused);
                            setDialogContent({
                              title:   'Save as Paused',
                              message: 'Post will not be visible or searchable in the app when the post is Paused. Change will take effect within half an hour. Proceed to pause post?',
                            });
                            setConfirmPopup(true);
                          } else {
                            handleUpdate(Status.Paused);
                          }
                        }}
                      >
                        <Typography>Pause</Typography>
                      </Button>
                    </div>
                  )}
                </>
              )
            }

            { [Status.Pending].includes(props.post.currentPostStatus) && (
              <>
                <div className="col-12 px-3 my-3">
                  <Tooltip
                    title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}
                    style={{
                      width: '100vw',
                    }}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      className="btn-block"
                      disabled={!IsAdminOrContributor(props.page.staffType)}
                      onClick={() => handleUpdate(Status.Draft)}
                    >
                      <Typography>Draft</Typography>
                    </Button>
                  </Tooltip>
                </div>

                <div className="col-12 px-3 mb-3">
                  <Tooltip
                    title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}
                    style={{
                      width: '100vw',
                    }}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      className="btn-block"
                      disabled={!IsAdminOrContributor(props.page.staffType)}
                      onClick={() => handleUpdate(Status.Pending)}
                    >
                      <Typography>Save</Typography>
                    </Button>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        </div>

        <Dialog
          fullWidth
          size="sm"
          open={confirmPopup}
          onClose={() => setConfirmPopup(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{ dialogContent.title }</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" color="inherit">{ dialogContent.message }</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleUpdate(confirmationStatus)} color="default" className="mr-4">Proceed</Button>
            <Button onClick={() => setConfirmPopup(false)} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          size="sm"
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Post</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mb-2">
            <Button className="px-2" onClick={() => deletePost()} color="default">Confirm</Button>
            <Button className="px-2 mx-3" onClick={() => setOpenDeleteDialog(false)} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>

        <SubscriptionLimitModal
          open={planModal}
          type="post"
          close={() => setPlanModal(false)}
          accountType={accountType}
          page={props.page}
        />
      </>
    ));
};

MobileUpdate.defaultProps = {
  match:  {},
  videos: [],
  images: [],
};

MobileUpdate.propTypes = {
  match:   PropTypes.shape(),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push:   PropTypes.func.isRequired,
  }).isRequired,
  getPost:                    PropTypes.func.isRequired,
  dispatchPostUpdate:         PropTypes.func.isRequired,
  dispatchSlidingPanelMobile: PropTypes.func.isRequired,
  post:                       PropTypes.shape({
    id:                 PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title:              PropTypes.string,
    description:        PropTypes.string,
    promoFromDate:      PropTypes.string,
    promoTillDate:      PropTypes.string,
    status:             PropTypes.string,
    categories:         PropTypes.arrayOf(PropTypes.shape({})),
    images:             PropTypes.arrayOf(PropTypes.shape({})),
    videos:             PropTypes.arrayOf(PropTypes.string),
    mallHighlight:      PropTypes.bool,
    promoCodes:         PropTypes.arrayOf(PropTypes.string),
    promoType:          PropTypes.string,
    promoTerms:         PropTypes.string,
    promoAvailOnlineAt: PropTypes.string,
    promoLocations:     PropTypes.arrayOf(PropTypes.shape({})),
    promoIsNationwide:  PropTypes.bool,
    promoPartners:      PropTypes.arrayOf(PropTypes.shape({})),
    promoSubsidiaries:  PropTypes.arrayOf(PropTypes.shape({})),
    promoCreditCards:   PropTypes.arrayOf(PropTypes.shape({})),
    promoUrl:           PropTypes.string,
    userTags:           PropTypes.arrayOf(PropTypes.string),
    currentPostStatus:  PropTypes.string,
  }).isRequired,
  page: PropTypes.shape({
    staffType: PropTypes.string,
    type:      PropTypes.string,
    status:    PropTypes.string,
    plan:      PropTypes.string,
  }).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      display:  PropTypes.string,
      original: PropTypes.string,
    }),
  ),
  videos: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  business: state.business.selected,
  page:     state.page.selected,
  post:     state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSlidingPanelMobile: (data) => layoutAction.slidingPanelMobile(dispatch, data),
  dispatchPostUpdate:         (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileUpdate);
