// React Dependencies
import React, { useState } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import {
  Button, MenuItem, Dialog, DialogActions, DialogContent, FormControlLabel, FormControl, Select, FormLabel, Radio, RadioGroup, Typography,
} from '@material-ui/core';

// Internal Dependencies
import { PostType, PromoType } from '../../../common/constants';

const PostTypeModal = ({ onClose, onComplete }) => {
  const [postType, setPostType] = useState(PostType.Promo);
  const [promoType, setPromoType] = useState(`${PromoType.Deals}`);
  const [showSelectPromo, setShowSelectPromo] = useState(true);

  const handleChange = (event) => {
    setPromoType(event.target.value);
  };

  const handlePostType = (event) => {
    setPostType(event.target.value);
    if (event.target.value === PostType.Promo) {
      setShowSelectPromo(true);
      setPromoType(PromoType.Coupon);
    } else {
      setShowSelectPromo(false);
      setPromoType('');
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogContent className="mt-3">
        <Typography variant="h5">Select</Typography>
        <FormLabel component="legend" className="mt-4">Post Type</FormLabel>
        <RadioGroup row aria-label="postType" name="postType" value={postType} onChange={(e) => handlePostType(e)}>
          <FormControlLabel
            value={PostType.Article}
            control={<Radio color="primary" />}
            label={PostType.Article}
          />
          <FormControlLabel
            value={PostType.Promo}
            control={<Radio color="primary" />}
            label={PostType.Promo}
          />
        </RadioGroup>

        {showSelectPromo && (
          <>
            <FormLabel component="legend">Promo Type</FormLabel>
            <FormControl variant="outlined" style={{ minWidth: '100%' }}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={promoType}
                onChange={handleChange}
                label="promoType"
              >
                <MenuItem value={PromoType.Coupon}>{PromoType.Coupon}</MenuItem>
                <MenuItem value={PromoType.CreditCard}>{PromoType.CreditCard}</MenuItem>
                <MenuItem value={PromoType.Deals}>{PromoType.Deals}</MenuItem>
                <MenuItem value={PromoType.Events}>{PromoType.Events}</MenuItem>
                <MenuItem value={PromoType.LuckyDraw}>{PromoType.LuckyDraw}</MenuItem>
                <MenuItem value={PromoType.NewLaunch}>{PromoType.NewLaunch}</MenuItem>
                <MenuItem value={PromoType.PromoCode}>{PromoType.PromoCode}</MenuItem>
                <MenuItem value={PromoType.Vouchers}>{PromoType.Vouchers}</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">Cancel</Button>
        <Button onClick={() => onComplete({ postType, promoType })} color="primary">Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostTypeModal;

PostTypeModal.propTypes = {
  onClose:    PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};
