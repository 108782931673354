// React Dependencies
import React, { useEffect } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { GroupOutlined } from '@material-ui/icons';

// Internal Dependencies
import HeadingAndSubText from './HeadingAndSubText';
import Colours from '../../../../../styles/colours';

const AuthorizedIssuers = ({ gift }) => {
  // TODO: Will be implemented in MS-2721
  // const history = useHistory();
  // const [issuers, setIssuers] = useState();

  const getAuthorizedIssuersHandler = () => {
    try {
      // ...
    } catch (error) {
      console.log('Error in getAuthorizedIssuersHandler: ', error.message);
    }
  };

  const toAuthorizedIssuerPageHandler = () => window.open(`/page/${gift.pageId}/vouchers-coupons`, '_blank');

  useEffect(() => {
    getAuthorizedIssuersHandler();
  }, []);

  return (
    <div>
      <HeadingAndSubText heading={`${gift.type} Description:`} text={gift.label} />
      <HeadingAndSubText heading={`${gift.type} ID:`} text={gift.id} />
      <hr />

      <Typography variant="body2" className="font-weight-bold">Authorized Issuers</Typography>
      <Typography variant="body2">Select one or more issuers who are authorized to issue the voucher</Typography>

      <div className="d-flex justify-content-center mt-5" style={{ height: '50vh' }}>
        <GroupOutlined fontSize="large" className="mr-2" />
        <div>
          <Typography variant="body2">You do not have any existing issuer.</Typography>
          <Typography variant="body2" className="d-flex" style={{ gap: '0.2rem' }}>
            Click
            <Typography variant="body2" style={{ color: Colours.Blue, cursor: 'pointer' }} onClick={toAuthorizedIssuerPageHandler}>here</Typography>
            {' '}
            to create one.
          </Typography>
        </div>
      </div>
    </div>
  );
};

AuthorizedIssuers.propTypes = {
  gift: PropTypes.shape({
    label:           PropTypes.string,
    id:              PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pageId:          PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type:            PropTypes.string,
    startDate:       PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)]),
    endDate:         PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)]),
    extendedEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)]),
    status:          PropTypes.string,
    terms:           PropTypes.string,
    images:          PropTypes.arrayOf(PropTypes.shape(Object)),
    pauseIssuance:   PropTypes.bool,
  }).isRequired,
};

export default AuthorizedIssuers;
