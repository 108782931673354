// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Tooltip, TextField, ClickAwayListener } from '@material-ui/core';
import { HexColorPicker } from 'react-colorful';

// Internal Dependencies
import Colours from '../styles/colours';
import { isHexColour } from '../common/util';

const FloatingColourPicker = (props) => (
  <Tooltip
    open={props.open}
    interactive
    placement={props.placement}
    classes={{ tooltip: 'p-2' }}
    title={(
      // Used to trigger function when user clicks anything other than the child elements
      <ClickAwayListener onClickAway={props.onClose}>
        <div className="d-flex flex-column align-items-center" style={{ width: 200 }}>
          <HexColorPicker
            color={isHexColour(props.colour) ? props.colour : props.defaultColour}
            onChange={props.onChange}
          />
          <div className="mt-2 d-flex justify-content-center align-items-center">
            <TextField
              variant="outlined"
              size="small"
              value={props.colour}
              className="w-50"
              onChange={(e) => props.onChange(e.target.value)}
              inputProps={{
                className: 'p-2',
                style:     { fontSize: 14, color: Colours.White },
              }}
            />
          </div>
        </div>
      </ClickAwayListener>
    )}
  >
    {props.children}
  </Tooltip>
);

FloatingColourPicker.propTypes = {
  children:      PropTypes.shape(Object),
  colour:        PropTypes.string,
  defaultColour: PropTypes.string,
  placement:     PropTypes.string,
  onChange:      PropTypes.func,
  open:          PropTypes.bool,
  onClose:       PropTypes.func,
};

FloatingColourPicker.defaultProps = {
  children:      null,
  colour:        null,
  onChange:      null,
  onClose:       null,
  placement:     'bottom',
  open:          false,
  defaultColour: Colours.Black,
};

export default FloatingColourPicker;
