/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Photos from './PromoMedia/photo/Photo';
import Videos from './PromoMedia/video/Video';
import PostAction from '../../../actions/post';
import postStyle from '../../../styles/post';
import { Status } from '../../../common/constants';

const Media = (props) => {
  const [disableMedia, setDisableMedia] = useState(false);

  useEffect(() => {
    if (props && props.post) {
      setDisableMedia([Status.Live, Status.Paused, Status.Deleted].includes(props.post.currentPostStatus));
    }
  }, [props.post]);

  return (
    <Paper variant="elevation" className={`${postStyle.paper} pb-4 px-5 pt-4 mt-2`}>
      <Photos
        {...props}
        disabled={disableMedia}
        match={props.match}
        images={props.post.images || []}
        onSetPostImages={(images) => props.dispatchPostUpdate({ images })}
        onSort={(images) => props.dispatchPostUpdate({ images })}
        showSaveImgPosBtn={false}
        disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`}
      />
      <br />
      <hr className="py-1" />
      <Videos
        {...props}
        disabled={disableMedia}
        postId={props.post.id}
        videos={props.post.videos || []}
        onVideoSort={(videos) => props.dispatchPostUpdate({ videos })}
        onVideoChanged={(videos) => props.dispatchPostUpdate({ videos })}
        disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`}
      />
    </Paper>
  );
};

Media.defaultProps = {
  match: {},
};

Media.propTypes = {
  dispatchPostUpdate: PropTypes.func.isRequired,

  match: PropTypes.shape(),

  post: PropTypes.shape({
    id:                PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentPostStatus: PropTypes.string,
    images:            PropTypes.arrayOf(
      PropTypes.shape({
        display:  PropTypes.string,
        original: PropTypes.string,
      }),
    ),

    videos: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});
export default connect(mapStateToProps, mapDispatchToProps)(Media);
