import React, { useEffect, useState } from 'react';
import { Button, DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText }
from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Skeleton } from '@material-ui/lab';
import Colours from '../styles/colours';
import { Error, WindowSize } from '../common/constants';
import ApiService from '../services/apiService';

const SubscriptionLimitDialog = (props) => (
  <>
    <DialogTitle id="alert-dialog-slide-title">{props.maxPublishedError}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Your current subscription plan :
        {' '}

        { props.windowSize === WindowSize.Mobile && <br /> }

        <span style={{ fontWeight: 'bold', color: Colours.Black }}>
          {props.accountType}
          {' '}
          Tier
        </span>

        <br />
        {props.windowSize === WindowSize.Mobile && <br />}
        <div
          className="d-flex flex-row align-items-center"
          style={{ columnGap: 8 }}
        >
          <span>
            Monthly live
            {' '}
            {props.type}
            (s) quota :
            {' '}
          </span>
          {
            props.loading ? (<Skeleton width={24} height="2rem" />) : (<span style={{ fontWeight: 'bold', color: Colours.Black }}>{props.accountLimit}</span>)
          }
        </div>

        <br />
        To find out more or upgrade your subscription plan, click
        {' '}
        <a href={`/support?upgradePlan=true&pageName=${props.page.name}&pageId=${props.page.id}`}>here</a>
        .
      </DialogContentText>
    </DialogContent>
  </>
);

const SubscriptionLimitModal = (props) => {
  const [maxPublishedError, setMaxPublishedError] = useState('');
  const [accountLimit, setAccountLimit] = useState(0);
  const [loading, setLoading] = useState(false);

  const titleMap = {
    post:   Error.MaxNumberPublishedPost,
    gift:   Error.MaxNumberLiveGifts,
    review: Error.MaxNumberPublishedReview,
  };

  const fetchAccountLimit = async () => {
    try {
      setLoading(true);
      const result = await ApiService.getPageAccountPlanLimit(props.page.id, props.type);
      setAccountLimit(result.limit);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setMaxPublishedError(titleMap[props.type]);
    if (props.open) {
      fetchAccountLimit();
    }
  }, [props.open, props.type]);

  return (
    <Dialog
      open={props.open}
      fullWidth
      keepMounted
      onClose={() => props.close()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <SubscriptionLimitDialog
        accountType={props.accountType}
        maxPublishedError={maxPublishedError}
        accountLimit={accountLimit}
        page={props.page}
        windowSize={props.windowSize}
        type={props.type}
        loading={loading}
      />
      <DialogActions>
        <Button onClick={() => props.close()} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SubscriptionLimitDialog.propTypes = {
  accountType:       PropTypes.string.isRequired,
  maxPublishedError: PropTypes.string.isRequired,
  accountLimit:      PropTypes.number.isRequired,
  page:              PropTypes.shape({
    id:   PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  windowSize: PropTypes.string.isRequired,
  type:       PropTypes.oneOf(['post', 'gift', 'review']).isRequired,
  loading:    PropTypes.bool.isRequired,
};

SubscriptionLimitModal.propTypes = {
  accountType: PropTypes.string.isRequired,
  close:       PropTypes.func.isRequired,
  open:        PropTypes.bool,
  page:        PropTypes.shape({
    id:   PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  windowSize: PropTypes.string.isRequired,
  type:       PropTypes.oneOf(['post', 'gift', 'review']).isRequired,
};

SubscriptionLimitModal.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(SubscriptionLimitModal);
