import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { containerHeaderTextStyle } from '../../../styles/common';
import PageAnalytics from './PageAnalytics';
import Menu from './Menu/Menu';
import PostsAnalytics from './PostsAnalytics/PostsAnalytics';

const Views = {
  PageAnalytics: 'Page Analytics',
  PostAnalytics: 'Post Analytics',
};

const Analytics = (props) => {
  const [view, setView] = useState(Views.PageAnalytics);

  return (
    <div className="container-fluid d-flex flex-column">
      <div className="row d-flex flex-column align-items-center justify-contents-center">
        <div className="col-12 mt-4 ml-4">
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Analytics</Typography>
        </div>
      </div>
      <div className="row flex-column align-items-center">
        <Menu
          data={[
            { text: Views.PageAnalytics },
            { text: Views.PostAnalytics },
          ]}
          selected={view}
          onChange={(viewClicked) => setView(viewClicked)}
        />
      </div>

      <div className="row mt-4 pb-5">
        <div className="col-12 mt-1 px-4">
          {
            view === Views.PageAnalytics ? (
              <PageAnalytics page={props.page.id} />
            ) : (
              <PostsAnalytics pageId={props.page.id} />
            )
          }
        </div>
      </div>
    </div>
  );
};

Analytics.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

export default connect(mapStateToProps, null)(Analytics);
