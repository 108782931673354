import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import Colours from '../../../../styles/colours';
import AddressSearch from '../../../../components/AddressSearch';
import { IsEmpty, Trim } from '../../../../common/util';
import { Error } from '../../../../common/constants';

function Info({ outlet = {}, handleInputChange, disabled }) {
  const [openAddress, setOpenAddress] = React.useState(false);
  const [addressDisplay, setAddressDisplay] = React.useState('');
  const closeAddress = () => setOpenAddress(false);

  useEffect(() => {
    if (!IsEmpty(outlet.address)) {
      setAddressDisplay(`${(outlet.locatedIn && `${outlet.locatedIn}\n`)}${outlet.address}\nSingapore ${outlet.postalCode}`);
    } else {
      setAddressDisplay('');
    }
  }, [outlet.address, outlet.postalCode]);

  const populateAddress = (address) => {
    try {
      if (IsEmpty(Trim(address.address))) {
        setOpenAddress(true);
        return toast.error('Address is required');
      }

      if (IsEmpty(Trim(address.postalCode))) {
        setOpenAddress(true);
        return toast.error('Postal code is required');
      }

      handleInputChange('address', address.address);
      handleInputChange('postalCode', address.postalCode);
      handleInputChange('lat', address.lat || null);
      handleInputChange('lng', address.lng || null);
      handleInputChange('locatedIn', address.name || '');

      setOpenAddress(false);
    } catch (err) {
      toast.error(Error.UnexpectedError);
    }
  };

  return (
    <div className="container-fluid p-1">
      {openAddress && <AddressSearch onCancel={closeAddress} onDone={(e) => populateAddress(e)} />}
      <Paper
        className="col-12 col-xl-9 p-2"
        style={{ backgroundColor: Colours.White }}
      >
        <div className="px-3 mt-3 col-12">
          <Typography variant="body2">
            Name
            {' '}
            <span className="ml-1" style={{ color: Colours.Red }}>*</span>
          </Typography>

          <TextField
            className="block mt-2"
            name="name"
            type="text"
            value={outlet.name || ''}
            onChange={(e) => handleInputChange('name', e.target.value)}
            variant="outlined"
            size="small"
            inputProps={{
              style: {
                fontSize: '0.9rem',
              },
            }}
          />
        </div>

        <div
          className="px-3 pt-4 col-12"
          style={{
            cursor: 'pointer',
          }}
        >
          <div className="row col-12 d-flex flex-row align-items-center">
            <Typography variant="body2">
              Address
              {' '}
              <span className="ml-1" style={{ color: Colours.Red }}>*</span>
            </Typography>

            <Button
              className="ml-3"
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => { setOpenAddress(true); }}
              disabled={disabled}
            >
              <Typography variant="caption">search</Typography>
            </Button>
          </div>

          <TextField
            id="standard-multiline-flexible"
            className="mt-2"
            multiline
            maxRows={4}
            value={addressDisplay}
            variant="outlined"
            size="small"
            placeholder=""
            fullWidth
            disabled
            inputProps={{
              style: {
                fontSize: '0.9rem',
              },
            }}
          />
        </div>

        <div className="px-3 pt-4 col-12">
          <Typography variant="body2">Unit No.</Typography>
          <div className="d-flex flex-row align-items-center">
            <Typography variant="body2" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
              #
              {' '}
            </Typography>
            <TextField
              className="block mt-2"
              name="unit"
              variant="outlined"
              value={outlet.unitNo || ''}
              onChange={(e) => handleInputChange('unitNo', e.target.value.toUpperCase())}
              size="small"
              inputProps={{
                style: {
                  fontSize: '0.9rem',
                },
              }}
            />
          </div>
        </div>

        <div className="px-3 py-4 col-12">
          <Typography variant="body2">Tel</Typography>
          <TextField
            className="block mt-2"
            name="tel"
            variant="outlined"
            value={outlet.tel || ''}
            onChange={(e) => handleInputChange('tel', e.target.value)}
            size="small"
            inputProps={{
              style: {
                fontSize: '0.9rem',
              },
            }}
          />
        </div>
      </Paper>
    </div>
  );
}

Info.defaultProps = {
  outlet: {},
};

Info.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  disabled:          PropTypes.bool.isRequired,
  outlet:            PropTypes.shape({}),
};

export default Info;
