/* eslint-disable react/prop-types */
// React Dependencies
import React, { useState, forwardRef, useImperativeHandle } from 'react';

// External Dependencies
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, Typography, useTheme } from '@material-ui/core';
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, arrayMove } from '@dnd-kit/sortable';

// Internal Dependencies
import { IsEmpty } from '../../../common/checks';
import { containerHeaderTextStyle } from '../../../styles/common';
import SortableCardItem from './SortableCardItem';

const SortableCard = forwardRef(({ cards, onEvent, onDelete, onUpdatePosType, isSortable = true, cardType = 'Pinned' }, ref) => {
  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
  );

  const [items, setItems] = useState(cards);
  const [open, setOpenDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [activeCardId, setActiveCardId] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const onChange = (postId, positionType) => {
    const payload = {
      postId,
      positionType,
    };

    onUpdatePosType(payload);
  };

  useImperativeHandle(ref, () => ({
    updateItems(data) {
      setItems(data);
    },
  }));

  const handleCloseRemoveDialog = () => {
    setItemToDelete(null);
    setOpenDialog(false);
  };

  const onClickRemove = (postId) => {
    setItemToDelete(postId);
    setOpenDialog(true);
  };

  const onConfirmRemove = () => {
    const newData = [...items];

    onDelete(itemToDelete);
    setItems(newData);
    setOpenDialog(false);
  };

  const handleDragEnd = (event) => {
    if (isSortable) {
      const { active, over } = event;

      if (IsEmpty(active) || IsEmpty(over)) {
        return;
      }

      if (active.id !== over.id) {
        setItems((_items) => {
          const oldIndex = _items.findIndex((item) => item.id === active.id);
          const newIndex = _items.findIndex((item) => item.id === over.id);

          const oldData = [...items];
          const sorted = arrayMove(_items, oldIndex, newIndex);

          onEvent({ name: 'move', data: { old: oldData, new: sorted } });

          return sorted;
        });
      }

      setActiveCardId(null);
    }
  };

  const handleDragStart = (event) => {
    if (isSortable) {
      setActiveCardId(event.active.id);
    }
  };

  return items.length > 0 ? (
    <>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext
          items={items}
          strategy={rectSortingStrategy}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {items.map((value) => (
              <SortableCardItem
                key={value.postId}
                active={value.id === activeCardId}
                cardItem={value}
                onChange={onChange}
                onClickRemove={onClickRemove}
                isSortable={isSortable}
                height={isSortable ? 442 : 380}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseRemoveDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Remove
          {' '}
          {cardType}
          {' '}
          Post
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure want to remove this
            {' '}
            {cardType.toLowerCase()}
            {' '}
            post?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onConfirmRemove()} color="default" variant="contained">
            Yes
          </Button>
          <Button autoFocus onClick={() => handleCloseRemoveDialog()} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <div>
      <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>No Data</Typography>
    </div>
  );
});

export default SortableCard;
