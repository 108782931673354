import { IsEmpty } from '../common/checks';
import Constants from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  selected: {
    id:   123131,
    name: 'ABC Shop',
  },

  // store the list of business(es) the user can access/manage
  /**
   * E.g. businesses: [
   *  {
   *    id: <businessId>
   *    name: <businessName>
   *    permissions: {
   *      isAdmin: true,
   *      businessRead: true,
   *      ...
   *    },
   *    pages: [
   *      id: <page id>
   *      name: <page name>
   *      permissions: {
   *        ...
   *      }
   *    ]
   *  }
   * ]
   */
  businesses: [],

  currentView: 'Analytics',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.BusinessesAccess:
      return {
        ...state,
        businesses: IsEmpty(payload) ? [] : payload,
      };

    case ReduxAction.BusinessCurrentView:
      return {
        ...state,
        currentView: IsEmpty(payload) ? null : payload,
      };

    case ReduxAction.BusinessSelected:
      return {
        ...state,
        selected: IsEmpty(payload)
          ? {}
          : {
            id:   payload.id,
            name: payload.name,
          },
      };

    default:
      return state;
  }
}
