// React Dependencies
import React, { useEffect, useRef, useState } from 'react';

// External Dependencies
import _ from 'lodash';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';

// Internal Dependencies
import ApiService from '../../../services/apiService';
import PostsMainView from './PostsMainView';
import PostsDialogView from './PostsDialogView';
import { PositionType, SpecialHighlightPostType } from '../../../common/constants';
import Util, { Trim } from '../../../common/util';
import { IsEmpty } from '../../../common/checks';

import PostTable from './PostTable';
import PostTypeModal from './PostTypeModal';
import ToolTip from '../../../components/Common/ToolTip';
import PageHighlightConst from './PageHighlightConst';

const { statusesAvailToSet } = PageHighlightConst;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft:  '1rem',
    paddingRight: '1rem',

    [theme.breakpoints.down('sm')]: {
      paddingLeft:  0,
      paddingRight: 0,
    },
  },
  postMainViewContainer: {
    display:       'flex',
    flexDirection: 'column',
    minHeight:     '30vh',
    marginTop:     20,
    paddingTop:    20,
  },
}));

const PageHighlightPosts = (props) => {
  const classes = useStyles();
  const { pageId, pageHighlightId } = props.match.params;

  const [pageHighlight, setPageHighlight] = useState(null);
  const [isSpecial, setIsSpecial] = useState(false);
  const [isExclusive, setIsExclusive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageSize] = useState(20);
  const [postPageHighlights, setPostPageHighlights] = useState([]);

  // Exclusive Post Page Highlight
  const [redirectUrl, setRedirectUrl] = useState();
  const [openPostTypeModal, setOpenPostTypeModal] = useState(false);

  // Special Post Page Highlight
  const SortableCardRefSpecialPost = useRef();
  const [specialPostBrandSearch, setSpecialPostBrandSearch] = useState(null);
  const [refreshSpecialPost, setRefreshSpecialPost] = useState(false);
  const [pageNoSpecialPost, setPageNoSpecialPost] = useState(1);
  const [openAddSpecialPost, setOpenAddSpecialPost] = useState(false);
  const [specialPostUpdated, setSpecialPostUpdated] = useState([]);
  const [totalCountSpecialPost, setTotalCountSpecialPost] = useState(0);
  const [allSpecialPostCount, setAllSpecialPostCount] = useState(0);

  // Pic Marker Post
  const SortableCardRefPicMarkerPost = useRef();
  const [picMarkerPosts, setPicMarkerPosts] = useState([]);
  const [picMarkerPostBrandSearch, setPicMarkerPostBrandSearch] = useState(null);
  const [refreshPicMarkerPost, setRefreshPicMarkerPost] = useState(false);
  const [pageNoPicMarkerPost, setPageNoPicMarkerPost] = useState(1);
  const [openAddPicMarkerPost, setOpenAddPicMarkerPost] = useState(false);
  const [picMarkerPostUpdated, setPicMarkerPostUpdated] = useState([]);
  const [totalCountPicMarkerPost, setTotalCountPicMarkerPost] = useState(0);
  const [allPicMarkerPostCount, setAllPicMarkerPostCount] = useState(0);

  // Pinned Post
  const SortableCardRefPinnedPost = useRef();
  const [pinnedPosts, setPinnedPosts] = useState([]);
  const [pinnedPostBrandSearch, setPinnedPostBrandSearch] = useState(null);
  const [refreshPinnedPost, setRefreshPinnedPost] = useState(false);
  const [pageNoPinnedPost, setPageNoPinnedPost] = useState(1);
  const [openAddPinnedPost, setOpenAddPinnedPost] = useState(false);
  const [pinnedPostUpdated, setPinnedPostUpdated] = useState([]);
  const [totalCountPinnedPost, setTotalCountPinnedPost] = useState(0);
  const [allPinnedPostCount, setAllPinnedPostCount] = useState(0);

  const createPost = async (data) => {
    try {
      const payload = {
        pageId,
        type:      data.postType,
        promoType: data.promoType,
      };
      const post = await ApiService.addPost(payload);
      console.log('post: ', post);
      if (post) {
        const res = await ApiService.addPostPageHighlight(pageId, pageHighlightId, post.id);
        if (res) {
          setOpenPostTypeModal(false);
          setRedirectUrl(`/page/${pageId}/post/${post.id}?pageHighlightId=${pageHighlightId}`);
        }
      } else {
        setOpenPostTypeModal(false);
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err.message);
    }
  };

  const fetchApi = async () => {
    try {
      const ph = await ApiService.getPageHighlight(pageHighlightId, { pageId });
      setPageHighlight(ph);

      // For Special & Exclusive Post Page Highlight
      const postPageHighlightData = await ApiService.getPostPageHighlights({ pageId, pageHighlightId });
      setPostPageHighlights(postPageHighlightData.rows);

      // Special Post Page Highlight
      if (isSpecial) {
        SortableCardRefSpecialPost.current.updateItems(postPageHighlightData.rows);
      }

      // Pic Marker Post
      const picMarkerData = await ApiService.getPostPageHighlights({ pageId, pageHighlightId, isPicMarker: true });
      const copyPickMarkers = _.cloneDeep(picMarkerData.rows);
      setPicMarkerPosts(copyPickMarkers);
      SortableCardRefPicMarkerPost.current.updateItems(copyPickMarkers);

      // Pinned Post
      const pinnedPostData = await ApiService.getPinnedPosts({ pageId, pageHighlightId });
      const copyPinnedPosts = _.cloneDeep(pinnedPostData);
      setPinnedPosts(copyPinnedPosts);
      SortableCardRefPinnedPost.current.updateItems(copyPinnedPosts);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
      console.log(err.message);
    }
  };

  const onDelete = async (postId, type) => {
    try {
      setLoading(true);
      let res;

      if (type === SpecialHighlightPostType.Special) {
        res = await ApiService.removePostPageHighlightPositionType(pageId, pageHighlightId, postId);
      } else if (type === SpecialHighlightPostType.PicMarker) {
        const postRemoved = picMarkerPosts.filter((p) => p.postId === postId);
        res = await ApiService.updatePostPageHighlight(postRemoved[0].id, { pageId, pageHighlightId, isPicMarker: false });
      } else if (type === SpecialHighlightPostType.Pinned) {
        const postRemoved = pinnedPosts.filter((p) => p.postId === postId);
        res = await ApiService.removePinnedPostPositionType(pageId, pageHighlightId, postRemoved[0].postPageHighlightId);
      }

      if (res) {
        toast.success('Post removed');

        fetchApi();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const onClickAdd = (type) => {
    if (type === SpecialHighlightPostType.Special) {
      setRefreshSpecialPost(true);
      setSpecialPostBrandSearch(null);
      setPageNoSpecialPost(1);
      setOpenAddSpecialPost(true);
    } else if (type === SpecialHighlightPostType.PicMarker) {
      setRefreshPicMarkerPost(true);
      setPicMarkerPostBrandSearch(null);
      setPageNoPicMarkerPost(1);
      setOpenAddPicMarkerPost(true);
    } else if (type === SpecialHighlightPostType.Pinned) {
      setRefreshPinnedPost(true);
      setPinnedPostBrandSearch(null);
      setPageNoPinnedPost(1);
      setOpenAddPinnedPost(true);
    }
  };

  const handleCloseAdd = (type) => {
    if (type === SpecialHighlightPostType.Special) {
      setOpenAddSpecialPost(false);
      setRefreshSpecialPost(true);
    } else if (type === SpecialHighlightPostType.PicMarker) {
      setOpenAddPicMarkerPost(false);
      setRefreshPicMarkerPost(true);
    } else if (type === SpecialHighlightPostType.Pinned) {
      setOpenAddPinnedPost(false);
      setRefreshPinnedPost(true);
    }
  };

  const onAdd = async (data, type) => {
    try {
      let res;
      let pinnedPosition = 1;
      let successMessage = 'Successfully added';

      if (type === SpecialHighlightPostType.Special) {
        res = await ApiService.addPostPageHighlight(pageId, pageHighlightId, data.postId);

        const filterPosts = specialPostUpdated.filter((p) => p.id !== data.postId);
        setSpecialPostUpdated(filterPosts);
      } else if (type === SpecialHighlightPostType.PicMarker) {
        const postAdded = picMarkerPostUpdated.filter((p) => p.id === data.postId);
        res = await ApiService.updatePostPageHighlight(postAdded[0].id, { pageId, pageHighlightId, isPicMarker: true });

        const filterPosts = picMarkerPostUpdated.filter((p) => p.id !== data.postId);
        setPicMarkerPostUpdated(filterPosts);
      } else if (type === SpecialHighlightPostType.Pinned) {
        if (pinnedPosts.length > 0) {
          const maxPinnedPosition = _.maxBy(pinnedPosts, 'position');
          pinnedPosition = maxPinnedPosition.position + 1;
        }

        const postAdded = pinnedPostUpdated.filter((p) => p.id === data.postId);
        res = await ApiService.addPinnedPost(pageId, pageHighlightId, postAdded[0].id, pinnedPosition, PositionType.Rotatable);

        const filterPosts = pinnedPostUpdated.filter((p) => p.id !== data.postId);
        setPinnedPostUpdated(filterPosts);
        successMessage = 'Successfully pinned';
      }

      if (res) {
        toast.success(successMessage);
        fetchApi();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const updateSeq = async (data) => {
    try {
      setLoading(true);
      const res = await ApiService.updatePinnedPostOrderPosition(pageId, pageHighlightId, data.postPageHighlightIds);

      if (res) {
        toast.success('Position updated');
        fetchApi();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const onSortableCardEvent = async (eventData) => {
    const newOrdering = eventData.data.new.map((v) => v.postPageHighlightId);
    const postsSorted = { postPageHighlightIds: newOrdering };
    updateSeq(postsSorted);
  };

  const onUpdatePosType = async (post) => {
    try {
      setLoading(true);
      const postUpdated = pinnedPosts.filter((p) => p.postId === post.postId);
      const res = await ApiService.updatePinnedPostPositionType(pageId, pageHighlightId, postUpdated[0].postPageHighlightId, post.positionType);

      if (res) {
        toast.success('Position type updated');

        fetchApi();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const onSearchSpecialPost = async () => {
    try {
      const mapExistingOfPinnedPostIds = postPageHighlights.map((e) => e.postId);
      const postBrandKey = Trim(specialPostBrandSearch, false, true);

      setLoading(true);
      const data = await ApiService.getPostsByBrandAndCategoryGroup({
        title:         postBrandKey ? postBrandKey.toLowerCase() : '',
        categoryGroup: 'All',
        pageSize,
        pageId,
        pageNo:        pageNoSpecialPost - 1,
        order:         [{ col: 'id', dir: 'DESC' }],
      });

      setAllSpecialPostCount(data.totalCount);
      setTotalCountSpecialPost(Math.ceil(data.totalCount / pageSize));

      const updatedData = _.map(data.rows, (d) => _.assign({
        ...d,
        isPinned: mapExistingOfPinnedPostIds.includes(d.id),
      }));
      setSpecialPostUpdated(updatedData);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
      console.log(err.message);
    }
  };

  const inputSearchFilterPageHighlight = (list, searchBy, searchValue) => _.filter(list, (item) => {
    if (searchValue) {
      return item[searchBy].toLowerCase().includes(searchValue.toLowerCase());
    }
    return true;
  });

  const onSearchPicMarkerPost = async () => {
    try {
      setLoading(true);
      const data = inputSearchFilterPageHighlight(postPageHighlights, 'title', picMarkerPostBrandSearch);
      setAllPicMarkerPostCount(data.length);
      setTotalCountPicMarkerPost(Math.ceil(data.length / pageSize));

      const updatedData = _.map(data, (d) => _.assign({
        ...d,
        isPinned: d.isPicMarker,
      }));
      setPicMarkerPostUpdated(updatedData);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const onSearchPinnedPost = async () => {
    try {
      const mapExistingOfPinnedPostIds = pinnedPosts.map((e) => e.postId);

      setLoading(true);
      const data = inputSearchFilterPageHighlight(postPageHighlights, 'title', pinnedPostBrandSearch);
      setAllPinnedPostCount(data.length);
      setTotalCountPinnedPost(Math.ceil(data.length / pageSize));

      const updatedData = _.map(data, (d) => _.assign({
        ...d,
        isPinned: mapExistingOfPinnedPostIds.includes(d.postId),
      }));
      setPinnedPostUpdated(updatedData);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    const query = Util.ExtractParamsFromUrlQueryStr(props.location.search);
    if (!IsEmpty(query)) {
        setIsSpecial(query.isSpecial === 'true' || false);
        setIsExclusive(query.isExclusive === 'true' || false);
    }
  }, []);

  useEffect(() => {
    fetchApi();
  }, [isSpecial, isExclusive]);

  useEffect(() => {
    if (refreshSpecialPost || refreshPicMarkerPost || refreshPinnedPost) {
      fetchApi();
    }
  }, [refreshSpecialPost, refreshPicMarkerPost, refreshPinnedPost]);

  useEffect(() => {
    if (openAddSpecialPost) {
      onSearchSpecialPost();
    }
  }, [openAddSpecialPost, specialPostBrandSearch, pageNoSpecialPost]);

  useEffect(() => {
    if (openAddPicMarkerPost) {
      onSearchPicMarkerPost();
    }
  }, [openAddPicMarkerPost, picMarkerPostBrandSearch, pageNoPicMarkerPost]);

  useEffect(() => {
    if (openAddPinnedPost) {
      onSearchPinnedPost();
    }
  }, [openAddPinnedPost, pinnedPostBrandSearch, pageNoPinnedPost]);

  return redirectUrl ? <Redirect to={redirectUrl} /> : (
    <>
      <div className={`container-fluid ${classes.mainContainer}`}>

        {/* Post Page Highlight */}
        <div className="row d-flex flex-column" style={{ minHeight: '30vh' }}>
          <div className="col-12 my-3">
            {
              isSpecial ? (
                <PostsMainView
                  title="Special Post(s)"
                  pageHighlight={pageHighlight}
                  sortableCardRef={SortableCardRefSpecialPost}
                  onClickAdd={() => onClickAdd(SpecialHighlightPostType.Special)}
                  onDelete={(postId) => onDelete(postId, SpecialHighlightPostType.Special)}
                  cards={postPageHighlights}
                  isSortable={false}
                  cardType="Special"
                />
              ) : (
                <>
                  <ToolTip
                    title={!statusesAvailToSet.includes(pageHighlight?.status) ? `Cannot add post to highlight because highlight's status is '${pageHighlight?.status}'` : ''}
                  >
                    <Button
                      style={{ marginLeft: 15, marginBottom: 20 }}
                      onClick={() => setOpenPostTypeModal(true)}
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={!statusesAvailToSet.includes(pageHighlight?.status)}
                    >
                      Add Post
                    </Button>
                  </ToolTip>
                  <div className="col-lg-12 col-12" style={{ marginBottom: 20 }}>
                    <PostTable pageId={pageId} pageHighlightId={pageHighlightId} setRefreshPicMarkerPost={setRefreshPicMarkerPost} />
                  </div>
                </>
              )
            }
          </div>
        </div>

        <hr />

        {/* Pic Marker Post */}
        <div className={`${classes.postMainViewContainer} row`}>
          <div className="col-12 my-3">
            <PostsMainView
              title="Post(s) showing as picture-marker in Deals Map"
              pageHighlight={pageHighlight}
              sortableCardRef={SortableCardRefPicMarkerPost}
              onClickAdd={() => onClickAdd(SpecialHighlightPostType.PicMarker)}
              onDelete={(postId) => onDelete(postId, SpecialHighlightPostType.PicMarker)}
              cards={picMarkerPosts}
              isSortable={false}
              cardType="Pic Marker"
            />
          </div>
        </div>

        <hr />

        {/* Pinned Post */}
        <div className={`${classes.postMainViewContainer} row`}>
          <div className="col-12 my-3">
            <PostsMainView
              title="Pinned Post(s) (Click-and-drag to re-order position)"
              pageHighlight={pageHighlight}
              sortableCardRef={SortableCardRefPinnedPost}
              onEvent={(eventData) => onSortableCardEvent(eventData)}
              onClickAdd={() => onClickAdd(SpecialHighlightPostType.Pinned)}
              onDelete={(postId) => onDelete(postId, SpecialHighlightPostType.Pinned)}
              onUpdatePosType={(post) => onUpdatePosType(post)}
              cards={pinnedPosts}
              isSortable
              cardType="Pinned"
            />
          </div>
        </div>

        {/* Special Post Page Highlight */}
        {isSpecial && (
          <PostsDialogView
            loading={loading}
            allPostCount={allSpecialPostCount}
            totalCount={totalCountSpecialPost}
            pageNo={pageNoSpecialPost}
            posts={postPageHighlights}
            postsUpdated={specialPostUpdated}
            openAdd={openAddSpecialPost}
            onAdd={(data) => onAdd(data, SpecialHighlightPostType.Special)}
            handleClose={() => handleCloseAdd(SpecialHighlightPostType.Special)}
            setPageNo={setPageNoSpecialPost}
            setBrandSearch={setSpecialPostBrandSearch}
            isPinLabel={false}
          />
        )}

        {/* Pic Marker Post */}
        <PostsDialogView
          loading={loading}
          allPostCount={allPicMarkerPostCount}
          totalCount={totalCountPicMarkerPost}
          pageNo={pageNoPicMarkerPost}
          posts={picMarkerPosts}
          postsUpdated={picMarkerPostUpdated}
          openAdd={openAddPicMarkerPost}
          onAdd={(data) => onAdd(data, SpecialHighlightPostType.PicMarker)}
          handleClose={() => handleCloseAdd(SpecialHighlightPostType.PicMarker)}
          setPageNo={setPageNoPicMarkerPost}
          setBrandSearch={setPicMarkerPostBrandSearch}
          isPinLabel={false}
        />

        {/* Pinned Post */}
        <PostsDialogView
          loading={loading}
          allPostCount={allPinnedPostCount}
          totalCount={totalCountPinnedPost}
          pageNo={pageNoPinnedPost}
          posts={pinnedPosts}
          postsUpdated={pinnedPostUpdated}
          openAdd={openAddPinnedPost}
          onAdd={(data) => onAdd(data, SpecialHighlightPostType.Pinned)}
          handleClose={() => handleCloseAdd(SpecialHighlightPostType.Pinned)}
          setPageNo={setPageNoPinnedPost}
          setBrandSearch={setPinnedPostBrandSearch}
          isPinLabel
        />
      </div>

      {
        openPostTypeModal && <PostTypeModal onComplete={(d) => createPost(d)} onClose={() => setOpenPostTypeModal(false)} />
      }
    </>
  );
};

PageHighlightPosts.defaultProps = {
  match: {},
};

PageHighlightPosts.propTypes = {
  match: PropTypes.shape(),

  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default PageHighlightPosts;
