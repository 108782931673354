import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chip, Typography } from '@material-ui/core';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Colours from '../../../../styles/colours';
import Tagging from '../../../../components/Common/Tagging';
import { IsEmpty } from '../../../../common/checks';

const ManagePlaceAndLocationTags = (props) => {
  const [currentTags, setCurrentTags] = useState([]);
  const [reviewTags, setReviewTags] = useState([]);

  const handleTagsFromParents = () => {
    try {
      setCurrentTags(_.uniq(_.concat(_.toLower(props.locationName), props.existingTags)));

      const unusedReviewTags = _.filter(_.uniq(_.difference(props.reviewTags, props.existingTags)), (d) => d !== _.toLower(props.locationName));
      setReviewTags(unusedReviewTags);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    handleTagsFromParents();
  }, []);

  useEffect(() => {
    handleTagsFromParents();
  }, [props.existingTags]);

  const addReviewTag = (tag) => {
    try {
      const index = currentTags.indexOf(tag);
      if (index === -1) {
        const curTagsCopy = currentTags.slice();
        curTagsCopy.push(tag);
        setCurrentTags(curTagsCopy);
        props.onTagsChange(curTagsCopy);
      }
    } catch (err) {
      toast.error(`Error in addReviewTag(): ${err.message}`);
    }
  };

  return (
    <div className="row px-4">
      <div className="col-12 d-flex flex-column">
        <Typography
          variant="h6"
          color="inherit"
          style={{ color: Colours.Gray1, fontWeight: 'bold', fontSize: 16 }}
        >
          List of tags used in this entire Review (Click to add tag to list below)
        </Typography>
        <div className="mt-2 d-flex flex-row flex-wrap" style={{}}>
          {
            _.map((reviewTags), (tag, index) => (
              <Chip
                key={tag || `option-${index}`}
                variant="outlined"
                label={tag}
                className="mr-2 mb-1"
                style={{ borderColor: Colours.Black, fontSize: 14 }}
                onClick={() => addReviewTag(tag)}
              />
            ))
          }
          {
            IsEmpty(reviewTags) && (
              <Typography
                variant="h6"
                color="inherit"
                style={{ color: Colours.Gray1, fontSize: 16 }}
              >
                -
              </Typography>
            )
          }
        </div>
      </div>

      <div className="mt-3 col-12 d-flex flex-column">
        <Typography
          className="mb-2"
          variant="h6"
          color="inherit"
          style={{ color: Colours.Gray1, fontWeight: 'bold', fontSize: 16 }}
        >
          List of tags for this Custom Location / Place
        </Typography>
        <Tagging
          data={currentTags}
          onChange={(tags) => {
            setCurrentTags(tags);
            props.onTagsChange(tags);
          }}
          limitTags={-1}
          halal={props.halal}
          persistTag={_.toLower(props.locationName) || null}
        />
      </div>
    </div>
  );
};

ManagePlaceAndLocationTags.propTypes = {
  existingTags: PropTypes.arrayOf(PropTypes.string),
  reviewTags:   PropTypes.arrayOf(PropTypes.string),
  onTagsChange: PropTypes.func,
  halal:        PropTypes.string,
  locationName: PropTypes.string,
};

ManagePlaceAndLocationTags.defaultProps = {
  existingTags: [],
  reviewTags:   [],
  onTagsChange: () => {},
  halal:        '',
  locationName: '',
};
export default ManagePlaceAndLocationTags;
