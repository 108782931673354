import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, FormControlLabel, Typography } from '@material-ui/core';

const RadioBtnSelect = (props) => (
  <RadioGroup
    row
    className={props.className}
    value={props.value}
    onChange={(e) => props.onChange(e.target.value)}
  >
    {
      _.map(props.options, (option) => (
        <FormControlLabel
          key={option.label || option.value}
          className="mr-5"
          value={option.value || ''}
          disabled={props.disabled}
          control={<Radio color={option.color || props.defaultColor} />}
          label={(
            <Typography style={{ fontSize: 14 }}>
              {option.label || option.value}
            </Typography>
          )}
        />
      ))
    }
  </RadioGroup>
);

RadioBtnSelect.propTypes = {
  className: PropTypes.string,
  disabled:  PropTypes.bool,

  options: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.PropTypes.string,
  })),

  defaultColor: PropTypes.oneOf([
    'secondary', 'primary', 'default',
  ]),

  onChange: PropTypes.func,
  value:    PropTypes.string, // must provide as a string value, so null input must be converted to a string
};

RadioBtnSelect.defaultProps = {
  className:    '',
  disabled:     false,
  options:      [],
  defaultColor: 'secondary',
  onChange:     () => {},
  value:        null,
};

export default RadioBtnSelect;
