import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Analytics from './Analytics/Analytics';
import CouponsVouchers from './CouponsVouchers/CouponsVouchers';
import GeographicBoundary from './GeographicBoundary';
import Page from './Page/Page';
import Post from './Post/Post';
import PostsListing from './Post/Listing/PostsListing';
import FoodReviewHighlight from './PageHighlight/FoodReview';
import GenericHighlight from './PageHighlight/Generic';
import Gift from './CouponsVouchers/List/Gift/Gift';
import PageHighlightListing from './PageHighlight/Listing';
import PageHighlight from './PageHighlight/PageHighlight';
import PageNotFound from '../../components/PageNotFound';
import Constants from '../../common/constants';
import Profile from './Profile/Profile';
import OutletsListing from './Outlets/OutletsListing';
import Access from './Access/Access';
import Outlet from './Outlets/Outlet/Outlet';
import Account from './Account/Account';
import Business from './Business';
import BusinessProfile from './BusinessProfile';
import CreatePageClaim from './PageClaim/CreatePageClaim';
import Support from './Support';
import Invitation from './Invitation';
import PageClaimView from './PageClaim/PageClaimView';
import AccessDenied from '../../components/AccessDeniedComp';
import { IsAdminOrContributor, IsAdminOnly } from '../../common/checks';
import Review from './Review/Review';
import ReviewsListing from './Review/Listing/ReviewsListing';
import Locations from './Review/Locations/Locations';
import LoyaltyProgram from './LoyaltyProgram/LoyaltyProgram';
import LoyaltyCards from './LoyaltyProgram/LoyaltyCards';
import ParticipateOutlets from './LoyaltyProgram/ParticipateOutlets';

const BusinessRoutes = ({ match, history, page }) => (
  <Switch>
    {/** When page is selected, default we show Posts Listing */}
    <Route
      exact
      path={Constants.Url.Page}
      render={() => <Redirect to={Constants.Url.Posts.replace(':pageId', match.params.pageId)} />}
    />
    <Route exact path={Constants.Url.Business} render={() => <Business />} />
    <Route
      exact
      path={Constants.Url.Access}
      render={() => {
        if (IsAdminOnly(page.staffType)) {
          return <Access />;
        } else {
          return <AccessDenied />;
        }
      }}
    />
    <Route
      exact
      path={Constants.Url.Account}
      render={() => {
        if (IsAdminOrContributor(page.staffType)) {
          return <Account />;
        } else {
          return <AccessDenied />;
        }
      }}
    />
    <Route exact path={Constants.Url.Analytics} render={() => <Analytics />} />
    {/* <Route exact path={Constants.Url.Analytic} render={() => <PostAnalytics history={history} />} /> */}
    <Route exact path={Constants.Url.BusinessProfile} component={BusinessProfile} />
    <Route exact path={Constants.Url.CouponsAndVouchers} component={CouponsVouchers} />
    <Route exact path={Constants.Url.CreatePageClaim} render={() => <CreatePageClaim />} />
    <Route exact path={Constants.Url.Invitation} render={() => <Invitation />} />
    <Route exact path={Constants.Url.GeographicBoundary} component={GeographicBoundary} />
    <Route exact path={Constants.Url.Outlets} component={OutletsListing} />
    <Route exact path={Constants.Url.Outlet} component={Outlet} history={history} />
    <Route exact path={Constants.Url.PageClaimView} component={PageClaimView} />
    <Route exact path={Constants.Url.PageInfo} component={Page} />
    <Route exact path={Constants.Url.PlaceLocation} component={Locations} />
    <Route exact path={Constants.Url.Post} component={Post} />
    <Route exact path={Constants.Url.Posts} component={PostsListing} history={history} />
    <Route exact path={Constants.Url.FoodReviewHighlight} component={FoodReviewHighlight} />
    <Route exact path={Constants.Url.GenericHighlight} component={GenericHighlight} />
    <Route exact path={Constants.Url.Gift} component={Gift} />
    <Route exact path={Constants.Url.PageHighlightListing} component={PageHighlightListing} />
    <Route path={Constants.Url.PageHighlight} component={PageHighlight} />
    <Route exact path={Constants.Url.Review} component={Review} />
    <Route exact path={Constants.Url.Reviews} component={ReviewsListing} history={history} />
    <Route exact path={Constants.Url.Profile} render={() => <Profile />} />
    <Route exact path={Constants.Url.Support} component={Support} />
    <Route exact path={Constants.Url.LoyaltyCards} component={LoyaltyCards} />
    <Route exact path={Constants.Url.LoyaltyCard} component={LoyaltyProgram} />
    <Route exact path={Constants.Url.StampSettings} component={LoyaltyProgram} />
    <Route exact path={Constants.Url.ParticipateOutlets} component={LoyaltyProgram} />

    <Route path="/*" render={(props) => <PageNotFound {...props} />} />
  </Switch>
);

BusinessRoutes.propTypes = {
  match: PropTypes.shape({
    path:   PropTypes.string.isRequired,
    params: PropTypes.shape({
      businessId: PropTypes.string,
      pageId:     PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape(Object).isRequired,
  page:    PropTypes.shape({
    staffType: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

export default connect(mapStateToProps)(BusinessRoutes);
