import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';

import Colours from '../styles/colours';
import Api from '../services/apiService';
import Constants, { Error } from '../common/constants';
import authAction from '../actions/auth';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:     false,
      email:       '',
      password:    '',
      redirectUrl: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async onFormSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const { email, password } = this.state;
      const { token } = await Api.login({ email, password });
      this.props.dispatchLogin(token);

      this.setState({ redirectUrl: Constants.Url.Main });
    } catch (err) {
      this.setState({ loading: false });
      // If email is not verified, redirect to the email verification sent site
      if (err.message === Error.EmailNotVerified) {
        this.setState({ redirectUrl: `${Constants.Url.UserVerificationSent}?email=${this.state.email}` });
      } else {
        toast.error(err.message);
      }
    }
  }

  render() {
    return this.state.redirectUrl
      ? <Redirect to={this.state.redirectUrl} />
      : (
        <>
          {
            this.state.loading && (
              <div
                className="d-flex"
                style={{
                  position:        'fixed',
                  zIndex:          1,
                  top:             0,
                  left:            0,
                  width:           '100%',
                  height:          '100vh',
                  justifyContent:  'center',
                  alignItems:      'center',
                  backgroundColor: 'rgba(255,255,255,0.7)',
                  backdropFilter:  'blur(10px)',
                }}
              >
                <h1 style={{ color: Colours.Black }}>Loading...</h1>
              </div>
            )
          }

          <div className="container-fluid" style={{ background: '#FFFFFF' }}>
            <div className="container pb-4" style={{ paddingTop: '2%' }}>
              <div className="row">
                <div className="col-sm-6 col-md-5 col-sm-push-7 order-sm-1 ">
                  <div className="row rounded mt-0 mt-sm-5 py-2 py-sm-5 px-3 px-lg-5" style={{ flexDirection: 'column', alignItems: 'center', background: '#fafafa', padding: '3rem 2.3rem' }}>
                    <Typography variant="inherit" align="center" style={{ fontSize: '1.3em', lineHeight: '1.1em' }}>
                      <span style={{ fontWeight: 'bold' }}>Monstyr</span>
                      {' '}
                      Business Login
                    </Typography>

                    <form
                      className="form mt-3 mt-sm-4"
                      onSubmit={(e) => this.onFormSubmit(e)}
                      autoComplete="off"
                      style={{ width: '100%', position: 'relative' }}
                    >
                      <div className="form-group mb-0">
                        <Typography className="ml-2" variant="inherit" color="inherit" style={{ fontSize: '1em', color: Colours.Black }}>Email</Typography>
                        <TextField
                          className="mt-0 mt-sm-1 textfield-rounded"
                          name="email"
                          value={this.state.email}
                          onChange={(e) => { this.onChange(e); }}
                          required
                          type="email"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </div>
                      <div className="form-group mt-2 mt-sm-3 mb-0">
                        <Typography className="ml-2" variant="inherit" color="inherit" style={{ fontSize: '1em', color: Colours.Black }}>Password</Typography>
                        <TextField
                          className="mt-0 mt-sm-1 textfield-rounded"
                          name="password"
                          value={this.state.password}
                          onChange={(e) => { this.onChange(e); }}
                          required
                          type="password"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </div>

                      <div className="form-group mt-3 mt-sm-5 mb-0">
                        <Button
                          type="submit"
                          className="block btn-rounded"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          <Typography variant="subtitle1">Login</Typography>
                        </Button>
                      </div>
                    </form>

                    <div className="mt-3 d-flex flex-column">
                      <Button>
                        <Link to={Constants.Url.ForgetPassword}>
                          <Typography variant="inherit" color="inherit" style={{ fontSize: '0.8em', color: Colours.Gray }}>Forget password</Typography>
                        </Link>
                      </Button>

                      <div className="mt-2 d-flex flex-row align-items-center justify-content-center">
                        <Typography variant="inherit" color="inherit" style={{ fontSize: '0.8em', color: Colours.Gray }}>
                          Don&lsquo;t have an account?
                          {' '}
                        </Typography>
                        <Button className="my-0 py-0">
                          <Link to={Constants.Url.Signup}>
                            <strong style={{ color: Colours.Blue, fontSize: '1.0em', fontWeight: 'bold' }}><u>Sign up</u></strong>
                          </Link>
                        </Button>
                      </div>

                      <div className="block mt-0 mt-sm-1">
                        <a href={Constants.Url.External.Website} rel="noreferrer" target="_blank">
                          <Typography variant="inherit" color="inherit" style={{ fontSize: '0.8em', color: Colours.Gray }}>
                            Visit
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>Monstyr</span>
                            {' '}
                            <u>Homepage</u>
                          </Typography>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 col-md-7 pr-md-5 px-0 pt-3 pt-sm-5 pt-md-0 col-sm-push-5">
                  <img src={require('../assets/images/free-ads.png')} alt="Free Ads" className="d-block mx-auto w-100" style={{ marginTop: 3 }} />

                  <div className="mt-4 mt-md-3">
                    <p>
                      We&lsquo;re a start up and we know that doing business is not easy. That&lsquo;s why
                      {' '}
                      <strong>Monstyr</strong>
                      {' '}
                      would like to help all the businesses out there that need a little awareness, with free ads.
                      <br />
                      <br />

                      Simply sign up for an account and you&lsquo;re on your way to getting noticed by more people.
                      <br />
                      <br />

                      Are you a new business? Or did you run promotions but didn&lsquo;t manage to bring in any/much incremental sales? Awareness is key, and that&lsquo;s where
                      {' '}
                      <strong>Monstyr</strong>
                      {' '}
                      can help.
                      <br />
                      <br />

                      <strong>Monstyr</strong>
                      {' '}
                      is making it really effortless for consumers to discover all the promotions that are going on around them, literally. They can easily view on
                      {' '}
                      <strong>Monstyr&lsquo;s</strong>
                      {' '}
                      map to find your business or deals that you&lsquo;ve put up, whatever the time, wherever they are. And they can find more information and get directions to your shop, etc. with just a tap of a button. We will also be coming up with exciting marketing tools to help you improve your business further.
                      <br />
                      <br />

                      If you have not tried the app yet, download it and find out for yourself how your business can benefit from being on
                      {' '}
                      <strong>Monstyr</strong>
                      :
                    </p>
                  </div>

                  <div className="my-5">
                    <div className="container px-0">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex flex-row align-items-center mb-3">
                            <div className="mr-3 text-left text-md-right" style={{ width: 80 }}><strong>Android</strong></div>
                            <div>
                              <a
                                href={process.env.REACT_APP_ANDROID_PLAY_STORE}
                                target="_blank"
                                rel="noreferrer"
                                className="d-block d-sm-block d-md-none"
                              >
                                <img src={require('../assets/images/google-play-btn.png')} alt="android" style={{ width: '100%', maxWidth: 150 }} />
                              </a>
                              <img src={require('../assets/images/google-play-qrcode.png')} alt="android" className="d-none d-md-block d-lg-block" style={{ width: '100%', minWidth: 100, maxWidth: 200 }} />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="d-flex flex-row align-items-center">
                            <div className="mr-3 text-left text-md-right" style={{ width: 80 }}><strong>iOS</strong></div>
                            <div>
                              <a
                                href={process.env.REACT_APP_IOS_APP_STORE}
                                target="_blank"
                                rel="noreferrer"
                                className="d-block d-sm-block d-md-none"
                              >
                                <img src={require('../assets/images/appstore-btn.png')} alt="iOS" style={{ width: '100%', maxWidth: 150 }} />
                              </a>
                              <img src={require('../assets/images/appstore-qrcode.png')} alt="iOS" className="d-none d-md-block d-lg-block" style={{ width: '100%', minWidth: 100, maxWidth: 200 }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </>
      );
  }
}

Login.propTypes = {
  dispatchLogin: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (token) => authAction.login(dispatch, token),
});

export default connect(
  null,
  mapDispatchToProps,
)(Login);
