import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Chip, TextField, Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import postStyle from '../../../../styles/post';
import postAction from '../../../../actions/post';
import { IsEmpty, Trim } from '../../../../common/util';
import ApiService from '../../../../services/apiService';
import MealsAvailableTag from './MealsAvailableTags';
import { PageType } from '../../../../common/constants';

const Tags = (props) => {
  const [userTags, setUserTags] = useState([]);
  const [suggest, setSuggest] = useState([]);

  useEffect(() => {
    if (props.post) {
      setUserTags(props.post.userTags || []);
    }
  }, [props.post]);

  const onInputChanged = async (value) => {
    try {
      // search only for approved tags
      value = Trim(value);
      if (!IsEmpty(value)) {
        const { rows } = await ApiService.getTags({ name: value, pageNo: 0, pageSize: 10 });
        const tags = _.map(rows, (r) => r.name);
        setSuggest(tags);
      } else {
        setSuggest([]);
      }
    } catch (error) {
      console.log('Error getting suggestions: ', error);
      setSuggest([]);
    }
  };

  const inputDebounced = _.debounce(onInputChanged, 500);

  const addInputAsUserTag = (value) => {
    // if value is already an existing tag, don't need to add it
    try {
      value = Trim(value);
      if (!IsEmpty(value) && !props.post.userTags.includes(value)) {
        props.dispatchPostUpdate({ userTags: props.post.userTags.concat(value) });
      }
    } catch (error) {
      console.log('Error in addInputAsUserTag(): ', error);
    }
  };

  return (
    <>
      {
        [PageType.FNB, PageType.Retailer, PageType.ECommerce].includes(props.page.type)
          ? <MealsAvailableTag disabled={props.disabled} disabledMsg={props.disabledMsg} tags={props.post.userTags} />
          : <hr className="my-4" />
      }

      <Typography variant="subtitle1" color="inherit" className="mt-4 mb-2" style={postStyle.textHeader}>
        Tags
      </Typography>

      <Autocomplete
        disabled={props.disabled}
        id="tags"
        className="mb-5"
        limitTags={5}
        multiple
        options={suggest}
        value={userTags}
        disableCloseOnSelect
        filterSelectedOptions
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            variant="outlined"
            label={option}
            style={postStyle.chip}
          />
        ))}
        renderInput={(params) => (
          <Tooltip
            title={props.disabled ? props.disabledMsg : ''}
            isLink
          >
            <TextField
              {...params}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => inputDebounced(e.target.value)}
              placeholder="Type and hit 'Enter' to add tag"
              onBlur={() => addInputAsUserTag(params.inputProps.value)}
            />
          </Tooltip>
        )}
        onChange={(event, values, reason) => {
          if ((reason === 'remove-option' && userTags.includes(_.toLower(props.post.brand)) && !values.includes(_.toLower(props.post.brand)))) {
            toast.warning('Brand tag cannot be deleted');
          } else if (reason === 'clear' && userTags.includes(_.toLower(props.post.brand))) {
            props.dispatchPostUpdate({ userTags: [_.toLower(props.post.brand)] });
          } else {
            // values will contain the distinct and updated array
            props.dispatchPostUpdate({ userTags: values });
          }
        }}
      />
      <hr className="my-4" />
    </>
  );
};

Tags.defaultProps = {
  disabled:    false,
  disabledMsg: 'Cannot be updated',
};

Tags.propTypes = {
  disabled:    PropTypes.bool,
  disabledMsg: PropTypes.string,
  post:        PropTypes.shape({
    userTags: PropTypes.arrayOf(PropTypes.string),
    brand:    PropTypes.string,
  }).isRequired,
  page: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  dispatchPostUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => postAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
