// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

// Internal Dependencies
import Loading from '../../../components/Loading';
import SearchCard from '../../../components/SearchCard';
import SearchBar from '../../../components/SearchBar';

function PostsDialogView({
  loading, allPostCount, totalCount, pageNo, posts, postsUpdated, openAdd, onAdd, handleClose, setPageNo, setBrandSearch, isPinLabel,
}) {
  return (
    <Dialog fullWidth maxWidth="lg" open={openAdd} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" style={{ padding: '20px 50px 0' }}>
        <SearchBar
          onSearch={(postBrand) => {
            setPageNo(1);
            setBrandSearch(postBrand);
          }}
          label="Search by Post Brand"
          style={{ width: '100%' }}
        />
      </DialogTitle>
      <DialogContent style={{ height: '80vh', paddingLeft: 50, paddingRight: 50 }}>
        { loading ? <Loading /> : (
          <div className="container">
            <div className="row">
              <SearchCard data={postsUpdated} lastIndex={posts.length} onAdd={(data) => onAdd(data)} isPinLabel={isPinLabel} />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions className="pt-5 pb-2 pr-5">
        <div className="d-block" style={{ width: '100%' }}>
          <div className="float-left">
            {totalCount > 0 && (
              <Pagination
                color="secondary"
                size="large"
                count={totalCount}
                page={pageNo}
                onChange={(event, value) => {
                  setPageNo(value);
                }}
                className="float-left"
              />
            )}
            <p className="d-inline-block" style={{ margin: '10px 0 0 30px', fontSize: 17 }}>
              Total count:
              {' '}
              {allPostCount}
            </p>
          </div>

          <Button onClick={handleClose} variant="contained" color="primary" className="float-right">Close</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default PostsDialogView;

PostsDialogView.propTypes = {
  loading:      PropTypes.bool.isRequired,
  allPostCount: PropTypes.number.isRequired,
  totalCount:   PropTypes.number.isRequired,
  pageNo:       PropTypes.number.isRequired,
  posts:        PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  postsUpdated: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  isPinLabel:   PropTypes.bool.isRequired,

  openAdd:        PropTypes.func.isRequired,
  onAdd:          PropTypes.func.isRequired,
  handleClose:    PropTypes.func.isRequired,
  setPageNo:      PropTypes.func.isRequired,
  setBrandSearch: PropTypes.func.isRequired,
};
