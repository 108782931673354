import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Button, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import TextInputMU from '../../../components/Common/form/TextInputMU';
import Colours from '../../../styles/colours';
import { ProfileDetailsSchema } from '../../../common/validationSchemas';
import { WindowSize } from '../../../common/constants';
import ApiService from '../../../services/apiService';
import { Trim } from '../../../common/util';

const ProfileDetailsForm = (props) => {
  const { userData } = props;
  return (
    <Formik
      enableReinitialize
      initialValues={userData}
      validationSchema={ProfileDetailsSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await ApiService.updateUserInfo(values).catch((error) => {
          toast.error(error.message);
        }).then((res) => {
          if (res) {
            toast.success('Your profile is updated');
            setSubmitting(false);
          }
        });
      }}
    >
      {({ isSubmitting, dirty, errors }) => (
        <Form>
          <Paper className="p-2 my-2">
            <div className="p-3">
              <label>Email</label>
              <Field
                component={TextInputMU}
                type="text"
                name="email"
                style={{
                  width: '100%',
                }}
                value
                disabled
              />
            </div>
          </Paper>
          <Paper className="p-2 mt-4 mb-2">
            <div className="p-3">
              <label>Name</label>
              <Field
                component={TextInputMU}
                name="name"
                type="text"
                style={{
                  width: '100%',
                }}
                onChange={(e) => {
                  if (Trim(e.target.value) === '') {
                    e.target.value = Trim(e.target.value);
                  }
                }}
              />
              <ErrorMessage
                name="name"
                render={(msg) => (
                  <span style={{
                    color:    'red',
                    fontSize: '0.7rem',
                  }}
                  >
                    {msg}
                  </span>
                )}
              />
            </div>
            <div
              className="p-2 my-2"
              style={{
                display:        'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                className="px-5"
                variant="contained"
                color="secondary"
                type="submit"
                disabled={isSubmitting}
                style={{
                  color: Colours.White,
                  width: (props.windowSize === WindowSize.Mobile) && '100%',
                }}
              >
                {isSubmitting ? 'Please Wait' : 'Update'}
              </Button>
            </div>
          </Paper>
        </Form>
      ) }
    </Formik>
  );
};
ProfileDetailsForm.propTypes = {
  windowSize: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(ProfileDetailsForm);
