import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import layoutAction from '../../actions/layout';

const sectionFontStyle = {
  fontSize:     '1em',
  marginBottom: '10px',
};

class BizSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:  false,
      redirect: false,
    };
  }

  componentDidMount() {
  }

  onLinkClicked(type) {
    this.props.dispatchSideBarHide();
    console.log(`${type} clicked`);
  }

  render() {
    return (
      <div
        className="d-flex"
        style={{
          ...this.props.style,
          padding:        '15px',
          flexDirection:  'column',
          justifyContent: 'flex-start',
          alignItems:     'flex-start',
        }}
      >
        <div style={{ marginTop: '10px', marginBottom: '20px' }}>
          <h5>{this.props.businessSelected.name}</h5>
        </div>

        <Link to={`/business/${this.props.match.params.businessId}/pages`} onClick={() => this.onLinkClicked()} className="menu-item">
          {/* <span><Icon fontSize="small">filter_none</Icon></span> */}
          Pages
        </Link>

        <Link to={`/business/${this.props.match.params.businessId}/staff`} onClick={() => this.onLinkClicked()} className="menu-item">
          {/* <span><Icon fontSize="small">dashboard</Icon></span> */}
          Staff
        </Link>

        <Link to={`/business/${this.props.match.params.businessId}/billing`} onClick={() => this.onLinkClicked()} className="menu-item">
          {/* <span><Icon fontSize="small">filter_none</Icon></span> */}
          Billing
        </Link>

      </div>
    );
  }
}

BizSideBar.propTypes = {
  businessSelected: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,

  dispatchSideBarHide: PropTypes.func.isRequired,

  style: PropTypes.shape({}),
  match: PropTypes.shape({
    params: PropTypes.shape({
      businessId: PropTypes.string,
    }),
  }).isRequired,
};

BizSideBar.defaultProps = {
  style: [],
};

const mapStateToProps = (state) => ({
  businessSelected: state.business.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSideBarHide: () => layoutAction.sideBarHide(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BizSideBar);
