import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import viewAction from '../../../actions/view';
import Colours from '../../../styles/colours';
import apiService from '../../../services/apiService';
import Constants from '../../../common/constants';
import { IsAdminOrContributor } from '../../../common/checks';
import AlertModal from '../../../components/Common/AlertModal';
import { containerHeaderTextStyle } from '../../../styles/common';
import Tooltip from '../../../components/Common/ToolTip';
import MPullToRefresh from '../../../components/MPullToRefresh';

const OutletsListing = ({ history, page }) => {
  const tableRef = useRef();
  const [name, setName] = useState('');
  const [outletId, setOutletId] = useState(null);
  const [open, setOpen] = useState(false);
  const [outlets, setOutlets] = useState([]);

  const getOutlets = async () => {
    try {
      const data = await apiService.getOutlets(page.id);
      if (data && _.isArray(data)) {
        const outletsData = _.orderBy(data, ['createdAt'], 'desc');
      setOutlets(outletsData);
      }
    } catch (err) {
      toast.error(err.message);
      setOutlets([]);
    }
  };

  useEffect(() => {
    getOutlets();
  }, [page]);

  const deleteOutlet = async () => {
    try {
      await apiService.deleteOutlet({ outletId, lastUpdatedBy: '' });

      const updatedOutlets = outlets.slice();
      _.remove(updatedOutlets, { id: outletId });
      setOutlets(updatedOutlets);
      setOpen(false);

      toast.success('Outlet deleted');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePullRefresh = async () => {
    await getOutlets();
    tableRef.current.onChangePage(null, 0);
  };

  return (
    <div className="container-fluid">
      <div className="mt-4 mx-4 d-flex flex-row justify-content-start">
        <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Outlets</Typography>
        <Tooltip title={IsAdminOrContributor(page.staffType) ? 'Click to add outlet' : 'Permission Denied'}>
          <Button
            variant="contained"
            href=""
            className="ml-3 mb-3"
            color="primary"
            disabled={!IsAdminOrContributor(page.staffType)}
          >
            <Link
              style={{  color: Colours.White  }}
              to={`${Constants.Url.Outlet.replace(':pageId', page.id)}?view=info&action=add&outletId=${undefined}`}
            >
              + Add Outlet
            </Link>
          </Button>
        </Tooltip>
      </div>
      <MPullToRefresh onRefresh={handlePullRefresh}>
        <div className="p-2">
          {
            outlets.length > 0 ? (
              <MaterialTable
                tableRef={tableRef}
                title=""
                columns={[
                  {
                    title:     'ID',
                    field:     'id',
                    type:      'numeric',
                    cellStyle: {
                      width: '5%',
                    },
                  },
                  { title: 'Name', field: 'name' },
                  { title: 'Address', field: 'address' },
                  { title: 'Postal Code', field: 'postalCode' },
                  { title: 'Located', field: 'locatedIn' },
                  { title: 'Unit', field: 'unitNo' },
                ]}
                data={outlets || []}
                actions={[
                  {
                    icon:     'edit',
                    tooltip:  'Edit',
                    disabled: !IsAdminOrContributor(page.staffType),
                    onClick:  (event, rowData) => {
                    history.push(`${Constants.Url.Outlet.replace(':pageId', page.id)}?view=info&action=edit&outletId=${rowData.id}`);
                    },
                  },
                  {
                    icon:     'delete',
                    tooltip:  'Delete',
                    disabled: !IsAdminOrContributor(page.staffType),
                    onClick:  (event, rowData) => {
                    // setOpen trigger for opening modal
                    setName(rowData.name);
                    setOutletId(rowData.id);
                    setOpen(true);
                    },
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize:           10,
                  pageSizeOptions:    [10, 20, 50],
                }}
              />
            ) : (
              <Tooltip title="Click to add outlet">
                <Button
                  disabled={!IsAdminOrContributor(page.staffType)}
                >
                  <Link
                    style={{ color: Colours.White }}
                    to={`${Constants.Url.Outlet.replace(':pageId', page.id)}?view=info&action=add&outletId=${undefined}`}
                  >
                    <div className="d-flex flex-column align-items-center py-5" style={{ backgroundColor: Colours.White }}>
                      <img
                        src={require('../../../assets/images/outlet-on-map.png')}
                        width="40%"
                        alt="outlet"
                      />
                      <Typography variant="h6" color="inherit" style={{ color: Colours.Gray1, textDecoration: 'underline' }}>+ Add an outlet</Typography>
                    </div>
                  </Link>
                </Button>
              </Tooltip>
            )
          }

          <AlertModal
            open={open}
            setOpen={setOpen}
            action={{
              name: 'Delete',
              func: deleteOutlet,
            }}
            alert={{
              title:              'Confirm Delete',
              description:        `Some existing references (if any) to this outlet, e.g. reviews on the outlet, will be deleted if you decide to proceed.`,
              newLineDescription: `Delete outlet '${name}' with ID=${outletId}?`,
            }}
          />
        </div>
      </MPullToRefresh>
    </div>
  );
};

OutletsListing.propTypes = {
  page: PropTypes.shape({
    id:        PropTypes.number.isRequired,
    name:      PropTypes.string,
    staffType: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchViewChanged: (name) => viewAction.viewChanged(dispatch, name),
});

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

export default connect(mapStateToProps, mapDispatchToProps)(OutletsListing);
