import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
}
  from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Loading from '../../../../components/Loading';
import apiService from '../../../../services/apiService';
import { IsEmpty, IsAdminOrContributor } from '../../../../common/checks';
import { formatDatetoString, datePeriodToString, showDateInSGTime } from '../../../../common/util';
import Colours from '../../../../styles/colours';
import Constants, {  Status } from '../../../../common/constants';
import Tooltip from '../../../../components/Common/ToolTip';
import Icons from '../../../../components/Common/Icons';
import { containerHeaderTextStyle } from '../../../../styles/common';
import ReviewAction from '../../../../actions/review';
import FilterReview from '../FilterReview';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding:  theme.spacing(2),
    margin:   'auto',
    maxWidth: '100%',
    flexGrow: 1,
  },
  image: {
    width:  '100%',
    height: 'fit-content',
  },
  img: {
    margin:    'auto',
    display:   'block',
    maxWidth:  '100%',
    maxHeight: '100%',
  },
}));

const ReviewsListing = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [reviews, setReviews] = useState([]);

  const [totalPages, setTotalPages] = useState([]);
  const [totalReviewsCount, setTotalReviewCount] = useState(0);

  const [filterStatus, setFilterStatus] = useState('');

  const classes = useStyles();

  const getPageReviews = () => {
    setPageSize(5);
    setIsLoading(true);

    apiService.getReviews(props.page.id, { pageId: props.page.id, pageNo: pageNo - 1, pageSize, status: filterStatus })
      .then(({ rows, count }) => {
        setReviews(rows);

        // Check if whole number. returns true otherwise false.
        if (Number.isInteger(count / pageSize)) {
          setTotalPages(Math.floor(count / pageSize));
        } else {
          // we add +1 from the total count for the remainder.
          setTotalPages(Math.floor(count / pageSize) + 1);
        }

        setTotalReviewCount(count);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const updateStatusFilter = (data) => setFilterStatus(data);

  useEffect(() => {
    getPageReviews();
  }, [pageNo, filterStatus]);

  const editReview = (review) => {
    props.dispatchReviewSelected(review);
    props.history.push(Constants.Url.Review.replace(':pageId', props.match.params.pageId).replace(':reviewId', review.id));
  };

  const deleteReview = async () => {
    try {
      if (!IsEmpty(reviewToDelete)) {
        await apiService.deleteReview(reviewToDelete.id, { pageId: reviewToDelete.pageId });
        getPageReviews();
        props.dispatchReviewUpdate({ status: Status.Deleted });
        toast.success('Review deleted');
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const handleChange = (event, value) => {
    setPageNo(value);
  };

  return (
    <>
      {isLoading && <Loading />}

      <div className="container-fluid">
        <div className="mt-4 mx-4 d-flex flex-row justify-content-start">
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Review Listing</Typography>
          <Tooltip title={IsAdminOrContributor(props.page.staffType) ? 'Create a new Review' : 'Permission Denied'}>
            <Button
              variant="contained"
              color="primary"
              className="ml-3 mb-3"
              disableElevation
              disabled={!IsAdminOrContributor(props.page.staffType)}
              onClick={() => {
                  props.addReview();
              }}
            >
              + Create Review
            </Button>
          </Tooltip>
        </div>
        <div className="mx-4">
          <FilterReview
            updateStatusFilter={updateStatusFilter}
          />
        </div>

        { !IsEmpty(reviews) ? (
          <div className="container-fluid px-0 mx-0">
            <div className="row">
              <List className="col-lg-12 col-12 px-2" style={{ marginBottom: 120 }}>
                {
                  reviews.map((r) => (
                    <ListItem key={r.id} style={{ flex: 1, width: '100%' }}>
                      <Paper className={classes.paper} style={{ background: r.status === Status.Deleted ? Colours.Gray4 : Colours.White, borderWidth: 1, borderColor: Colours.Gray4, borderStyle: 'solid' }}>
                        <Grid container spacing={2} direction="row">
                          <Grid item container lg={2} md={2} className={classes.image}>
                            <div
                              className="d-flex align-items-center"
                              style={{ position: 'relative', width: '100%', height: 'auto' }}
                            >
                              {
                                !IsEmpty(r.videos)
                                  ? (
                                    <>
                                      <ReactPlayer
                                        url={r.videos[0]}
                                        muted
                                        playing
                                        loop
                                        width="100%"
                                        height="auto"
                                      />
                                    </>
                                  )
                                  : (
                                    <>
                                      {
                                        !IsEmpty(r.images) && (
                                          <img
                                            style={{
                                              width:  200,
                                              height: 'auto',
                                            }}
                                            alt={r.title || 'cover'}
                                            src={r.images[0].display}
                                          />
                                        )
                                      }
                                    </>
                                  )
                              }

                              { IsEmpty(r.videos) && IsEmpty(r.images) && (
                                <div
                                  className="d-flex flex-column align-items-center justify-content-start"
                                  style={{ width: '100%', height: 'auto', maxWidth: 200, minHeight: 100 }}
                                >
                                  <Icons.Impression fontSize={175} colour={Colours.Gray3} />
                                  {/* <img
                                    style={{
                                      width:  200,
                                      height: 'auto',
                                    }}
                                    alt="cover"
                                    // eslint-disable-next-line global-require
                                    src={require(`../../../../assets/images/no-media-placeholder.jpg`)}
                                  /> */}
                                </div>
                              )}

                            </div>
                          </Grid>

                          <Grid item lg={6} md={6} container direction="column" className="pl-2" spacing={0}>
                            <Typography variant="caption" color="inherit" style={{ color: Colours.Gray2 }}>{`ID: ${r.id}`}</Typography>

                            <Typography variant="caption" color="inherit" style={{ color: Colours.Gray2 }}>Title</Typography>
                            <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Black }}>{r.title || '--'}</Typography>

                            {/*<Grid className="mt-2" item container direction="row" alignItems="center">
                              <VisibilityIcon color="inherit" style={{ color: Colours.Gray }} />
                              <Typography variant="subtitle1" className="ml-1">1312</Typography>
                              </Grid>*/}

                            <Typography variant="caption" color="inherit" style={{ color: Colours.Gray2, marginTop: 5 }}>Review Period</Typography>
                            {
                              !IsEmpty(r.reviewDate) && !IsEmpty(r.expireDate)
                                ? (
                                  <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>
                                    {`${datePeriodToString(showDateInSGTime(r.reviewDate), showDateInSGTime(r.expireDate))}`}
                                  </Typography>
                                )
                                : <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>Unspecified</Typography>
                            }

                            <Typography
                              className="mt-4"
                              variant="caption"
                              color="inherit"
                              style={{ color: Colours.Gray2, marginTop: 5 }}
                            >
                              Content
                            </Typography>
                            <Typography variant="subtitle2" color="inherit" style={{ color: Colours.Gray, wordBreak: 'break-word' }}>{r.content || '--'}</Typography>
                          </Grid>

                          <Grid
                            item
                            lg={2}
                            md={2}
                            sm={6}
                            container
                            direction="column"
                            spacing={0}
                            className="pl-2"
                            style={{  borderLeft: `1px solid ${Colours.Gray2}` }}
                          >
                            <Typography variant="caption" color="inherit" style={{ color: Colours.Gray2, marginTop: 5 }}>Status</Typography>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              style={{ color: r.Status === Status.Live ? Colours.Green : r.Status === Status.Pending ? Colours.Amber : Colours.Gray }}
                            >
                              {r.status}
                            </Typography>

                            {[Status.Live, Status.Paused].includes(r.status) && (
                              <>
                                <Typography variant="caption" color="inherit" style={{ color: Colours.Gray2, marginTop: 5 }}>Review Date</Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="inherit"
                                  style={{ color: Colours.Black }}
                                >
                                  {formatDatetoString(r.reviewDate)}
                                </Typography>
                              </>
                            )}

                            <Typography variant="caption" color="inherit" style={{ color: Colours.Gray2, marginTop: 5 }}>Last Updated</Typography>
                            <Typography
                              variant="subtitle1"
                              color="inherit"
                              style={{ color: Colours.Black }}
                            >
                              {formatDatetoString(r.updatedAt)}
                            </Typography>
                          </Grid>

                          <Grid item lg={2} md={2} sm={6} container direction="row" alignItems="center">
                            <Grid item container lg={6} md={6} sm={6} direction="column" alignItems="center">
                              <Tooltip title="View / Edit Review">
                                <Button
                                  className="py-3"
                                  onClick={() => editReview(r)}
                                >
                                  <Icons.Edit fontSize="1.4rem" />
                                </Button>
                              </Tooltip>
                            </Grid>
                            {r.status !== Status.Deleted && (
                              <Grid item container lg={6} md={6} sm={6} direction="column" alignItems="center">
                                <Tooltip title={IsAdminOrContributor(props.page.staffType) ? 'Delete Review' : 'Permission Denied'}>
                                  <Button
                                    className="py-3"
                                    disabled={!IsAdminOrContributor(props.page.staffType)}
                                    onClick={() => {
                                    setOpenDeleteDialog(true);
                                    setReviewToDelete(r);
                                    }}
                                  >
                                    <Icons.DeleteBin fontSize="1.4rem" colour={!IsAdminOrContributor(props.page.staffType) ? Colours.Disabled : Colours.Black} />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Paper>
                    </ListItem>
                  ))
                }
              </List>
            </div>

            <div
              className="position-fixed py-4 d-flex flex-row align-items-center"
              style={{
                background: Colours.White,
                bottom:     0,
                width:      '100%',
              }}
            >
              {totalReviewsCount > 0 && (
                <Pagination
                  color="primary"
                  size="large"
                  count={totalPages}
                  page={pageNo}
                  defaultPage={1}
                  onChange={handleChange}
                  className="float-left"
                />
              )}
              <Typography variant="body2" className="ml-3" color="inherit" style={{ color: Colours.Black }}>
                Total count:
                {' '}
                {totalReviewsCount}
              </Typography>
            </div>
          </div>
        )
          : (
            <div className="container-fluid">
              <div className="row pt-4">
                <Button className="ml-3" onClick={() => { props.addReview(); }}>
                  <Paper className="d-flex flex-row p-1 pr-5 align-items-center" style={{ borderRadius: 10 }}>
                    <img
                      src={require('../../../../assets/images/post.png')}
                      alt="Create a Review"
                      width={200}
                    />
                    <div className="mb-4 d-flex flex-row align-items-center">
                      <Icons.Send colour={Colours.Gray5} fontSize="1.8rem" />
                      <Typography variant="h6" className="ml-2" color="inherit" style={{ color: Colours.Gray, fontWeight: 'normal', textDecoration: 'underline' }}>
                        Click to create a new review
                      </Typography>
                    </div>
                  </Paper>
                </Button>
              </div>
            </div>
          )}

      </div>

      <Dialog
        fullWidth
        size="sm"
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Review</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this review?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-2">
          <Button className="px-2" onClick={() => deleteReview()} color="default">Confirm</Button>
          <Button className="px-2 mx-3" onClick={() => setOpenDeleteDialog(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ReviewsListing.propTypes = {
  dispatchReviewUpdate:   PropTypes.func.isRequired,
  dispatchReviewSelected: PropTypes.func.isRequired,
  addReview:              PropTypes.func.isRequired,
  history:                PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push:   PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      pageId: PropTypes.string.isRequired,
    }),
  }).isRequired,
  page: PropTypes.shape({
    id:        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    staffType: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewSelected: (review) => ReviewAction.reviewSelected(dispatch, review),
  dispatchReviewUpdate:   (review) => ReviewAction.reviewDataUpdate(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsListing);
