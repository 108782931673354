// React Dependencies
import React, { useEffect, useState } from 'react';

// External Dependencies
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, MenuItem, MenuList } from '@material-ui/core';

// Internal Dependencies
import Icons from '../../../components/Common/Icons';
import PageHighlightRouter from './PageHighlightRouter';
import Util from '../../../common/util';
import ApiService from '../../../services/apiService';
import pageAction from '../../../actions/page';
import Colours from '../../../styles/colours';
import { Url } from '../../../common/constants';
import { IsEmpty } from '../../../common/checks';
import { containerHeaderTextStyle } from '../../../styles/common';

const PageHighlight = (props) => {
  const [pageId, setPageId] = useState(null);
  const [pageHighlightId, setPageHighlightId] = useState(null);
  const [isSpecial, setIsSpecial] = useState(false);
  const [isExclusive, setIsExclusive] = useState(false);

  useEffect(() => {
    const query = Util.ExtractParamsFromUrlQueryStr(props.location.search);
    if (!IsEmpty(query)) {
      setIsSpecial(query.isSpecial === 'true' || false);
      setIsExclusive(query.isExclusive === 'true' || false);
    }

    setPageId(props.selectedPage.id || null);
    setPageHighlightId(props.match.params.pageHighlightId || null);
  }, []);

  useEffect(() => {
    const getPageData = async () => {
      const p = await ApiService.getPageById(pageId);
      props.dispatchPageUpdate(p);
    };

    if (!IsEmpty(pageId)) {
      getPageData();
    }
  }, [pageId]);

  return (
    <div className="container-fluid" style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
      <div className="row">
        <div className="col-12 mt-4 mx-1 d-flex flex-row justify-content-start align-items-center">
          <Link to={`${Url.PageHighlights.replace(':pageId', pageId)}${isSpecial ? '?isSpecial=true' : isExclusive ? '?isExclusive=true' : ''}`} title="Back" className="d-flex align-items-center">
            <Icons.ArrowBack fontSize="1.8rem" />
          </Link>

          <Typography className="ml-3" variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>
            {props.selectedPage ? props.selectedPage.name : ''}
            {` / highlight / ${pageHighlightId}`}
          </Typography>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-1 my-3">
          <MenuList>
            <Link to={`${props.match.url}${isSpecial ? '?isSpecial=true' : isExclusive ? '?isExclusive=true' : ''}`}>
              <span>
                <MenuItem>
                  <Typography
                    variant="body2"
                    color="inherit"
                    style={{ color: Colours.Gray }}
                  >
                    Info
                  </Typography>
                </MenuItem>
              </span>
            </Link>

            <Link to={`${Url.PageHighlightPosts.replace(':pageId', pageId).replace(':pageHighlightId', pageHighlightId)}${isSpecial ? '?isSpecial=true' : isExclusive ? '?isExclusive=true' : ''}`}>
              <span>
                <MenuItem>
                  <Typography
                    variant="body2"
                    color="inherit"
                    style={{ color: Colours.Gray }}
                  >
                    Posts
                  </Typography>
                </MenuItem>
              </span>
            </Link>
          </MenuList>
        </div>

        <div className="col-xs-12 col-sm-11">
          <PageHighlightRouter match={props.match} />
        </div>
      </div>
    </div>
  );
};

PageHighlight.defaultProps = {
  match: {},
};

PageHighlight.propTypes = {
  selectedPage: PropTypes.shape({
    id:   PropTypes.number,
    name: PropTypes.string,
  }).isRequired,

  location: PropTypes.shape({
    search:   PropTypes.string,
    pathname: PropTypes.string,
  }).isRequired,

  match: PropTypes.shape(),
};

const mapStateToProps = (state) => ({
  selectedPage: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageUpdate: (page) => pageAction.pageUpdate(dispatch, page),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHighlight);
