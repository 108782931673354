import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Typography, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import { IsEmpty } from '../../../common/checks';
import { Trim } from '../../../common/util';
import useDebounce from '../../../common/use-debounce';
import ApiService from '../../../services/apiService';
import pageClaimStyle from '../../../styles/pageClaim';
import pageClaimAction from '../../../actions/pageClaim';
import Icons from '../../../components/Common/Icons';
import Colours from '../../../styles/colours';
import { Monstyr } from '../../../common/constants';

const Step1 = (props) => {
  const [pageName, setPageName] = useState('');
  const [pageId, setPageId] = useState(null);
  const [existingPages, setExistingPages] = useState([]);
  const debouncedSearch = useDebounce(pageName, 300);

  useEffect(() => {
    if (props.pageClaim) {
      const pc = props.pageClaim;
      setPageName(pc.pageName ? pc.pageName : '');
      setPageId(pc.pageId ? pc.pageId : '');
    }
  }, []);

  const searchPages = async (name) => {
    try {
      if (!IsEmpty(Trim(name))) {
        let pages = await ApiService.getPagesSuggest(name);

        // Prevent `Monstyr` to be claim by biz users.
        pages = pages.filter((d) => d.name !== Monstyr);

        setExistingPages(pages);
      } else {
        setExistingPages([]);
      }
    } catch (error) {
      console.log(error);
      setExistingPages([]);
    }
  };

  useEffect(() => {
    searchPages(pageName);
  }, [debouncedSearch]);

  const onNext = async () => {
    try {
      const name = Trim(pageName);
      // check if the page has been claimed and the claim has been approved
      const approvedPageClaim = await ApiService.getApprovedPageClaimByPageName(name);
      setPageName(name);

      if (IsEmpty(name)) {
        toast.error('Please enter page name');
      } else if (IsEmpty(approvedPageClaim)) {
        // ONLY NOT EXISTING PAGE CLAIM
        let id = pageId;
        if (IsEmpty(id)) {
          // set the pageId if there is a identical match of the name entered with one of the existing pages
          for (const page of existingPages) {
            if (String(page.name).toLowerCase() === name.toLowerCase()) {
              setPageId(page.id);
              id = page.id;
              break;
            }
          }
        }
        if (name !== props.pageClaim.pageName) {
          let pageClaim = {};
          // if there is a switch between selecting an existing to entering a custom name or vis versa,
          // then we need to clear all the previously selected pageClaim data in step 2.
          if ((IsEmpty(id) && !IsEmpty(props.pageClaim.pageId)) || (!IsEmpty(id) && IsEmpty(props.pageClaim.pageId))) {
            pageClaim = {
              pageName: name,
              pageId:   id,
            };
          } else {
            pageClaim = {
              ...props.pageClaim,
              pageName: name,
              pageId:   id,
            };
          }
          props.dispatchPageClaimUpdate({ pageClaim });
        }
        props.onNextClick();
      } else {
        toast.error(`The page '${name}' you're requesting is not available. Please contact support for assistance.`, { autoClose: 8000 });
      }
    } catch (err) {
      console.log('Error: ', err);
      toast.error(err.message);
    }
  };

  return (
    <>
      <Paper style={pageClaimStyle.paper}>
        <div className="d-flex flex-row justify-content-center">
          <img
            src={require('../../../assets/images/select.png')}
            alt="select"
            width="75%"
          />
        </div>
        <div className="d-flex flex-column mb-4">
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Enter page name
            {' '}
            <span style={{ color: 'red' }}>*</span>
          </Typography>

          <Autocomplete
            className="mt-2"
            freeSolo
            options={existingPages}
            defaultValue=""
            value={{ name: pageName }}
            getOptionLabel={(option) => (option.name ? option.name : '')}
            onChange={(e, v) => {
              if (!IsEmpty(v)) {
                setPageName(v.name);
                setPageId(v.id);
              }
            }}
            renderInput={(params) => (
            /* eslint-disable react/jsx-props-no-spreading */
              <TextField
                {...params}
                value={pageName || ''}
                onChange={(e) => {
                  setPageName(e.target.value);
                  setPageId(null);
                }}
                variant="outlined"
                size="small"
                fullWidth
              />
            )}
          />
        </div>
      </Paper>
      <div className="d-flex flex-row justify-content-end my-3">
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => onNext()}
        >
          <div className="d-flex flex-row align-items-center">
            <Typography className="mr-2" variant="body1">Next: Step 2</Typography>

            <Icons.ArrowFoward colour={Colours.White} fontSize="1.5rem" />
          </div>
        </Button>
      </div>
    </>
  );
};

Step1.propTypes = {
  dispatchPageClaimUpdate: PropTypes.func.isRequired,
  pageClaim:               PropTypes.shape().isRequired,
  onNextClick:             PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pageClaim: state.pageClaim.claim.pageClaim,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchPageClaimUpdate: (data) => pageClaimAction.pageClaimUpdate(dispatch, data),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
