// React Dependencies
import React, { Component } from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Chip, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';

// Internal Dependencies
import ApiService from '../../../../../services/apiService';
import SortableMedia from '../../../../../components/Common/SortableMedia/SortableMedia';
import Tooltip from '../../../../../components/Common/ToolTip';
import Icons from '../../../../../components/Common/Icons';
import OverLoading from '../../../../../components/Loading';
import Colours from '../../../../../styles/colours';
import FixedCropper from '../../../Post/PromoMedia/photo/FixedCropper';
import PostMediaLimitModal from '../../../Post/PromoMedia/PostMediaLimitModal';
import { convertDataURLtoBlob } from '../../../../../common/util';
import { IsAdminOrContributor } from '../../../../../common/checks';
import Constants, { AccountMaxImageUpload } from '../../../../../common/constants';

class Photo extends Component {
  constructor(props) {
    super(props);
    this.selectCategoryRef = null;
    this.dropZoneRef = null;
    this.state = {
      planModals:   false,
      accountType:  '',
      maxUpload:    0,
      totalUploads: 0,

      mediaData:         '',
      alert:             false,
      openCropDialog:    false,
      originalFile:      null,
      currentFile:       null,
      src:               null,
      type:              null,
      uploadUrl:         '',
      base64:            '',
      images:            [],
      view:              false,
      viewImage:         '',
      cropping:          false,
      tempImgToDelete:   [],
      isImagePosChanged: false,
      loading:           false,
    };
    this.maxFileSize = Constants.BinaryOneMBSize * 1.5;
    this.imageFormats = ['image/jpeg', 'image/jpg', 'image/png'];

    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getBase64Image = this.getBase64Image.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleCrop = this.handleCrop.bind(this);

    this.hiddenInputFileRef = React.createRef();
  }

  componentDidMount = () => {
    const maxNoUpload = AccountMaxImageUpload[this.props.page.plan];
    this.setState({ accountType: this.props.page.plan, maxUpload: maxNoUpload });

    const fetchGift = async () => {
      try {
        const gift = await ApiService.getGift({ giftId: this.props.giftId, pageId: this.props.page.id });
        if (gift) {
          this.setState({ images: !_.isEmpty(gift.images) ? gift.images : [] });
        }
      } catch (err) {
        console.log('Error in fetchGift: ', err.message);
      }
    };

    fetchGift();
    this.checkUploadCount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.gift !== prevProps.gift) {
      this.checkUploadCount();
    }
    if (this.state.images !== prevState.images) {
      this.props.onUpdateImages(this.state.images);
    }
    // Image position changed
    if (!_.isEqual(this.state.images, prevState.images) && this.state.images.length > 1 && this.state.isImagePosChanged) {
      this.handleUpdate();
    }
  }

  checkUploadCount = () => {
    // checks all the media upload count.
    // const totalPhotoUpload = (this.props.images && this.props.images.length > 0) ? this.props.images.length : 0;
    const totalPhotoUpload = 0;
    this.setState({ totalUploads: (totalPhotoUpload) });
  }

  b64toBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: 'image/jpeg' });
  }

  handleCrop = (image) => {
    const temp = [];
    this.setState({ uploadUrl: image.original, cropping: true }, () => {
      temp.push(image);
      this.setState({ tempImgToDelete: temp }, () => {
        this.handleUploadUrl();
      });
    });
  }

  handleUploadUrl = () => {
    this.getBase64Image(this.state.uploadUrl, (base64image) => {
      this.setState({ openCropDialog: true, base64: base64image, type: 'url', currentFile: null },
        () => {
          const imgFile = this.b64toBlob(`data:image/*;base64,${this.state.base64}`);
          this.setState({ src: `data:image/*;base64,${this.state.base64}`, originalFile: new File([imgFile], 'o.jpeg', { type: 'image/jpeg' }) });
        });
    });
  }

  handleUpload = (files) => {
    if (this.state.totalUploads >= this.state.maxUpload) {
      this.handleOpenPlanModals();
    } else {
      const isOk = this.handleErrorMessage(files);
      if (isOk && files.length > 0) {
        this.setState({ openCropDialog: true, type: 'upload' }, () => {
          if (this.state.openCropDialog) {
            this.setState({ currentFile: files[files.length - 1] }, () => {
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                this.setState({ src: reader.result });
                const originalImageBlob = convertDataURLtoBlob(reader.result);
                this.setState({ originalFile: new File([originalImageBlob], 'o.jpeg', { type: 'image/jpeg' }) });
              });
              reader.readAsDataURL(this.state.currentFile);
            });
          }
        });
      }
    }
  }

  getBase64Image = (imgUrl, callback) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      let dataURL = canvas.toDataURL('image/png');
      dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
      callback(dataURL); // the base64 string
    };

    if (img.complete) {
      img.crossOrigin = Constants.CrossOrigin;
      img.src = imgUrl;
    }
  }

  handleOpenPlanModals = () => {
    this.setState({ planModals: true });
  }

  handleClosePlanModals = () => {
    this.setState({ planModals: false });
  }

  handleInputUpload = () => {
    if (this.state.totalUploads >= this.state.maxUpload) {
      this.handleOpenPlanModals();
    } else {
      this.hiddenInputFileRef.current.click();
    }
  }

  handleSave = async (file) => {
    try {
      if (file) {
        const tempPostImages = this.state.images;

        const formData = new FormData();
        formData.append('image', file);
        formData.append('image', this.state.originalFile);
        formData.append('type', 'images');
        formData.append('pageId', this.props.page.id);

        if (this.state.cropping === true && this.state.tempImgToDelete.length > 0) {
          await this.handleDelete()
            .then(async (res) => {
              if (res && this.state.images.length > 0) {
                /*
                  * CROP MODE
                  * to get the difference from the state images take note that the state changes after it got deleted after upload, check code at Ln 158,
                  * we are getting the index of the element in the array that got omitted, created another temporary array - finalArrayOfImages to hold the current array
                  * then pushed the newly uploaded image (result) to finalArrayOfImages.
                */
                const diff = _.differenceWith(tempPostImages, this.state.images, _.isEqual);
                const index = _.findIndex(tempPostImages, diff[0]);
                const finalArrayOfImages = this.state.images;
                formData.append('imgIndex', index);
                const result = await ApiService.uploadGiftMedia({ pageId: this.props.page.id, giftId: this.props.giftId }, formData);
                finalArrayOfImages.splice(index, 0, result);

                if (result) {
                  const gift = await ApiService.getGift({ giftId: this.props.giftId, pageId: this.props.page.id });
                  if (gift) {
                      this.setState({
                        images:         gift.images,
                        openCropDialog: false,
                        cropping:       false,
                        currentFile:    null,
                        src:            null,
                        type:           null,
                        uploadUrl:      '',
                        base64:         '',
                      });

                      this.handleClose();
                  }
                }
              } else {
                  // if cropping and only single image
                  formData.append('imgIndex', null);
                  const result = await ApiService.uploadGiftMedia({ pageId: this.props.page.id, giftId: this.props.giftId }, formData);
                  if (result) {
                    this.setState({
                      images:         this.state.images.concat(result),
                      openCropDialog: false,
                      cropping:       false,
                      currentFile:    null,
                      src:            null,
                      type:           null,
                      uploadUrl:      '',
                      base64:         '',
                    });

                    this.handleClose();
                  }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
            formData.append('imgIndex', null);
            const result = await ApiService.uploadGiftMedia({ pageId: this.props.page.id, giftId: this.props.giftId }, formData);
            if (result) {
              this.setState({
                images:         this.state.images.concat(result),
                openCropDialog: false,
                cropping:       false,
                currentFile:    null,
                src:            null,
                type:           null,
                uploadUrl:      '',
                base64:         '',
              });

              this.handleClose();
              toast.success('Image uploaded successfully.');
            }
        }
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  }

  handleDelete = async () => {
    try {
      this.setState({ loading: true, alert: false });
      if (this.state.cropping && this.state.tempImgToDelete.length > 0) {
        this.setState({ mediaData: this.state.tempImgToDelete[0] });
      }

      const images = this.state.images.filter((image) => {
        if (image.display) {
          return image.display !== this.state.mediaData.display;
        } else {
          return image.original !== this.state.mediaData.original;
        }
      });

      await ApiService.deleteGiftMedia({
        pageId: this.props.page.id,
        giftId: this.props.giftId,
      }, { pageId: this.props.page.id, mediaData: this.state.mediaData, fileType: Constants.FileType.Image })
        .then((res) => {
          if (res) {
            this.setState({
              images,
              alert: false,
            });

            if (this.state.cropping && this.state.tempImgToDelete.length > 0) {
              toast.success('Image cropped successfully.',  { delay: 800 });
              this.handleClose();
            } else {
              toast.success('Image successfully deleted.',  { delay: 800 });
            }

            this.setState({ loading: false });
          }
        })
        .catch(() => {
          this.setState({ alert: false, loading: false });
          toast.error('An unexpected error has occurred');
        });

      return images;
    } catch (err) {
      toast.error(err.message);
      this.setState({ loading: false });
    }
  }

  handleConfirmation = (mediaData) => {
    this.setState({ alert: true, mediaData });
  }

  handleClose = () => {
    this.setState({
      openCropDialog:  false,
      currentFile:     null,
      alert:           false,
      base64:          '',
      view:            false,
      type:            null,
      uploadUrl:       '',
      cropping:        false,
      tempImgToDelete: [],
    });
  }

  handleSort = (images) => {
    this.setState({ images, isImagePosChanged: true });
  }

  handleView = (image) => {
    this.setState({ view: true, viewImage: image.original });
  }

  handleUpdate = async () => {
    try {
      const pageId = parseInt(this.props.page.id, 10);
      const payload = { ...this.props.gift, images: this.state.images };
      await ApiService.updateGift({ giftId: this.props.giftId, pageId }, payload);

      this.setState({ isImagePosChanged: false });
      toast.success('Image position updated');
    } catch (err) {
      toast.error(err.message);
    }
  }

  handleErrorMessage = (fileImage) => {
    const file = fileImage[0];

    if (file.size > this.maxFileSize) {
      toast.error('Maximum file size of 1.5MB exceeded.');
      return false;
    }
    if (!this.imageFormats.includes(file.type)) {
      toast.error('Only JPG, JPEG and PNG files are supported.');
      return false;
    }

    return true;
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <>
        { this.state.loading && <OverLoading /> }
        <div className="d-flex flex-column">
          <Typography className="d-inline-block" variant="subtitle1" color="inherit" style={{ fontSize: '14px' }}>
            Requirements:
            <ul>
              <li style={{ fontSize: '14px' }}>Min. 700 x 300 px (width x height)</li>
              <li style={{ fontSize: '14px' }}>Max. file size 1.5mb</li>
            </ul>
          </Typography>

          <div>
            <Tooltip title={IsAdminOrContributor(this.props.page.staffType) && !this.props.disabled ? 'Click to upload' : 'Permission Denied'}>
              <Button
                disabled={(!IsAdminOrContributor(this.props.page.staffType)) || (this.props.disabled)}
                variant="contained"
                className="d-inline-block"
                color="primary"
                component="span"
                onClick={() => this.handleInputUpload()}
              >
                + Add Photo
              </Button>
            </Tooltip>
          </div>

          {
            this.state.images.length > 1 && (
              <Typography color="primary" variant="body2" className="d-block mt-3" style={{ marginBottom: 17 }}>
                *Select &amp; hold and image to re-order its position
              </Typography>
            )
          }

          <input
            className="d-none"
            ref={this.hiddenInputFileRef}
            accept="image/jpg,image/jpeg,image/png"
            id="icon-button-file"
            type="file"
            onChange={(e) => this.handleUpload(e.target.files)}
            onClick={(e) => {
              e.target.value = null;
            }}
          />

          {this.state.images.length > 0
            ? (
              <div style={{ position: 'relative' }}>
                <Chip style={{
                  width:         '180px',
                  height:        '170px',
                  verticalAlign: 'bottom',
                  margin:        '-15px 0px -195px 20px',
                }}
                />
                {this.state.images
                  ? (
                    <SortableMedia
                      maxHt={90}
                      minHt={90}
                      maxWd={160}
                      minWd={160}
                      images={this.state.images}
                      stylesActionBtns={{ right: '0', left: '10px' }}
                      handleCrop={this.handleCrop}
                      handleView={this.handleView}
                      handleDelete={(mediaData) => this.handleConfirmation(mediaData)}
                      handleSort={this.handleSort}
                      disabled={!IsAdminOrContributor(this.props.page.staffType) || (this.props.disabled)}
                    />
                  )
                  : ''}
              </div>
            )
            : (
              <div className="py-2">
                <Tooltip title={IsAdminOrContributor(this.props.page.staffType) && !this.props.disabled ? 'Click to upload' : 'Permission Denied'}>
                  <Button
                    className="px-3"
                    style={{ height: 'auto', borderRadius: 10 }}
                    disabled={(!IsAdminOrContributor(this.props.page.staffType)) || (this.props.disabled)}
                    onClick={() => this.handleInputUpload()}
                  >
                    <div className="d-flex flex-column align-items-center">
                      <Icons.Impression fontSize={110} colour={Colours.Gray3} />
                      <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Gray2 }}>Upload Photo</Typography>
                    </div>
                  </Button>
                </Tooltip>
              </div>
            )}

          {(this.props.showSaveImgPosBtn && this.state.images.length > 0 && this.state.isImagePosChanged) && (
            <center>
              <div style={{ marginTop: 50 }}>
                <Button
                  style={{ marginLeft: 30 }}
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleUpdate();
                  }}
                >
                  Save Image Position
                </Button>
              </div>
            </center>
          )}

          <Dialog
            fullScreen
            open={this.state.openCropDialog}
            onClose={() => { this.handleClose(); }}
          >
            <FixedCropper
              width={16}
              height={9}
              cropPixelWidth={360}
              cropPixelHeight={203}
              outputPixelWidth={720}
              outputPixelHeight={405}
              type={this.state.type}
              url={this.state.uploadUrl}
              src={this.state.src}
              file={this.state.currentFile}
              handleSave={this.handleSave}
              handleClose={this.handleClose}
              isCropping={this.state.cropping}
            />
          </Dialog>

          <Dialog
            fullScreen
            open={this.state.view}
            onClose={() => { this.handleClose(); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">View Image</DialogTitle>
            <DialogContent>
              <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  alt="cover"
                  src={this.state.viewImage}
                  style={{
                    maxWidth:        '100%', minWidth:        '400px', maxHeight:       'auto', minHeight:       'auto',
                    resizeMode:      'contain',
                    backgroundColor: 'blue',
                    boxShadow:       '0 1px 3px 1px rgba(0, 0, 0, .3)', borderRadius:    '10px',
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { this.handleClose(); }} color="primary">Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            size="sm"
            open={this.state.alert}
            onClose={() => { this.handleClose(); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove Image</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the image?
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mb-2">
              <Button className="px-2" onClick={() => { this.handleDelete(); }} color="default">Confirm</Button>
              <Button className="px-2 mx-3" onClick={() => { this.handleClose(); }} color="primary">Cancel</Button>
            </DialogActions>
          </Dialog>

          <PostMediaLimitModal
            open={this.state.planModals}
            close={() => this.handleClosePlanModals()}
            accountType={this.state.accountType}
            page={this.props.page}
          />
        </div>
      </>
    );
  }
}

Photo.defaultProps = {
  disabled: false,
  page:     {
    plan: '',
  },
  showSaveImgPosBtn: false,
};

Photo.propTypes = {
  disabled: PropTypes.bool,
  gift:     PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  page: PropTypes.shape({
    id:        PropTypes.number,
    plan:      PropTypes.string,
    staffType: PropTypes.string,
  }),
  giftId:            PropTypes.string.isRequired,
  showSaveImgPosBtn: PropTypes.bool,
  onUpdateImages:    PropTypes.func.isRequired,
};

Photo.defaultProps = {
  gift: null,
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

export default connect(mapStateToProps, null)(Photo);
