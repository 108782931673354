/***
 * This file manages all the icons we are using. All components should use the icons here.
 */
// To use the icons from react-native-vector-icons
// import Icon from 'react-native-vector-icons/FontAwesome5';
/* <Icon name={'chevron-left'} size={16} color={Colours.Purple} /> */

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@material-ui/core';
import Colours from '../../styles/colours';

const Account = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>account_circle</Icon>);
Account.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Account.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Add = ({ colour, fontSize }) => (<Icon style={{ color: colour, fontSize }}>add</Icon>);
Add.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Add.defaultProps = {
  colour:   Colours.Gray,
  fontSize: '1.6rem',
};

const ArrowBack = ({ colour, fontSize = '1.6rem' }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>arrow_back</Icon>);
ArrowBack.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
ArrowBack.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.6rem',
};

const ArrowFoward = ({ colour, fontSize = '1.6rem' }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>arrow_forward</Icon>);
ArrowFoward.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
ArrowFoward.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.6rem',
};

const Business = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>business</Icon>);
Business.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Business.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.6rem',
};

const Calendar = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>calendar_today</Icon>);
Calendar.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Calendar.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Cross = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>close</Icon>);
Cross.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Cross.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Crop = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>crop</Icon>);
Crop.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Crop.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Checked = ({ colour, fontSize, style }) => (<Icon style={{ color: colour || Colours.Primary, fontSize, ...style }}>check_circle</Icon>);
Checked.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
  style:    PropTypes.shape(Object),
};
Checked.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.2rem',
  style:    {},
};

const CropFree = (props) => (<Icon style={{ color: props.colour, fontSize: props.fontSize }}>crop_free</Icon>);
CropFree.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
CropFree.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const DeleteBin = ({ colour, fontSize, outline }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>{outline ? 'delete_outline' : 'delete'}</Icon>);
DeleteBin.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
  outline:  PropTypes.bool,
};
DeleteBin.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
  outline:  false,
};

const Edit = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>edit</Icon>);
Edit.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Edit.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1rem',
};

const Engagement = (props) => (<Icon style={{ color: props.colour, fontSize: props.fontSize }}>touch_app</Icon>);
Engagement.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Engagement.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Eye = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>visibility</Icon>);
Eye.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Eye.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1rem',
};

const Filter = (props) => (<img alt="Menu" src={require(`../../assets/images/icon-adjust.png`)} style={{ borderRadius: '50%', ...props.style }} />);
Filter.propTypes = {
  style: PropTypes.shape({}),
};
Filter.defaultProps = {
  style: {},
};

const FindInPage = (props) => (<Icon style={{ color: props.colour, fontSize: props.fontSize }}>find_in_page</Icon>);
FindInPage.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
FindInPage.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Follow = (props) => (<Icon style={{ color: props.colour, fontSize: props.fontSize }}>people</Icon>);
Follow.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Follow.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const InfoOutlined = ({ colour, fontSize, style }) => (<Icon style={{ color: colour || Colours.Black, fontSize, ...style }}>info</Icon>);
InfoOutlined.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
  style:    PropTypes.shape(Object),
};
InfoOutlined.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1rem',
  style:    {},
};

const Invitation = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>mail_outline</Icon>);
Invitation.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Invitation.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1rem',
};

const Impression = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>crop_original</Icon>);
Impression.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
Impression.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.6rem',
};

const Like = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>thumb_up_alt</Icon>);
Like.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Like.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.6rem',
};

const Logout = ({ colour, fontSize = '2rem' }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>transit_enterexit</Icon>);
Logout.propTypes = {
  colour: PropTypes.string,
};
Logout.defaultProps = {
  colour: Colours.Black,
};

const Menu = (props) => (<img alt="Menu" src={require(`../../assets/images/Monstyr-Icon.png`)} style={{ ...props.style, borderRadius: '50%' }} />);
Menu.propTypes = {
  style: PropTypes.shape({}),
};
Menu.defaultProps = {
  style: {},
};

const Profile = ({ colour, fontSize = '1.6rem' }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>person_outline</Icon>);
Profile.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Profile.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.6rem',
};

const PlayCircles = ({ colour, fontSize, outline }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>{outline ? 'play_circle_outline' : 'play_circle'}</Icon>);
PlayCircles.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
  outline:  PropTypes.bool,
};
PlayCircles.defaultProps = {
  colour:   Colours.Gray,
  fontSize: '2rem',
  outline:  false,
};

const Send = ({ colour, fontSize }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>send</Icon>);
Send.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Send.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Settings = (props) => (<Icon style={{ color: props.colour, fontSize: props.fontSize }}>settings</Icon>);
Settings.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Settings.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Share = (props) => (<Icon style={{ color: props.colour, fontSize: props.fontSize }}>share</Icon>);
Share.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Share.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const StaffMgmt = (props) => (<Icon style={{ color: props.colour, fontSize: props.fontSize }}>supervisor_account</Icon>);
StaffMgmt.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
StaffMgmt.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const Support = (props) => (<Icon style={{ color: props.colour, fontSize: props.fontSize }}>build</Icon>);
Support.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Support.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

Support.defaultProps = {
  colour:   Colours.Black,
  fontSize: '2rem',
};

const UnChecked = ({ colour, fontSize, borderColor, style }) => (<Icon style={{ color: colour || Colours.Gray2, fontSize, backgroundColor: borderColor || Colours.Gray2,  borderRadius: '50%', ...style }}>circle</Icon>);
UnChecked.propTypes = {
  colour:      PropTypes.string,
  fontSize:    PropTypes.string,
  borderColor: PropTypes.string,
  style:       PropTypes.shape(Object),
};
UnChecked.defaultProps = {
  colour:      Colours.White,
  fontSize:    '1.2rem',
  borderColor: Colours.Gray,
  style:       {},
};

const Video = ({ colour, fontSize, onClick }) => (<Icon style={{ color: colour || Colours.Black, fontSize }} onClick={onClick}>video_library</Icon>);
Video.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
  onClick:  PropTypes.func,
};
Video.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.2rem',
  onClick:  () => { },
};

const Visibility = ({ colour, fontSize, onClick }) => (<Icon style={{ color: colour || Colours.Black, fontSize  }} onClick={onClick}>visibility</Icon>);
Visibility.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
  onClick:  PropTypes.func,
};
Visibility.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.2rem',
  onClick:  () => {},
};

const VisibilityOff = ({ colour, fontSize, onClick }) => (<Icon style={{ color: colour || Colours.Black, fontSize  }} onClick={onClick}>visibility_off</Icon>);
VisibilityOff.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
  onClick:  PropTypes.func,
};
VisibilityOff.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.2rem',
  onClick:  () => {},
};

const Wallpaper = ({ colour, fontSize = '1.6rem' }) => (<Icon style={{ color: colour || Colours.Black, fontSize }}>wallpaper</Icon>);
Wallpaper.propTypes = {
  colour:   PropTypes.string,
  fontSize: PropTypes.string,
};
Wallpaper.defaultProps = {
  colour:   Colours.Black,
  fontSize: '1.6rem',
};

const Icons = {
  Account,
  ArrowBack,
  ArrowFoward,
  Add,
  Business,
  Calendar,
  Cross,
  Crop,
  CropFree,
  Checked,
  DeleteBin,
  Edit,
  Engagement,
  Eye,
  Filter,
  FindInPage,
  Follow,
  Impression,
  Invitation,
  InfoOutlined,
  Like,
  Logout,
  Menu,
  Profile,
  PlayCircles,
  Send,
  Settings,
  Share,
  StaffMgmt,
  Support,
  UnChecked,
  Video,
  Visibility,
  VisibilityOff,
  Wallpaper,
};

export default Icons;
