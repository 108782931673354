import Constants from '../common/constants';

const { ReduxAction } = Constants;

const viewChanged = (dispatch, name) => {
  dispatch({
    type:    ReduxAction.ViewChanged,
    payload: name,
  });
};

const viewAction = {
  viewChanged,
};

export default viewAction;
