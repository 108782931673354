// React Dependencies
import React, { useEffect, useState } from 'react';

// External Dependencies
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Tabs, Tab } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

// Internal Dependencies
import List from './List/List';
import { containerHeaderTextStyle } from '../../../styles/common';
import { IsTrue, IsEmpty } from '../../../common/checks';
import OverLoading from '../../../components/Loading';
import ApiService from '../../../services/apiService';

const TAB_VALUE = {
  Issuers:             'issuers',
  CouponsVouchersList: 'list',
};

function CouponsVouchers(props) {
  const history = useHistory();
  const [tabValue, setTabValue] = useState(TAB_VALUE.CouponsVouchersList);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event, newValue) => {
    const params = new URLSearchParams(window.location.search);
    params.set('tab', newValue);
    props.history.replace({ search: params.toString() });
    setTabValue(newValue);
  };

  const fetchPage = async () => {
    try {
      setIsLoading(true);

      const page = await ApiService.getPageById(props.match.params.pageId);

      if (!IsTrue(page.giftEnabled)) {
        history.goBack();
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const setInitialActiveTab = () => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (!IsEmpty(tab) && Object.values(TAB_VALUE).includes(tab)) {
      setTabValue(tab);
    }
  };

  useEffect(() => {
    setInitialActiveTab();
    fetchPage();
  }, []);

  if (isLoading) {
    return <OverLoading />;
  }

  return (
    <div className="container-fluid">
      <div className="mt-4 ml-2 mb-2 d-flex flex-row justify-content-start align-items-center">
        <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Coupons & Vouchers</Typography>
      </div>
      <div className="ml-2 mb-2">
        <Tabs value={tabValue} onChange={handleChange}>
          <Tab label="List" value={TAB_VALUE.CouponsVouchersList} />
          <Tab label="Issuers" value={TAB_VALUE.Issuers} />
        </Tabs>
      </div>

      {tabValue === TAB_VALUE.Issuers && (
        <div>Issuers</div>
      )}

      {tabValue === TAB_VALUE.CouponsVouchersList && (
        <List />
      )}

    </div>
  );
}

CouponsVouchers.propTypes = {
  history:      PropTypes.shape(Object).isRequired,
  pageSelected: PropTypes.shape({
    id:        PropTypes.number,
    name:      PropTypes.string,
    staffType: PropTypes.string,
    plan:      PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      pageId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  pageSelected: state.page.selected,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponsVouchers);
