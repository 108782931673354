/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button,  TextField, Typography, Paper } from '@material-ui/core';
import Loading from '../../../components/Loading';
import PageImagesContainer from './PageImagesContainer';
import PageLogo from './PageLogo';
import AddressSearch from '../../../components/AddressSearch';
import Colours from '../../../styles/colours';
import { IfTrueThrowError, IfFalseThrowError, EmailIsValid } from '../../../common/checks';
import { IsEmpty, Trim } from '../../../common/util';
import ApiService from '../../../services/apiService';
import PageTags from './PageTags';
import { PageType } from '../../../common/constants';
import { containerHeaderTextStyle } from '../../../styles/common';
import Tooltip from '../../../components/Common/ToolTip';
import Icons from   '../../../components/Common/Icons';

const DesktopUpdate = (props) => {
  const [loading, setLoading] = useState(true);
  const [openAddress, setOpenAddress] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [userTags, setUserTags] = useState([]);
  const [logo, setLogo] = useState('');
  const [website, setWebsite] = useState('');
  const [address, setAddress] = useState('');
  const [addressDisplay, setAddressDisplay] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [contactOthers, setContactOthers] = useState('');
  const [pagePlan, setPagePlan] = useState('');

  const closeAddress = () => setOpenAddress(false);

  const populateAddress = (result) => {
    if (!IsEmpty(result)) {
      setAddress(result.address);
      setPostalCode(result.postalCode);
      setLat(result.lat || null);
      setLng(result.lng || null);
    } else {
      setAddress('');
      setPostalCode('');
      setLat(result.lat || null);
      setLng(result.lng || null);
    }
  };

  const handleChangeTags = (values) => {
    if (!IsEmpty(values)) {
      setUserTags(values);
    } else {
      setUserTags([]);
    }
  };

  useEffect(() => {
    if (props.page) {
      setPhotos(props.page.images || []);
      setDescription(props.page.description || '');
      setType(props.page.type || '');
      setUserTags(props.page.userTags || []);
      setLogo(props.page.logo || '');
      setWebsite(props.page.website || '');
      setAddress(props.page.address || '');
      setPostalCode(props.page.postalCode || '');
      setPagePlan(props.page.plan || '');
      setLat(props.page.lat);
      setLng(props.page.lng);
      setContactEmail(props.page.contactEmail || '');
      setContactNo(props.page.contactNo || '');
      setContactOthers(props.page.contactOthers || '');

      setLoading(false);
    }
  }, [props.page]);

  useEffect(() => {
    if (!IsEmpty(address)) {
      setAddressDisplay(`${address}\nSingapore ${postalCode}`);
    } else {
      setAddressDisplay('');
    }
  }, [address, postalCode]);

  const validate = (payload) => {
    // eslint-disable-next-line no-shadow
    const { contactEmail, contactOthers, contactNo } = payload;

    const fldContactEmail = Trim(contactEmail);
    const fldContactOthers = Trim(contactOthers);
    const fldContactNo = Trim(contactNo);

    setContactEmail(fldContactEmail);
    setContactOthers(fldContactOthers);
    setContactNo(fldContactNo);

    if (!IsEmpty(fldContactEmail)) {
      IfFalseThrowError(EmailIsValid(fldContactEmail), 'Email invalid format.');
    }

    IfTrueThrowError((IsEmpty(fldContactEmail) && IsEmpty(fldContactOthers) && IsEmpty(fldContactNo)), 'Please provide at least one method for customers to contact you.');

    return { ...payload, contactEmail: fldContactEmail, contactOthers: fldContactOthers, contactNo: fldContactNo };
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      let payload = {
        description,
        website,
        userTags,
        contactEmail,
        lat,
        lng,
        contactOthers,
        contactNo,
      };

      payload = validate(payload);
      setLoading(true);

      if (type === PageType.Mall) {
        payload = { ...payload, address, postalCode, lat, lng };
      }
      await ApiService.updatePage(props.page.id, payload);
      toast.success('Page updated.');
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      {openAddress && <AddressSearch onCancel={closeAddress} onDone={(e) => populateAddress(e)} />}
      <form
        className="container-fluid"
        onSubmit={(e) => onSubmit(e)}
        autoComplete="off"
      >
        <div className="mt-4 ml-2 mb-2">
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Home Page</Typography>
        </div>

        <div className="row d-flex flex-column">
          <div className="col-12 my-3">
            <Paper className="p-4">
              <div className="d-flex flex-column">
                <div>
                  <PageImagesContainer
                    mediaType="image"
                    images={photos}
                    windowSize={props.windowSize}
                    pageId={props.page.id}
                    accountType={pagePlan}
                    disabled={props.disabled}
                  />
                </div>
              </div>

              <div className="d-flex flex-column my-3">
                <div className="row pt-3">
                  <div className="col-md-12">
                    <span className="d-block mb-3" style={{ width: '100%', borderTop: `1px solid ${Colours.Gray4}` }} />
                  </div>

                  <div className="col-md-6" style={{ borderRight: `1px solid ${Colours.Gray4}` }}>
                    <div className="mb-4">
                      <Typography variant="body1" color="inherit" style={{ color: Colours.Gray, marginBottom: 10 }}>
                        2. Description  (max. 500 characters)
                      </Typography>

                      <TextField
                        id="standard-multiline-flexible"
                        multiline
                        maxRows={8}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </div>

                    <div className="mb-4">
                      <Typography variant="body1" className="mb-2" color="inherit" style={{ color: Colours.Gray }}>
                        3. Tags
                        {' '}
                        <Tooltip title="Associate tags with your page">
                          <Icons.InfoOutlined colour={Colours.Gray1} fontSize="1.3rem" />
                        </Tooltip>
                      </Typography>

                      <PageTags />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-4">
                      <Typography variant="body1" color="inherit" className="mb-2" style={{ color: Colours.Gray }}>
                        4. Logo (min. 200 x 200px resolution)
                      </Typography>

                      <PageLogo setLogo={(url) => setLogo(url)} currentPhoto={logo} pageId={props.page.id} disabled={props.disabled} />
                    </div>

                    <div className="mb-4">
                      <Typography variant="body1" color="inherit" style={{ color: Colours.Gray }}>
                        5. Website
                      </Typography>

                      <TextField
                        className="mt-2"
                        id="standard-multiline-flexible"
                        multiline
                        maxRows={4}
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </div>

                    <div className="mb-4">
                      <div className="d-flex flex-row">
                        <Typography variant="body1" color="inherit" style={{ color: Colours.Gray }}>
                          6. Address
                        </Typography>
                        {
                          (type === PageType.Mall)
                            && (
                              <Button
                                className="ml-2"
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => { setOpenAddress(true); }}
                              >
                                <Typography variant="caption">search</Typography>
                              </Button>
                            )
                        }
                      </div>

                      <TextField
                        id="standard-multiline-flexible"
                        className="mt-2"
                        multiline
                        maxRows={4}
                        value={addressDisplay}
                        variant="outlined"
                        size="small"
                        placeholder={(type !== PageType.Mall) ? '(Not Applicable)' : ''}
                        fullWidth
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column my-3">
                <div className="row">
                  <div className="col-md-12">
                    <span className="d-block mb-3" style={{ width: '100%', borderTop: `1px solid ${Colours.Gray4}` }} />
                  </div>

                  <div className="col-md-12">
                    <Typography variant="body1" color="inherit" style={{ color: Colours.Gray }}>
                      7. How should customers contact you
                      <sup style={{ color: Colours.Red }}>*</sup>
                      {' '}
                      <span className="ml-2" style={{ display: 'block', fontSize: 12, color: Colours.Gray, marginBottom: 5 }}>(provide at least 1 method)</span>
                    </Typography>
                  </div>

                  <div className="col-md-6 mt-1 pl-4" style={{ borderRight: `1px solid ${Colours.Gray4}` }}>
                    <div className="mb-4">
                      <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                        Email
                      </Typography>

                      <TextField
                        className="mt-2"
                        id="standard-multiline-flexible"
                        multiline
                        maxRows={4}
                        value={contactEmail}
                        onChange={(e) => setContactEmail(Trim(e.target.value))}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </div>

                    <div>
                      <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                        Phone Number
                      </Typography>

                      <TextField
                        className="mt-2"
                        id="standard-multiline-flexible"
                        multiline
                        maxRows={4}
                        value={contactNo}
                        onChange={(e) => setContactNo(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mb-5">
                      <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                        Others
                      </Typography>

                      <TextField
                        className="mt-2"
                        id="standard-multiline-flexible"
                        multiline
                        maxRows={4}
                        value={contactOthers}
                        onChange={(e) => setContactOthers(e.target.value)}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    </div>
                  </div>

                  <div className="col-12 row mt-5 d-flex flex-row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-3">
                      <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                        <Button
                          className="block"
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={props.disabled}
                        >
                          Save
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </form>
    </>
  );
};

DesktopUpdate.propTypes = {
  page: PropTypes.shape({
    id:            PropTypes.number.isRequired,
    images:        PropTypes.arrayOf(PropTypes.object),
    description:   PropTypes.string,
    type:          PropTypes.string,
    userTags:      PropTypes.arrayOf(PropTypes.string),
    logo:          PropTypes.string,
    website:       PropTypes.string,
    address:       PropTypes.string,
    postalCode:    PropTypes.string,
    lat:           PropTypes.number,
    lng:           PropTypes.number,
    plan:          PropTypes.string,
    contactNo:     PropTypes.string,
    contactEmail:  PropTypes.string,
    contactOthers: PropTypes.string,
  }).isRequired,
  windowSize: PropTypes.string.isRequired,
  disabled:   PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  page:       state.page.update,
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(DesktopUpdate);
