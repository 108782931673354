// React Dependencies
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// External Dependencies
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Internal Dependencies
import { WindowSize, Url } from '../../../common/constants';
import { IsEmpty } from '../../../common/checks';
import layoutAction from '../../../actions/layout';
import LoyaltyProgramSidebarMenu from './LoyaltyProgramSidebarMenu';
// import MembershipSetting from './MembershipSetting/MembershipSetting';
import LoyaltyCardInfo from './LoyaltyCardInfo';
import StampSettings from './StampSettings/StampSettings';
// import TransactionHistory from './TransactionHistory/TransactionHistory';
// import Members from './Members/Members';
import ParticipateOutlets from './ParticipateOutlets';

const LoyaltyProgramMenuItems = [
  {
    id:   1,
    name: 'Info',
    url:  Url.LoyaltyCard,
  },
  {
    id:   2,
    name: 'Stamp Settings',
    url:  Url.StampSettings,
  },
  {
    id:   3,
    name: 'Participating Outlets',
    url:  Url.ParticipateOutlets,
  },
  // {
  //   id:   4,
  //   name: 'Transaction History',
  // },
  // {
  //   id:   5,
  //   name: 'Membership Setting',
  // },
];

const LoyaltyProgram = (props) => {
  const history = useHistory();

  const [selectedMenuItem, setSelectedMenuItem] = useState(LoyaltyProgramMenuItems.find((item) => item.url === props.match.path));

  const selectMenuItemHandler = (menuItem) => {
    if (IsEmpty(menuItem.url)) {
      return;
    }
    setSelectedMenuItem(menuItem);
    history.push(menuItem.url.replace(':pageId', props.match.params.pageId).replace(':loyaltyCardId', props.match.params.loyaltyCardId));
  };

  const closeMenuHandler = () => {
    setSelectedMenuItem({ id: '' });
    props.dispatchLoyaltyProgramSideBarHide();
    history.push(Url.LoyaltyCards.replace(':pageId', props.match.params.pageId));
  };

  useEffect(() => {
    if (props.layout.windowSize === WindowSize.Mobile && props.layout.showSideBar === false) {
      props.dispatchLoyaltyProgramSideBarHide();
    } else {
      props.dispatchLoyaltyProgramSideBarShow();
    }
  }, [props.layout.showSideBar]);

  const getSelectedMenuItemContent = () => {
    switch (props.match.path) {
      case Url.LoyaltyCard:
        return <LoyaltyCardInfo match={props.match} />;

      case Url.StampSettings:
        return <StampSettings match={props.match} />;

      case Url.ParticipateOutlets:
        return <ParticipateOutlets match={props.match} />;

      // case 3:
      //   return <TransactionHistory />;
      // case 4:
      //   return <MembershipSetting />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid mt-3">
      {props.layout.showLoyaltyProgramSideBar && (
        <LoyaltyProgramSidebarMenu
          match={props.match}
          menuItems={LoyaltyProgramMenuItems}
          selectedMenuItem={selectedMenuItem}
          onSelectMenuHandler={selectMenuItemHandler}
          onCloseMenuHandler={closeMenuHandler}
        />
      )}
      {getSelectedMenuItemContent(selectedMenuItem)}
    </div>
  );
};

LoyaltyProgram.propTypes = {
  match:                             PropTypes.shape(Object).isRequired,
  layout:                            PropTypes.shape(Object).isRequired,
  dispatchLoyaltyProgramSideBarHide: PropTypes.func.isRequired,
  dispatchLoyaltyProgramSideBarShow: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  layout: state.layout,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLoyaltyProgramSideBarShow: () => layoutAction.loyaltyProgramSideBarShow(dispatch),
  dispatchLoyaltyProgramSideBarHide: () => layoutAction.loyaltyProgramSideBarHide(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyProgram);
