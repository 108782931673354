// React Dependencies
import React, { useState } from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dialog, DialogActions, DialogContent, Typography, Button, makeStyles } from '@material-ui/core';
import { Edit, GroupOutlined, VisibilityOutlined, Storefront } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

// Internal Dependencies
import HeadingAndSubText from './HeadingAndSubText';
import MIconButton from '../../../../../components/MIconButton';
import MBadge from '../../../../../components/MBadge';
import Colours from '../../../../../styles/colours';
import { Url } from '../../../../../common/constants';
import AuthorizedIssuers from './AuthorizedIssuers';

const useStyles = makeStyles({
  container: {
    display:         'flex',
    flexDirection:   'row',
    justifyContent:  'space-between',
    backgroundColor: '#F2F2F2',
    width:           '82%',
    borderRadius:    '10px',
    paddingLeft:     '1.25rem',
    paddingRight:    '1.25rem',
    marginBottom:    '0.75rem',

    '@media (max-width: 1490px)': {
      flexWrap: 'wrap',
    },
    '@media (max-width: 600px)': {
      width:        '100%',
      paddingLeft:  '0.75rem',
      paddingRight: '0.75rem',
    },
  },
  subContainer: {
    paddingTop:    '1.5rem',
    paddingBottom: '1.5rem',

    '@media (max-width: 1149px)': {
      flexWrap:  'wrap',
      margin:    '0 !important',
      marginTop: '1rem',
    },

  },
  actionContainer: {
    paddingTop:    '1.5rem',
    paddingBottom: '1.5rem',
    borderLeft:    '2px solid #FEF6F6',
    paddingLeft:   '1.25rem',

    '@media (max-width: 1490px)': {
      paddingTop: '0',
      border:     'none',
    },
  },
  subContainerAction: {
    gap: '0.8rem',

    '@media (max-width: 1490px)': {
      flexDirection: 'row !important',
      flexWrap:      'wrap',
      width:         '80%',
    },
  },
  mobileActionIconBtns: {
    width:   '30px !important',
    padding: '18px !important',
  },
});

const MODAL_TYPE = {
  TERMS:      'T&Cs',
  AUTHORIZED: 'Authorized Issuers',
};

const GiftCard = ({ gift, pageId }) => {
  const classes = useStyles();
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  const viewModalTermsAndConditionsHandler = () => {
    setModalType(MODAL_TYPE.TERMS);
    openModal();
  };

  const editHandler = () => history.push(Url.Gift.replace(':pageId', pageId).replace(':giftId', gift.id));

  const authorizedIssuersHandler = () => {
    setModalType(MODAL_TYPE.AUTHORIZED);
    openModal();
  };

  const formatDate = (date) => {
    const newDate = new Date(date);
    return moment(newDate).format('YYYY-MM-DD');
  };

  return (
    <div className={classes.container} aria-hidden="true">
      <div className={`d-flex flex-row ${classes.subContainer}`} style={{ gap: '0.5rem' }}>
        <div className="d-flex flex-column" style={{ gap: '0.5rem' }}>
          <div
            className="d-flex justify-content-center align-items-center py-4"
            style={{ width: '19.5rem', height: '10.5rem', backgroundColor: '#D9D9D9', textAlign: 'center', borderRadius: '4px' }}
          >
            { !_.isEmpty(gift.images) ? (
              <img
                src={gift.images[0].display}
                style={{ width: '100%', height: '10.5rem', objectFit: 'cover', borderRadius: '4px' }}
                alt={gift.label}
              />
            ) : <p>Image</p> }
          </div>

          <div className="d-flex align-items-center" style={{ gap: '0.5rem' }}>
            { !_.isEmpty(gift.terms) && <MBadge text="View T&Cs" styles={{ width: '8rem' }} onClick={viewModalTermsAndConditionsHandler} />}
            <div className="d-md-none">
              <MBadge text={gift.status} status={gift.status} />
            </div>
          </div>

          <div className="d-md-none mt-3">
            <Typography variant="body2" className="font-weight-bold mx-2">
              ID:
              {' '}
              {gift.id}
            </Typography>

            <div className="mt-2 d-flex justify-content-between" style={{ gap: '0.5rem' }}>
              <MIconButton className={classes.mobileActionIconBtns} onClick={editHandler} icon={() => <Edit fontSize="small" className="ml-1" />} />
              <MIconButton className={classes.mobileActionIconBtns} onClick={authorizedIssuersHandler} icon={() => <GroupOutlined fontSize="small" className="ml-1" />} />
              <MIconButton className={classes.mobileActionIconBtns} icon={() => <VisibilityOutlined fontSize="small" className="ml-1" />} />
              <MIconButton className={classes.mobileActionIconBtns} icon={() => <Storefront fontSize="small" className="ml-1" />} />
            </div>
          </div>
        </div>

        <div className="d-none d-md-flex flex-column" style={{ gap: '0.5rem' }}>
          <HeadingAndSubText heading="Description:" text={gift.label} />
          <HeadingAndSubText heading="ID:" text={gift.id} />
          <HeadingAndSubText heading="Type:" text={gift.type} />

          <div className="d-flex flex-row" style={{ gap: '1rem' }}>
            <HeadingAndSubText heading="Start Date:" text={!_.isEmpty(gift.startDate) ? formatDate(gift.startDate) : '-'} />
            <HeadingAndSubText heading="Expiry Date:" text={!_.isEmpty(gift.endDate) ? formatDate(gift.endDate) : '-'} />
            <HeadingAndSubText heading="Extended Expiry Date:" text={!_.isEmpty(gift.extendedEndDate) ? formatDate(gift.extendedEndDate) : '-'} />
          </div>

          <HeadingAndSubText heading="Status:">
            <div className="d-flex " style={{ gap: '0.5rem' }}>
              <MBadge text={gift.status} status={gift.status} />
              { gift.pauseIssuance && <MBadge text="Issuance Paused" styles={{ backgroundColor: Colours.Orange, color: Colours.White  }} />}
            </div>
          </HeadingAndSubText>
        </div>
      </div>

      <div
        className={`d-none d-md-flex flex-column ${classes.actionContainer}`}
        style={{ flexWrap: 'wrap', gap: '0.8rem' }}
      >
        <Typography variant="body2" className="mb-2">Actions:</Typography>

        <div className={`d-none d-md-flex flex-column ${classes.subContainerAction}`}>
          <MIconButton onClick={editHandler} icon={() => <Edit fontSize="small" className="ml-1" />} text="Edit" />
          <MIconButton onClick={authorizedIssuersHandler} icon={() => <GroupOutlined fontSize="small" className="ml-1" />} text="Authorized Issuers" />
          <MIconButton icon={() => <VisibilityOutlined fontSize="small" className="ml-1" />} text="Participating Outlets" />
          <MIconButton icon={() => <Storefront fontSize="small" className="ml-1" />} text="Voucher Tracking" />
        </div>
      </div>

      <Dialog open={isModalOpen} fullWidth maxWidth="lg" onClose={closeModal}>
        <DialogContent className="mt-3">
          { modalType === MODAL_TYPE.TERMS && (
            <div style={{ height: '50vh' }}>
              <Typography variant="body1" className="font-weight-bold mb-3">Terms & Conditions</Typography>
              <Typography variant="subtitle1" style={{ fontSize: '14px !important' }}>{gift.terms}</Typography>
            </div>
          )}
          {
            modalType === MODAL_TYPE.AUTHORIZED && <AuthorizedIssuers gift={gift} />
          }
        </DialogContent>

        <DialogActions>
          <Button onClick={() => closeModal()} color="primary" variant="text">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

GiftCard.propTypes = {
  gift: PropTypes.shape({
    label:           PropTypes.string,
    id:              PropTypes.string,
    type:            PropTypes.string,
    startDate:       PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)]),
    endDate:         PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)]),
    extendedEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)]),
    status:          PropTypes.string,
    terms:           PropTypes.string,
    images:          PropTypes.arrayOf(PropTypes.shape(Object)),
    pauseIssuance:   PropTypes.bool,
  }).isRequired,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default GiftCard;
