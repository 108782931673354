import Constants from '../common/constants';

const { ReduxAction } = Constants;

const invites = (dispatch, categories) => {
  dispatch({
    type:    ReduxAction.Invites,
    payload: categories,
  });
};

const inviteRemove = (dispatch, invite) => {
  dispatch({
    type:    ReduxAction.InviteRemove,
    payload: invite,
  });
};

const inviteSelected = (dispatch, invite) => {
  dispatch({
    type:    ReduxAction.InviteSelected,
    payload: invite,
  });
  // clear any page & pageClaim selected (affects the sideBar display)
  dispatch({
    type:    ReduxAction.PageSelected,
    payload: {},
  });
  dispatch({
    type:    ReduxAction.PageClaimSelected,
    payload: {},
  });
};

const inviteAction = {
  invites,
  inviteRemove,
  inviteSelected,
};

export default inviteAction;
