// React Dependencies
import React, { useState, useEffect } from 'react';

// External Dependencies
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

// Internal Dependencies
import OverLoading from '../../../components/Loading';
import ApiService from '../../../services/apiService';
import MText from '../../../components/Common/MText';
import Colours from '../../../styles/colours';
import { IsEmpty } from '../../../common/checks';

const ParticipateOutlets = ({ match }) => {
  const defaultColumns = [
    { title: 'Brand', field: 'brand', filtering: true, editable: 'never',   cellStyle: { width: '30%' } },
    { title:     'Address', field:     'address', filtering: true, editable:  'never',   cellStyle: { width: '70%' }, render:    (participateOutlet) => (
      <span>
        {participateOutlet.address}
        {' '}
        {!IsEmpty(participateOutlet.unitNo) && `#${participateOutlet.unitNo}`}
        {' '}
        Singapore
        {' '}
        {participateOutlet.postalCode}
      </span>
    ) },
  ];

  const [columns] = useState(defaultColumns);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await ApiService.getParticipateOutlets(match.params.pageId, match.params.loyaltyCardId);
      setData(result.rows);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading && <OverLoading />}
      <div className="container-fluid pt-3 pl-4" style={{ backgroundColor: Colours.White }}>
        <div className="row mb-4">
          <div className="col-12">
            <MText size={12} text={`Loyalty Card ID: ${match.params.loyaltyCardId}`} className="mb-2" colour={Colours.Gray} />
            <MText size={16} bold text="Participating Outlets" />
            <MText size={13} text="The following outlets are participating in this loyalty card" />
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-12">
            <MaterialTable
              title=""
              isLoading={loading}
              columns={columns}
              components={{ Toolbar: () => null }}
              options={{ filtering: true }}
              data={data}
            />
          </div>
        </div>
      </div>
    </>
  );
};

ParticipateOutlets.propTypes = {
  match: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = (state) => ({
  pageSelected: state.page.selected,
});

export default connect(mapStateToProps)(ParticipateOutlets);
