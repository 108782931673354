import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@material-ui/core';
import { Redirect } from 'react-router';
import viewAction from '../../actions/view';
import { IsEmpty } from '../../common/checks';
import pageAction from '../../actions/page';
import businessAction from '../../actions/business';
import Constants from '../../common/constants';

const Business = (props) => {
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    /**
     * The logic to determine what to display to the user when he has logged in resides here.
     * Logic:
     * 1) If user is not staff of any page and is not invited to any, display the createPageClaim
     * 2) If user is not staff of any page and has one or more invitations to manage a page, display the pageStaffInvite
     * 3) If user is a staff of any page(s), select and go to the first page
     */
    if (!IsEmpty(props.pages)) {
      const page = props.pages[0];
      props.dispatchPageSelected(page);
      setRedirectUrl(Constants.Url.Page.replace(':pageId', page.id));
    }
  }, []);

  return !IsEmpty(redirectUrl)
    ? <Redirect to={redirectUrl} />
    : (
      <div className="container-fluid m-3 mt-4 ml-4">
        <div className="row" />
      </div>
    );
};

const mapStateToProps = (state) => ({
  pages: state.page.pages,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageSelected: (page) => pageAction.pageSelected(dispatch, page),
});

export default connect(mapStateToProps, mapDispatchToProps)(Business);
