// React Dependencies
import React, { useEffect, useState } from 'react';

// External Dependencies
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormControlLabel, Typography, Grid, Checkbox, Button, TextareaAutosize, Paper, makeStyles } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

// Internal Dependencies
import ApiService from '../../../services/apiService';
import Loading from '../../../components/Loading';
import { IfTrueThrowError, IsEmpty } from '../../../common/checks';
import { Sleep, convertTime12to24, convertTime24to12, Trim } from '../../../common/util';
import RadioBtnSelect from '../../../components/Common/RadioBtnSelect';
import MaterialDatePicker from '../../../components/Common/form/MaterialDatePicker';
import TimeSelect from '../../../components/Common/form/TimeSelect';
import { containerHeaderTextStyle } from '../../../styles/common';
import Colours from '../../../styles/colours';
import Photos from './Photo';
import ToolTip from '../../../components/Common/ToolTip';
import PageHighlightConst from './PageHighlightConst';

const { statusesAvailToSet } = PageHighlightConst;

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '1rem',

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },

    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },
  datePicker: {
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
      fontSize:  '14px',

      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
  },
  sampleAreaMarkerImageContainer: {
    width: '65%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formContainer: {
    padding: '2rem',

    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  dateContainer: {
    marginLeft: '1rem',

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const PageHighlightInfo = (props) => {
  const classes = useStyles();

  const [pageHighlight, setPageHighlight] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isSpecificTime, setIsSpecificTime] = useState(true);
  const [startTimeHours, setStartTimeHours] = useState(null);
  const [startTimeMins, setStartTimeMins] = useState(null);
  const [startTimeType, setStartTimeType] = useState(null);
  const [endTimeHours, setEndTimeHours] = useState(null);
  const [endTimeMins, setEndTimeMins] = useState(null);
  const [endTimeType, setEndTimeType] = useState(null);

  const pageHighlightData = async (id) => {
    try {
      setLoading(true);

      const ph = await ApiService.getPageHighlight(id, { pageId: props.selectedPage.id });
      if (!IsEmpty(ph)) {
        setIsSpecificTime(!IsEmpty(ph.dailyStartTime));

        // From saved time, E.g: 22:00:00+08:00
        if (!IsEmpty(ph.dailyStartTime)) {
          const dailyStartTime = convertTime24to12(ph.dailyStartTime.substring(0, 5)).split(':');
          setStartTimeHours(dailyStartTime[0]);
          setStartTimeMins(dailyStartTime[1].split(' ')[0]);
          setStartTimeType(dailyStartTime[1].split(' ')[1]);
        }
        if (!IsEmpty(ph.dailyEndTime)) {
          const dailyEndTime = convertTime24to12(ph.dailyEndTime.substring(0, 5)).split(':');
          setEndTimeHours(dailyEndTime[0]);
          setEndTimeMins(dailyEndTime[1].split(' ')[0]);
          setEndTimeType(dailyEndTime[1].split(' ')[1]);
        }

        setPageHighlight(ph);
      } else {
        toast.error(`No page highlight found with id=${id}`);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { pageHighlightId } = props.match.params;
    if (!IsEmpty(pageHighlightId)) {
      pageHighlightData(pageHighlightId);
    } else {
      toast.error('Invalid link');
    }
  }, []);

  const formatDate = (date) => {
    const newDate = new Date(date);
    return moment(newDate).format('YYYY-MM-DD');
  };

  const onChangeSpecificTime = () => {
    setIsSpecificTime(!isSpecificTime);

    if (isSpecificTime) {
      setStartTimeHours(null);
      setStartTimeMins(null);
      setStartTimeType(null);
      setEndTimeHours(null);
      setEndTimeMins(null);
      setEndTimeType(null);
    }
  };

  const onPageHighlightImageChange = (images) => {
    if (images !== pageHighlight.images) {
      pageHighlight.images = images;
      setPageHighlight({ ...pageHighlight });
    }
  };

  const validatePageHighlight = () => {
    IfTrueThrowError(IsEmpty(pageHighlight.title), 'Title is required');
    IfTrueThrowError(IsEmpty(pageHighlight.startDate) && !IsEmpty(pageHighlight.endDate), 'Start date is required');
    IfTrueThrowError(!IsEmpty(pageHighlight.startDate) && IsEmpty(pageHighlight.endDate), 'End date is required');
    IfTrueThrowError(!IsEmpty(pageHighlight.startDate) && !IsEmpty(pageHighlight.endDate)
      && new Date(pageHighlight.startDate) > new Date(pageHighlight.endDate), 'The start date must be before the end date');

    if (isSpecificTime) {
      IfTrueThrowError(IsEmpty(startTimeHours) || IsEmpty(startTimeMins) || IsEmpty(startTimeType), 'Start time is required');
      IfTrueThrowError(IsEmpty(endTimeHours) || IsEmpty(endTimeMins) || IsEmpty(endTimeType), 'End time is required');

      // Time with time zone, E.g: 00:00:00+08
      pageHighlight.dailyStartTime = `${convertTime12to24(`${startTimeHours}:${startTimeMins} ${startTimeType}`)}:00+08`;
      pageHighlight.dailyEndTime = `${convertTime12to24(`${endTimeHours}:${endTimeMins} ${endTimeType}`)}:00+08`;
    } else {
      pageHighlight.dailyStartTime = null;
      pageHighlight.dailyEndTime = null;
    }

    IfTrueThrowError(pageHighlight.calloutContent
      && Trim(pageHighlight.calloutContent).length > 100, 'The map content should have maximum 100 characters');

    if (!IsEmpty(pageHighlight.images)) {
      const hasEmptyDisplay = pageHighlight.images.some((val) => IsEmpty(val.display));
      IfTrueThrowError(!IsEmpty(pageHighlight.images) && hasEmptyDisplay, 'Please crop all image(s) before saving the pageHighlight');
    }
  };

  const updatePageHighlight = async () => {
    try {
      setLoading(true);
      await Sleep(200);

      validatePageHighlight();
      await ApiService.updatePageHighlight(pageHighlight.id, pageHighlight);
      toast.success('Info Updated');
    } catch (error) {
      toast.error(error.message, { autoClose: 7000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}

      <div className="container-fluid px-0">
        <div className="mt-4 mx-4 d-flex flex-row justify-content-start">
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>
            Info
          </Typography>
        </div>
        {
          !IsEmpty(pageHighlight) && (
            <div className="row d-flex flex-column">
              <div className="col-12 my-3">
                <Paper className={classes.formContainer}>
                  <form>
                    <div className="form-group">
                      <Typography>Title *</Typography>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="title"
                        value={pageHighlight.title}
                        onChange={(e) => {
                          setPageHighlight({ ...pageHighlight, title: e.target.value });
                        }}
                        required
                      />
                    </div>
                    <div className="form-group mt-3 mb-0">
                      <Typography>
                        Status *
                        {`${!statusesAvailToSet.includes(pageHighlight.status) ? (
                          ` (${pageHighlight.status})`
                        ) : ''}`}
                      </Typography>
                      <RadioBtnSelect
                        className="ml-2"
                        disabled={!statusesAvailToSet.includes(pageHighlight.status)}
                        value={pageHighlight.status}
                        options={statusesAvailToSet.map((status) => _.assign({ value: status }))}
                        onChange={(status) => setPageHighlight({ ...pageHighlight, status })}
                      />
                    </div>
                    <div className="form-group mb-1 pb-2" style={{ borderBottom: '1px solid gray' }}>
                      <Typography>Type</Typography>
                      <RadioBtnSelect
                        className="ml-2"
                        value={`${pageHighlight.isSpecial ? 'Special' : pageHighlight.isExclusive ? 'Exclusive' : ''} Highlight`}
                        options={[
                          { value: `${pageHighlight.isSpecial ? 'Special' : pageHighlight.isExclusive ? 'Exclusive' : ''} Highlight` },
                        ]}
                      />
                    </div>
                    <div className="form-group pt-4" style={{ borderBottom: '1px solid black' }}>
                      <Typography>Dates</Typography>
                      <div className={classes.dateContainer}>
                        <Grid item container direction="row" spacing={1} className="mb-3">
                          <Grid item>
                            <MaterialDatePicker
                              minDate={false}
                              variant="outlined"
                              label="Start Date"
                              value={IsEmpty(pageHighlight.startDate) ? null : new Date(pageHighlight.startDate)}
                              onChange={(e) => {
                                setPageHighlight({ ...pageHighlight, startDate: formatDate(e.toString('yyyy-MM-dd')) });
                              }}
                              inputStyle={{ padding: '10px 14px', fontSize: '14px' }}
                              className={classes.datePicker}
                            />
                          </Grid>
                          <Grid item>
                            <MaterialDatePicker
                              minDate={false}
                              variant="outlined"
                              label="End Date"
                              value={IsEmpty(pageHighlight.endDate) ? null : new Date(pageHighlight.endDate)}
                              onChange={(e) => {
                                setPageHighlight({ ...pageHighlight, endDate: formatDate(e.toString('yyyy-MM-dd')) });
                              }}
                              inputStyle={{ padding: '10px 14px', fontSize: '14px' }}
                              className={classes.datePicker}
                            />
                          </Grid>
                        </Grid>

                        <Typography style={{ fontSize: '14px' }}>Specific daily display time-period</Typography>
                        <FormControlLabel
                          control={(<Checkbox checked={isSpecificTime} onChange={() => onChangeSpecificTime()} />)}
                          label="Show only on specific hours (un-check to display 24hrs)"
                          className={`${classes.root}`}
                          style={{ fontSize: '14px !important' }}
                        />

                        <Grid item container direction="row" spacing={3} className="md:ml-3 mb-3">
                          <TimeSelect
                            disabled={!isSpecificTime}
                            text="Start Time"
                            valueHours={startTimeHours}
                            valueMins={startTimeMins}
                            valueTimeType={startTimeType}
                            onChangeHours={(e) => setStartTimeHours(e.target.value)}
                            onChangeMins={(e) => setStartTimeMins(e.target.value)}
                            onChangeTimeType={(e) => setStartTimeType(e.target.value)}
                          />
                          <Grid item className="d-flex flex justify-content-center align-items-center" style={{ position: 'relative', top: 15 }}>
                            <Typography>to</Typography>
                          </Grid>
                          <TimeSelect
                            disabled={!isSpecificTime}
                            text="End Time"
                            valueHours={endTimeHours}
                            valueMins={endTimeMins}
                            valueTimeType={endTimeType}
                            onChangeHours={(e) => setEndTimeHours(e.target.value)}
                            onChangeMins={(e) => setEndTimeMins(e.target.value)}
                            onChangeTimeType={(e) => setEndTimeType(e.target.value)}
                          />
                        </Grid>
                      </div>
                    </div>
                    <div className="form-group">
                      <Typography className="mb-1">Map</Typography>
                      <Typography variant="subtitle1" color="inherit" style={{ fontSize: '14px', maxWidth: '360px' }}>
                        Enter the title and content that will be shown in Deals Map for the geographic boundary
                      </Typography>

                      <div
                        className="geographic_boundary__formContentContainer mb-1 pb-3"
                        style={{ borderBottom: '1px solid gray', width: '100%', gap: 10 }}
                      >
                        <div className="geographic_boundary__formContentSubContainer">
                          <div className="mt-3">
                            <h6>Title</h6>
                            <input
                              type="text"
                              className="form-control mt-2"
                              style={{ fontSize: '14px', color: Colours.Black }}
                              name="title"
                              value={pageHighlight.calloutTitle}
                              onChange={(e) => setPageHighlight({ ...pageHighlight, calloutTitle: e.target.value })}
                              required
                            />
                          </div>

                          <div className="mt-3">
                            <h6 className="mb-2">Content (Max 100 characters)</h6>
                            <TextareaAutosize
                              variant="outlined"
                              minRows={6}
                              style={{
                                fontSize:     '14px',
                                width:        '100%',
                                borderRadius: '4px',
                                padding:      '12px',
                                border:       pageHighlight.calloutContent && pageHighlight.calloutContent.length > 100 ? `2px solid ${Colours.Red}` : `1.5px solid ${Colours.Gray5}`,
                                outlineWidth: pageHighlight.calloutContent && pageHighlight.calloutContent.length > 100 ? '0' : '2px',
                              }}
                              value={pageHighlight.calloutContent}
                              onChange={(e) => {
                                setPageHighlight({ ...pageHighlight, calloutContent: e.target.value });
                              }}
                            />
                          </div>
                        </div>

                        <div className={classes.sampleAreaMarkerImageContainer} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                          <img
                            // eslint-disable-next-line global-require
                            src={require('../../../assets/images/sampleAreaMarker.jpeg')}
                            alt="map"
                            style={{ width: '300px', height: '250px', objectFit: 'cover' }}
                          />
                          <Typography style={{ textAlign: 'center', fontStyle: 'italic' }} variant="subtitle1" color="inherit">Example</Typography>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <Photos
                        page={props.selectedPage}
                        pageHighlight={pageHighlight}
                        images={pageHighlight.images || []}
                        onSort={(images) => onPageHighlightImageChange(images)}
                        onPageHighlightImageChange={(images) => onPageHighlightImageChange(images)}
                        showSaveImgPosBtn={false}
                        disabled={!statusesAvailToSet.includes(pageHighlight.status)}
                      />
                    </div>
                    <div className="form-group text-center mt-5">
                      <ToolTip title={!statusesAvailToSet.includes(pageHighlight.status) ? `Cannot update highlight because status is '${pageHighlight.status}'` : ''}>
                        <Button
                          variant="contained"
                          onClick={updatePageHighlight}
                          color="secondary"
                          disabled={!statusesAvailToSet.includes(pageHighlight.status)}
                        >
                          <Typography className="px-4" variant="body1">Update Info</Typography>
                        </Button>
                      </ToolTip>
                    </div>
                  </form>
                </Paper>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};

PageHighlightInfo.propTypes = {
  selectedPage: PropTypes.shape({
    id:        PropTypes.number,
    staffType: PropTypes.string,
  }).isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      pageHighlightId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  selectedPage: state.page.selected,
});

export default connect(mapStateToProps, null)(PageHighlightInfo);
