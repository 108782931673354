import React from 'react';
import { connect }  from 'react-redux';
import PropTypes    from 'prop-types';
import { Link }     from 'react-router-dom';
import './AppMenuSidebar.css';
import IconButton from '@material-ui/core/IconButton';
import { Avatar, Button, Typography } from '@material-ui/core';
import BizSideBar   from './BizSideBar';
import PageSideBar  from './PageSideBar';
import Icons        from '../Common/Icons';

import layoutAction   from '../../actions/layout';
import pageAction     from '../../actions/page';
import { IsEmpty } from '../../common/checks';
import Colours from '../../styles/colours';
import Constants  from '../../common/constants';
import inviteAction from '../../actions/invite';
import pageClaimAction from '../../actions/pageClaim';
import { AcronymWord } from '../../common/util';
import sideBarStyle from '../../styles/sidebar';

const subMenuStyle = {
  width:            sideBarStyle.width,
  backgroundColor:  Colours.LightGray,
  borderRightWidth: '2px',
  borderRightColor: '#ffffff',
  borderRightStyle: 'inset',
};

const menuItemStyle = {
  width:  '70px',
  height: '50px',
};

class AppMenuSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuClass:     'd-flex',
      activeIconTab: null,
    };
  }

  componentDidMount() {
   this.setState({
     activeIconTab: this.props.match.url,
   });
  }

  onLogoutIconClicked() {
    this.props.dispatchLogoutModalToggle();
  }

  /**
   *
   * @param {String} activeIconTab
   *
   * We need to store current tab for checking which tab is currently clicked
   * for reload , we can get from route match .
   */
  setActiveIconTab(activeIconTab) {
    this.setState({
      activeIconTab,
    });
  }

  onPageClicked(page) {
    this.props.dispatchPageSelected(page);
  }

  renderIconLinks() {
    const IconLinks = [
      {
        title: 'Profile',
        Icon:  <Icons.Profile
          colour={
            Constants.Url.Profile === this.state.activeIconTab ? Colours.Black : Colours.Gray2
          }
          fontSize="1.8rem"
        />,
        to: Constants.Url.Profile,
      },
      {
        title: 'Support',
        Icon:  <Icons.Support
          colour={
            Constants.Url.Support === this.state.activeIconTab ? Colours.Black : Colours.Gray2
          }
          fontSize="1.4rem"
        />,
        to: Constants.Url.Support,
      },
    ];
    return IconLinks.map(({ to, title, Icon }, index) => (
      <IconButton
        href={to}
        title={title}
        key={index}
        className="menu-item d-flex align-items-center"
        style={{
          paddingLeft:            0,
          paddingRight:           0,
          borderRadius:           0,
          backgroundColor:        to === this.state.activeIconTab ? Colours.White : 'transparent',
          borderTopLeftRadius:    to === this.state.activeIconTab ? 30 : 0,
          borderBottomLeftRadius: to === this.state.activeIconTab ? 30 : 0,
          marginLeft:             to === this.state.activeIconTab ? 4 : 0,
          ...menuItemStyle,
        }}
        onClick={() => {
          this.setActiveIconTab(to);
          this.onPageClicked(null);
        }}
      >
        {Icon}
      </IconButton>
    ));
  }

  render() {
    return (
      <div
        // className={this.state.menuClass}
        className="d-flex mt-3"
        style={{
          // position: '',
          top:      '50px',
          bottom:   0,
          height:   '85vh',
          position: 'sticky',
          zIndex:   1000,
        }}
      >
        <div
          className="d-flex"
          style={{
            // position:        'relative',
            // height:          '100%',
            flexDirection:   'column',
            justifyContent:  'space-between',
            backgroundColor: Colours.LightGray,
            borderRadius:    '10px',
          }}
        >
          {/* <div
            className="d-flex flex-column"
            style={{
              // padding:         '5px',
              backgroundColor: Colours.White,
              width:           '100%',
              height:          '70px',
              alignItems:      'center',
            }}
          >
            <Icons.Menu style={{ height: '70px', width: '70px', marginTop: '25px' }} />
          </div> */}

          <div
            className="app_menu_sidebar mt-1"
            style={{
              flex:           1,
              flexDirection:  'column',
              justifyContent: 'flex-start',
              alignItems:     'center',
              // marginTop:      30,
              overflowY:      'auto',
              overflowX:      'hidden',
              width:          '70px',
            }}
          >
            {
              this.props.pages.map((page) => (
                <Button
                  className="block p-0"
                  key={`link-page-${page.id}`}
                  style={{
                    backgroundColor:        (this.props.pageSelected && this.props.pageSelected.id === page.id) ? Colours.White : 'transparent',
                    borderRadius:           0,
                    borderTopLeftRadius:    (this.props.pageSelected && this.props.pageSelected.id === page.id) ? 30 : 0,
                    borderBottomLeftRadius: (this.props.pageSelected && this.props.pageSelected.id === page.id) ? 30 : 0,
                    marginLeft:             (this.props.pageSelected && this.props.pageSelected.id === page.id) ? 2 : 0,
                    ...menuItemStyle,
                  }}
                >
                  <Link
                    className="block menu-item d-flex align-items-center"
                    to={`/page/${page.id}`}
                    title={page.name}
                    onClick={() => {
                      this.onPageClicked(page);
                      this.setActiveIconTab(page.name || null);
                    }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        flexDirection:  'column',
                        justifyContent: 'center' }}
                    >
                      {
                        page.pageLogo
                          ? <Avatar alt={page.name} src={page.pageLogo} />
                          : (
                            <Typography
                              variant="body2"
                              align="center"
                              color="inherit"
                              style={{ color: Colours.Black }}
                            >
                              {AcronymWord(String(page.name), 3)}
                            </Typography>
                          )
                      }
                    </div>
                  </Link>
                </Button>
              ))
            }

            {
              !IsEmpty(this.props.invites) && (
                this.props.invites.map((invite) => (
                  <Button
                    className="block p-0"
                    key={`link-invite-${invite.id}`}
                    style={{
                      backgroundColor:        (this.props.inviteSelected && this.props.inviteSelected.id === invite.id) ? Colours.White : 'transparent',
                      borderRadius:           0,
                      borderTopLeftRadius:    (this.props.inviteSelected && this.props.inviteSelected.id === invite.id) ? 30 : 0,
                      borderBottomLeftRadius: (this.props.inviteSelected && this.props.inviteSelected.id === invite.id) ? 30 : 0,
                      ...menuItemStyle,
                    }}
                  >
                    <Link
                      className="block menu-item d-flex align-items-center"
                      to={`${Constants.Url.Invitation.replace(':inviteId', invite.id)}`}
                      title={invite.pageName}
                      onClick={() => {
                        this.props.dispatchInviteSelected(invite);
                        this.setActiveIconTab(Constants.Url.Invitation);
                      }}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        {
                          invite.pageLogo ? (
                            <Avatar alt={invite.pageName} src={invite.pageLogo} />
                          ) : (
                            <>
                              <Icons.Invitation colour={Colours.Gray} />
                              <Typography
                                variant="body2"
                                align="center"
                                color="inherit"
                                style={{ color: Colours.Black }}
                              >
                                {AcronymWord(String(invite.pageName), 3)}
                              </Typography>
                            </>
                          )
                        }

                      </div>
                    </Link>
                  </Button>
                ))
              )
            }

            {
              !IsEmpty(this.props.pageClaims) && (
                <div className="mb-0" style={{}}>
                  {
                    this.props.pageClaims.map((pageClaim) => (
                      <Button
                        className="block p-0"
                        key={`link-pageClaim-${pageClaim.id}`}
                        style={{
                          backgroundColor:        (this.props.pageClaimSelected && this.props.pageClaimSelected.id === pageClaim.id) ? Colours.White : 'transparent',
                          borderRadius:           0,
                          borderTopLeftRadius:    (this.props.pageClaimSelected && this.props.pageClaimSelected.id === pageClaim.id) ? 30 : 0,
                          borderBottomLeftRadius: (this.props.pageClaimSelected && this.props.pageClaimSelected.id === pageClaim.id) ? 30 : 0,
                          marginLeft:             (this.props.pageClaimSelected && this.props.pageClaimSelected.id === pageClaim.id) ? 2 : 0,
                          ...menuItemStyle,
                        }}
                      >
                        <Link
                          className="block menu-item d-flex align-items-center"
                          to={`${Constants.Url.PageClaimView.replace(':pageClaimId', pageClaim.id)}`}
                          title={pageClaim.pageName}
                          onClick={() => {
                            this.props.dispatchPageClaimSelected(pageClaim);
                            this.setActiveIconTab(Constants.Url.PageClaimView);
                          }}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div className="d-flex flex-row justify-content-center align-items-center">
                            <Typography
                              variant="body2"
                              align="center"
                              color="inherit"
                              style={{ color: Colours.Black }}
                            >
                              {AcronymWord(String(pageClaim.pageName), 3)}
                            </Typography>
                          </div>
                        </Link>
                      </Button>
                    ))
                  }
                </div>
              )
            }
          </div>

          <div className="d-flex" style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Button
              className="block p-0"
              style={{
                backgroundColor:        Constants.Url.CreatePageClaim === this.state.activeIconTab ? Colours.White : 'transparent',
                borderRadius:           0,
                borderTopLeftRadius:    Constants.Url.CreatePageClaim === this.state.activeIconTab ? 30 : 0,
                borderBottomLeftRadius: Constants.Url.CreatePageClaim === this.state.activeIconTab ? 30 : 0,
                marginLeft:             Constants.Url.CreatePageClaim === this.state.activeIconTab ? 4 : 0,
                ...menuItemStyle,
              }}
              onClick={() => {
                this.props.dispatchPageClaimNew();
                this.setActiveIconTab(Constants.Url.CreatePageClaim);
              }}
            >
              <Link
                className="block py-2 d-flex flex-column justify-content-center align-items-center"
                to={Constants.Url.CreatePageClaim}
                title="Create/Claim Page"
              >
                <Icons.Add colour={Constants.Url.CreatePageClaim === this.state.activeIconTab ? Colours.Black : Colours.Gray2} />
              </Link>
            </Button>

            {this.renderIconLinks()}

            <Button
              className="mb-1"
              title="Logout"
              style={{
                borderRadius: 0,
                ...menuItemStyle,
              }}
              onClick={() => {
               this.onLogoutIconClicked();
              }}
            >
              <Icons.Logout colour={Colours.Gray2} />
            </Button>
          </div>
        </div>

        { /* Sub-menu directly on the right */ }
        {
          this.props.viewName && String(this.props.viewName).includes('business')
          && this.props.viewName !== 'select_business'
          && <BizSideBar style={subMenuStyle} match={this.props.match} />
        }

        {
          // this.props.viewName
          // && String(this.props.viewName).startsWith('page')
          !IsEmpty(this.props.match)
          && !IsEmpty(this.props.match.params.pageId)
          && <PageSideBar style={subMenuStyle} match={this.props.match} />
        }
      </div>
    );
  }
}

AppMenuSideBar.propTypes = {
  dispatchInviteSelected:    PropTypes.func.isRequired,
  dispatchPageSelected:      PropTypes.func.isRequired,
  dispatchPageClaimSelected: PropTypes.func.isRequired,
  dispatchPageClaimNew:      PropTypes.func.isRequired,
  dispatchLogoutModalToggle: PropTypes.func.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      businessId: PropTypes.string,
      pageId:     PropTypes.string,
    }).isRequired,
  }).isRequired,

  invites:        PropTypes.arrayOf(PropTypes.shape()).isRequired,
  inviteSelected: PropTypes.PropTypes.shape().isRequired,

  pageClaims:        PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pageClaimSelected: PropTypes.PropTypes.shape().isRequired,

  pages: PropTypes.arrayOf(
    PropTypes.shape({
      businessAccountId: PropTypes.number,
      id:                PropTypes.number,
      name:              PropTypes.string,
    }),
  ).isRequired,

  pageSelected: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,

  style:    PropTypes.shape({}),
  viewName: PropTypes.string.isRequired,
};

AppMenuSideBar.defaultProps = {
  style: {},
};

const mapStateToProps = (state) => ({
  invites:        state.invite.invites,
  inviteSelected: state.invite.selected,

  pageClaims:        state.pageClaim.pageClaims,
  pageClaimSelected: state.pageClaim.selected,

  pages:        state.page.pages,
  pageSelected: state.page.selected,
  viewName:     state.view.name,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchInviteSelected:    (invite) => inviteAction.inviteSelected(dispatch, invite),
  dispatchLogoutModalToggle: () => layoutAction.logoutModalToggle(dispatch),

  dispatchPageClaimNew:      () => pageClaimAction.pageClaimNew(dispatch),
  dispatchPageClaimSelected: (pageClaim) => pageClaimAction.pageClaimSelected(dispatch, pageClaim),

  dispatchPageSelected: (page) => pageAction.pageSelected(dispatch, page),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMenuSideBar);
