import axios from 'axios';

// export const axiosConfig = {
//   headers: { "Content-Type": "application/json" },
//   validateStatus: function (status) { return status < 400; }
// }

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token;
  } else {
    delete axios.defaults.headers.common['x-auth-token'];
  }
};

export const ThrowErrorMsg = (err) => {
  if (err.response) {
    throw err.response.data;
  } else {
    throw err.message;
  }
};
