import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, makeStyles, Paper, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForward from '@material-ui/icons/ArrowForwardIosOutlined';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { toast } from 'react-toastify';
import reviewAction from '../../../../actions/review';
import apiService from '../../../../services/apiService';
import { IsEmpty, IsAdminOrContributor } from '../../../../common/checks';
import Colours from '../../../../styles/colours';
import Loading from '../../../../components/Loading';
import postStyle from '../../../../styles/post';
import { formatDatetoString } from '../../../../common/util';
import Constants, { Status } from '../../../../common/constants';
import Tooltip from '../../../../components/Common/ToolTip';
import FilterReview from '../FilterReview';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding:  theme.spacing(2),
    margin:   'auto',
    maxWidth: '100%',
    flexGrow: 1,
  },
  image: {
    width:  '100%',
    height: 'fit-content',
  },
  img: {
    margin:    'auto',
    display:   'block',
    maxWidth:  '100%',
    maxHeight: '100%',
  },
}));

const ReviewListing = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [reviews, setReviews] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalReviewsCount, setTotalReviewCount] = useState(0);
  const [goToReviewId, setGoToReviewId] = useState(false);

  const [nthPage, setNthPage] = useState(1);
  const [range, setRange] = useState(pageSize);

  const [filterStatus, setFilterStatus] = useState();

  const classes = useStyles();

  const paginateReview = (dir) => {
    let currentPageNo = pageNo;

    if (dir === 'next') {
      currentPageNo += 1;
      setPageNo(currentPageNo);
      setRange(pageSize * currentPageNo);

      if (currentPageNo > 1) {
        setNthPage((pageSize * (currentPageNo)) - 4);

        // last page
        if ((pageSize * currentPageNo) > totalReviewsCount) {
          setRange(totalReviewsCount);
        }
      }
    } else {
      currentPageNo -= 1;
      setPageNo(currentPageNo);

      if (currentPageNo === 1) {
        setNthPage(1);
      } else {
        setNthPage((pageSize * currentPageNo) - pageSize + 1);
      }

      setRange(pageSize * currentPageNo);
    }
  };

  const updateFilterStatus = (data) => setFilterStatus(data);

  const getPageReviews = (pageNumber = pageNo - 1) => {
    setIsLoading(true);
    setPageSize(5);
    setGoToReviewId(false);

    apiService.getReviews(props.page.id, { pageId: props.page.id, pageNo: pageNumber, status: filterStatus })
      .then(({ rows, totalCount }) => {
        setReviews(rows);

        // Check if whole number. returns true otherwise false.
        if (Number.isInteger(totalCount / pageSize)) {
          setTotalPages(Math.floor(totalCount / pageSize));
        } else {
          // we add +1 from the total count for the remainder.
          setTotalPages(Math.floor(totalCount / pageSize) + 1);
        }

        if (pageSize > totalCount && nthPage <= totalCount) {
          setRange(rows.length);
        }

        setTotalReviewCount(totalCount);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPageReviews();
    toast.error(`Update of review via mobile phone is currently not supported. Please use desktop to update review`, { autoClose: 9000 });
  }, [pageNo, filterStatus]);

  const editReview = (review) => {
    props.dispatchReviewSelected(review);
    props.history.push(Constants.Url.Review.replace(':pageId', props.match.params.pageId).replace(':reviewId', review.id));
  };

  const handlePullRefresh = async () => {
    await getPageReviews(0);
    setPageNo(1);
    setNthPage(1);
    setRange(pageSize);
  };

  if (goToReviewId) {
    return (
      /** The props 'push' is required to main the history state so we can navigate back and forth */
      <Redirect push to={`/page/${props.match.params.pageId}/review/${goToReviewId}`} />
    );
  } else {
    return (
      <div className="container-fluid">
        <PullToRefresh onRefresh={handlePullRefresh}>
          <div className="container mt-4 px-0 d-flex">
            <div className="d-flex flex-row align-items-center" style={{ width: '100%' }}>
              <Paper className="d-flex flex-row p-2 justify-content-between" elevation={1} style={{ width: '100%' }}>
                <Typography variant="body1" color="inherit" className="mt-1 ml-2 float-left" style={postStyle.textHeader}>Reviews</Typography>
                <Tooltip title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => props.addReview()}
                    disabled
                  >
                    Create
                  </Button>
                </Tooltip>
              </Paper>
            </div>
          </div>

          <div className="container px-0 mb-4">
            <div className="row">
              <div className="col-12 mt-2 px-4">
                <FilterReview
                  updateStatusFilter={updateFilterStatus}
                  isMobile
                />
              </div>
            </div>
          </div>

          { !IsEmpty(reviews)
            ? (
              <>
                <div className="container my-3 px-0 d-flex">
                  <div className="d-flex flex-row align-items-center" style={{ width: '100%' }}>
                    <Paper className={classes.paper} elevation={1} style={{ textAlign: 'center' }}>
                      { pageNo > 1 && <ArrowBack size="small" className="d-inline-block text-left" onClick={() => paginateReview('prev')} /> }

                      <Typography variant="body1" color="inherit" className="d-inline-block mx-4" style={{ color: Colours.Gray2 }}>
                        {`${nthPage === range ? nthPage : `${nthPage} - ${range}`} of ${totalReviewsCount}`}
                      </Typography>

                      { pageNo < totalPages
                      && <ArrowForward size="small" className="d-inline-block text-right" onClick={() => paginateReview('next')} /> }
                    </Paper>
                  </div>
                </div>

                <div className="container my-2 px-0 d-flex">
                  <div className="row">
                    { isLoading ? <Loading />
                      : reviews.map((r) => (
                        <div
                          key={r.id}
                          className="col-12"
                          style={{
                            minWidth: '100vw',
                          }}
                        >
                          <div className="d-flex flex-row align-items-center mt-2 mb-4" style={{ width: '100%' }}>
                            <Paper
                              className={classes.paper}
                              elevation={1}
                              style={{ backgroundColor: Colours.White, padding: 0, borderWidth: 1, borderColor: Colours.Gray4, borderStyle: 'solid' }}
                              // onClick={() => {
                              // editReview(r);
                              // }}
                            >

                              <div style={{ position: 'relative', width: '100%', minHeight: 250, height: 'auto' }}>
                                {
                                  !IsEmpty(r.videos)
                                    ? (
                                      <>
                                        <ReactPlayer
                                          url={r.videos[0]}
                                          muted
                                          playing
                                          loop
                                          width="100%"
                                          height="auto"
                                        />
                                      </>
                                    )
                                    : (
                                      <>
                                        {
                                          !IsEmpty(r.images) && (
                                            <img
                                              style={{
                                                width:  'auto',
                                                height: 'auto',
                                              }}
                                              alt={r.title || 'cover-photo'}
                                              src={r.images[0].display}
                                            />
                                          )
                                        }
                                      </>
                                    )
                                }

                                { IsEmpty(r.videos) && IsEmpty(r.images) && (
                                  <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{
                                      background: Colours.Gray3,
                                      minHeight:  300,
                                      width:      '100%',
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      color="inherit"
                                      style={{
                                        color: Colours.Black,
                                      }}
                                    >
                                      No media found

                                    </Typography>
                                  </div>
                                )}

                                { /** Display posted date only if post's status is Live **/
                                  (r.status === Status.Live && r.reviewDate) && (
                                    <div
                                      style={{
                                        background: 'rgba(0,0,0,0.5)',
                                        position:   'absolute',
                                        bottom:     15,
                                        left:       0,
                                      }}
                                      className="px-3 py-1"
                                    >
                                      <Typography variant="caption" color="inherit" style={{ color: Colours.White }}>
                                        { `Posted on ${formatDatetoString(r.reviewDate)}` }
                                      </Typography>
                                    </div>
                                  )
                                }
                              </div>

                              <div className="col-12 my-3">
                                <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>{r.title}</Typography>
                              </div>

                              <div className="col-12 mb-3">
                                <Typography
                                  variant="body1"
                                  color="inherit"
                                  className="d-inline-block px-3"
                                  style={{
                                    color:                  Colours.Black,
                                    border:                 `1px solid ${Colours.Gray2}`,
                                    borderRight:            0,
                                    borderTopLeftRadius:    5,
                                    borderBottomLeftRadius: 5 }}
                                >
                                  Status
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="inherit"
                                  className="d-inline-block px-3"
                                  style={{
                                    border:                  `1px solid ${Colours.Gray2}`,
                                    borderTopRightRadius:    5,
                                    borderBottomRightRadius: 5,
                                    color:                   (r.status === Status.Draft || r.status === Status.Deleted) ? Colours.Orange : Colours.Primary }}
                                >
                                  { r.status }
                                </Typography>
                              </div>

                            </Paper>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="container px-0 d-flex">
                  <div className="d-flex flex-row align-items-center mb-4" style={{ width: '100%' }}>
                    <Paper className={classes.paper} elevation={1} style={{ textAlign: 'center' }}>
                      { pageNo > 1 && <ArrowBack size="small" className="d-inline-block text-left" onClick={() => paginateReview('prev')} /> }

                      <Typography variant="body1" color="inherit" className="d-inline-block mx-4" style={{ color: Colours.Gray2 }}>
                        {`${nthPage === range ? nthPage : `${nthPage} - ${range}`} of ${totalReviewsCount}`}
                      </Typography>

                      { pageNo < totalPages
                      && <ArrowForward size="small" className="d-inline-block text-right" onClick={() => paginateReview('next')} /> }
                    </Paper>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="container mt-3 px-0 d-flex">
                  <div className="d-flex flex-row align-items-center mb-3" style={{ width: '100%', height: 200 }}>
                    <Paper
                      className={classes.paper}
                      elevation={1}
                      style={{ textAlign: 'center', height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <Typography variant="body1" color="inherit" className="d-inline-block mx-4" style={{ color: Colours.Primary }}>
                        + Click to add your first review
                      </Typography>
                    </Paper>
                  </div>
                </div>
              </>
            )}
        </PullToRefresh>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewSelected: (review) => reviewAction.reviewSelected(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewListing);
