import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import PostAction from '../../../../actions/post';
import SelectCategories from '../../../../components/SelectCategories';
import Colours from '../../../../styles/colours';

const PostCategories = (props) => {
  const [postCategories, setPostCategories] = useState([]);

  useEffect(() => {
    setPostCategories(props.post.categories ? props.post.categories : []);
  }, [props.post]);

  return (

    <div className="d-flex flex-column" style={{ background: (props.disabled ? Colours.LightGray : Colours.White) }}>

      <Tooltip
        title={props.disabled ? props.disabledMsg : ''}
        isLink
      >
        <div className="ml-3">
          <SelectCategories
            value={postCategories}
            onChange={(cats) => {
              props.dispatchPostUpdate({  categories: cats  });
            }}
            disabled={props.disabled}
          />
        </div>
      </Tooltip>

    </div>
  );
};

PostCategories.propTypes = {
  disabled:           PropTypes.bool,
  disabledMsg:        'Cannot be updated',
  dispatchPostUpdate: PropTypes.func.isRequired,
  post:               PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({
      id:    PropTypes.number,
      name:  PropTypes.string,
      group: PropTypes.string,
    })),
  }).isRequired,
};

PostCategories.defaultProps = {
  disabled:    false,
  disabledMsg: PropTypes.string,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCategories);
