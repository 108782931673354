import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Typography, Paper, FormControl, RadioGroup, FormControlLabel, Radio, Tooltip } from '@material-ui/core';
import moment from 'moment';
import MaterialDatePicker from '../../../../components/Common/form/MaterialDatePicker';
import Colours from '../../../../styles/colours';
import { IsEmpty } from '../../../../common/util';
import { Status } from '../../../../common/constants';
import PostAction from '../../../../actions/post';
import PromoCodes from './PromoCodes';
import PostCategories from './PostCategories';
import postStyle from '../../../../styles/post';
import Tags from './Tags';

const Details = (props) => {
  const [title, setTitle] = useState('');

  const [promoPeriodRangeStatus, setPromoPeriodRangeStatus] = useState(false);
  const [noSpecPeriodStatus, setNoSpecPeriodStatus] = useState(true);
  const [promoFromDate, setPromoFromDate] = useState('');
  const [promoTillDate, setPromoTillDate] = useState('');
  const [description, setDescription] = useState('');
  const [promoTerms, setPromoTerms] = useState('');
  const [disableInputFields, setDisableInputFields] = useState(false);

  useEffect(() => {
    if (props && props.post) {
      const p = props.post;
      setTitle(p.title || '');
      setPromoFromDate(p.promoFromDate || '');
      setPromoTillDate(p.promoTillDate || '');
      setDescription(p.description || '');
      setPromoTerms(p.promoTerms || '');

      if (!IsEmpty(p.promoFromDate) || !IsEmpty(p.promoTillDate)) {
        setPromoPeriodRangeStatus(true);
        setNoSpecPeriodStatus(false);
      }

      setDisableInputFields([Status.Live, Status.Paused].includes(props.post.currentPostStatus));
    }
  }, [props.post]);

  return (
    <>
      <Paper variant="elevation" className="px-5" style={postStyle.paper}>
        <Typography variant="subtitle1" color="inherit" className="mt-3 mb-2" style={postStyle.textHeader}>
          Title
          <sup style={{ color: Colours.Red }}>*</sup>
        </Typography>

        <TextField
          className="mb-3"
          id="standard-multiline-flexible"
          name="title"
          value={title || ''}
          variant="outlined"
          size="small"
          fullWidth
          helperText={
            IsEmpty(title) ? <Typography component="span" variant="body2" style={{ color: 'red' }}>Title is required</Typography> : ''
          }
          onChange={(e) => props.dispatchPostUpdate({ title: e.currentTarget.value })}
        />

        <hr className="my-4" />

        <Typography variant="subtitle1" color="inherit" className="mt-4 mb-1" style={postStyle.textHeader}>
          Period
          <sup style={{ color: Colours.Red }}>*</sup>
        </Typography>

        { ![Status.Live, Status.Pending].includes(props.post.currentPostStatus) ? (
          <FormControl component="fieldset" className="pl-4">
            <RadioGroup aria-label="promo period" name="customized-radios">
              <FormControlLabel
                control={(
                  <>
                    <Radio checked={promoPeriodRangeStatus} color="primary" disabled={disableInputFields} />

                    <MaterialDatePicker
                      disabled={disableInputFields}
                      minDate={false}
                      onChange={(date = new Date()) => {
                        const from = date.toString('yyyy-MM-dd');
                        props.dispatchPostUpdate({ promoFromDate: from });
                      }}
                      value={!promoFromDate ? null : new Date(promoFromDate)}
                      readOnly={!promoPeriodRangeStatus}
                      label="Starts on"
                      size="small"
                      variant="outlined"
                      style={{ marginLeft: 10, marginRight: 20 }}
                    />

                    <MaterialDatePicker
                      disabled={disableInputFields}
                      minDate={!promoFromDate ? false : new Date(promoFromDate)}
                      onChange={(date = new Date()) => {
                        const to = date.toString('yyyy-MM-dd');
                        props.dispatchPostUpdate({ promoTillDate: to });
                      }}
                      value={!promoTillDate ? null : new Date(promoTillDate)}
                      readOnly={!promoPeriodRangeStatus}
                      label="Ends on (inclusive)"
                      size="small"
                      variant="outlined"
                    />
                  </>
                )}
                onClick={() => {
                  if (!disableInputFields) {
                    setPromoPeriodRangeStatus(true);
                    setNoSpecPeriodStatus(false);
                  }
                }}
              />

              <FormControlLabel
                control={(
                  <>
                    <Radio checked={noSpecPeriodStatus} color="primary" disabled={disableInputFields} />
                    <Typography style={{ marginLeft: 10 }} variant="body1">No specific period</Typography>
                  </>
                )}
                onClick={() => {
                  if (!disableInputFields) {
                    props.dispatchPostUpdate({ promoFromDate: null, promoTillDate: null });
                    setNoSpecPeriodStatus(true);
                    setPromoPeriodRangeStatus(false);
                  }
                }}
              />
            </RadioGroup>
          </FormControl>
        ) : (
          <>
            <div className="alert alert-warning" role="alert">
              <Typography variant="body">
                Promo dates cannot be changed once the post goes live.
              </Typography>
              <br />
              <hr />
              { props.post.promoFromDate && props.post.promoTillDate ? (
                <div>
                  <h5><strong>{`Promo starts: ${moment(new Date(props.post.promoFromDate)).utcOffset(+8, false).format('YYYY-MM-DD')}`}</strong></h5>
                  <h5><strong>{`Promo ends: ${moment(new Date(props.post.promoTillDate)).utcOffset(+8, false).format('YYYY-MM-DD')}`}</strong></h5>
                </div>
              ) : <Typography variant="body2" style={{ fontWeight: 'bold' }}>Promo period not specified</Typography>}
            </div>
          </>
        ) }

        <hr className="my-4" />

        <Typography variant="subtitle1" color="inherit" className="mt-4 mb-2" style={postStyle.textHeader}>
          Description
        </Typography>

        <TextField
          id="details-description"
          multiline
          rows={4}
          maxRows={12}
          name="description"
          value={description || ''}
          onChange={(e) => props.dispatchPostUpdate({ description: e.currentTarget.value })}
          variant="outlined"
          size="small"
          fullWidth
        />

        <hr className="my-4" />
        <Typography variant="subtitle1" color="inherit" className="mt-4" style={postStyle.textHeader}>
          Categories
          <sup style={{ color: Colours.Red }}>*</sup>
        </Typography>
        <PostCategories disabled={disableInputFields} disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`} />

        <hr className="my-4" />
        <Typography variant="subtitle1" color="inherit" className="mt-4 mb-2" style={postStyle.textHeader}>
          Promo Codes
        </Typography>
        <PromoCodes disabled={disableInputFields} disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`} />

        <Typography variant="subtitle1" color="inherit" className="mt-4 mb-2" style={postStyle.textHeader}>
          Terms &amp; Conditions
        </Typography>
        <Tooltip
          title={disableInputFields ? `Cannot be updated because post is ${props.post.currentPostStatus}` : ''}
          isLink
        >
          <TextField
            className="mb-3"
            id="details-tnc"
            multiline
            rows={4}
            maxRows={8}
            name="terms"
            value={promoTerms || ''}
            onChange={(e) => props.dispatchPostUpdate({ promoTerms: e.currentTarget.value })}
            variant="outlined"
            size="small"
            fullWidth
            disabled={disableInputFields}
          />
        </Tooltip>

        <Tags disabled={disableInputFields} disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`} />
      </Paper>
    </>
  );
};

Details.propTypes = {
  post: PropTypes.shape({
    status:            PropTypes.string,
    currentPostStatus: PropTypes.string,
    title:             PropTypes.string,
    promoFromDate:     PropTypes.string,
    promoTillDate:     PropTypes.string,
    description:       PropTypes.string,
    promoTerms:        PropTypes.string,
  }).isRequired,
  dispatchPostUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
