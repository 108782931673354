import styled from 'styled-components';
import { Button } from '@material-ui/core';
import Colours from './colours';

const PageMenuButton = styled(Button)`
  background-color: ${Colours.White};
  border-radius: 0px;
  &:hover {
    background-color: ${Colours.LightGray};
    border-radius: 0px;
  }
`;

export { PageMenuButton };
