import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { Paper } from '@material-ui/core';
import { Status, WindowSize } from '../../../../common/constants';
import Colours from '../../../../styles/colours';
import ApiService from '../../../../services/apiService';
import { IsEmpty } from '../../../../common/util';

const PostsListing = (props) => {
  const tableRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [curPageNo, setCurPageNo] = useState(0);

  return (
    <MaterialTable
      title=""
      tableRef={tableRef}
      components={{
        // eslint-disable-next-line react/jsx-props-no-spreading
        Container: (p) => <Paper {...p} elevation={0} />,
        Toolbar:   () => null,
      }}
      columns={[
        {
          title:     '',
          field:     'post.images',
          cellStyle: { width: '10%' },
          render:    (post) => (
            <div style={{ width: 45, height: 45, border: `1px solid ${Colours.Gray2}` }}>
              { !IsEmpty(post.images) ? <img src={post.images[0].display} alt="post-img" style={{ width: 45, height: 45 }} /> : '' }
            </div>
          ),
        },
        {
          title:     'Title',
          field:     'title',
          cellStyle: { width: '90%' },
        },
      ]}
      localization={{
        header: {
          actions: '',
        },
      }}
      // eslint-disable-next-line no-async-promise-executor
      data={(query) => new Promise(async (resolve) => {
        try {
          const data = await ApiService.getAnalyticsPagePostsList(
            props.pageId,
            {
              pageNo:   query.page,
              pageSize: query.pageSize,
            },
          );

          setCurPageNo(query.page);

          if (data) {
            resolve({
              data:       data.posts || [],
              page:       query.page || 0,
              totalCount: data.totalCount || 0,
            });
            setIsLoading(false);
          } else {
            resolve({
              data:       [],
              page:       0,
              totalCount: 0,
            });
            setIsLoading(false);
          }
        } catch (err) {
          toast.error(err.message);
          resolve({
            data:       [],
            page:       0,
            totalCount: 0,
          });
          setIsLoading(false);
        }
      })}
      onChangePage={(pageNo) => setCurPageNo(pageNo)}
      onRowClick={(event, post) => {
        if (selectedRowId[0] !== post.tableData.id || props.windowSize === WindowSize.Mobile) {
          // trigger callbacks
          props.setData(post);
          props.refresh();

          setSelectedRowId([post.tableData.id, curPageNo]);
        }
      }}
      options={{
        actionsColumnIndex:  -1,
        search:              false,
        emptyRowsWhenPaging: false,
        debounceInterval:    500,
        pageSizeOptions:     [5, 10, 20, 40, 60, 80],
        rowStyle:            (rowData) => ({
          backgroundColor:
            rowData.tableData.id === selectedRowId[0]
            && selectedRowId[1] === curPageNo
            && props.windowSize === WindowSize.Desktop
              ? Colours.Gray3
              : '',
        }),
      }}
      isLoading={isLoading}
    />
  );
};

PostsListing.propTypes = {
  pageId:     PropTypes.number.isRequired,
  setData:    PropTypes.func,
  refresh:    PropTypes.func,
  windowSize: PropTypes.string.isRequired,
};

PostsListing.defaultProps = {
  setData: () => null,
  refresh: () => false,
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(PostsListing);
