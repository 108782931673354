// React Dependencies
import React, { Component, createRef } from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputAdornment, TextField, Button,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import SearchIcon from '@material-ui/icons/Search';

// Internal Dependencies
import ApiService from '../../../services/apiService';
import postAction from '../../../actions/post';
import { IsEmpty, IsTrue } from '../../../common/checks';
import { formatDateToDayMonthNameYear, showDateInSGTime, ElipsisString } from '../../../common/util';
import Colours from '../../../styles/colours';
import { Status } from '../../../common/constants';

const pageOptions = [5, 10, 15, 20, 25];
class PostTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirUrl:         null,
      pageSize:         pageOptions[1],
      search:           '', //the searchBar component state
      searchQuery:      '', //the table query search state
      searchFocus:      false,
      openDeleteDialog: false,
      postToDelete:     null,
    };

    this.tableRef = createRef();

    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.deletePost = this.deletePost.bind(this);
  }

   handleRedirect = (url) => {
    this.setState({ redirUrl: url });
   }

  deletePost = async () => {
    try {
      const { postToDelete } = this.state;

      if (!IsEmpty(postToDelete)) {
        const softDeleteStatus = [Status.Live, Status.Paused];

        if (softDeleteStatus.includes(postToDelete.status)) {
          await ApiService.updatePost(postToDelete.id, { status: Status.Deleted });
        } else {
          await ApiService.deletePost(postToDelete.id);
        }

        this.props.setRefreshPicMarkerPost(true);
        this.tableRef.current.onQueryChange();
        this.props.dispatchPostUpdate({ status: Status.Deleted });
        toast.success('Post deleted');
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      this.setState({ openDeleteDialog: false });
    }
  };

  handleChangeRowsPerPage = (pageSize) => {
    this.setState({ pageSize });
  };

  debounceSearchQuery = _.debounce((text) => this.setState({ searchQuery: text }, () => this.tableRef.current.onQueryChange()), 500);

  handleSearchChange = (e) => {
    this.setState({
      search:      e.target.value,
      searchFocus: true,
    });
    this.debounceSearchQuery(e.target.value);
  };

  render() {
    if (this.state.redirUrl) { return <Redirect to={this.state.redirUrl} />; }

    return (
      <>
        <MaterialTable
          tableRef={this.tableRef}
          title=""
          columns={[
            { title: 'Id', field: 'id', width: '5%' },
            {
              title:  'Image', field:  'image', render: (post) => {
                let postImage = '';
                if (post.images.length > 0) {
                  postImage = <img src={post.images[0].original} style={{ maxWidth: '100px', resizeMode: 'contain' }} alt="post img" />;
                }

                return postImage;
              },
              sorting: false,
              width:   '10%',
            },
            {
              title:  'Title', field:  'title', render: (post) => {
              // Exclusive highlight post has its post id saved in 'postId'
                const postId = !IsEmpty(post.postId) ? post.postId : post.id;
                return (
                  <Link to={`/page/${this.props.pageId}/post/${postId}${!IsEmpty(this.props.pageHighlightId) ? `?pageHighlightId=${this.props.pageHighlightId}` : ''}`}>
                    {post.title}
                  </Link>
                );
              },
              width: '20%',
            },
            { title: 'Type', field: 'type', width: '7.5%' },
            { title: 'Status', field: 'status', width: '7.5%' },
            { title: 'Searchable Status', field: 'searchableStatus', width: '7.5%' },
            {
              title:  'Post Date',
              field:  'postDate',
              render: (post) => (post.postDate !== null ? `${formatDateToDayMonthNameYear(showDateInSGTime(post.postDate))}` : '-'),
              width:  '10%',
            },
            {
              title:  'Start Date',
              field:  'promoFromDate',
              render: (post) => (post.promoFromDate !== null ? `${formatDateToDayMonthNameYear(showDateInSGTime(post.promoFromDate))}` : '-'),
              width:  '10%',
            },
            {
              title:  'End Date',
              field:  'promoTillDate',
              render: (post) => (post.promoTillDate !== null ? `${formatDateToDayMonthNameYear(showDateInSGTime(post.promoTillDate))}` : '-'),
              width:  '10%',
            },
            {
              title:  'Page Highlight(s)', field:  'pageHighlights', render: (post) => (
                <div style={{ display: 'grid' }}>
                  {_.map(post.pageHighlights, (pageHighlight) => (
                    <Link
                      className="mb-2"
                      target="_blank"
                      to={`/page/${this.props.pageId}/highlight/${pageHighlight.id}${IsTrue(pageHighlight.isExclusive) ? `?isExclusive=true` : `?isSpecial=true`}`}
                    >
                      <div
                        className="px-3 py-2"
                        style={{
                          backgroundColor: pageHighlight.isSpecial ? Colours.Grey : Colours.Gold,
                          borderRadius:    15,
                        }}
                      >
                        <p style={{ color: pageHighlight.isExclusive ? Colours.Black : Colours.White }}>
                          {ElipsisString(pageHighlight.title, 25)}
                        </p>
                      </div>
                    </Link>
                  ))}
                </div>
              ),
              width: '30%',
            },
            { title: 'Last Updated By', field: 'lastUpdatedBy', width: '10%' },
          ]}
          components={{ Toolbar: () => (
            <div className="pt-3">
              <Box display="flex" justifyContent="flex-start" m={0} p={1} bgcolor="background.paper">
                <Box p={1} width="23%">
                  <FormControl fullWidth>
                    <TextField
                      label="Search Here "
                      id="standard-adornment-amount"
                      placeholder="Search by Title"
                      value={this.state.search}
                      onChange={this.handleSearchChange}
                      type="search"
                      startAdornment={(
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )}
                      autoFocus={this.state.searchFocus}
                      alignSelf="flex-end"
                    />
                  </FormControl>
                </Box>
              </Box>
            </div>
          ) }}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          data={(query) => new Promise(async (resolve) => {
            try {
              query.pageNo = query.page;
              query.order = { col: 'postDate', dir: 'desc' }; //default sort is descending post Date
              query.title = this.state.searchQuery;

              if (!IsEmpty(query.orderDirection) && !IsEmpty(query.orderBy)) {
                query.order = { col: query.orderBy.field, dir: query.orderDirection };
              }

              const { page } = query;
              const result = await ApiService.getPostPageHighlights({
                ...query,
                pageId:          this.props.pageId,
                pageHighlightId: this.props.pageHighlightId,
              });

            resolve({ data: result.rows, page, totalCount: result.totalCount });
            } catch (err) {
              toast.error(err.message);
              resolve({ data: [], page: 0, totalCount: 0 });
            }
          })}
          actions={[
            {
              icon:    'edit',
              tooltip: 'View/Edit Post',
              onClick: (event, post) => {
              // Exclusive highlight post has its post id saved in 'postId'
                const postId = !IsEmpty(post.postId) ? post.postId : post.id;
              this.handleRedirect(`/page/${this.props.pageId}/post/${postId}${!IsEmpty(this.props.pageHighlightId) ? `?pageHighlightId=${this.props.pageHighlightId}` : ''}`);
              },
            },
            {
              icon:    'delete',
              tooltip: 'Delete Post',
              onClick: (event, post) => {
              // Exclusive highlight post has its post id saved in 'postId'
                const postId = !IsEmpty(post.postId) ? post.postId : post.id;
                console.log('postId: ', postId);
                this.setState({ openDeleteDialog: true, postToDelete: ({ ...post, id: postId }) });
              },
            },
          ]}
          options={{
            actionsColumnIndex:  -1,
            sorting:             true,
            paging:              true,
            emptyRowsWhenPaging: false,
            pageSize:            this.state.pageSize,
            pageSizeOptions:     pageOptions,
          }}
        />

        <Dialog
          fullWidth
          size="sm"
          open={this.state.openDeleteDialog}
          onClose={() => this.setState({ openDeleteDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>Delete Post</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mb-2">
            <Button className="px-2" onClick={this.deletePost} color="default">Confirm</Button>
            <Button className="px-2 mx-3" onClick={() => this.setState({ openDeleteDialog: false })} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

PostTable.propTypes = {
  pageId:                  PropTypes.number.isRequired,
  pageHighlightId:         PropTypes.number,
  dispatchPostUpdate:      PropTypes.func.isRequired,
  setRefreshPicMarkerPost: PropTypes.func,
};

PostTable.defaultProps = {
  pageHighlightId:         null,
  setRefreshPicMarkerPost: () => {},
};

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => postAction.postDataUpdate(dispatch, post),
});

export default connect(null, mapDispatchToProps)(PostTable);
