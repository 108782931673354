/* eslint-disable react/jsx-props-no-spreading */
// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {  IconButton } from '@material-ui/core';

// Internal Dependencies
import ToolTip from '../ToolTip';
import Colours from '../../../styles/colours';
import Icons from '../Icons';

const MediaItem = ({ maxHt, minHt, maxWd, minWd, hasMinWidth, item, disabled, stylesActionBtns, handleCrop, handleDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id });

  const style = {
    transform:     CSS.Transform.toString(transform),
    transition,
    position:      'relative',
    top:           '0',
    right:         '15px',
    width:         hasMinWidth ? '250px' : 'auto',
    padding:       '10px 15px',
    margin:        '30px',
    paddingBottom: '50px',
    marginBottom:  '0px',
  };

  const actionContainerStyles = {
    position: 'absolute', bottom: '0', right: '10px',
    ...stylesActionBtns,
  };

  return (
    <div style={style}>
      <div ref={setNodeRef} {...attributes} {...listeners}>
        <img
          src={item.display}
          alt="monstyr"
          style={{
            minHeight:  minHt,
            minWidth:   minWd,
            maxHeight:  maxHt,
            maxWidth:   maxWd,
            resizeMode: 'contain',
            boxShadow:  '0 26px 30px -10px rgb(0 0 0 / 69%), 0 16px 10px -10px rgb(0 0 0 / 73%)',
          }}
        />
        <br />
      </div>

      <div style={actionContainerStyles}>
        <ToolTip title={!disabled ? 'Crop the Image' : 'Permission Denied'}>
          <IconButton
            onClick={() => handleCrop(item)}
            style={{ verticalAlign: 'bottom' }}
            disabled={disabled}
          >
            <Icons.Crop outline colour={Colours.Gray} fontSize="1.5rem" />
          </IconButton>
        </ToolTip>

        <ToolTip title={!disabled ? 'Delete the Image' : 'Permission Denied'}>
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              handleDelete(item);
            }}
            style={{ verticalAlign: 'bottom' }}
            disabled={disabled}
          >
            <Icons.DeleteBin outline colour={Colours.Gray} fontSize="1.5rem" />
          </IconButton>
        </ToolTip>
      </div>
    </div>
  );
};

export default MediaItem;

MediaItem.propTypes = {
  item:             PropTypes.shape(Object).isRequired,
  disabled:         PropTypes.bool,
  hasMinWidth:      PropTypes.bool,
  maxHt:            PropTypes.number.isRequired,
  minHt:            PropTypes.number.isRequired,
  maxWd:            PropTypes.number.isRequired,
  minWd:            PropTypes.number.isRequired,
  stylesActionBtns: PropTypes.shape(Object),
  handleCrop:       PropTypes.func,
  handleDelete:     PropTypes.func,
};

MediaItem.defaultProps = {
  disabled:         false,
  hasMinWidth:      false,
  stylesActionBtns: {},
  handleCrop:       () => {},
  handleDelete:     () => {},
};
