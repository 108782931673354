import styled from 'styled-components';
import {  Link } from 'react-router-dom';
import Colours from '../colours';

const TabLinkStyled = styled(Link)`
/* Adapt the colors based on primary prop */
color: ${(props) => (props.checked ? Colours.Primary : Colours.Gray)};
border-bottom: 2px solid  ${(props) => (props.checked ? Colours.Primary : 'transparent')};
text-decoration : none;
padding : 10px;
margin : 10px;
&:hover {
  color: ${Colours.Primary};
}
`;

export { TabLinkStyled };
