import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Chip, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import pageAction from '../../../actions/page';
import { IsEmpty, Trim } from '../../../common/util';
import ApiService from '../../../services/apiService';

const PageTags = (props) => {
  const [userTags, setUserTags] = useState([]);
  const [suggest, setSuggest] = useState([]);

  const onInputChanged = async (value) => {
    try {
      // search only for approved tags
      value = Trim(value);
      if (!IsEmpty(value)) {
        const { rows } = await ApiService.getTags({ name: value, status: 'approved', pageNo: 0, pageSize: 10 });
        const tags = _.map(rows, (r) => r.name);
        setSuggest(tags);
      } else {
        setSuggest([]);
      }
    } catch (error) {
      console.log('Error getting suggestions: ', error);
      setSuggest([]);
    }
  };

  const inputDebounced = _.debounce(onInputChanged, 500);

  useEffect(() => {
    setUserTags(!IsEmpty(props.page.userTags) ? props.page.userTags : []);
  }, [props.page]);

  const addInputAsUserTag = (value) => {
    // if value is already an existing tag, don't need to add it
    try {
      value = Trim(value);
      if (!IsEmpty(value) && !props.page.userTags.includes(value)) {
        props.dispatchPageUpdate({ userTags: props.page.userTags.concat(value) });
      }
    } catch (error) {
      toast.error('An error occured while adding tag');
      console.log('Error in addInputAsUserTag(): ', error);
    }
  };

  return (
    <Autocomplete
      id="tags"
      className="mb-3"
      limitTags={5}
      multiple
      options={suggest}
      value={userTags}
      disableCloseOnSelect
      filterSelectedOptions
      freeSolo
      renderTags={(value, getTagProps) => value.map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          variant="outlined"
          label={option}
          //style={postStyle.chip}
        />
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => inputDebounced(e.target.value)}
          placeholder="Type and hit 'Enter' to add tag"
          onBlur={() => addInputAsUserTag(params.inputProps.value)}
        />
      )}
      onChange={(event, values, reason) => {
        // values will contain the distinct and updated array
        props.dispatchPageUpdate({ userTags: values });
      }}
    />
  );
};

PageTags.propTypes = {
  page: PropTypes.shape({
    userTags: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,

  dispatchPageUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  page: state.page.update,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageUpdate: (page) => pageAction.pageUpdate(dispatch, page),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageTags);
