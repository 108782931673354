import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Trim, Sleep } from '../../common/util';
import apiService from '../../services/apiService';
import { IsEmpty } from '../../common/checks';
import Colours from '../../styles/colours';

const SearchAddress = (props) => {
  const [inputPostalCode, setInputPostalCode] = useState('');
  const [inputUnitNo, setInputUnitNo] = useState('');
  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    // on address selection change, inform parent com about it
    props.onChange(selected);
  }, [selected]);

  async function searchByPostalCode(postalCode = '') {
    try {
      const { rows } = await apiService.getAddresses({ postalCode, pageNo: 0, pageSize: 4 });
      setSuggestedAddresses(rows);
    } catch (error) {
      toast.error(error.message);
    } finally {
      await Sleep(400);
      setIsSearching(false);
    }
  }

  const onInputPostalCodeChanged = (pc) => {
    try {
      setIsSearching(true);
      setInputPostalCode(pc);
      if (!IsEmpty(suggestedAddresses)) {
        setSuggestedAddresses([]);
      }
      if (!IsEmpty(selected)) {
        setSelected(null);
      }

      searchByPostalCode(pc);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addressSearchDebounced = _.debounce(onInputPostalCodeChanged, 300);

  const onManualAddressChanged = (addr) => {
    try {
      if (IsEmpty(addr)) {
        setSelected(null);
      } else {
        setSelected({
          postalCode: inputPostalCode,
          address:    addr,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onUnitNoChanged = (unitNo) => {
    try {
      const trimmedUnitNo = Trim(unitNo);

      setSelected({
        ...selected,
        unitNo: trimmedUnitNo,
      });

      setInputUnitNo(trimmedUnitNo);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const manualAddressInputDebounced = _.debounce(onManualAddressChanged, 500);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 d-flex flex-row align-items-center">
          <Typography variant="subtitle1" style={{ fontSize: 14 }}>Enter Postal Code:</Typography>
          <TextField
            className="ml-3"
            size="small"
            variant="outlined"
            onChange={(evt) => addressSearchDebounced(evt.target.value)}
          />
        </div>
        <div className="mt-3 col-12">
          {
            isSearching && (
              <div className="d-flex flex-row align-items-center">
                <Typography variant="subtitle1" className="ml-3" style={{ fontSize: 14 }}>Searching</Typography>
                <CircularProgress size="2rem" className="m-3" color="secondary" />
              </div>
            )
          }
          {
            !isSearching && !IsEmpty(inputPostalCode) && (
              IsEmpty(suggestedAddresses) ? (
                <div className="row">
                  <div className="col-12">
                    <Typography variant="subtitle1" style={{ fontSize: 14 }}>Enter Address:</Typography>
                  </div>
                  <div className="col-12 col-md-8">
                    <TextField
                      size="small"
                      multiline
                      fullWidth
                      variant="outlined"
                      onChange={(evt) => manualAddressInputDebounced(evt.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <div className="row d-flex flex-column">
                  <div className="col-12">
                    <Typography variant="subtitle1" style={{ fontSize: 14 }}>Select Address:</Typography>
                  </div>
                  <div className="col-12 col-md-7 row px-5 mt-2">
                    <div className="col-12 d-flex flex-column px-3 pt-3" style={{ backgroundColor: Colours.Gray1, borderRadius: '10px' }}>
                      {
                        _.map(suggestedAddresses, (addr) => (
                          <Button
                            key={`${addr.id}-${addr.postalCode}`}
                            className="mb-3"
                            style={{ backgroundColor: (selected && selected.id === addr.id) ? Colours.Primary : Colours.White }}
                            onClick={() => setSelected(addr)}
                          >
                            <Typography variant="body1" color="inherit" style={{ color: (selected && selected.id === addr.id) ? Colours.White : Colours.Gray1, fontSize: 14 }}>
                              {
                                !IsEmpty(addr.name) && (
                                  <>
                                    {addr.name}
                                    <br />
                                  </>
                                )
                              }
                              {addr.address}
                              {
                                !IsEmpty(addr.postalCode) && (
                                  <span className="ml-3">
                                    {`S(${addr.postalCode})`}
                                    <br />
                                  </span>
                                )
                              }
                            </Typography>
                          </Button>
                        ))
                      }
                    </div>
                  </div>
                </div>
              )
            )
          }

          {!isSearching && !IsEmpty(inputPostalCode) && (
            <div className="row mt-3">
              <div className="col-12 d-flex flex-row align-items-center">
                <Typography variant="subtitle1" style={{ fontSize: 14 }}>Unit no:</Typography>
              </div>

              <div className="col-3 d-flex flex-row align-items-center">
                <TextField
                  className="d-block"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={inputUnitNo}
                  onChange={(evt) => onUnitNoChanged(evt.target.value)}
                />
              </div>
            </div>
          ) }

        </div>
      </div>
    </div>
  );
};

SearchAddress.propTypes = {
  currentAddr: PropTypes.shape({
    postalCode: PropTypes.string,
    address:    PropTypes.string,
    unitNo:     PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

SearchAddress.defaultProps = {
  currentAddr: null,
};

export default SearchAddress;
