import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TextField,
  FormControl,
  Select,
  makeStyles,
  Box,
  Button,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import _ from 'lodash';
import { connect } from 'react-redux';
import Colours from '../../styles/colours';
import DateRangeInput from './DateRangeInput';
import { Status, WindowSize } from '../../common/constants';
import dateStrToFormat from '../../common/convert';
import { Trim } from '../../common/util';
import { IsEmpty } from '../../common/checks';
import Icons from './Icons';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  w200: {
    width: '200px',
  },
  w250: {
    width: '250px',
  },
  bgWhite: {
    backgroundColor: Colours.White,
  },
}));

function FilterPost(props) {
  const classes = useStyles();

  const [isOpenModal, setIsOpenModal]       = useState(false);
  const [fieldStartDate, setFieldStartDate] = useState(null);
  const [fieldEndDate, setFieldEndDate]     = useState(null);
  const [startDate, setStartDate]           = useState(null);
  const [endDate, setEndDate]               = useState(null);
  const [status, setStatus]                 = useState('');

  const onTitleChange = (data) => {
    // to replace double whitespace before & after each word with ''
    const trimmedTitle = Trim(data, false, false);
    props.updateTitleFilter(trimmedTitle);
  };

  const debounceTitle = _.debounce(onTitleChange, 700);

  const onDateRangeChange = (data) => {
    setStartDate(dateStrToFormat(data.startDate));
    setEndDate(dateStrToFormat(data.endDate));
  };

  const onDateRangeModalClose = () => {
    if (!IsEmpty(startDate) && !IsEmpty(endDate)) {
      setFieldStartDate(startDate);
      setFieldEndDate(endDate);
      props.updatePostDateFilter({ startDate, endDate });
    }

    setIsOpenModal(false);
  };

  const resetDateRange = () => {
    setStartDate(null);
    setEndDate(null);
    setFieldStartDate(null);
    setFieldEndDate(null);
    props.updatePostDateFilter();
    setIsOpenModal(false);
  };

  const onStatusChange = (data) => {
    setStatus(data);
    props.updateStatusFilter(data);
  };

  return (
    <div className={props.isMobile ? `d-block` : ` mt-1 d-flex flex-row justify-content-start`}>
      {
        props.windowSize === WindowSize.Desktop && (
          <div className="mt-4">
            <Icons.Filter style={{ width: '1,5rem', height: '1.5rem', borderRadius: 0 }} />
          </div>
        )
      }

      <div className="ml-lg-3 pl-lg-3">
        <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>
          Title
        </Typography>
        <TextField
          className={props.isMobile ? `mt-1 ${classes.fullWidth} ${classes.bgWhite}` : `mt-1 ${classes.w250} ${classes.bgWhite}`}
          variant="outlined"
          size="small"
          defaultValue=""
          multiline
          maxRows={8}
          inputProps={{ 'aria-label': 'naked' }}
          onChange={(e) => debounceTitle(e.target.value)}
        />
      </div>
      <div className={props.isMobile ? `mt-3` : `ml-3`}>
        <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>
          Post Date
        </Typography>
        <Box className="d-flex" style={{ alignItems: 'baseline' }}>
          <TextField
            className={props.isMobile ? `${classes.fullWidth} ${classes.bgWhite} mt-1` : `${classes.w250} ${classes.bgWhite} mt-1`}
            variant="outlined"
            size="small"
            value={`${IsEmpty(fieldStartDate) ? '' : fieldStartDate} ${(!IsEmpty(fieldStartDate) || !IsEmpty(fieldEndDate)) ? ' to ' : ''} ${IsEmpty(fieldEndDate) ? '' : fieldEndDate}`}
            onClick={() => setIsOpenModal(true)}
            inputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Dialog
          open={isOpenModal}
          onClose={onDateRangeModalClose}
        >
          <DialogContent>
            <Box style={{ textAlign: 'center', paddingBottom: '12px' }}>
              <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                Select Start and End Dates
              </Typography>
              <DateRangeInput startDate={startDate} endDate={endDate} onDateRangeChange={onDateRangeChange} />
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  size="large"
                  variant="outlined"
                  style={{ borderRadius: '50px', width: '45%' }}
                  onClick={() => resetDateRange()}
                >
                  Clear Dates
                </Button>
                <Button
                  size="large"
                  variant="outlined"
                  style={{ borderRadius: '50px', width: '45%' }}
                  onClick={onDateRangeModalClose}
                >
                  Done
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
      <div className={props.isMobile ? `mt-3` : `ml-3`}>
        <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>
          Status
        </Typography>
        <FormControl size="small" className={props.isMobile ? `${classes.fullWidth} mt-1` : `${classes.w200} mt-1`} variant="outlined">
          <Select
            native
            value={status}
            onChange={(e) => onStatusChange(e.target.value)}
            className={classes.bgWhite}
          >
            <option aria-label="None" value="" />
            <option value={Status.Draft}>Draft</option>
            <option value={Status.Live}>Live</option>
            <option value={Status.Pending}>Pending</option>
            <option value={Status.Paused}>Paused</option>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

FilterPost.propTypes = {
  isMobile:             PropTypes.bool,
  updateStatusFilter:   PropTypes.func.isRequired,
  updateTitleFilter:    PropTypes.func.isRequired,
  updatePostDateFilter: PropTypes.func.isRequired,
  windowSize:           PropTypes.string.isRequired,
};

FilterPost.defaultProps = {
  isMobile: false,
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(FilterPost);
