// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Typography, Grid, Select, MenuItem, FormControl, InputLabel, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  inputLabel: {
    '&.MuiInputLabel-outlined': {
      transform: 'translate(12px, 12px) scale(1)',
      fontSize:  '14px',

      '&.MuiInputLabel-shrink': {
        transform: 'translate(12px, -6px) scale(0.75)',
      },
    },
  },
});

const TimeSelect = ({ disabled, text, valueHours, valueMins, valueTimeType, onChangeHours, onChangeMins, onChangeTimeType }) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Typography style={{ fontSize: '14px' }}>{text}</Typography>
      <FormControl variant="outlined" className="mt-3 ml-1">
        <InputLabel className={classes.inputLabel} id="start-time-hours-label">Hours</InputLabel>
        <Select
          disabled={disabled}
          labelId="start-time-hours-label"
          value={valueHours}
          onChange={onChangeHours}
          style={{ width: 80, height: 38, fontSize: '14px' }}
          label="Hours"
        >
          {[...new Array(13)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={i} value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className="mt-3 ml-1">
        <InputLabel className={classes.inputLabel} id="start-time-mins-label">Mins</InputLabel>
        <Select
          disabled={disabled}
          labelId="start-time-mins-label"
          value={valueMins}
          onChange={onChangeMins}
          style={{ width: 80, height: 38, fontSize: '14px' }}
          label="Mins"
        >
          {[...new Array(60)].map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={i} value={i}>{i}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className="mt-3 ml-1">
        <InputLabel className={classes.inputLabel} id="start-time-type-label">AM/PM</InputLabel>
        <Select
          disabled={disabled}
          labelId="start-time-type-label"
          value={valueTimeType}
          onChange={onChangeTimeType}
          style={{ width: 100, height: 38 }}
          label="AM/PM"
        >
          <MenuItem value="AM">AM</MenuItem>
          <MenuItem value="PM">PM</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  );
};

export default TimeSelect;

TimeSelect.propTypes = {
  disabled:   PropTypes.bool.isRequired,
  text:       PropTypes.string.isRequired,
  valueHours: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  valueMins: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  valueTimeType:    PropTypes.string,
  onChangeHours:    PropTypes.func.isRequired,
  onChangeMins:     PropTypes.func.isRequired,
  onChangeTimeType: PropTypes.func.isRequired,
};

TimeSelect.defaultProps = {
  valueHours:    null,
  valueMins:     null,
  valueTimeType: null,
};
