import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormGroup, FormControlLabel, Checkbox, Typography, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import postStyle from '../../../../styles/post';
import postAction from '../../../../actions/post';
import { IsEmpty } from '../../../../common/util';
import Constants from '../../../../common/constants';
import Colours from '../../../../styles/colours';

const MealsAvailableTag = (props) => {
  const [selected, setSelected] = useState([]);
  const optionList = Constants.MealTags;
  const [active, setActive] = useState(false);
  let tempArr = [];

  useEffect(() => {
    if (!IsEmpty(props.post.mealsAvailable) && props.post.mealsAvailable[0] === Constants.NotApplicable) {
      setActive(true);
    }
  }, [props]);

  useEffect(() => {
    _.map(props.tags, (tag) => {
      if (optionList.includes(tag)) {
        tempArr.push(tag);
      }
    });
    setSelected(props.tags);
  }, [props.tags]);

  const onChange = (tag) => {
    if (!IsEmpty(tag)) {
      if (!selected.includes(tag)) {
        tempArr = _.concat(selected, tag);
        setActive(false);
        setSelected(tempArr);
        props.dispatchPostUpdate({ flagMealsAvailNA: !IsEmpty(tempArr), userTags: props.tags.concat(tag), mealsAvailable: _.compact(_.filter(props.tags.concat(tag), (v) => (optionList.includes(v) ? v : null))) });
      } else if (selected.includes(tag)) {
        tempArr = _.without(selected, tag);
        const trimmedUserTags = _.without(props.tags, tag);
        tempArr.concat(trimmedUserTags);
        setSelected(tempArr);
        const filterMealsAvailOnly = _.compact(_.filter(trimmedUserTags, (v) => (optionList.includes(v) ? v : null)));
        props.dispatchPostUpdate({ flagMealsAvailNA: !IsEmpty(filterMealsAvailOnly), userTags: trimmedUserTags, mealsAvailable: filterMealsAvailOnly });
      } else {
        tempArr = _.without(selected, tag);
        setActive(false);
      }
    }
  };

  const clearTags = () => {
    setActive(!active);
    props.dispatchPostUpdate({ flagMealsAvailNA: !active, userTags: _.compact(_.filter(props.tags, (v) => (!optionList.includes(v) ? v : null))), mealsAvailable: [] });
  };

  return (
    <>
      <hr className="my-4" />
      <Typography variant="subtitle1" color="inherit" className="mt-4 mb-2" style={postStyle.textHeader}>
        Meals Available
        <sup style={{ color: Colours.Red }}>*</sup>
      </Typography>

      <Tooltip
        title={props.disabled ? props.disabledMsg : ''}
        isLink
      >
        <div>
          { optionList.map((t) => (
            <div style={{ display: 'inline-block', width: 175 }} key={t}>
              <FormGroup>
                <FormControlLabel control={<Checkbox color="primary" value={t} name={t} checked={selected.includes(t)} disabled={props.disabled} />} label={_.capitalize(t)} onChange={(e) => onChange(e.target.value)} />
              </FormGroup>
            </div>
          ))}

          <div style={{ display: 'inline-block', width: 250 }}>
            <FormGroup>
              <FormControlLabel control={<Checkbox color="primary" checked={active} disabled={props.disabled} />} label="Not Applicable" onChange={() => clearTags()} />
            </FormGroup>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

MealsAvailableTag.defaultProps = {
  disabled: false,
  post:     { },
  tags:     [],
};

MealsAvailableTag.propTypes = {
  disabled:    PropTypes.bool,
  disabledMsg: PropTypes.string,
  post:        PropTypes.shape({
    mealsAvailable: PropTypes.arrayOf(PropTypes.string),
    status:         PropTypes.string,
  }),
  tags:               PropTypes.arrayOf(PropTypes.string),
  dispatchPostUpdate: PropTypes.func.isRequired,
};

MealsAvailableTag.defaultProps = {
  disabled:    false,
  disabledMsg: 'Cannot be updated',
  post:        { },
  tags:        [],
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => postAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealsAvailableTag);
