import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Dialog, IconButton } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { getThumbnailFromVideoURL } from '../../../../../common/util';
import { IsEmpty } from '../../../../../common/checks';
import Colours from '../../../../../styles/colours';
import Tooltip from '../../../../../components/Common/ToolTip';
import Icons from '../../../../../components/Common/Icons';

const VideoGrid = (props) => {
  const [items, setItems] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    if (props.post && props.post.videos) {
      setItems(!IsEmpty(props.post.videos) ? props.post.videos : []);
    }
  }, [props.post]);

  const Item = SortableElement(({ value }) => (
    <li style={{
      margin:  '35px 30px',
      display: 'inline-block',
    }}
    >
      {value}
    </li>
  ));

  const List = SortableContainer((media) => (
    <ul style={{ padding: 0 }}>
      {(media.items).map((value, index) => (
        <React.Fragment key={index}>
          <Item
            key={`item-${index}`}
            index={index}
            value={(
              <div style={{ position: 'relative', width: 150, height: 150 }}>
                {!showVideo && getThumbnailFromVideoURL(value)
                  ? (
                    <div>
                      <img
                        alt={`${getThumbnailFromVideoURL(value)}?${Math.random()}`}
                        src={`${getThumbnailFromVideoURL(value)}?${Math.random()}`}
                        style={{
                          margin:     0,
                          padding:    0,
                          background: Colours.Black,
                          boxShadow:  '0 26px 30px -10px rgb(0 0 0 / 69%), 0 16px 10px -10px rgb(0 0 0 / 73%)',
                          position:   'relative',
                          minHeight:  150,
                          minWidth:   150,
                          maxHeight:  150,
                          maxWidth:   150,
                        }}
                        crossOrigin
                      />
                    </div>
                  )
                  : (
                    <ReactPlayer
                      url={value}
                      width={150}
                      height={150}
                      style={{
                        margin:     0,
                        padding:    0,
                        background: Colours.Black,
                        boxShadow:  '0 26px 30px -10px rgb(0 0 0 / 69%), 0 16px 10px -10px rgb(0 0 0 / 73%)',
                        position:   'relative',
                      }}
                      config={{
                        file: {
                          attributes: {
                            crossOrigin: "anonymous",
                          }
                        }
                      }}
                    />
                  )}

                <div className="text-right mt-3">
                  <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                    <IconButton
                      onClick={() => {
                        props.onGenerateThumbnail(value);
                      }}
                      disabled={props.disabled}
                      style={{
                        padding:       0,
                        verticalAlign: 'bottom',
                        margin:        '10px 10px 10px 0px',
                      }}
                    >
                      <Icons.CropFree
                        outline
                        colour={Colours.Gray}
                        fontSize="1.5rem"
                      />
                    </IconButton>
                  </Tooltip>

                  <IconButton
                    onClick={() => {
                      setVideoUrl(value);
                      setShowVideo(true);
                    }}
                    style={{
                      padding:       0,
                      verticalAlign: 'bottom',
                      margin:        10,
                    }}
                  >
                    <Icons.PlayCircles outline color={Colours.Gray} fontSize="1.5rem" />
                  </IconButton>

                  <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                    <IconButton
                      id="deleteBtn"
                      onClick={() => { props.onDelete(value); }}
                      disabled={props.disabled}
                      style={{
                        padding:       0,
                        verticalAlign: 'bottom',
                        margin:        '10px 0 10px 10px',
                      }}
                    >
                      <Icons.DeleteBin
                        outline
                        colour={Colours.Gray}
                        fontSize="1.5rem"
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
          />
        </React.Fragment>
      ))}
    </ul>
  ));

  const addMovableSign = ({ helper }) => {
    helper.style.transform = 'scale(0.8)';
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const shuffle = arrayMove(items, oldIndex, newIndex);
    setItems(shuffle);
    props.onSort(shuffle);
  };

  const onShouldCancelStart = (mouseEvent) => { 
    if (mouseEvent.target.getAttribute('class') === 'material-icons MuiIcon-root') {
      return true;
    }
  };

  return (
    <div>
      <div>
        <List
          axis="xy"
          items={items}
          onSortStart={addMovableSign}
          onSortEnd={onSortEnd}
          shouldCancelStart={onShouldCancelStart}
        />
      </div>

      <Dialog
        id="videoDialog"
        autoDetectWindowHeight={false}
        autoScrollBodyContent={false}
        contentStyle={{ width: '100%', maxWidth: 'auto' }}
        open={showVideo}
        onClose={() => setShowVideo(false)}
      >
        <ReactPlayer url={videoUrl} playing loop controls width="100%" style={{ background: Colours.Black }} />
      </Dialog>
    </div>
  );
};

VideoGrid.defaultProps = {
  onSort:   false,
  disabled: false,
};

VideoGrid.propTypes = {
  onGenerateThumbnail: PropTypes.func.isRequired,
  onDelete:            PropTypes.func.isRequired,
  onSort:              PropTypes.func,
  post:                PropTypes.shape({
    id:     PropTypes.number,
    videos: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

export default connect(mapStateToProps, null)(VideoGrid);
