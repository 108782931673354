// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';

// Internal Dependencies
import Colours from '../styles/colours';
import { Status } from '../common/constants';

const MBadge = ({ text, status, styles, onClick }) => (
  <Button
    className="badge badge-pill py-2 px-4"
    style={{
      background:
      status === Status.Activated ? Colours.Green
        : status === Status.Deactivated ? Colours.Red : '#D0D0D0',
      color:        status === Status.Draft ? Colours.Black : Colours.White,
      borderRadius: '30px',
      width:        'auto',
      ...styles,
    }}
    onClick={onClick}
  >
    <Typography variant="body2">{text || status}</Typography>
  </Button>
);

export default MBadge;

MBadge.propTypes = {
  text:    PropTypes.string,
  status:  PropTypes.string,
  styles:  PropTypes.shape(Object),
  onClick: PropTypes.func,
};

MBadge.defaultProps = {
  text:    null,
  status:  'Draft',
  styles:  {},
  onClick: () => {},
};
