// React Dependencies
import React from 'react';

// External Dependencies
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

// Internal Dependencies
import PageHighlightInfo from './Info';
import PageHighlightPosts from './Posts';

const PageHighlightRouter = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={PageHighlightInfo} />
    <Route exact path={`${match.path}/posts`} component={PageHighlightPosts} />
  </Switch>
);

PageHighlightRouter.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default PageHighlightRouter;
