import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@material-ui/core';
import Loading from '../../../components/Loading';
import PageImagesContainer from './PageImagesContainer';
import Photo from './PageLogo';
import AddressSearch from '../../../components/AddressSearch';
import Colours from '../../../styles/colours';
import { IsEmpty } from '../../../common/util';
import ApiService from '../../../services/apiService';
import { PageType } from '../../../common/constants';
import Tooltip from '../../../components/Common/ToolTip';

const MobileUpdate = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [openAddress, setOpenAddress] = React.useState(false);
  const [photos, setPhotos] = React.useState([]);
  const [description, setDescription] = React.useState('');
  const [type, setType] = React.useState('');
  //const [userTags, setUserTags] = React.useState([]);
  const [logo, setLogo] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [addressDisplay, setAddressDisplay] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [lat, setLat] = React.useState('');
  const [lng, setLng] = React.useState('');
  const [contactEmail, setContactEmail] = React.useState('');
  const [contactNo, setContactNo] = React.useState('');
  const [contactOthers, setContactOthers] = React.useState('');
  const [pagePlan, setPagePlan] = React.useState('');

  const closeAddress = () => setOpenAddress(false);
  const populateAddress = (result) => {
    if (!IsEmpty(result)) {
      setAddress(result.address);
      setLat(result.lat || null);
      setLng(result.lng || null);
    } else {
      setAddress('');
      setLat(result.lat || null);
      setLng(result.lng || null);
    }
  };

  useEffect(() => {
    if (props.page) {
      setPhotos(props.page.images || []);
      setDescription(props.page.description || '');
      setType(props.page.type || '');
      //setUserTags(props.page.userTags || []);
      setLogo(props.page.logo || '');
      setWebsite(props.page.website || '');
      setAddress(props.page.address || '');
      setPostalCode(props.page.postalCode || '');
      setPagePlan(props.page.plan || '');
      setLat(props.page.lat);
      setLng(props.page.lng);
      setContactEmail(props.page.contactEmail || '');
      setContactNo(props.page.contactNo || '');
      setContactOthers(props.page.contactOthers || '');

      setLoading(false);
    }
  }, [props.page]);

  useEffect(() => {
    if (!IsEmpty(address)) {
      setAddressDisplay(`${address}\nSingapore ${postalCode}`);
    } else {
      setAddressDisplay('');
    }
  }, [address, postalCode]);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        description,
        website,
        //userTags,
        postalCode,
        address,
        lat,
        lng,
        contactEmail,
        contactOthers,
        contactNo,
      };
      setLoading(true);
      await ApiService.updatePage(props.page.id, payload);
      toast.success('Page updated.');
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      {openAddress && <AddressSearch onCancel={closeAddress} onDone={populateAddress} />}
      <form
        className="form position-relative"
        onSubmit={(e) => onSubmit(e)}
        autoComplete="off"
        style={{ width: '100%' }}
      >
        <div className="container-fluid d-flex align-items-start justify-content-between m-0 px-0">
          <div className="flex-grow-1 pt-1 flex-column my-4">

            <div className="col d-flex flex-row align-items-center my-2">
              <div className="col">
                <div className="d-flex flex-column mb-3">
                  <Typography variant="h5" color="inherit" style={{ color: Colours.Black }}>
                    Page Info
                  </Typography>
                </div>

                <div className="d-flex flex-column">
                  <PageImagesContainer
                    mediaType="image"
                    images={photos}
                    windowSize={props.windowSize}
                    pageId={props.page.id}
                    accountType={pagePlan}
                    disabled={props.disabled}
                  />
                </div>

                <div className="d-flex flex-column my-3">
                  <div className="row pt-3">
                    <div className="col-md-12">
                      <span className="d-block mb-3" style={{ width: '100%' }} />
                    </div>

                    <div className="col-md-12">
                      <div>
                        <Typography variant="body1" color="inherit" className="mb-2" style={{ color: Colours.Black }}>
                          2. Logo
                        </Typography>
                        <Photo setLogo={(url) => setLogo(url)} currentPhoto={logo} pageId={props.page.id} disabled={props.disabled} />
                      </div>

                      <div className="mt-3">
                        <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>
                          3. About
                        </Typography>

                        <TextField
                          className="mt-1"
                          variant="outlined"
                          size="small"
                          defaultValue=""
                          multiline
                          maxRows={8}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          inputProps={{ 'aria-label': 'naked' }}
                          style={{ width: '100%' }}
                        />
                      </div>

                      <div className="mt-4">
                        <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>
                          4. Website
                        </Typography>

                        <TextField
                          className="mt-1"
                          variant="outlined"
                          size="small"
                          multiline
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                          style={{ width: '100%' }}
                        />
                      </div>

                      <div className="mt-4">
                        <div className="d-flex flex-row">
                          <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>
                            5. Address
                            {
                              type === PageType.Mall && <sup style={{ color: Colours.Red }}>*</sup>
                            }
                          </Typography>
                          {
                            (type === PageType.Mall)
                            && (
                              <Button
                                className="ml-2"
                                size="small"
                                variant="outlined"
                                color="primary"
                                onClick={() => { setOpenAddress(true); }}
                              >
                                <Typography variant="caption">search</Typography>
                              </Button>
                            )
                          }
                        </div>

                        <TextField
                          className="mt-2"
                          variant="outlined"
                          size="small"
                          multiline
                          maxRows={4}
                          value={addressDisplay}
                          placeholder={(type !== PageType.Mall) ? '(Not Applicable)' : ''}
                          style={{ width: '100%' }}
                          disabled
                        />
                      </div>

                      <div className="mt-4">
                        <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>
                          6. How should customers contact you
                          <sup style={{ color: Colours.Red }}>*</sup>
                          <span className="ml-2" style={{ display: 'block', fontSize: 12, color: Colours.Gray, marginBottom: 5 }}>(provide at least 1 method)</span>
                        </Typography>

                        <div className="mt-1 ml-1">
                          <div className="">
                            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                              Email
                            </Typography>

                            <TextField
                              className="mt-1"
                              variant="outlined"
                              size="small"
                              value={contactEmail}
                              onChange={(e) => setContactEmail(e.target.value)}
                              style={{ width: '100%' }}
                            />
                          </div>

                          <div className="mt-3">
                            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                              Phone Number
                            </Typography>

                            <TextField
                              className="mt-1"
                              variant="outlined"
                              size="small"
                              value={contactNo}
                              onChange={(e) => setContactNo(e.target.value)}
                              style={{ width: '100%' }}
                            />
                          </div>

                          <div className="mt-3">
                            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                              Others
                            </Typography>

                            <TextField
                              className="mt-1"
                              variant="outlined"
                              size="small"
                              value={contactOthers}
                              onChange={(e) => setContactOthers(e.target.value)}
                              style={{ width: '100%' }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column my-3 mt-5">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                        <Button
                          className="block"
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={props.disabled}
                        >
                          <Typography variant="h6">Save</Typography>
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

MobileUpdate.propTypes = {
  page: PropTypes.shape({
    id:     PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.shape({
      display:  PropTypes.string,
      original: PropTypes.string,
    })),
    description:   PropTypes.string,
    type:          PropTypes.string,
    logo:          PropTypes.string,
    website:       PropTypes.string,
    address:       PropTypes.string,
    postalCode:    PropTypes.string,
    lat:           PropTypes.number,
    lng:           PropTypes.number,
    plan:          PropTypes.string,
    contactNo:     PropTypes.string,
    contactEmail:  PropTypes.string,
    contactOthers: PropTypes.string,
  }).isRequired,
  windowSize: PropTypes.string.isRequired,
  disabled:   PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  page:       state.page.update,
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(MobileUpdate);
