import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Colours from '../../../styles/colours';
import TotalCircleComp from '../../../components/Analytics/TotalCircleComp';

const Card = (props) => (

  <div className="d-flex flex-column px-2">
    <div style={{ minHeight: 100 }}>
      <div className="d-flex flex-row align-items-center">
        {
          props.icon && <props.icon fontSize="1.6rem" />
        }
        <Typography className="ml-2" variant="h6">{props.title}</Typography>
      </div>

      <div className="d-flex flex-column">
        <Typography variant="subtitle2" color="inherit" style={{ color: Colours.Gray1 }}>{props.subtitle}</Typography>
        <Typography variant="subtitle2" color="inherit" style={{ color: Colours.Gray2 }}>{props.subtitle2}</Typography>
      </div>
    </div>

    <div className="mt-3 mb-3 d-flex flex-column align-items-center">
      <TotalCircleComp totalCount={props.count || 0} title="total" selectable={props.selectable || []} setDuration={(value) => props.setSelected(value)} />
    </div>
  </div>
);

Card.propTypes = {
  icon:        PropTypes.func,
  subtitle:    PropTypes.string,
  subtitle2:   PropTypes.string,
  title:       PropTypes.string,
  count:       PropTypes.number,
  selectable:  PropTypes.arrayOf(PropTypes.string),
  setSelected: PropTypes.func,
};

Card.defaultProps = {
  icon:        null,
  subtitle:    '',
  subtitle2:   '',
  title:       '',
  count:       0,
  selectable:  [],
  setSelected: () => null,
};

export default Card;
