// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Card, CardHeader, CardMedia, Button, Typography } from '@material-ui/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// Internal Dependencies
import { IsEmpty } from '../../../common/checks';
import { PositionType } from '../../../common/constants';
import Colours from '../../../styles/colours';

const useStyles = makeStyles(() => ({
  media: {
    background:       '#000000',
    height:           200,
    width:            200,
    backgroundSize:   'cover',
    backgroundRepeat: 'no-repeat',
    maxWidth:         '100%',
    display:          'flex',
    alignItems:       'center',
  },
  oneLine: { display: 'block', overflow: 'hidden' },
}));

const SortableCardItem = ({ cardItem, active, onChange, onClickRemove, height, isSortable = true }) => {
  const classes = useStyles();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: cardItem.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="position-relative">
      <div style={style}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div ref={setNodeRef} {...attributes} {...listeners}>
          <div className="position-relative mr-4 mb-4" style={{ width: '200px', height: `${height}px`, zIndex: 1 }} />
        </div>

        <div className="position-absolute" style={{ top: 0 }}>
          <div className="d-inline-block" style={{ backgroundColor: active ? Colours.DarkPurple : 'inherit', padding: 10 }}>
            <Card className="d-inline-block" style={{ width: '200px' }}>
              {!IsEmpty(cardItem.videos)
                ? (
                  <div className={classes.media}>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      width="100%"
                      height={200}
                      controls
                      style={{ display: 'flex' }}
                    >
                      <source src={cardItem.videos[0]} />
                    </video>
                  </div>
                )
                : (<CardMedia className={classes.media} image={cardItem.images[0] ? cardItem.images[0].display : ''} />)}
              <CardHeader
                title={(
                  <div style={{  overflowY: 'hidden', zIndex: 10 }} className="position-relative">
                    <a style={{ fontSize: 13 }} target="_blank" rel="noreferrer" title="View details" href={`/page/${cardItem.pageId}/post/${cardItem.postId}`} className={classes.oneLine}>
                      {cardItem.title}
                    </a>

                    <div className="mt-1" style={{ fontSize: 10, color: Colours.DarkPurple }}>
                      {IsEmpty(cardItem.promoFromDate) ? `${cardItem.promoType} period unspecified` : `${new Date(cardItem.promoFromDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} to ${new Date(cardItem.promoTillDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`}
                    </div>

                    <div style={{ fontSize: 10, marginTop: 5, color: Colours.DarkPurple }}>
                      {`ID: ${cardItem.postId}`}
                    </div>

                    <div style={{ zIndex: 10 }} className="position-relative mt-2 d-flex flex-row align-items-center">
                      <Avatar size="small" alt="logo" src={cardItem.logoUrl} className="mr-2" />
                      <a target="_blank" rel="noreferrer" href={`/page/${cardItem.pageId}`}>
                        <Typography>{cardItem.brand}</Typography>
                      </a>
                    </div>
                    {isSortable && (
                      <div style={{ color: Colours.DarkPurple }}>
                        <Typography variant="caption">Set position</Typography>
                        <div style={{ zIndex: 10 }} className="position-relative d-flex flex-row">
                          <Button
                            disableElevation
                            variant={cardItem.positionType === PositionType.Rotatable ? 'contained' : 'text'}
                            size="small"
                            color={cardItem.positionType === PositionType.Rotatable ? 'secondary' : 'default'}
                            onClick={() => onChange(cardItem.postId, PositionType.Rotatable)}
                          >
                            {PositionType.Rotatable}
                          </Button>

                          <Button
                            className="ml-3"
                            disableElevation
                            variant={cardItem.positionType === PositionType.Fixed ? 'contained' : 'text'}
                            size="small"
                            color={cardItem.positionType === PositionType.Fixed ? 'secondary' : 'default'}
                            onClick={() => onChange(cardItem.postId, PositionType.Fixed)}
                          >
                            {PositionType.Fixed}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                subheader={(
                  <div style={{ zIndex: 10 }} className="position-relative mt-2 d-flex">
                    <Button
                      className="ml-auto"
                      onClick={() => {
                      console.log('cick remove called!');
                      onClickRemove(cardItem.postId);
                      }}
                      color="secondary"
                      size="small"
                    >
                      Remove
                    </Button>
                  </div>
                )}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortableCardItem;

SortableCardItem.propTypes = {
  cardItem:      PropTypes.shape(Object),
  active:        PropTypes.bool,
  onChange:      PropTypes.func,
  onClickRemove: PropTypes.func,
  isSortable:    PropTypes.bool,
};

SortableCardItem.defaultProps = {
  cardItem:      {},
  active:        false,
  onChange:      () => {},
  onClickRemove: () => {},
  isSortable:    true,
};
