// React Dependencies
import React, { useState } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, FormLabel, TextField, Typography } from '@material-ui/core';

// Internal Dependencies
import { IsEmpty } from '../../../common/checks';

const PreCreateModal = ({ isSpecial, isExclusive, onClose, onComplete }) => {
  const [title, setTitle] = useState('');

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className="px-4">
        <div className="mt-1">
          <Typography variant="h5">{`Create ${isSpecial ? 'Special' : isExclusive ? 'Exclusive' : ''} Highlight`}</Typography>
          <FormLabel component="legend" className="mt-3">Enter title of highlight (can be modified later)</FormLabel>
          <TextField
            id="titleTextField"
            className="mt-2"
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            multiline
            size="small"
            fullWidth
          />
          {IsEmpty(title) && (
            <label htmlFor="titleTextField" className="mt-1" style={{ color: 'red', fontStyle: 'italic' }}>*Title is required</label>
          )}
        </div>
      </DialogContent>
      <DialogActions className="pb-4">
        <Button className="px-5" onClick={() => onClose()}>Cancel</Button>
        <Button className="mx-3 px-5" variant="contained" color="primary" disabled={IsEmpty(title)} onClick={() => onComplete({ title })}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

PreCreateModal.propTypes = {
  isSpecial:   PropTypes.bool,
  isExclusive: PropTypes.bool,
  onComplete:  PropTypes.func.isRequired,
  onClose:     PropTypes.func.isRequired,
};

PreCreateModal.defaultProps = {
  isSpecial:   false,
  isExclusive: false,
};

export default connect(null, {})(PreCreateModal);
