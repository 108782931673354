import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  FormControl,
  Select,
  makeStyles,
} from '@material-ui/core';
import Colours from '../../../styles/colours';
import { Status } from '../../../common/constants';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  w200: {
    width: '200px',
  },
  w250: {
    width: '250px',
  },
  bgWhite: {
    backgroundColor: Colours.White,
  },
}));

function FilterReview(props) {
  const classes = useStyles();

  const [status, setStatus] = useState('');

  const onStatusChange = (data) => {
    setStatus(data);
    props.updateStatusFilter(data);
  };

  return (
    <div className={props.isMobile ? `d-block` : `d-flex flex-row justify-content-start`}>
      <div className={props.isMobile ? `mt-3` : `mt-3 ml-3`}>
        <Typography variant="body1" color="inherit" style={{ color: Colours.Black }}>
          Status
        </Typography>
        <FormControl size="small" className={props.isMobile ? `${classes.fullWidth} mt-1` : `${classes.w200} mt-1`} variant="outlined">
          <Select
            native
            value={status}
            onChange={(e) => onStatusChange(e.target.value)}
            className={classes.bgWhite}
          >
            <option aria-label="None" value="">All</option>
            <option value={Status.Draft}>Draft</option>
            <option value={Status.Live}>Live</option>
            <option value={Status.Pending}>Pending</option>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

FilterReview.propTypes = {
  isMobile:           PropTypes.bool,
  updateStatusFilter: PropTypes.func.isRequired,
};

FilterReview.defaultProps = {
  isMobile: false,
};

export default FilterReview;
