// React Dependencies
import React from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Recaptcha from 'react-google-recaptcha';
import { TextField, InputAdornment, Typography, Button } from '@material-ui/core';

// Internal Dependencies
import Api from '../services/apiService';
import Colours from '../styles/colours';
import { emailValidation, passwordValidation, passwordMatchValidation, IsEmpty, Trim } from '../common/util';
import { Error, Url } from '../common/constants';
import authAction from '../actions/auth';
import Icons from '../components/Common/Icons';

class Signup extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading:            true,
      email:                '',
      password:             '',
      confirmPassword:      '',
      emailError:           '',
      passwordError:        '',
      confirmPasswordError: '',

      showPassword:        false,
      showConfirmPassword: false,

      isCaptchaVerified: false,
      isValid:           false,
      redirect:          false,
    };
    this.validate = this.validate.bind(this);
    this.onVerifyCaptcha = this.onVerifyCaptcha.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  onEmailChanged(email = '') {
    email = Trim(email, true);
    const emailError = emailValidation(email);
    this.setState({ email, emailError }, () => this.validate());
  }

  onPasswordChanged(password = '') {
    password = Trim(password, true);
    const passwordError = passwordValidation(password);
    if (!IsEmpty(this.state.confirmPassword)) {
      const confirmPasswordError = passwordMatchValidation(password, this.state.confirmPassword);
      this.setState({ confirmPasswordError });
    }
    this.setState({ password, passwordError }, () => this.validate());
  }

  onConfirmPasswordChanged(confirmPassword = '') {
    confirmPassword = Trim(confirmPassword, true);
    const confirmPasswordError = passwordMatchValidation(this.state.password, confirmPassword);
    this.setState({ confirmPassword, confirmPasswordError }, () => this.validate());
  }

  validate() {
    const { email, password, confirmPassword, emailError, passwordError, confirmPasswordError, isCaptchaVerified } = this.state;
    if (
      !IsEmpty(email) && IsEmpty(emailError)
      && !IsEmpty(password) && IsEmpty(passwordError)
      && !IsEmpty(confirmPassword) && IsEmpty(confirmPasswordError)
      && isCaptchaVerified
    ) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  }

  onVerifyCaptcha(res) {
    if (!_.isEmpty(res)) {
      this.setState({ isCaptchaVerified: true });
    }
    this.validate();
  }

  onChange(e) {
    e.preventDefault();
    const value = Trim(e.target.value);
    this.setState({ [e.target.name]: value });
    this.validate(e.target.name, value);
  }

  async onFormSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    try {
      const { email, password } = this.state;
      await Api.signup({ email, password });
      this.setState({ redirect: true });
    } catch (err) {
      this.setState({ isLoading: false });
      if (err.message === Error.AccountWithEmailExist) {
        toast.warn(err.message);
      } else {
        toast.error(err.message);
      }
    }
  }

  render() {
    return (
      this.state.redirect ? <Redirect to={`${Url.UserVerificationSent}?email=${this.state.email}`} />
        : (
          <>
            {
              this.state.isLoading && (
                <div
                  className="d-flex"
                  style={{
                    position:        'fixed',
                    zIndex:          1,
                    top:             0,
                    left:            0,
                    width:           '100%',
                    height:          '100vh',
                    justifyContent:  'center',
                    alignItems:      'center',
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    backdropFilter:  'blur(10px)',
                  }}
                >
                  <h1 style={{ color: Colours.Black }}>Loading...</h1>
                </div>
              )
            }

            <div className="container-fluid" style={{ background: '#FFFFFF' }}>
              <div className="container pb-4" style={{ paddingTop: '2%' }}>
                <div className="row">
                  <div className="col-sm-6 col-md-5 col-sm-push-7 order-sm-1">
                    <div className="row rounded mt-0 mt-sm-5 py-2 py-sm-5 px-3 px-lg-5" style={{ flexDirection: 'column', alignItems: 'center', background: '#fafafa' }}>
                      <Typography variant="inherit" align="center" style={{ fontSize: '1.3em', lineHeight: '1.1em' }}>
                        <span style={{ fontWeight: 'bold' }}>Monstyr</span>
                        {' '}
                        Business Account
                        <br />
                        Sign Up
                      </Typography>

                      <form
                        className="form mt-1 mt-sm-4"
                        onSubmit={(e) => this.onFormSubmit(e)}
                        autoComplete="off"
                        style={{ width: '100%', position: 'relative' }}
                      >
                        <div className="form-group mb-0">
                          <Typography className="ml-2" variant="inherit" color="inherit" style={{ fontSize: '1em', color: Colours.Black }}>Email</Typography>
                          <TextField
                            className="mt-0 mt-sm-1 textfield-rounded"
                            name="email"
                            value={this.state.email}
                            onChange={(e) => { this.onEmailChanged(e.target.value); }}
                            required
                            type="email"
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={!IsEmpty(this.state.emailError)}
                            helperText={this.state.emailError}
                          />
                        </div>
                        <div className="form-group mt-2 mt-sm-3 mb-0">
                          <Typography className="ml-2" variant="inherit" color="inherit" style={{ fontSize: '1em', color: Colours.Black }}>Password</Typography>
                          <TextField
                            className="mt-0 mt-sm-1 textfield-rounded"
                            name="password"
                            value={this.state.password}
                            onChange={(e) => { this.onPasswordChanged(e.target.value); }}
                            required
                            type={this.state.showPassword ? 'text' : 'password'}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {this.state.showPassword === true ? (
                                    <Icons.VisibilityOff
                                      onClick={() => (this.setState({ showPassword: !this.state.showPassword }))}
                                    />
                                  ) : (
                                    <Icons.Visibility
                                      onClick={() => (this.setState({ showPassword: !this.state.showPassword }))}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            error={!IsEmpty(this.state.passwordError)}
                            helperText={this.state.passwordError}
                          />
                        </div>
                        <div className="form-group mt-2 mt-sm-3 mb-0">
                          <Typography className="ml-2" variant="inherit" style={{ fontSize: '1em' }}>Retype Password</Typography>
                          <TextField
                            className="mt-0 mt-sm-1 textfield-rounded"
                            name="confirmPassword"
                            value={this.state.confirmPassword}
                            onChange={(e) => { this.onConfirmPasswordChanged(e.target.value); }}
                            required
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {this.state.showConfirmPassword === true ? (
                                    <Icons.VisibilityOff
                                      onClick={() => (this.setState({ showConfirmPassword: !this.state.showConfirmPassword }))}
                                    />
                                  ) : (
                                    <Icons.Visibility
                                      onClick={() => (this.setState({ showConfirmPassword: !this.state.showConfirmPassword }))}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            error={!IsEmpty(this.state.confirmPasswordError)}
                            helperText={this.state.confirmPasswordError}
                          />
                        </div>

                        {
                          !this.state.isLoading && (
                            <div className="form-group mt-3 mt-lg-4">
                              <Recaptcha
                                className="g-recaptcha"
                                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                                onChange={this.onVerifyCaptcha}
                              />
                            </div>
                          )
                        }

                        <div className="form-group mt-2 mt-sm-0 mb-0">
                          <Button
                            type="submit"
                            className="block btn-rounded"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={!this.state.isValid}
                          >
                            <Typography variant="subtitle1">Sign Up</Typography>
                          </Button>
                        </div>

                        <div className="d-flex mb-0 mt-3 flex-row justify-content-center align-items-center">
                          <Typography variant="inherit" color="inherit" style={{ fontSize: '0.8em', color: Colours.Gray }}>
                            Already have an account?
                          </Typography>
                          <Button className="pl-0 my-0 py-0">
                            <Link to={Url.Login}>
                              <strong style={{ color: Colours.Blue, fontSize: '1.0em', fontWeight: 'bold' }}><u>Login</u></strong>
                            </Link>
                          </Button>
                        </div>

                        <div className="block mt-0 mt-sm-1">
                          <a href={Url.External.Website} rel="noreferrer" target="_blank">
                            <Typography variant="inherit" color="inherit" style={{ fontSize: '0.8em', color: Colours.Gray }}>
                              Visit
                              {' '}
                              <span style={{ fontWeight: 'bold' }}>Monstyr</span>
                              {' '}
                              <u>Homepage</u>
                            </Typography>
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-7 pr-md-5 px-0 pt-3 pt-sm-5 pt-md-0 col-sm-push-5">
                    <img src={require('../assets/images/free-ads.png')} alt="Free Ads" className="d-block mx-auto w-100" style={{ marginTop: 3 }} />

                    <div className="mt-4 mt-md-3">
                      <p>
                        We&lsquo;re a start up and we know that doing business is not easy. That&lsquo;s why
                        {' '}
                        <strong>Monstyr</strong>
                        {' '}
                        would like to help all the businesses out there that need a little awareness, with free ads.
                        <br />
                        <br />

                        Simply sign up for an account and you&lsquo;re on your way to getting noticed by more people.
                        <br />
                        <br />

                        Are you a new business? Or did you run promotions but didn&lsquo;t manage to bring in any/much incremental sales? Awareness is key, and that&lsquo;s where
                        {' '}
                        <strong>Monstyr</strong>
                        {' '}
                        can help.
                        <br />
                        <br />

                        <strong>Monstyr</strong>
                        {' '}
                        is making it really effortless for consumers to discover all the promotions that are going on around them, literally. They can easily view on
                        {' '}
                        <strong>Monstyr&lsquo;s</strong>
                        {' '}
                        map to find your business or deals that you&lsquo;ve put up, whatever the time, wherever they are. And they can find more information and get directions to your shop, etc. with just a tap of a button. We will also be coming up with exciting marketing tools to help you improve your business further.
                        <br />
                        <br />

                        If you have not tried the app yet, download it and find out for yourself how your business can benefit from being on
                        {' '}
                        <strong>Monstyr</strong>
                        :
                      </p>
                    </div>

                    <div className="my-5">
                      <div className="container px-0">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex flex-row align-items-center mb-3">
                              <div className="mr-3 text-left text-md-right" style={{ width: 80 }}><strong>Android</strong></div>
                              <div>
                                <a
                                  href={process.env.REACT_APP_ANDROID_PLAY_STORE}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="d-block d-sm-block d-md-none"
                                >
                                  <img src={require('../assets/images/google-play-btn.png')} alt="android" style={{ width: '100%', maxWidth: 150 }} />
                                </a>
                                <img src={require('../assets/images/google-play-qrcode.png')} alt="android" className="d-none d-md-block d-lg-block" style={{ width: '100%', minWidth: 100, maxWidth: 200 }} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="d-flex flex-row align-items-center">
                              <div className="mr-3 text-left text-md-right" style={{ width: 80 }}><strong>iOS</strong></div>
                              <div>
                                <a
                                  href={process.env.REACT_APP_IOS_APP_STORE}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="d-block d-sm-block d-md-none"
                                >
                                  <img src={require('../assets/images/appstore-btn.png')} alt="iOS" style={{ width: '100%', maxWidth: 150 }} />
                                </a>
                                <img src={require('../assets/images/appstore-qrcode.png')} alt="iOS" className="d-none d-md-block d-lg-block" style={{ width: '100%', minWidth: 100, maxWidth: 200 }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
    );
  }
}

Signup.propTypes = {
  signUpDispatcher: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  signUpDispatcher: (token) => authAction.signup(dispatch, token),
});

export default connect(
  null,
  mapDispatchToProps,
)(Signup);
