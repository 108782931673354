import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Checkbox, CircularProgress, Typography } from '@material-ui/core';
import _ from 'lodash';
import { IsEmpty } from '../common/checks';
import ApiService from '../services/apiService';
import categoryAction from '../actions/category';
import Colours from '../styles/colours';

const SelectCategories = (props) => {
  const [options, setOptions] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const groupCategories = (cats) => {
    const groupedCats = _.groupBy(cats, 'group');
    setOptions(groupedCats);
  };

  const retrieveCategories = async () => {
    try {
      setIsLoading(true);
      const cats = await ApiService.getCategories();
      groupCategories(cats);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (IsEmpty(props.categories)) {
      retrieveCategories();
    } else {
      groupCategories(props.categories);
    }
  }, []);

  useEffect(() => {
    setSelectedCategories(props.value || []);
  }, [props.value]);

  const renderGroupAndCategories = (groupName) => (
    <div className="mt-2">
      <div className="d-flex flex-row align-items-center">
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{groupName}</Typography>

        <Checkbox
          color="primary"
          title={`Select all under ${groupName}`}
          checked={options[groupName].every((cat) => _.find(selectedCategories, ['id', cat.id]))}
          onChange={(e) => {
            const { checked } = e.target;
            let updatedSelection = selectedCategories;
            if (checked) {
              // select all cats from this group
              updatedSelection = _.uniq(selectedCategories.concat(options[groupName]));
            } else {
              // remove all cats from this group
              updatedSelection = _.differenceBy(selectedCategories, options[groupName], 'id');
            }
            setSelectedCategories(updatedSelection);
            props.onChange(updatedSelection);
          }}
          disabled={props.disabled}
        />
      </div>

      {
        _.map(options[groupName], (cat) => (
          <Button
            key={cat.id}
            className="mr-2 mb-2"
            variant="outlined"
            color="inherit"
            style={{
              backgroundColor: _.find(selectedCategories, ['id', cat.id]) ? (props.disabled ? Colours.Gray2 : Colours.Primary) : Colours.White,
              color:           _.find(selectedCategories, ['id', cat.id]) ? Colours.White : Colours.Gray,
            }}
            onClick={() => {
              // toggle
              let updatedCats = selectedCategories.slice();
              if (_.find(selectedCategories, ['id', cat.id])) {
                _.remove(updatedCats, { id: cat.id });
              } else {
                updatedCats = updatedCats.concat(cat);
              }
              setSelectedCategories(updatedCats);
              props.onChange(updatedCats);
            }}
            disabled={props.disabled}
          >
            <Typography variant="body2">{cat.name}</Typography>
          </Button>
        ))
      }
    </div>
  );

  return (
    <div className="d-flex flex-column">
      {
        isLoading && <CircularProgress className="my-2 mx-2" />
      }
      {
        !IsEmpty(options) && (
          <>
            { renderGroupAndCategories('Shop')}
            { renderGroupAndCategories('Eat & Drink')}
            { renderGroupAndCategories('Relax')}
            { renderGroupAndCategories('Play')}
            { renderGroupAndCategories('Travel & Services')}
          </>
        )
      }
    </div>
  );
};

SelectCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id:    PropTypes.number,
    name:  PropTypes.string,
    group: PropTypes.string,
  })).isRequired,

  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value:    PropTypes.arrayOf(PropTypes.shape()),
};

SelectCategories.defaultProps = {
  onChange: () => {},
  disabled: false,
  value:    [],
};

const mapStateToProps = (state) => ({
  categories: state.category.categories,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCategoriesSet: (cats) => categoryAction.categoriesSet(dispatch, cats),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectCategories);
