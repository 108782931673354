// React Dependencies
import React, { useEffect, useState } from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import moment from 'moment';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// Internal Dependencies
import { Button } from '@material-ui/core';
import ApiService from '../../../services/apiService';
import Colours from '../../../styles/colours';
import { PaymentStatus } from '../../../common/constants';

const PaymentHistory = ({ selectedPage }) => {
  const [paymentsHistory, setPaymentsHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPaymentHistory = async () => {
    try {
      setIsLoading(true);
      const paymentsHistoryData = await ApiService.getPaymentHistory(selectedPage.id);
      const formattedPaymentsHistoryData = _.map(paymentsHistoryData, (paymentHistory) => ({
        ...paymentHistory,
        createdAt: moment(paymentHistory.createdAt).format('D MMMM YYYY'),
        amount:    paymentHistory.amount,
      }));

      setPaymentsHistory(formattedPaymentsHistoryData);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentHistory();
  }, []);

  return (
    <div style={{ width: '95%',  marginLeft: '1.25rem' }}>
      <MaterialTable
        title="Payment History"
        isLoading={isLoading}
        columns={[
          { title: 'ID', field: 'id' },
          { title: 'Product', field: 'product' },
          { title: 'Amount', field: 'amount', type: 'currency' },
          { title: 'Date Time', field: 'updatedAt', type: 'datetime' },
          { title: 'Status', field: 'status', width: '10%' },
          { title: 'Transacted By', field: 'email' },
          {
            title:  '',
            field:  '',
            render: (payment) => (
              payment.status === PaymentStatus.Pending
                ? (
                  <div className="d-flex flex-row">
                    <Button variant="contained" color="primary" onClick={() => window.open(payment.paymentUrl, '_self')}>Make Payment</Button>
                    <Button className="ml-4" style={{ color: Colours.Gray1 }} onClick={() => { /** Prompt confirmation before calling backend */ }}>Cancel</Button>
                  </div>
                )
                : (
                  payment.status === PaymentStatus.Paid
                    ? (
                      <div className="d-flex flex-row">
                        <Button className="ml-4" style={{ color: Colours.Gray1 }} onClick={() => { window.open(payment.stripeReceiptUrl, '_blank'); }}>Receipt</Button>
                      </div>
                    ) : null
                )
            ),
          },
        ]}
        data={paymentsHistory || []}
        options={{
          actionsColumnIndex: -1,
          pageSize:           10,
          pageSizeOptions:    [10, 20, 50],
        }}
      />
    </div>
  );
};

PaymentHistory.propTypes = {
  selectedPage: PropTypes.shape({
    id:   PropTypes.number,
    plan: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  selectedPage: state.page.selected,
});

export default connect(mapStateToProps, null)(PaymentHistory);
