import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Snackbar, TextField, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useLocation, useHistory  } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { IsEmpty } from '../common/checks';
import { Trim } from '../common/util';
import apiService from '../services/apiService';

const ResetPassword = (data) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [pwdErrorMsg, setPwdErrorMsg] = useState('');
  const [confirmPwdErrorMsg, setConfirmPwdErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [statusMsg, setStatusMsg] = useState('');
  const location = useLocation();
  const history = useHistory();
  const passwordLength = 8;
  const passwordDoesNotMatch = 'Password does not match';
  const resetId = queryString.parse(location.search).id;

  useEffect(() => {
    if (IsEmpty(resetId)) {
      setStatus('error');
      setStatusMsg('Link is invalid. Please contact us.');
    }
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setConfirmPwdErrorMsg(passwordDoesNotMatch);
      return;
    }

    try {
      if (!IsEmpty(resetId)) {
        setLoading(true);
        await apiService.resetPassword(resetId, password);
        history.push('/login');
        toast.success('Password Changed');
        setStatus('success');
        setStatusMsg('Reset password is successful');
        setLoading(false);

        setPassword('');
        setConfirmPassword('');
      }
    } catch (err) {
      setStatus('error');
      setStatusMsg(err.message);
      setLoading(false);

      setPassword('');
      setConfirmPassword('');
    }
  };

  return (
    <div className="container" style={{ height: '100vh' }}>
      <div className="row mt-5" style={{ marginTop: 30, alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h5" color="initial">Reset Password</Typography>
      </div>

      <div className="row d-flex flex-column align-items-center" style={{ marginTop: 50 }}>
        <div className="col-12 col-md-6 col-lg-4">
          <form
            className="form"
            onSubmit={(e) => onSubmit(e)}
            autoComplete="off"
          >
            <div className="form-group">
              <Typography variant="subtitle1" color="initial">{`New Password (min. ${passwordLength} characters. Spaces not allowed)`}</Typography>
              <TextField
                className="mt-3 textfield-rounded"
                variant="outlined"
                name="password"
                size="small"
                value={password}
                onChange={(e) => {
                  const input = Trim(e.target.value, true);
                    setPassword(input);
                    if (IsEmpty(input) || input.length < passwordLength) {
                      setPwdErrorMsg('Password does not meet requirement');
                    } else {
                      setPwdErrorMsg('');
                    }
                }}
                helperText={pwdErrorMsg}
                error={!IsEmpty(pwdErrorMsg)}
                required
                type="password"
                fullWidth
              />
            </div>

            <div className="form-group">
              <Typography variant="subtitle1" color="initial">Confirm Password</Typography>
              <TextField
                className="mt-3 textfield-rounded"
                name="confirmPassword"
                variant="outlined"
                size="small"
                value={confirmPassword}
                onChange={(e) => {
                    setConfirmPassword(Trim(e.target.value));
                    if (e.target.value !== password) {
                      setConfirmPwdErrorMsg(passwordDoesNotMatch);
                    } else {
                      setConfirmPwdErrorMsg('');
                    }
                }}
                error={!IsEmpty(confirmPwdErrorMsg)}
                helperText={confirmPwdErrorMsg}
                required
                type="password"
                fullWidth
              />
            </div>

            <div className="form-group" style={{ marginTop: 30 }}>
              <Button
                type="submit"
                className="block mt-3 btn-rounded"
                variant="contained"
                color="primary"
                size="medium"
                fullWidth
              >
                <Typography variant="subtitle1">Submit</Typography>
              </Button>
            </div>
          </form>
        </div>
      </div>

      {
        loading && (
          <div className="d-flex" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: '#00000033', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </div>
        )
      }

      <Snackbar
        open={!IsEmpty(statusMsg)}
        autoHideDuration={6000}
        onClose={() => { setStatusMsg(''); setLoading(false); }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={status}>{statusMsg}</Alert>
      </Snackbar>
    </div>
  );
};

export default ResetPassword;
