import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Typography, Paper, Tooltip } from '@material-ui/core';
import Colours from '../../../../styles/colours';
import { IsEmpty } from '../../../../common/util';
import { Status } from '../../../../common/constants';
import PostAction from '../../../../actions/post';
import AvailOutlets from './AvailOutlets';
import OtherLocations from './OtherLocations';
import postStyle from '../../../../styles/post';

const Availability = (props) => {
  const [disableInputFields, setDisableInputFields] = useState(false);

  useEffect(() => {
    if (props && props.post) {
      const p = props.post;
      setDisableInputFields([Status.Live, Status.Paused].includes(p.currentPostStatus));
    }
  }, [props.post]);

  return (
    <>
      <Paper variant="elevation" className="px-5" style={postStyle.paper}>
        <Typography variant="body1" color="inherit" className="mt-3 mb-2" style={postStyle.textHeader}>
          Online Purchase URL
        </Typography>

        <Tooltip
          title={disableInputFields ? `Cannot be updated because post is ${props.post.currentPostStatus}` : ''}
          isLink
        >
          <TextField
            className="mb-3"
            id="standard-multiline-flexible"
            value={props.post.promoAvailOnlineAt || ''}
            variant="outlined"
            size="small"
            placeholder="Must start with https://"
            fullWidth
            onChange={(e) => props.dispatchPostUpdate({ promoAvailOnlineAt: e.currentTarget.value })}
            disabled={disableInputFields}
          />
        </Tooltip>
        {
          props.post.promoAvailOnlineAt && !IsEmpty(props.post.promoAvailOnlineAt) && !String(props.post.promoAvailOnlineAt).startsWith('https://')
          && <Typography className="mt-2 ml-1" variant="caption" color="inherit" style={{ color: Colours.Red }}>*Url must start with 'https://'</Typography>
        }

        <Typography variant="body1" color="inherit" className="mt-3 mb-2" style={postStyle.textHeader}>
          Available in Outlets
          {disableInputFields && (
            <>
              <br />
              <small className="mb-2 d-block" style={{ color: '#ff9100', fontSize: 12 }}>{`Cannot be updated because post is ${props.post.currentPostStatus}`}</small>
            </>
          )}
        </Typography>

        <AvailOutlets className="mb-4 pb-3" disabled={disableInputFields} disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`} />

        <OtherLocations className="mt-3 mb-4" disabled={disableInputFields} disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`} />
      </Paper>
    </>
  );
};

Availability.propTypes = {
  dispatchPostUpdate: PropTypes.func.isRequired,
  post:               PropTypes.shape({
    promoAvailOnlineAt: PropTypes.string,
    currentPostStatus:  PropTypes.string,
  }).isRequired,
};

Availability.defaultProps = {

};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
