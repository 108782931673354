import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Typography, Paper, Button } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { trim } from 'lodash';
import MaterialDatePicker from '../../../../components/Common/form/MaterialDatePicker';
import Colours from '../../../../styles/colours';
import { IsEmpty, Trim } from '../../../../common/util';
import { Status } from '../../../../common/constants';
import postStyle from '../../../../styles/post';
import reviewAction from '../../../../actions/review';
import Tags from '../Tags';
import RadioBtnSelect from '../../../../components/Common/RadioBtnSelect';

const Details = (props) => {
  const [title, setTitle] = useState('');

  const [reviewDate, setReviewDate] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [content, setContent] = useState('');
  const [url, setUrl] = useState('');
  const [isFnb, setIsFnb] = useState(false);
  const [disableInputFields, setDisableInputFields] = useState(false);
  const [tags, setTags] = useState([]);
  const [cuisineTags, setCuisineTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);

  useEffect(() => {
    if (props && props.review) {
      const r = props.review;
      setTitle(r.title || '');
      setReviewDate(r.reviewDate || '');
      setExpireDate(r.expireDate || '');
      setContent(r.content || '');
      setUrl(r.url || '');
      setIsFnb(r.isFnb || false);
      setTags(props.otherTags);
      setCuisineTags(props.cuisineTags);
      setLoadingTags(true);
      setDisableInputFields([Status.Live].includes(props.review.currentReviewStatus));
    }
  }, [props.review]);

  return (
    <>
      <Paper variant="elevation" className="px-5" style={postStyle.paper}>
        <Typography variant="subtitle1" color="inherit" className="mt-3 mb-2" style={postStyle.textHeader}>
          Title
          <sup style={{ color: Colours.Red }}>*</sup>
        </Typography>

        <TextField
          className="mb-3"
          id="standard-multiline-flexible"
          value={title || ''}
          name="title"
          onChange={(e) => props.dispatchReviewUpdate({ title: e.currentTarget.value })}
          variant="outlined"
          multiline
          size="small"
          helperText={
            IsEmpty(title) ? <Typography component="span" variant="body2" style={{ color: 'red' }}>Title is required</Typography> : ''
          }
          fullWidth
        />

        <hr className="my-4" />

        <Typography variant="subtitle1" color="inherit" className="mt-4 mb-2" style={postStyle.textHeader}>
          Content
        </Typography>

        <TextField
          id="details-description"
          multiline
          rows={4}
          maxRows={12}
          name="description"
          value={content || ''}
          onChange={(e) => props.dispatchReviewUpdate({ content: e.currentTarget.value })}
          variant="outlined"
          size="small"
          fullWidth
        />

        <hr className="my-4" />

        <Typography variant="subtitle1" color="inherit" className="mt-3 mb-2" style={postStyle.textHeader}>
          Link to external URL
        </Typography>

        {(!IsEmpty(Trim(url)) && (String(url).startsWith('http://') || String(url).startsWith('https://')))
          ? (
            <>
              <Button
                href={url}
                target="_blank"
                variant="outlined"
                color="primary"
                style={{ border: `1px solid ${Colours.Primary}` }}
                size="medium"
                className="d-inline-block mb-2"
              >
                <span style={{ color: Colours.Primary, marginRight: 5 }}>Open Link</span>
                <LaunchIcon style={{ color: Colours.Primary }} />
              </Button>
            </>
          ) : (!IsEmpty(Trim(url)) && (!String(url).startsWith('http://') || !String(url).startsWith('https://'))) ? (
            <p style={{ color: 'red' }}>
              Invalid url
              <br />
              <small style={{ color: 'gray' }}>Must start with http:// or https://</small>
            </p>
          ) : ''}

        <TextField
          id="details-description"
          name="description"
          value={url || ''}
          onChange={(e) => props.dispatchReviewUpdate({ url: trim(e.currentTarget.value) })}
          variant="outlined"
          size="small"
          fullWidth
        />

        <hr className="my-4" />

        <div className="row">
          <div className="col-5">
            <Typography variant="subtitle1" color="inherit" className="mt-2 mb-1" style={postStyle.textHeader}>
              Review Date
            </Typography>

            <MaterialDatePicker
              disabled={disableInputFields}
              minDate={false}
              onChange={(date = new Date()) => {
                const rDate = date.toString('yyyy-MM-dd');
            props.dispatchReviewUpdate({ reviewDate: rDate });
              }}
              value={!reviewDate ? null : new Date(reviewDate)}
              label="Review date"
              size="small"
              variant="outlined"
              style={{ marginRight: 20 }}
            />
          </div>

          <div className="col-5">
            <Typography variant="subtitle1" color="inherit" className="mt-2 mb-1" style={postStyle.textHeader}>
              Expire Date
            </Typography>

            <MaterialDatePicker
              disabled={disableInputFields}
              minDate={!reviewDate ? false : new Date(reviewDate)}
              onChange={(date = new Date()) => {
                const eDate = date.toString('yyyy-MM-dd');
            props.dispatchReviewUpdate({ expireDate: eDate });
              }}
              value={!expireDate ? null : new Date(expireDate)}
              // readOnly={!promoPeriodRangeStatus}
              label="Expire date"
              size="small"
              variant="outlined"
              style={{ marginRight: 20 }}
            />
          </div>

        </div>

        <hr className="my-4" />

        <Typography variant="subtitle1" color="inherit" className="mt-3 mb-2" style={postStyle.textHeader}>
          Review is on F&B
        </Typography>

        <div className="mb-4 mt-3 d-flex">
          <RadioBtnSelect
            className="ml-2"
            value={isFnb ? 'Yes' : 'No'}
            onChange={(value) => {
              if (value === 'Yes') {
                setIsFnb(true);
              } else {
                setIsFnb(false);
              }
              props.dispatchReviewUpdate(isFnb === true ? { isFnb: false } : { isFnb: true });
            }}
            options={[
              { value: 'No' },
              { value: 'Yes' },
            ]}
          />
        </div>

        <hr className="my-4" />

        {loadingTags && (<Tags otherTags={tags} cuisineTags={cuisineTags} />)}

      </Paper>
    </>
  );
};

Details.defaultProps = {
  otherTags:   [],
  cuisineTags: [],
};

Details.propTypes = {
  review: PropTypes.shape({
    status:              PropTypes.string,
    currentReviewStatus: PropTypes.string,
    title:               PropTypes.string,
    content:             PropTypes.string,
    url:                 PropTypes.string,
    isFnb:               PropTypes.bool,
    reviewDate:          PropTypes.string,
    expireDate:          PropTypes.string,
  }).isRequired,
  otherTags:            PropTypes.arrayOf(PropTypes.string),
  cuisineTags:          PropTypes.arrayOf(PropTypes.string),
  dispatchReviewUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  review: state.review.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewUpdate: (review) => reviewAction.reviewDataUpdate(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);
