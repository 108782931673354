// import {
//   TOGGLE_SIDEBAR,
//   HIDDEN_SIDEBAR,
//   SHOW_SIDEBAR,
// } from '../../actions/types';

import Constants from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  name: '',
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.ViewChanged:
      return {
        ...state,
        name: payload,
      };
    default:
      return state;
  }
}
