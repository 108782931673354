// React Dependencies
import React, { useState, useEffect } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Paper, InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

// Internal Dependencies
import useDebounce from '../common/use-debounce';

function SearchBar({ style, label, onSearch }) {
  const [searchBrand, setSearchBrand] = useState('');
  const debouncedSearch = useDebounce(searchBrand, 500);

  const onChange = (key) => {
    setSearchBrand(key);
    onSearch(key);
  };

  useEffect(() => {
    onChange(searchBrand);
  }, [debouncedSearch]);

  return (
    <Paper component="form" className="d-flex align-items-center p-1" style={{ width: '400px', ...style }}>
      <InputBase
        style={{ marginLeft: '8px', flex: 1, ...style }}
        placeholder={label}
        autoFocus
        onChange={(e) => setSearchBrand(e.target.value)}
        inputProps={{ 'aria-label': label }}
      />
      <IconButton onClick={() => onSearch(searchBrand)} className="p-2" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export default SearchBar;

SearchBar.propTypes = {
  style:    PropTypes.shape(Object),
  label:    PropTypes.string,
  onSearch: PropTypes.func,
};

SearchBar.defaultProps = {
  style:    {},
  label:    '',
  onSearch: () => {},
};
