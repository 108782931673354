import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Constants from '../../common/constants';
import layoutAction from '../../actions/layout';
import authAction from '../../actions/auth';

const Logout = (props) => {
  const [redirect, setRedirect] = useState(false);

  const onLogoutClicked = async () => {
    props.dispatchLogout();
    props.dispatchLogoutModalToggle();

    // setRedirect(true);
  };

  const onCancelClicked = async () => {
    props.dispatchLogoutModalToggle();
  };

  return (
    <>
      {
        redirect && <Redirect to={Constants.Url.Login} />
      }
      {
        props.showLogout && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999, backgroundColor: '#00000088' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5>Confirm logout?</h5>
                </div>
                <div className="modal-footer">
                  <Button className="px-4" variant="contained" color="default" size="medium" onClick={() => onLogoutClicked()}>Logout</Button>
                  <Button className="px-4 ml-3" variant="contained" color="primary" size="medium" onClick={() => onCancelClicked()}>Cancel</Button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

Logout.propTypes = {
  dispatchLogout:            PropTypes.func.isRequired,
  dispatchLogoutModalToggle: PropTypes.func.isRequired,
  showLogout:                PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  showLogout: state.layout.showLogout,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogoutModalToggle: () => layoutAction.logoutModalToggle(dispatch),
  dispatchLogout:            () => authAction.logout(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
