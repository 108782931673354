import React from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { WindowSize } from '../common/constants';

const MPullToRefresh = (props) => {
  if (props.windowSize !== WindowSize.Desktop) {
    return (
      <PullToRefresh onRefresh={props.onRefresh} isPullable>
        {props.children}
      </PullToRefresh>
    );
  } else {
    return (
      <>
        {props.children}
      </>
    );
  }
};

MPullToRefresh.propTypes = {
  onRefresh: PropTypes.func,
};

MPullToRefresh.defaultProps = {
  onRefresh: () => {},
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(MPullToRefresh);
