import React from 'react';
import { connect }  from 'react-redux';
import PropTypes    from 'prop-types';
import { Link }     from 'react-router-dom';
import './AppMenuSidebar.css';
import IconButton from '@material-ui/core/IconButton';
import { Avatar, Button, Typography } from '@material-ui/core';
import { isIOS } from 'react-device-detect';
import Icons        from '../Common/Icons';
import layoutAction   from '../../actions/layout';
import pageAction     from '../../actions/page';
import { IsEmpty } from '../../common/checks';
import Colours from '../../styles/colours';
import { Url }  from '../../common/constants';
import inviteAction from '../../actions/invite';
import pageClaimAction from '../../actions/pageClaim';
import { AcronymWord } from '../../common/util';

class AppMenuSideBarMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuClass:     'd-flex',
      activeIconTab: null,
    };
  }

  componentDidMount() {
   this.setState({
     activeIconTab: this.props.match.url,
   });
  }

  onLogoutIconClicked() {
    this.props.dispatchLogoutModalToggle();
  }

  /**
   *
   * @param {String} activeIconTab
   *
   * We need to store current tab for checking which tab is currently clicked
   * for reload , we can get from route match .
   */
  setActiveIconTab(activeIconTab) {
    this.setState({
      activeIconTab,
    });
  }

  onPageClicked(page) {
    this.props.dispatchPageSelected(page);
  }

  renderIconLinks() {
    const IconLinks = [
      {
        title: 'Profile',
        Icon:  <Icons.Profile
          colour={
            Url.Profile === this.state.activeIconTab ? Colours.Black : Colours.Gray2
          }
          fontSize="1.8rem"
        />,
        to: Url.Profile,
      },
      {
        title: 'Support',
        Icon:  <Icons.Support
          colour={
            Url.Support === this.state.activeIconTab ? Colours.Black : Colours.Gray2
          }
          fontSize="1.4rem"
        />,
        to: Url.Support,
      },
    ];
    return IconLinks.map(({ to, title, Icon }, index) => (
      <IconButton
        href={to}
        title={title}
        key={index}
        className="menu-item d-flex align-items-center"
        style={{
          paddingLeft:  0,
          paddingRight: 0,
          borderRadius: 0,
        }}
        onClick={() => {
          this.setActiveIconTab(to);
          this.onPageClicked(null);
        }}
      >
        {Icon}
      </IconButton>
    ));
  }

  render() {
    return (
      <div
        className={this.state.menuClass}
        style={{
          backgroundColor: Colours.White,
          flexDirection:   'row',
          top:             0,
          left:            0,
          height:          '100vh',
        }}
      >
        <div
          className="d-flex"
          style={{
            width:           '80px',
            height:          '100vh',
            flexDirection:   'column',
            justifyContent:  'space-between',
            backgroundColor: Colours.Gray3,
          }}
        >
          <div className="mb-2">
            <Button
              className="block p-0"
              style={{  borderRadius: 0 }}
              onClick={() => { this.props.dispatchSideBarHide(); }}
            >
              <div
                className="d-flex"
                style={{
                  flexDirection:  'column',
                  justifyContent: 'center',
                }}
              >
                <Icons.Menu style={{ height: 60 }} />
              </div>
            </Button>
          </div>
          <div
            className="d-flex app_menu_sidebar"
            style={{
              flex:           1,
              flexDirection:  'column',
              justifyContent: 'flex-start',
              alignItems:     'center',
              overflowY:      'scroll',
              overflowX:      'hidden',
            }}
          >
            {
              this.props.pages.map((page) => (
                <Button
                  className="block p-0"
                  key={`link-page-${page.id}`}
                  style={{
                    backgroundColor:        (this.props.pageSelected && this.props.pageSelected.id === page.id) ? Colours.White : 'transparent',
                    borderRadius:           0,
                    borderTopLeftRadius:    (this.props.pageSelected && this.props.pageSelected.id === page.id) ? 30 : 0,
                    borderBottomLeftRadius: (this.props.pageSelected && this.props.pageSelected.id === page.id) ? 30 : 0,
                    marginLeft:             (this.props.pageSelected && this.props.pageSelected.id === page.id) ? 2 : 0,
                  }}
                >
                  <Link
                    className="block menu-item d-flex align-items-center"
                    to={`/page/${page.id}`}
                    title={page.name}
                    onClick={() => {
                      this.onPageClicked(page);
                      this.setActiveIconTab(page.name || null);
                    }}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        flexDirection:  'column',
                        justifyContent: 'center',
                        height:         '35px',
                      }}
                    >
                      {
                        page.pageLogo
                          ? <Avatar alt={page.name} src={page.pageLogo} />
                          : (
                            <Typography
                              variant="body2"
                              align="center"
                              color="inherit"
                              style={{ color: Colours.Black }}
                            >
                              {AcronymWord(String(page.name), 3)}
                            </Typography>
                          )
                      }
                    </div>
                  </Link>
                </Button>
              ))
            }

            {
              !IsEmpty(this.props.invites) && (
                this.props.invites.map((invite) => (
                  <Button
                    className="block p-0"
                    key={`link-invite-${invite.id}`}
                    style={{
                      backgroundColor:        (this.props.inviteSelected && this.props.inviteSelected.id === invite.id) ? Colours.White : 'transparent',
                      borderRadius:           0,
                      borderTopLeftRadius:    (this.props.inviteSelected && this.props.inviteSelected.id === invite.id) ? 30 : 0,
                      borderBottomLeftRadius: (this.props.inviteSelected && this.props.inviteSelected.id === invite.id) ? 30 : 0,
                      marginLeft:             (this.props.inviteSelected && this.props.inviteSelected.id === invite.id) ? 2 : 0,
                    }}
                  >
                    <Link
                      className="block menu-item d-flex align-items-center"
                      to={`${Url.Invitation.replace(':inviteId', invite.id)}`}
                      title={invite.pageName}
                      onClick={() => { this.props.dispatchInviteSelected(invite); }}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <Icons.Invitation colour={Colours.Gray} />
                        <Typography
                          variant="body2"
                          align="center"
                          color="inherit"
                          style={{ color: Colours.Black }}
                        >
                          {AcronymWord(String(invite.pageName), 3)}
                        </Typography>
                      </div>
                    </Link>
                  </Button>
                ))
              )
            }

            {
              !IsEmpty(this.props.pageClaims) && (
                <>
                  {
                    this.props.pageClaims.map((pageClaim) => (
                      <Button
                        className="block p-0"
                        key={`link-pageClaim-${pageClaim.id}`}
                        style={{
                          backgroundColor:        (this.props.pageClaimSelected && this.props.pageClaimSelected.id === pageClaim.id) ? Colours.White : 'transparent',
                          borderRadius:           0,
                          borderTopLeftRadius:    (this.props.pageClaimSelected && this.props.pageClaimSelected.id === pageClaim.id) ? 30 : 0,
                          borderBottomLeftRadius: (this.props.pageClaimSelected && this.props.pageClaimSelected.id === pageClaim.id) ? 30 : 0,
                          marginLeft:             (this.props.pageClaimSelected && this.props.pageClaimSelected.id === pageClaim.id) ? 2 : 0,
                        }}
                      >
                        <Link
                          className="block menu-item d-flex align-items-center"
                          to={`${Url.PageClaimView.replace(':pageClaimId', pageClaim.id)}`}
                          title={pageClaim.pageName}
                          onClick={() => this.props.dispatchPageClaimSelected(pageClaim)}
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div
                            className="d-flex flex-row justify-content-center align-items-center"
                            style={{ height: '35px' }}
                          >
                            <Typography
                              variant="body2"
                              align="center"
                              color="inherit"
                              style={{ color: Colours.Black }}
                            >
                              {AcronymWord(String(pageClaim.pageName), 3)}
                            </Typography>
                          </div>
                        </Link>
                      </Button>
                    ))
                  }
                </>
              )
            }
          </div>
          <div
            className="d-flex"
            style={{
              flexDirection:  'column',
              justifyContent: 'flex-start',
              alignItems:     'center',
              marginBottom:   isIOS ? '110px' : '70px',
            }}
          >
            <Button
              className="block p-0"
              style={{ borderRadius: 0 }}
              onClick={() => {
                this.props.dispatchPageClaimNew();
                this.setActiveIconTab('claimPage');
              }}
            >
              <a
                className="block py-2 d-flex flex-column justify-content-center align-items-center"
                href={Url.CreatePageClaim}
                title="Create/Claim Page"
              >
                <Icons.Add />
              </a>
            </Button>
            {this.renderIconLinks()}
            <Button
              title="Logout"
              style={{   borderRadius: 0 }}
              onClick={() => {
               this.onLogoutIconClicked();
              }}
            >
              <Icons.Logout colour={Colours.Gray2} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

AppMenuSideBarMobile.propTypes = {
  dispatchInviteSelected:    PropTypes.func.isRequired,
  dispatchPageSelected:      PropTypes.func.isRequired,
  dispatchPageClaimSelected: PropTypes.func.isRequired,
  dispatchPageClaimNew:      PropTypes.func.isRequired,
  dispatchLogoutModalToggle: PropTypes.func.isRequired,
  dispatchSideBarHide:       PropTypes.func.isRequired,
  match:                     PropTypes.shape({
    url:    PropTypes.string,
    params: PropTypes.shape({
      businessId: PropTypes.string,
      pageId:     PropTypes.string,

    }).isRequired,
  }).isRequired,
  invites:           PropTypes.arrayOf(PropTypes.shape()).isRequired,
  inviteSelected:    PropTypes.PropTypes.shape().isRequired,
  pageClaims:        PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pageClaimSelected: PropTypes.PropTypes.shape().isRequired,
  pages:             PropTypes.arrayOf(
    PropTypes.shape({
      businessAccountId: PropTypes.number,
      id:                PropTypes.number,
      name:              PropTypes.string,
    }),
  ).isRequired,
  pageSelected: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  style: PropTypes.shape({}),
};

AppMenuSideBarMobile.defaultProps = {
  style: {},
};

const mapStateToProps = (state) => ({
  invites:           state.invite.invites,
  inviteSelected:    state.invite.selected,
  pageClaims:        state.pageClaim.pageClaims,
  pageClaimSelected: state.pageClaim.selected,
  pages:             state.page.pages,
  pageSelected:      state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchInviteSelected:    (invite) => inviteAction.inviteSelected(dispatch, invite),
  dispatchLogoutModalToggle: () => layoutAction.logoutModalToggle(dispatch),
  dispatchPageClaimNew:      () => pageClaimAction.pageClaimNew(dispatch),
  dispatchPageClaimSelected: (pageClaim) => pageClaimAction.pageClaimSelected(dispatch, pageClaim),
  dispatchPageSelected:      (page) => pageAction.pageSelected(dispatch, page),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMenuSideBarMobile);
