// React Dependencies
import React, { useState, useRef } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import { toast } from 'react-toastify';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import { Visibility as VisibilityIcon, FilterHdr as ImageIcon, CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import 'react-image-lightbox/style.css';

// Internal Dependencies
import MText from '../../../components/Common/MText';
import Colours from '../../../styles/colours';
import Constants, { IMAGE_FORMATS } from '../../../common/constants';
import { IsEmpty } from '../../../common/checks';
import { getFileDataUrl } from '../../../common/util';

const maxFileSize = 2.5; // in Megabytes

const Photo = ({ image, handleImage, title, disabled }) => {
  const fileInputRef = useRef(null);

  const [imageViewerOpen, setImageViewerOpen] = useState(false);

  const validateImage = (file) => {
    if (!IsEmpty(file)) {
      if (!IMAGE_FORMATS.includes(file.type)) {
          toast.error('Only JPG, JPEG and PNG files are supported.');
          return false;
      }
      if (file.size > Constants.BinaryOneMBSize * maxFileSize) {
          toast.error(`Maximum file size of ${maxFileSize}MB exceeded.`);
          return false;
      }
      return true;
    }
  };

  const handleSave = async (e) => {
    if (IsEmpty(e.target.files)) {
      return false;
    }
    const file = e.target.files[0];

    if (!validateImage(file)) {
      return false;
    }

    const dataUrl = await getFileDataUrl(file, (err) => toast.error(err.message));
    const fileObject = { file, data: dataUrl };
    handleImage(fileObject);

    // clears file input
    fileInputRef.current.value = '';
  };

  return (
    <>
      <div className="pl-0">
        <input
          hidden
          accept={IMAGE_FORMATS}
          type="file"
          onChange={handleSave}
          ref={fileInputRef}
        />
        {IsEmpty(image) && (
          <Button
            onClick={() => fileInputRef.current.click()}
            disabled={disabled}
            className="mx-2 mx-sm-0"
            style={{ width: '95%', height: 180, minHeight: 50, backgroundColor: Colours.LightGray1, borderRadius: 15 }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <ImageIcon fontSize="large" />
              <MText size={11} text="+ Click to upload" />
            </div>
          </Button>
        )}
        <div style={{ display: (image ? 'flex' : 'none'), width: '250px' }} className="mt-2 flex-column">
          <img
            className="lc-box-shadow"
            alt={title}
            src={image}
            style={{
              width:        '100%',
              borderRadius: '10px',
              boxShadow:    '0 1px 2px 1px rgba(0, 0, 0, .1)',
            }}
          />
          <div className="d-flex mt-2">
            {!disabled && (
              <div className="mr-2">
                <Tooltip title="Upload">
                  <IconButton onClick={() => fileInputRef.current.click()} className="shadow-sm bg-white">
                    <CloudUploadIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            <div className="mr-2">
              <Tooltip title="View">
                <IconButton onClick={() => setImageViewerOpen(true)} className="shadow-sm bg-white">
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {imageViewerOpen && (
        <Lightbox
          animationDisabled
          mainSrc={image}
          onCloseRequest={() => setImageViewerOpen(false)}
        />
      )}
    </>
  );
};

Photo.propTypes = {
  title:       PropTypes.string.isRequired,
  image:       PropTypes.string.isRequired,
  handleImage: PropTypes.func.isRequired,
  disabled:    PropTypes.bool,
};

Photo.defaultProps = {
  disabled: false,
};

export default Photo;
