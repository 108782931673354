import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Typography, Paper, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions }
  from '@material-ui/core';
import { connect } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import queryString from 'query-string';
import pageClaimStyle from '../../../styles/pageClaim';
import { IsEmpty } from '../../../common/checks';
import Constant, { Status } from '../../../common/constants';
import ApiService from '../../../services/apiService';
import pageClaimAction from '../../../actions/pageClaim';
import Colours from '../../../styles/colours';

const PageClaimView = (props) => {
  const [pageClaim, setPageClaim] = useState(null);
  const [businessProfile, setBusinessProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const history  = useHistory();

  useEffect(() => {
    setIsLoading(true);

    // Note: When we navigate from create page claim to viewing it here and query param 'showSuccess'
    //       is included in the url, we will show the modal displaying the submission is received
    let displaySuccessModal = false;
    if (!IsEmpty(props.location) && !IsEmpty(props.location.search)) {
      const query = queryString.parse(props.location.search);
      displaySuccessModal = query && query.showSuccess === 'true';
    }

    if (!IsEmpty(props.pageClaim) && !IsEmpty(props.pageClaim.id)) {
      ApiService.getPageClaim(props.pageClaim.id)
        .then((claim) => {
          props.dispatchPageClaimUpdate(claim);
          setPageClaim(claim.pageClaim);
          setShowModalSuccess(displaySuccessModal);

          if (!IsEmpty(claim.businessProfile)) {
            setBusinessProfile(claim.businessProfile);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setIsLoading(false);
        });
    } else if (IsEmpty(props.pageClaimData.pageClaim)) {
      history.push(Constant.Url.Main);
    } else {
      setPageClaim(props.pageClaimData.pageClaim);
      setBusinessProfile(props.pageClaimData.businessProfile);
      setIsLoading(false);
    }
  }, [props.pageClaim.id]);

  const RenderPageCategories = () => {
    const pageGroupCategories = [...new Set(pageClaim.pageCategories.map((el) => el.group))];
    const pageCategories = pageGroupCategories.map((el, index) => {
      const curMembersGroupCategory = pageClaim.pageCategories.filter((cur) => cur.group === el).map((cat) => cat.name);
      return (
        <li className="d-flex flex-column" key={el} style={{ marginTop: index === 0 ? '0' : '1rem' }}>
          <Typography variant="body1" display="inline" style={{ fontWeight: 'bold' }}>{el}</Typography>
          <div className="d-flex flex-column justify-content-start">
            {
              _.map(curMembersGroupCategory, (categoryName) => (
                <Typography key={categoryName} variant="body1">
                  {categoryName}
                </Typography>
              ))
            }
          </div>
        </li>
      );
    });

    return (
      <ul style={{
        listStyleType: 'none',
        margin:        0,
        padding:       0,
      }}
      >
        {pageCategories}
      </ul>
    );
  };

  const onDeletePageClaim = async () => {
    try {
      setIsLoading(true);
      await ApiService.deletePageClaim(props.pageClaim.id);
      props.dispatchPageClaimDelete(props.pageClaim.id);

      toast.success('Page claim deleted successfully');
      setIsRedirect(true);
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return isRedirect ? <Redirect to={Constant.Url.Main} /> : (

    <div className="container-fluid">
      <div className="my-4 row d-flex flex-column align-items-center">
        <div className={IsEmpty(props.pageClaim) ? 'col-12 mb-4' : 'col-12 col-md-7 mb-4'}>
          {!IsEmpty(props.pageClaim) && <Typography variant="h5" style={pageClaimStyle.textHeader}>Summary of Page Claim</Typography>}
          {isLoading && <CircularProgress className="mt-3" /> }
          {
            !isLoading && (
              IsEmpty(pageClaim)
                ? <Typography className="mt-3" variant="h5">Sorry, the claim cannot be found</Typography>
                : (
                  <>
                    <div className="mt-3 pl-2">
                      { props.pageClaim.id && (
                        <Typography className="mt-2" variant="body1">
                          Claim ID:
                          {' '}
                          {props.pageClaim.id}
                        </Typography>
                      )}
                      { pageClaim.updatedAt && (
                        <Typography className="mt-2" variant="body1">
                          Date:
                          {' '}
                          <span className="ml-4">{moment(pageClaim.updatedAt).format('YYYY-MM-DD h:mm A')}</span>
                        </Typography>
                      )}
                      { pageClaim.status && (
                        <div style={{ display: 'flex' }} className="mt-2 justify-content-between align-items-center">
                          <Typography variant="body1">
                            Status:
                            {' '}
                            <span
                              className="ml-2 py-2 px-3"
                              style={{
                                color:           Colours.White,
                                borderRadius:    '30px',
                                backgroundColor: pageClaim.status === Status.Live ? Colours.Green
                                  : pageClaim.status === Status.Rejected ? Colours.Red : Colours.Amber,
                              }}
                            >
                              {pageClaim.status}
                            </span>
                          </Typography>
                          <Button
                            variant="outlined"
                            size="medium"
                            color="primary"
                            onClick={() => setOpenModal(true)}
                          >
                            Delete Page Claim
                          </Button>
                        </div>
                      )}
                      {
                        (pageClaim && pageClaim.status === Status.Rejected) && (
                          <div className="d-flex flex-row mt-2" style={{ backgroundColor: Colours.Gray4 }}>
                            <Typography>Reason:</Typography>
                            <Typography className="ml-3">{pageClaim.remarks}</Typography>
                          </div>
                        )
                      }
                    </div>

                    <div className="mt-3">
                      <Paper style={pageClaimStyle.paper}>
                        <Typography variant="h5" className="mt-4" style={pageClaimStyle.textHeader}>Page Information</Typography>
                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              Page name
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1">{pageClaim.pageName}</Typography>
                          </div>
                        </div>

                        <Divider className="mt-2" />
                        {pageClaim.pageId && pageClaim.pageRename && (
                          <>
                            <div className="row mt-2">
                              <div className="col-5">
                                <Typography variant="body1">
                                  New page name
                                </Typography>
                              </div>
                              <div className="col-7">
                                <Typography variant="body1">{pageClaim.pageRename}</Typography>
                              </div>
                            </div>
                            <Divider className="mt-2" />
                          </>
                        )}

                        {pageClaim.pageId && (
                          <>
                            <div className="row mt-2">
                              <div className="col-5">
                                <Typography variant="body1">
                                  Keep the existing page data
                                </Typography>
                              </div>
                              <div className="col-7">
                                <Typography variant="body1">{ pageClaim.isKeepData ? 'Yes' : 'No'}</Typography>
                              </div>
                            </div>
                            <Divider className="mt-2" />
                          </>
                        )}

                        {pageClaim.pageType && (
                          <>
                            <div className="row mt-2">
                              <div className="col-5">
                                <Typography variant="body1">
                                  Page Type
                                </Typography>
                              </div>
                              <div className="col-7">
                                <Typography variant="body1">{pageClaim.pageType}</Typography>
                              </div>
                            </div>
                            <Divider className="mt-2" />
                          </>
                        )}

                        {!pageClaim.pageId && pageClaim.address && (
                          <>
                            <div className="row mt-2">
                              <div className="col-5">
                                <Typography variant="body1">
                                  Address
                                </Typography>
                              </div>
                              <div className="col-7">
                                <Typography variant="body1">{`${pageClaim.address}, Singapore ${pageClaim.postalCode}`}</Typography>
                              </div>
                            </div>
                            <Divider className="mt-2" />
                          </>
                        )}

                        {!pageClaim.pageId && pageClaim.pageCategories && (
                          <>
                            <div className="row mt-2 align-items-center">
                              <div className="col-5">
                                <Typography variant="body1">
                                  Categories
                                </Typography>
                              </div>
                              <div className="col-7">
                                <RenderPageCategories />
                              </div>
                            </div>
                            <Divider className="mt-2" />
                          </>
                        )}

                        <Typography variant="h5" className="mt-4" style={pageClaimStyle.textHeader}>Business Profile Information</Typography>
                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              Company name
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1" style={{ wordWrap: 'break-word' }}>{businessProfile.name}</Typography>
                          </div>
                        </div>
                        <Divider className="mt-2" />
                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              ACRA Registration No
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1">{businessProfile.regNo}</Typography>
                          </div>
                        </div>
                        <Divider className="mt-2" />
                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              Company Address
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1" style={{ wordWrap: 'break-word' }}>{businessProfile.address}</Typography>
                          </div>
                        </div>
                        <Divider className="mt-2" />
                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              Postal Code
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1">{businessProfile.postalCode}</Typography>
                          </div>
                        </div>
                        <Divider className="mt-2" />
                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              Contact Person
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1" style={{ wordWrap: 'break-word' }}>{businessProfile.contactPerson}</Typography>
                          </div>
                        </div>
                        <Divider className="mt-2" />

                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              Designation
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1">{businessProfile.contactDesignation}</Typography>
                          </div>
                        </div>
                        <Divider className="mt-2" />

                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              Contact Email
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1" style={{ wordWrap: 'break-word' }}>{businessProfile.contactEmail}</Typography>
                          </div>
                        </div>
                        <Divider className="mt-2" />
                        <div className="row mt-3">
                          <div className="col-5">
                            <Typography variant="body1">
                              Contact Phone Number
                            </Typography>
                          </div>
                          <div className="col-7">
                            <Typography variant="body1">{businessProfile.contactNo}</Typography>
                          </div>
                        </div>
                        <Divider className="mt-2" />
                      </Paper>
                    </div>
                  </>
                )
            )
          }
        </div>
      </div>

      <Dialog
        fullWidth
        size="sm"
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Page Claim</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this page claim?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDeletePageClaim()} style={{ width: '120px' }} color="default">Confirm</Button>
          <Button className="mx-3" style={{ width: '120px' }} onClick={() => setOpenModal(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>

      {showModalSuccess && (
        <Dialog
          open
          onClose={() => setShowModalSuccess(false)}
          onBackdropClick="false"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Thank you for your application. You should receive a reply via email within 10 working days. You may need to check your email's junk/promotions folder.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ width: '200px' }} onClick={() => setShowModalSuccess(false)}>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

PageClaimView.defaultProps = {
  pageClaimData: {
    pageClaim:       {},
    businessProfile: {},
  },
};

PageClaimView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,

  pageClaim: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,

  pageClaimData: PropTypes.shape({
    pageClaim: PropTypes.shape({
      pageName:       PropTypes.string,
      pageRename:     PropTypes.string,
      isKeepData:     PropTypes.bool,
      pageType:       PropTypes.string,
      pageCategories: PropTypes.arrayOf(PropTypes.number),
      postalCode:     PropTypes.string,
      address:        PropTypes.string,
    }).isRequired,

    businessProfile: PropTypes.shape({
      name:          PropTypes.string,
      regNo:         PropTypes.string,
      address:       PropTypes.string,
      postalCode:    PropTypes.string,
      contactPerson: PropTypes.string,
      contactEmail:  PropTypes.string,
      contactNo:     PropTypes.string,
    }).isRequired,
  }),

  dispatchPageClaimUpdate: PropTypes.func.isRequired,
  dispatchPageClaimDelete: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pageClaim: state.pageClaim.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageClaimUpdate: (claim) => pageClaimAction.pageClaimUpdate(dispatch, claim),
  dispatchPageClaimDelete: (id) => pageClaimAction.pageClaimDelete(dispatch, id),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageClaimView);
