import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';

function AccessDeniedComp(props) {
  return (
    <div
      style={{
        ...props.style,
        height:         '80vh',
        width:          '100%',
        display:        'flex',
        justifyContent: 'center',
        alignItems:     'center',
      }}
    >
      <Paper className="p-3 m-3 text-center">
        <Typography variant="h4" className="my-2">Permission Denied</Typography>
        <Typography variant="body1">You are not allowed to view this page. Please contact your administrator for access.</Typography>
      </Paper>
    </div>
  );
}

AccessDeniedComp.defaultProps = {
  style: {},
};

AccessDeniedComp.propTypes = {
  style: PropTypes.shape(Object),
};

export default AccessDeniedComp;
