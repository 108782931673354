import React from 'react';
import TextField from '@material-ui/core/TextField';

const TextInput = ({ onChange, onKeyPress, field, form, type, size, ...props }) => (
  <TextField
    {...props}
    {...field}
    variant="outlined"
    value={field.value || ''}
    type={type}
    size={size}
    onChange={(e) => {
      form.setFieldValue(field.name, e.target.value, false);
      onChange(e, field.name);
      form.setFieldValue(field.name, e.target.value, true);
    }}

    onKeyPress={onKeyPress}
    InputProps={{
      inputProps: {
        min: 1,
      },
    }}
    InputLabelProps={{
      style: {},
    }}
  />
);

TextInput.defaultProps = {
  field: {
    value: null,
  },
  size: 'small',
};

export default TextInput;
