import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Typography, TextField, Paper, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { containerHeaderTextStyle } from '../../styles/common';
import Colours from '../../styles/colours';
import ApiService from '../../services/apiService';
import { ExtractParamsFromUrlQueryStr, IsEmpty, Trim } from '../../common/util';

const Support = (props) => {
  const [formData, setForm] = useState({
    subject: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [errorObj, setErrorObj] = useState({
    subject: {
      message: '',
      error:   false,
    },
    message: {
      message: '',
      error:   false,
    },
  });

  const [pageInQns, setPageInQns] = useState(null);

  useEffect(() => {
    // if url includes query param 'upgradePlan=true', then we will prefill the subject
    if (props.location) {
      const params = ExtractParamsFromUrlQueryStr(props.location.search);
      if (!IsEmpty(params)) {
        if (params.upgradePlan === 'true') {
          setForm({ subject: 'Upgrade/queries on subscription tier(s)' });
        }
        if (!IsEmpty(params.pageName)) {
          const { pageName, pageId } = params;
          setPageInQns({ pageName, pageId });
        }
      }
    }
  }, []);

  const onBlurChange = (name, value) => {
    if (IsEmpty(Trim(value))) {
      setErrorObj((prevState) => ({
        ...prevState,
        [name]: {
          error:   true,
          message: `${name} is required`,
        },
      }));
    }
  };

  const handleInputChange = (name, value) => {
    if (IsEmpty(Trim(value))) {
      setErrorObj((prevState) => ({
        ...prevState,
        [name]: {
          error:   true,
          message: `${name} is required`,
        },
      }));
    } else {
      setErrorObj((prevState) => ({
        ...prevState,
        [name]: {
          error:   false,
          message: ``,
        },
      }));
    }
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formDataCopy = JSON.parse(JSON.stringify(formData));
      if (!IsEmpty(pageInQns)) {
        // append the page info to the form's message
        formDataCopy.message = `
          <h4>Page: ${pageInQns.pageName}</h4>
          <h4>Page Id: ${pageInQns.pageId}</h4>
          <h4>Message:</h4>
          <p>${formDataCopy.message}</p>
        `;
      }

      await ApiService.sendEmail({ formData: formDataCopy });
      setLoading(false);
      toast.success('Support request received');
      setShowSuccessMsg(true);
      setForm({
        subject: '',
        message: '',
      });
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="container pt-4 p-2">
      <form className="ml-lg-5 pl-lg-5 d-flex flex-column align-items-start" onSubmit={handleSubmit}>
        <div className="d-flex flex-row mt-2">
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Support</Typography>
        </div>

        {
          showSuccessMsg ? (
            <div className="d-flex justify-content-center">
              <div className="mt-3 p-3" style={{ backgroundColor: Colours.Primary, borderRadius: '5px' }}>
                <Typography variant="body2" color="inherit" style={{ color: Colours.White }}>
                  We have received your support request. It will be attended to within 3 business days.
                  <br />
                  <br />
                  Please check your email for our response.
                  <br />
                  <br />
                  {' '}
                  Thank you.
                  <br />
                  Monstyr Support
                </Typography>
              </div>
            </div>
          ) : (
            <Paper
              className="col-12 col-xl-9 p-2 my-2"
              style={{ backgroundColor: Colours.White }}
            >
              <div className="px-3 my-3 col-12">
                <Typography variant="body2">
                  Subject
                  {' '}
                  <span className="ml-1" style={{ color: Colours.Red }}>*</span>
                </Typography>

                <TextField
                  className="block mt-2"
                  name="subject"
                  type="text"
                  value={formData.subject}
                  onChange={(e) => handleInputChange('subject', e.target.value)}
                  onBlur={(e) => onBlurChange('subject', e.target.value)}
                  variant="outlined"
                  size="small"
                  helperText={errorObj.subject && errorObj.subject.message}
                  error={errorObj.subject && errorObj.subject.error}
                  inputProps={{
                    style: {
                      fontSize: '0.9rem',
                    },
                  }}
                />
              </div>
              <div className="px-3 my-3 col-12">
                <Typography variant="body2">
                  Message
                  {' '}
                  <span className="ml-1" style={{ color: Colours.Red }}>*</span>
                </Typography>

                <TextField
                  className="block mt-2"
                  name="message"
                  type="text"
                  value={formData.message}
                  onChange={(e) => handleInputChange('message', e.target.value)}
                  onBlur={(e) => onBlurChange('message', e.target.value)}
                  variant="outlined"
                  multiline
                  rows={5}
                  maxRows={10}
                  helperText={errorObj.message && errorObj.message.message}
                  error={errorObj.message && errorObj.message.error}
                  inputProps={{
                    style: {
                      fontSize: '0.9rem',
                    },
                  }}
                />
              </div>

              <div className="d-flex justify-content-center">
                <div className="col-12 col-md-4 col-lg-4">
                  <Button
                    className="block mt-3 mb-4"
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={loading || (IsEmpty(formData.subject) || IsEmpty(formData.message))}
                  >
                    <div className="d-flex flex-row justify-content-center align-items-center">
                      <Typography>
                        {
                          showSuccessMsg ? 'Sent' : (loading ? 'Please wait' : 'Send')
                        }
                      </Typography>
                      {loading && <CircularProgress className="ml-2" size="1rem" />}
                    </div>
                  </Button>
                </div>
              </div>

            </Paper>
          )
        }
      </form>
    </div>
  );
};

Support.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Support);
