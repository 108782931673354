import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import {
  Button,
  Typography,
}
  from '@material-ui/core';
import { IsEmpty, IsAdminOrContributor } from '../../../../common/checks';
import { formatDatetoString, datePeriodToString, getThumbnailFromVideoURL, showDateInSGTime } from '../../../../common/util';
import Colours from '../../../../styles/colours';
import { Status } from '../../../../common/constants';
import Tooltip from '../../../../components/Common/ToolTip';
import Icons from '../../../../components/Common/Icons';

const DesktopPostCard = (props) => {
  const [p, setP] = useState(null);
  useEffect(() => {
    setP(props.post);
  }, []);

  if (IsEmpty(p)) {
    return null;
  } else {
    return (
      <div className="" style={{ width: '100%', backgroundColor: Colours.White, borderRadius: '10px', borderColor: Colours.Gray5, borderWidth: '1px', borderStyle: 'solid' }}>
        <div className="d-flex flex-row p-2">
          <div className="d-flex flex-row pl-1 align-items-center" style={{ width: '65%' }}>
            <div className="d-flex align-items-center" style={{ background: '#000000', borderRadius: 15, width: '100px', height: '100px', position: 'relative', overflow: 'hidden' }}>
              {
                !IsEmpty(p.videos) && !IsEmpty(p.images) && getThumbnailFromVideoURL(p.videos[0])
                  ? (
                    <>
                      <ReactPlayer
                        url={p.videos[0]}
                        muted
                        playing
                        loop
                        width="100%"
                        height="auto"
                      />
                    </>
                  ) : !IsEmpty(p.videos) && getThumbnailFromVideoURL(p.videos[0])
                    ? (
                      <>
                        <img
                          style={{
                            width:        '100%',
                            height:       'auto !important',
                            borderRadius: '15px',
                          }}
                          alt={p.title || 'cover'}
                          src={getThumbnailFromVideoURL(p.videos[0])}
                        />
                      </>
                    )
                    : (
                      <>
                        {
                          !IsEmpty(p.images) && (
                            <img
                              style={{
                                width:        '100%',
                                height:       'auto !important',
                                borderRadius: '15px',
                              }}
                              alt={p.title || 'cover'}
                              src={p.images[0].display}
                            />
                          )
                        }
                      </>
                    )
              }
            </div>

            <div className="flex-grow-1 flex-column px-2" style={{ }}>
              <Typography noWrap variant="subtitle1" color="inherit" style={{  width: '95%', color: Colours.Black }}>{p.title || '<No title>'}</Typography>

              {
                !IsEmpty(p.promoFromDate) && !IsEmpty(p.promoTillDate)
                  ? (
                    <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>
                      {`${datePeriodToString(showDateInSGTime(p.promoFromDate), showDateInSGTime(p.promoTillDate))}`}
                    </Typography>
                  )
                  : <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>Promo period not specified</Typography>
              }

              <div className="d-flex flex-row justify-content-end mt-3" style={{ }}>
                <Tooltip title="View / Edit Post">
                  <Button
                    className="pt-3"
                    onClick={() => props.editPost(p)}
                  >
                    <Icons.Edit fontSize="1.2rem" />
                  </Button>
                </Tooltip>

                {
                  p.status !== Status.Deleted && (
                    <Tooltip title={IsAdminOrContributor(props.page.staffType) ? 'Delete Post' : 'Permission Denied'}>
                      <Button
                        className="pt-3"
                        disabled={!IsAdminOrContributor(props.page.staffType)}
                        onClick={() => props.deletePost(p)}
                      >
                        <Icons.DeleteBin fontSize="1.4rem" colour={!IsAdminOrContributor(props.page.staffType) ? Colours.Disabled : Colours.Black} />
                      </Button>
                    </Tooltip>
                  )
                }
              </div>
            </div>
          </div>

          <div className="my-2 py-1" style={{ borderLeftStyle: 'solid', borderColor: Colours.Gray4, borderWidth: '1px'   }} />

          <div className="ml-4 py-1" style={{ }}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="body2" color="inherit" style={{ color: Colours.Gray1 }}>ID</Typography>
                  </td>
                  <td>
                    <Typography variant="body2" className="ml-3" color="inherit" style={{ color: Colours.Black }}>{`: ${p.id}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body2" className="mt-1" color="inherit" style={{ color: Colours.Gray1 }}>Status</Typography>
                  </td>
                  <td>
                    <Typography variant="body2" className="ml-3 mt-1" color="inherit" style={{ color: Colours.Black }}>{`: ${p.status}`}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="body2" className="mt-1" color="inherit" style={{ color: Colours.Gray1 }}>Post Date</Typography>
                  </td>
                  <td>
                    <Typography variant="body2" className="ml-3 mt-1" color="inherit" style={{ color: Colours.Black }}>
                      {`: ${formatDatetoString(p.postDate)}`}
                    </Typography>
                  </td>
                </tr>
                <tr className="mt-2">
                  <td>
                    <Typography variant="body2" className="mt-1" color="inherit" style={{ color: Colours.Gray1 }}>Last Updated</Typography>
                  </td>
                  <td>
                    <Typography variant="body2" className="ml-3 mt-1" color="inherit" style={{ color: Colours.Black }}>
                      {`: ${formatDatetoString(p.updatedAt)}`}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DesktopPostCard);
