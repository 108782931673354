import Constants from '../common/constants';

const { ReduxAction } = Constants;

const pagesAccess = (dispatch, pages) => {
  dispatch({
    type:    ReduxAction.PagesAccess,
    payload: pages,
  });
};

const pagesAccessAdd = (dispatch, pages) => {
  dispatch({
    type:    ReduxAction.PagesAccessAdd,
    payload: pages,
  });
};

const pageSelected = (dispatch, page) => {
  dispatch({
    type:    ReduxAction.PageSelected,
    payload: page,
  });
  // clear any invitation & pageClaim selected (affects the sideBar display)
  dispatch({
    type:    ReduxAction.InviteSelected,
    payload: {},
  });
  dispatch({
    type:    ReduxAction.PageClaimSelected,
    payload: {},
  });
};

const pageUpdate = (dispatch, page) => {
  dispatch({
    type:    ReduxAction.PageUpdate,
    payload: page,
  });
};

const pageAction = {
  pagesAccess,
  pagesAccessAdd,
  pageSelected,
  pageUpdate,
};

export default pageAction;
