import { Button, Link, TextField, Typography } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { IsEmpty } from '../common/checks';
import Constants from '../common/constants';
import { emailValidation } from '../common/util';
import Colours from '../styles/colours';
import ApiService from '../services/apiService';

class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:           true,
      email:             '',
      isValid:           false,
      isCaptchaVerified: false,
      redirect:          false,
      errors:            {},
    };

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onVerifyCaptcha = this.onVerifyCaptcha.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  onChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
    this.validate(e.target.name, e.target.value);
  }

  onVerifyCaptcha(res) {
    if (res) {
      this.setState({ isCaptchaVerified: true }, () => {
        this.validate('email', this.state.email);
      });
    }
  }

  validate(field, value) {
    const { isCaptchaVerified } = this.state;
    const errors = {};
    errors.email = emailValidation(value);
    this.setState({
      errors:  _.assign(this.state.errors, errors),
      isValid: (IsEmpty(errors.email) && isCaptchaVerified),
    });
  }

  async onFormSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.validate();

    try {
      const { email } = this.state;
      await ApiService.forgetPassword({
        email,
      });

      toast.success('A reset email has been sent to you. Please check your inbox / promotions folder.');

      this.setState({ redirect: true });
    } catch (err) {
      if (err.message === 'A reset password link has been emailed to you') {
        toast.info('A reset password link has been emailed to you. Please submit a new request 5 minutes later');
      } else {
        toast.error(err.message);
      }
      this.setState({ loading: false });
    }
  }

  render() {
    return this.state.redirect
      ? <Redirect to={Constants.Url.Login} />
      : (
        <div className="container-fluid" style={{ paddingTop: '2%', background: '#FFFFFF' }}>
          {
            this.state.loading && (
              <div
                className="d-flex"
                style={{
                  position:        'absolute',
                  zIndex:          1,
                  top:             0,
                  left:            0,
                  width:           '100%',
                  height:          '100%',
                  justifyContent:  'center',
                  alignItems:      'center',
                  backgroundColor: '#00000088',
                }}
              >
                <h1 style={{ color: Colours.White }}>Loading...</h1>
              </div>
            )
          }
          <div className="row">
            <div className="col-2 col-md-3 col-lg-4" />
            <div className="col-12 col-md-6 col-lg-4 px-3">
              <div className="py-3 pt-md-4 px-3" style={{ background: '#fafafa' }}>
                <div className="d-flex flex-column" style={{ alignItems: 'center' }}>
                  <Typography variant="inherit" align="center" style={{ fontSize: '1.3em', lineHeight: '1.1em' }}>Forget Password</Typography>
                  <form
                    className="form mt-4"
                    onSubmit={(e) => this.onFormSubmit(e)}
                    autoComplete="off"
                    style={{ width: '100%', position: 'relative' }}
                  >
                    <div className="form-group">
                      <Typography className="ml-2" variant="inherit" color="inherit" style={{ fontSize: '1em', color: Colours.Black }}>Enter Email</Typography>
                      <TextField
                        className="mt-1 textfield-rounded"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => { this.onChange(e); }}
                        required
                        type="email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={!!this.state.errors.email}
                        helperText={this.state.errors.email}
                      />
                    </div>

                    {
                      !this.state.isLoading && (
                        <div className="form-group mt-3 mt-lg-4 d-flex justify-content-center align-items-center">
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                            onChange={this.onVerifyCaptcha}
                            onExpired={() => { this.setState({ isValid: false, isCaptchaVerified: false }); }}
                            onError={() => { this.setState({ isValid: false, isCaptchaVerified: false }); }}
                            hidden={false}
                          />
                        </div>
                      )
                    }

                    <div className="form-group mt-2 mt-sm-0 mb-0">
                      <Button
                        type="submit"
                        className="block btn-rounded"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!this.state.isValid}
                      >
                        <Typography variant="subtitle1">Send Reset Link</Typography>
                      </Button>
                    </div>
                  </form>

                </div>
                <div className="d-flex flex-column align-items-center mt-4 mb-5">
                  <Button className="px-4">
                    <Link className="block" href={Constants.Url.Login}>
                      <Typography variant="inherit" color="inherit" style={{ fontSize: '1.0em', fontWeight: 'bold', color: Colours.Gray }}><u>Login</u></Typography>
                    </Link>
                  </Button>

                  <div className="d-flex flex-row mt-1 align-items-center justify-content-center">
                    <Typography variant="inherit" color="inherit" style={{ fontSize: '0.8em', color: Colours.Gray }}>
                      Don&lsquo;t have an account?
                      {' '}
                    </Typography>
                    <Button className="my-0 py-0">
                      <Link href={Constants.Url.Signup}>
                        <strong style={{ color: Colours.Blue, fontSize: '1.0em', fontWeight: 'bold' }}><u>Sign up</u></strong>
                      </Link>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default connect(null, null)(ForgetPassword);
