import { Button, Card, CardHeader, Checkbox, Divider, InputAdornment, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { IsEmpty, IsNumber } from '../../../../common/checks';
import apiService from '../../../../services/apiService';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width:           '100%',
    height:          230,
    backgroundColor: theme.palette.background.paper,
    overflow:        'auto',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const PageOutletSelect = (props) => {
  const classes = useStyles();

  const [checked, setChecked]         = useState([]);
  const [left, setLeft]               = useState([]);
  const [leftCopy, setLeftCopy]       = useState([]);
  const [right, setRight]             = useState([]);
  const [rightCopy, setRightCopy]     = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const init = async (pageId, inputSelectedOutlets) => {
    try {
      const pageOutlets = await apiService.getReviewPlaceOutlets(pageId);

      const selectedOutletIdSet = new Set();
      for (const inputSelectedOutlet of inputSelectedOutlets) {
        // handle case where props.selected passed to this com is an array of outlet ids
        // or an array of outlet objects
        if (IsNumber(inputSelectedOutlet)) {
          selectedOutletIdSet.add(inputSelectedOutlet);
        } else if (!IsEmpty(inputSelectedOutlet) && 'id' in inputSelectedOutlet) {
          selectedOutletIdSet.add(inputSelectedOutlet.id);
        }
      }

      const notSelectedOutlets = [];
      const selectedOutlets = [];
      for (const outlet of pageOutlets) {
        if (!selectedOutletIdSet.has(outlet.id)) {
          notSelectedOutlets.push(outlet);
        } else {
          selectedOutlets.push(outlet);
        }
      }

      setLeft(notSelectedOutlets);
      setLeftCopy(notSelectedOutlets);

      setRight(selectedOutlets);
      setRightCopy(selectedOutlets);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    // expect props.selected to be an array of the Outlets data object
    // setSelected(props.selected || []);
    const { pageId, selected } = props;

    if (!IsEmpty(pageId)) {
      init(pageId, selected || []);
    }
  }, []);

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setSearchValue('');
    setRight(rightCopy.concat(leftChecked));
    setRightCopy(rightCopy.concat(leftChecked));
    setLeft(not(leftCopy, leftChecked));
    setLeftCopy(not(leftCopy, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setSearchValue('');
    setLeft(leftCopy.concat(rightChecked));
    setLeftCopy(leftCopy.concat(rightChecked));
    setRight(not(rightCopy, rightChecked));
    setRightCopy(not(rightCopy, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    props.onChange(rightCopy);
  }, [rightCopy]);

  const searchOutlets = (outlets, searchTerm) => _.filter(outlets, (o) => String(o.locatedIn).toLowerCase().includes(searchTerm)
      || String(o.address).toLowerCase().includes(searchTerm)
      || String(o.unitNo).toLowerCase().includes(searchTerm)
      || String(o.postalCode).includes(searchTerm));

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      // use lowercase string for the search
      const searchTerm = String(e.target.value).toLowerCase();
      const leftArr = searchOutlets(leftCopy, searchTerm);
      setLeft(leftArr);

      const rightArr = searchOutlets(rightCopy, searchTerm);
      setRight(rightArr);
    } else {
      setLeft(leftCopy);
      setRight(rightCopy);
    }
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={(
          <Checkbox
            color="primary"
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'All Items Selected' }}
          />
        )}
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} checked`}
      />

      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem key={`${value.id}`} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={(
                  <div>
                    {
                      !IsEmpty(value.locatedIn) && (
                        <>
                          {value.locatedIn}
                          <br />
                        </>
                      )
                    }
                    {value.address}
                    <br />
                    {
                      !IsEmpty(value.unitNo) && (
                        <>
                          {`#${value.unitNo}`}
                          <br />
                        </>
                      )
                    }
                    {
                      !IsEmpty(value.postalCode) && (
                        <>
                          {`S(${value.postalCode})`}
                          <br />
                        </>
                      )
                    }
                  </div>
                )}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Typography variant="body1" style={{ fontSize: 14 }}>Select Outlet(s)</Typography>
        </div>
        <div className="col-12">
          <TextField
            id="input-with-icon-textfield"
            placeholder="Search"
            autoComplete="off"
            onChange={(e) => onChangeSearch(e)}
            value={searchValue}
            style={{ marginTop: 10, marginBottom: 10, fontSize: 14 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-5">
          {customList('Unselected Outlets', left)}
        </div>

        <div className="col-2 d-flex flex-column justify-content-center align-items-center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </div>

        <div className="col-5">
          {customList('Selected Outlets', right)}
        </div>
      </div>
    </div>
  );
};

PageOutletSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  pageId:   PropTypes.number.isRequired,
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})])).isRequired,
};

export default PageOutletSelect;
