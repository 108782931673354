import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import ProfileDetailsForm from './ProfileDetailsForm';
import ChangePasswordForm from './ChangePasswordForm';
import ApiService from '../../../services/apiService';
import { containerHeaderTextStyle } from '../../../styles/common';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: { name: '', email: '' },
    };
  }

  async getBusinessProfile() {
    try {
      const data = await ApiService.getUserInfo();
      this.setState({
        userData: data,
      });
    } catch (error) {
      toast.error(error.message);
    }
  }

  componentDidMount() {
    this.getBusinessProfile();
  }

  render() {
    return (
      <div className="container-fluid mt-4">
        <div className="row d-flex flex-column align-items-center">
          <div className="col-12 col-md-8 col-lg-6">
            <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Your Profile</Typography>
            <div className="mt-3">
              <ProfileDetailsForm userData={this.state.userData} />
              <ChangePasswordForm />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profile.propTypes = {};

export default connect(null, null)(Profile);
