import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Chip, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import postStyle from '../../../styles/post';
import ReviewAction from '../../../actions/review';
import Colours from '../../../styles/colours';

const Tags = (props) => {
  const [tags, setTags] = useState([]);
  const [cuisineTags, setCuisineTags] = useState([]);

  useEffect(() => {
    if (props.review) {
      setTags(props.otherTags || []);
      setCuisineTags(props.cuisineTags || []);
    }
  }, [props.review]);

  return (
    <>

      <Typography variant="subtitle1" color="inherit" className="mt-1 mb-2" style={postStyle.textHeader}>
        Cuisine Tags
      </Typography>

      <Autocomplete
        disabled
        id="tags"
        className="mb-3"
        limitTags={-1}
        multiple
        value={cuisineTags}
        filterSelectedOptions
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            variant="outlined"
            label={option}
            style={postStyle.chip}
          />
        ))}
        renderInput={(params) => (
          <Tooltip
            title={props.disabled ? props.disabledMsg : ''}
            isLink
          >
            <TextField
              {...params}
              variant="outlined"
              size="small"
              fullWidth
              style={{ background: Colours.Gray3 }}
            />
          </Tooltip>
        )}
        onChange={(event, values) => {
            props.dispatchReviewUpdate({ cuisineTags: values });
        }}
      />

      <Typography variant="subtitle1" color="inherit" className="mt-3 mb-2" style={postStyle.textHeader}>
        Tags
      </Typography>

      <Autocomplete
        disabled
        id="tags"
        className="mb-5"
        limitTags={-1}
        multiple
        value={tags}
        filterSelectedOptions
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            variant="outlined"
            label={option}
            style={postStyle.chip}
          />
        ))}
        renderInput={(params) => (
          <Tooltip
            title={props.disabled ? props.disabledMsg : ''}
            isLink
          >
            <TextField
              {...params}
              variant="outlined"
              size="small"
              fullWidth
              style={{ background: Colours.Gray3 }}
            />
          </Tooltip>
        )}
        onChange={(event, values) => {
            props.dispatchReviewUpdate({ tags: values });
        }}
      />
    </>
  );
};

Tags.defaultProps = {
  disabled:    false,
  disabledMsg: 'Cannot be updated',
  otherTags:   [],
  cuisineTags: [],
};

Tags.propTypes = {
  disabled:    PropTypes.bool,
  disabledMsg: PropTypes.string,
  review:      PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  page: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  otherTags:            PropTypes.arrayOf(PropTypes.string),
  cuisineTags:          PropTypes.arrayOf(PropTypes.string),
  dispatchReviewUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  review: state.review.selected,
  page:   state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewUpdate: (review) => ReviewAction.reviewDataUpdate(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
