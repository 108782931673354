/* eslint-disable no-case-declarations */
import { IsEmpty } from '../common/checks';
import Constants from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  selected: {
    id:   null,
    name: '',
  },

  update: {
  },

  pages: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.PageSelected:
      return {
        ...state,
        selected: IsEmpty(payload)
          ? {}
          : payload,
      };

    /**
     * This state is used exclusively in the UI where we
     * edit the page info, so that any changes during the edit
     * is not propagated to other UI (those should be using the 'selected' state)
     */
    case ReduxAction.PageUpdate:
      return {
        ...state,
        update: { ...state.update, ...payload },
      };

    case ReduxAction.PagesAccess:
      return {
        ...state,
        pages: IsEmpty(payload) ? [] : payload,
      };

    case ReduxAction.PagesAccessAdd:
      const updated = state.pages.slice();
      updated.push(payload);
      return {
        ...state,
        pages: updated,
      };

    default:
      return state;
  }
}
