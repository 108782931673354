import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, Paper, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { IsEmpty } from '../../common/checks';
import pageAction from '../../actions/page';
import Colours from '../../styles/colours';
import ApiService from '../../services/apiService';
import Constants, { Status } from '../../common/constants';
import inviteAction from '../../actions/invite';

const Invitation = (props) => {
  const [actionSelected, setActionSelected] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [pageName, setPageName] = useState(null);
  const history = useHistory();

  const updateInvite = useCallback(async () => {
    try {
      setIsUpdating(true);
      const inviteStatus = actionSelected === 'Accept' ? Status.Accepted : Status.Rejected;
      const { pageId } = await ApiService.acceptRejectStaffInvite(props.invite.id, inviteStatus);

      // close the modal
      setActionSelected(null);
      setIsUpdating(false);

      if (inviteStatus === Status.Accepted) {
        // add to redux state and pre-select it before we navigate to that page
        const newPage = { id: pageId, name: props.invite.pageName, staffType: props.invite.staffType, pageLogo: props.invite.pageLogo  };
        props.dispatchPagesAccessAdd(newPage);
        props.dispatchPageSelected(newPage);
        toast.success('Invitation accepted');
        history.push(Constants.Url.PageInfo.replace(':pageId', pageId));
      } else {
        // update the invitation as declined
        setIsDeclined(true);
        props.dispatchInviteSelected(null);
        toast.info('Invitation declined');
      }

      // update redux state
      props.dispatchInviteRemove(props.invite);
    } catch (error) {
      setIsUpdating(false);
      toast.error(error.message);
    }
  }, [actionSelected]);

  useEffect(() => {
    // we get the invitation select
    if (props.invite && props.invite.pageName) {
      setPageName(props.invite.pageName);
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="row d-flex flex-column align-items-center">
        <div className="col-12 col-md-8 col-lg-6">
          {
            (IsEmpty(pageName))
              ? (
                <div className="d-flex flex-column align-items-center">
                  <Typography className="mt-5" variant="h5">Sorry, the invitation cannot be found</Typography>
                </div>
              )
              : (
                <Paper className="py-4 px-5 mt-5">
                  <div className="d-flex flex-column align-items-center mb-4">
                    <Typography className="mt-3" variant="h4" color="inherit" style={{ color: Colours.Black }}>Invitation</Typography>
                    <Typography className="mt-4" variant="body1">
                      {
                        !isDeclined && !IsEmpty(props.invite.inviter) ? `(${_.capitalize(props.invite.inviter.name)}) ${props.invite.inviter.email} has invited you to view/manage`
                          : !isDeclined && IsEmpty(props.invite.inviter) ? 'You have been invited to view/manage'
                            : 'You have declined the invitation to view / manage'
                      }
                    </Typography>
                    <Typography className="mt-2" variant="h6" style={{ color: Colours.Primary, fontWeight: 'bold' }}>
                      {String(pageName)}
                    </Typography>

                    {
                      isUpdating && <CircularProgress className="ml-2" size="1rem" />
                    }
                  </div>

                  {
                    !isDeclined && (
                      <div className="mt-4 mb-3 d-flex flex-row justify-content-center">
                        <Button
                          size="large"
                          variant="outlined"
                          disabled={isUpdating}
                          onClick={() => setActionSelected('Decline')}
                        >
                          <Typography>Decline</Typography>
                        </Button>
                        <Button
                          size="large"
                          className="ml-5"
                          color="primary"
                          variant="contained"
                          disabled={isUpdating}
                          onClick={() => setActionSelected('Accept')}
                        >
                          <Typography>Accept</Typography>
                        </Button>
                      </div>
                    )
                  }
                </Paper>
              )
          }
        </div>
      </div>

      <Dialog
        open={!IsEmpty(actionSelected)}
        onClose={() => setActionSelected(null)}
      >
        <DialogTitle className="mt-2 px-5 pt-4">
          {actionSelected}
          {' '}
          invitation?
        </DialogTitle>
        <DialogActions className="px-5">
          <div className="mx-5 mt-2 mb-4">
            <Button
              className="mr-3"
              size="large"
              variant="outlined"
              disabled={isUpdating}
              onClick={() => setActionSelected(null)}
            >
              Cancel
            </Button>
            <Button
              className="ml-3"
              size="large"
              variant="contained"
              color="primary"
              disabled={isUpdating}
              onClick={() => updateInvite()}
            >
              {actionSelected}
              {
                isUpdating && <CircularProgress className="ml-2" size="1rem" />
              }
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Invitation.defaultProps = {
  invite: null,
};

Invitation.propTypes = {
  dispatchInviteRemove:   PropTypes.func.isRequired,
  dispatchInviteSelected: PropTypes.func.isRequired,

  dispatchPageSelected:   PropTypes.func.isRequired,
  dispatchPagesAccessAdd: PropTypes.func.isRequired,

  invite: PropTypes.shape({
    id:        PropTypes.number,
    pageName:  PropTypes.string,
    staffType: PropTypes.string,
    pageLogo:  PropTypes.string,
    inviter:   PropTypes.shape({
      email: PropTypes.string,
      name:  PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state) => ({
  invite: state.invite.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchInviteRemove:   (invite) => inviteAction.inviteRemove(dispatch, invite),
  dispatchInviteSelected: (invite) => inviteAction.inviteSelected(dispatch, invite),
  dispatchPageSelected:   (page) => pageAction.pageSelected(dispatch, page),
  dispatchPagesAccessAdd: (page) => pageAction.pagesAccessAdd(dispatch, page),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invitation);
