import React from 'react';
import { Radio } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Colours from '../styles/colours';

const StyledRadio = withStyles({
  root: {
    '&$checked': {
      color: Colours.Primary,
    },
  },
  checked: {},
})((props) => <Radio color="primary" {...props} />);

export default StyledRadio;
