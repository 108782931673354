import { combineReducers } from 'redux';

import auth          from './auth';
import business      from './business';
import category      from './category';
import invite        from './invite';
import layout        from './layout';
import page          from './page';
import pageClaim     from './pageClaim';
import post          from './post';
import review        from './review';
import view          from './view';

const rootReducer = combineReducers({
  auth,
  business,
  category,
  invite,
  layout,
  view,
  page,
  pageClaim,
  post,
  review,
});

export default rootReducer;
