import React from 'react';

const PageNotFound = () => (
  <div className="app-layout">
    <div className="page-wrapper">
      <div className="non-logged">
        <main className="app-content">
          <div className="content-wrapper">
            <div className="container">
              <div className="text-center">
                <h1 className="text-warning">
                  <i className="fa fa-exclamation-triangle" />
                  {' '}
                  Page Not Found
                </h1>
                <p className="h1 font-weight-bold">Sorry, this page does not exist</p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
);

export default PageNotFound;
