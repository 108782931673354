// React Dependencies
import React, { useState, useEffect } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogContent, DialogActions, FormControlLabel, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Internal Dependencies
import GiftCard from './GiftCard/GiftCard';
import OverLoading from '../../../../components/Loading';
import Constants, { Url } from '../../../../common/constants';
import ApiService from '../../../../services/apiService';
import FilterGift from './Gift/FilterGift';
import Colours from '../../../../styles/colours';

const List = (props) => {
  const history = useHistory();
  const pageId = props.page.id;

  const [gifts, setGifts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [giftType, setGiftType] = useState(Constants.GiftType.Coupon); // by default 'Coupon' gift type selected

  const order = { col: 'updatedAt', dir: 'DESC' };
  const pageSize = 5;
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [filterDescription, setFilterDescription] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const [loading, setLoading] = useState(true);

  const addNewGiftHandler = async () => {
    try {
      setLoading(true);
      setIsModalOpen(false);

      const payload = {
        pageId,
        type: giftType,
      };

      const gift = await ApiService.createGift(pageId, payload);
      history.push(Url.Gift.replace(':pageId', pageId).replace(':giftId', gift.id));
    } catch (err) {
      console.log('Error in createGiftHandler: ', err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getGiftHandler = async () => {
    try {
      setLoading(true);

      const { rows, totalCount: totalGiftsCount } = await ApiService.getAllGifts(pageId, {
        pageNo: pageNo - 1, pageSize, order, description: filterDescription, type: filterType, status: filterStatus,
      });
      setGifts(rows);

      // Check if whole number. returns true otherwise false.
      if (Number.isInteger(totalGiftsCount / pageSize)) {
        setTotalPages(Math.floor(totalGiftsCount / pageSize));
      } else {
        // we add +1 from the total count for the remainder.
        setTotalPages(Math.floor(totalGiftsCount / pageSize) + 1);
      }

      setTotalCount(totalGiftsCount);
    } catch (err) {
      console.log('Error in getGiftHandler: ', err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateDescriptionFilter = (data) => setFilterDescription(data);

  const updateStatusFilter = (data) => setFilterStatus(data);

  const updateTypeFilter = (data) => setFilterType(data);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    getGiftHandler();
  }, [pageNo, filterDescription, filterType, filterStatus]);

  return (
    <div className="ml-2">
      { loading && <OverLoading /> }
      <Button variant="contained" color="primary" className="ml-1 mt-2 mb-4" onClick={openModal}>
        + Add New
      </Button>

      <FilterGift
        updateDescriptionFilter={updateDescriptionFilter}
        updateTypeFilter={updateTypeFilter}
        updateStatusFilter={updateStatusFilter}
      />

      <div style={{ marginBottom: '5rem' }}>
        { gifts.map((gift) => <GiftCard key={gift.id} gift={gift} pageId={pageId} />) }
      </div>

      <div
        className="position-fixed ml-2 py-2 pr-3 d-flex flex-row align-items-center"
        style={{
          background:   Colours.White,
          bottom:       5,
          width:        '100%',
          borderRadius: '10px',
        }}
      >
        {totalCount > 0 && (
          <Pagination
            color="primary"
            size="large"
            count={totalPages}
            page={pageNo}
            defaultPage={1}
            onChange={(e, value) => setPageNo(value)}
            className="float-left"
          />
        )}
        <Typography variant="body1" className="ml-3" color="inherit" style={{ color: Colours.Black }}>
          Total count:
          {' '}
          {totalCount}
        </Typography>
      </div>

      <Dialog open={isModalOpen} fullWidth maxWidth="xs" onClose={closeModal}>
        <DialogContent className="mt-3">
          <Typography variant="body1" className="font-weight-bold mb-3">Add Coupon / Voucher</Typography>

          <Typography variant="body1">Select Type</Typography>
          <RadioGroup row name="giftType" value={giftType} onChange={(e) => setGiftType(e.target.value)}>
            <FormControlLabel
              value={Constants.GiftType.Coupon}
              control={<Radio color="primary" />}
              label={Constants.GiftType.Coupon}
            />
            <FormControlLabel
              value={Constants.GiftType.Voucher}
              control={<Radio color="primary" />}
              label={Constants.GiftType.Voucher}
            />
          </RadioGroup>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => closeModal()} color="primary" variant="text">Cancel</Button>
          <Button onClick={() => addNewGiftHandler()} color="primary" variant="contained">Next</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

export default connect(mapStateToProps, null)(List);

List.propTypes = {
  page: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};
