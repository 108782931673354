import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Paper, Typography } from '@material-ui/core';
import { Status } from '../../../../common/constants';
import CreditCardsList from './CreditCardsList';
import postStyle from '../../../../styles/post';
import OtherPartners from './OtherPartners';
import Colours from '../../../../styles/colours';

const Partnerships = (props) => {
  const data = [];
  const [promoCreditCards, setPromoCreditCards] = useState([]);
  const [others, setOthers] = useState([]);
  const [disableInputFields, setDisableInputFields] = useState(false);

  const setCreditCardData = (data) => {
  };

  useEffect(() => {
    if (props && props.post) {
      const p = props.post;
      setDisableInputFields([Status.Live, Status.Paused].includes(p.currentPostStatus));
    }
  }, [props.post]);
  return (
    <>
      <Paper variant="elevation" className="px-5 pt-4" style={postStyle.paper}>
        <Typography variant="subtitle1" color="inherit" className="mt-3 mb-3" style={postStyle.textHeader}>
          Credit Cards
        </Typography>
        <CreditCardsList
          {...props}
          promoCreditCardsData={promoCreditCards}
          onCreditCardChanged={(data) => setCreditCardData(data)}
          disabled={disableInputFields}
          disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`}
        />

        <Typography variant="subtitle1" color="inherit" className="mt-4 mb-2" style={postStyle.textHeader}>
          Others
          <span>
            <br />
            <Typography variant="subtitle2" color="inherit" style={{ color: Colours.Gray1 }}>
              E.g. For partnership with a brand, enter the brand's name
            </Typography>
          </span>
        </Typography>

        <OtherPartners disabled={disableInputFields} disabledMsg={`Cannot be updated because post is ${props.post.currentPostStatus}`} />
      </Paper>
    </>
  );
};

Partnerships.propTypes = {
  post: PropTypes.shape({
    promoAvailOnlineAt: PropTypes.string,
    currentPostStatus:  PropTypes.string,
  }).isRequired,
};

Partnerships.defaultProps = {

};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Partnerships);
