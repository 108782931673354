// React Dependencies
import React, { useState, useEffect } from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { isMobile } from 'react-device-detect';

// Internal Dependencies
import MediaItem from './MediaItem';

const SortableMedia = ({ maxHt, minHt, maxWd, minWd, hasMinWidth, images, disabled, stylesActionBtns, handleCrop, handleDelete, handleSort }) => {
  const [items, setItems] = useState([]);

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((_items) => {
        const oldIndex = _items.findIndex((item) => item.id === active.id);
        const newIndex = _items.findIndex((item) => item.id === over.id);

        const sorted = arrayMove(_items, oldIndex, newIndex);
        handleSort(sorted);

        return sorted;
      });
    }
  };

  useEffect(() => {
    const copyImages = _.cloneDeep(images);
    const formattedImages = _.map(copyImages, (image, index) => ({ id: `${new Date().getTime()}-index-${index + 1}`, ...image }));

    setItems(formattedImages);
  }, [images]);

  return (
    <div>
      <DndContext onDragEnd={handleDragEnd} sensors={sensors} modifiers={[isMobile ? restrictToVerticalAxis : restrictToFirstScrollableAncestor]}>
        <SortableContext
          items={items}
          strategy={isMobile ? verticalListSortingStrategy : rectSortingStrategy}
        >
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', flexWrap: 'wrap' }}>
            {items.map((item) => (
              <MediaItem
                key={item.id}
                item={item}
                disabled={disabled}
                maxHt={maxHt}
                minHt={minHt}
                maxWd={maxWd}
                minWd={minWd}
                hasMinWidth={hasMinWidth}
                stylesActionBtns={stylesActionBtns}
                handleCrop={handleCrop}
                handleDelete={handleDelete}
                handleSort={handleSort}
              />
            )) }
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default SortableMedia;

SortableMedia.propTypes = {
  maxHt:            PropTypes.number,
  minHt:            PropTypes.number,
  maxWd:            PropTypes.number,
  minWd:            PropTypes.number,
  images:           PropTypes.arrayOf(PropTypes.shape(Object)),
  disabled:         PropTypes.bool,
  hasMinWidth:      PropTypes.bool,
  stylesActionBtns: PropTypes.shape(Object),
  handleSort:       PropTypes.func,
  handleCrop:       PropTypes.func,
  handleDelete:     PropTypes.func,
};

SortableMedia.defaultProps = {
  maxHt:            0,
  minHt:            0,
  maxWd:            0,
  minWd:            0,
  images:           [],
  disabled:         false,
  hasMinWidth:      false,
  stylesActionBtns: {},
  handleSort:       () => {},
  handleCrop:       () => {},
  handleDelete:     () => {},
};
