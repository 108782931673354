// React Dependencies
import React from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';

// Internal Dependencies
import Colours from '../styles/colours';

const MIconButton = ({ text, className, styles, icon, onClick }) => (
  <Button
    onClick={onClick}
    style={{
      width:           '250px',
      backgroundColor: Colours.White,
      border:          '1px solid #919191',
      borderRadius:    '30px',
      ...styles,
    }}
    className={`relative p-0 ${className}`}
  >
    {icon && <div style={{ position: 'absolute', left: '1rem' }}>{icon()}</div>}
    { !_.isEmpty(text) && <Typography variant="body2" className="my-2">{text}</Typography> }
  </Button>
);

export default MIconButton;

MIconButton.propTypes = {
  text:      PropTypes.string.isRequired,
  className: PropTypes.string,
  styles:    PropTypes.shape(Object),
  icon:      PropTypes.func,
  onClick:   PropTypes.func,
};

MIconButton.defaultProps = {
  className: '',
  styles:    {},
  icon:      () => {},
  onClick:   () => {},
};
