import React from 'react';
import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Colours from '../../../../styles/colours';

const MenuItem = (props) => (
  <Button
    className="mx-3 mb-2"
    style={{
      width:           `${props.width}px`,
      height:          '45px',
      borderRadius:    props.selected ? '12px' : '29px',
      borderWidth:     '1px',
      borderStyle:     'solid',
      borderColor:     props.selected ? Colours.Primary : Colours.Gray5,
      backgroundColor: props.selected ? Colours.White : Colours.Gray5,
    }}
    onClick={() => props.onClick()}
  >
    {
      props.icon && <props.icon />
    }
    <Typography
      variant="subtitle1"
      style={{ fontWeight: props.selected ? 'bold' : 'regular' }}
    >
      {props.text}
    </Typography>
  </Button>
);

MenuItem.propTypes = {
  width:    PropTypes.number,
  icon:     PropTypes.func,
  selected: PropTypes.bool,
  text:     PropTypes.string,
  onClick:  PropTypes.func,
};

MenuItem.defaultProps = {
  width:    0,
  icon:     null,
  selected: false,
  text:     '',
  onClick:  () => {},
};

export default MenuItem;
