import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { EscapeSingleQuote, StringToLowercase, Trim } from '../../common/util';
import apiService from '../../services/apiService';
import { IsEmpty, IsString } from '../../common/checks';
import { Error } from '../../common/constants';
import postStyle from '../../styles/post';

const Tagging = (props) => {
  const [suggestedTags, setSuggestedTags] = useState([]);
  const [addedTags, setAddedTags] = useState([]);

  const findTagsOfDataAndSetAsAddedTags = async (data) => {
    try {
      // format the tag names needed by server
      const tagNamesDelimited = _.join(data, '||');
      const tags = await apiService.getTagsByNames({ tagNames: tagNamesDelimited });
      const displayTags = [];
      for (const d of data) {
        let found = false;
        for (const t of tags) {
          if (d === t.name) {
            found = true;
            displayTags.push(t);
            break;
          }
        }
        if (!found) {
          displayTags.push({ name: d, isApproved: null });
        }
      }
      setAddedTags(displayTags);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (IsEmpty(props.data)) {
      setAddedTags([]);
    } else {
      // Note: We are expecting props.data to be an array of String of the tag names
      // Get the tag status of each item in the data
      findTagsOfDataAndSetAsAddedTags(props.data);
    }
  }, [props.data]);

  const onInputChanged = async (value) => {
    try {
      const nameSearch = EscapeSingleQuote(value);
      const result = await apiService.getTags({ name: nameSearch, pageNo: 0, pageSize: 20 });
      const uniqueTags = _.compact(_.map(result.rows, (tag) => (!props.data.includes(tag.name) ? tag : false)));

      setSuggestedTags(uniqueTags || []);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const findAndRemoveTag = (values, tagName = '') => {
    // user deleted one of the partner. Find out which is deleted
    const existingTags = props.data.slice();
    for (const index in existingTags) {
      if (!IsEmpty(index)) {
        let deleted = true;
        if (tagName) {
          deleted = tagName === existingTags[index];
        } else {
          for (const value of values) {
            if (value.name === existingTags[index]) {
              deleted = false;
              break;
            }
          }
        }
        if (deleted) {
          // found the partner index to delete
          existingTags.splice(index, 1);
          break;
        }
      }
    }
    // update parent com
    props.onChange(existingTags);

    // Code below is to update items displayed in the UI
    const existingAddedTags = addedTags.slice();
    for (const index in existingAddedTags) {
      if (!IsEmpty(index)) {
        let deleted = true;
        if (tagName) {
          deleted = tagName === existingAddedTags[index].name;
        } else {
          for (const value of values) {
            if (value.name === existingAddedTags[index].name) {
              deleted = false;
              break;
            }
          }
        }
        if (deleted) {
          // found the index to delete
          existingAddedTags.splice(index, 1);
          break;
        }
      }
    }
    setAddedTags(existingAddedTags);
  };

  const addValueIfNew = async (value) => {
    try {
      value = IsString(value) ? StringToLowercase(value) : StringToLowercase(value.name);
      const existingTags = props.data.slice();
      let found = false;

      // handle case where user hit 'Enter', which causes input 'value' to be a string
      const enteredValue = IsString(value) ? value : value.name;

      if (IsEmpty(Trim(enteredValue, false, true))) {
        // ignore empty string
        return;
      }

      for (const tag of existingTags) {
        if (tag === enteredValue) {
          found = true;
          break;
        }
      }

      if (!found) {
        // add and update parent com
        existingTags.push(enteredValue);
        props.onChange(existingTags);

        // update UI
        // handle case where user hit 'Enter', which causes input 'value' to be a string
        if (IsString(value)) {
          const tags = await apiService.getTagsByNames({ tagNames: value });
          if (IsEmpty(tags)) {
            value = { name: value, isApproved: null };
          } else {
            value = tags[0];
          }
        }
        setAddedTags([...addedTags.slice(), value]);
      }
    } catch (error) {
      toast.error(Error.UnexpectedError);
    }
  };

  const onBlur = (value) => {
    addValueIfNew(value);
  };

  const handleChanges = (values) => {
    try {
      // values will contain the distinct and updated array
      // Note that the data is an array of { name, isApproved }
      if (IsEmpty(values)) {
        props.onChange([]);
        setAddedTags([]);
      } else if (values.length > props.data.length) {
        // new value added will always be the last time
        addValueIfNew(values[values.length - 1]);
      } else {
        findAndRemoveTag(values);
      }
    } catch (err) {
      toast.error(Error.ReportToIT);
      toast.error(err);
    }
  };

  const handleDelete = (value) => {
    try {
      if (props.halal && value.name === 'halal') toast.error(`halal tag cannot be deleted because the link page has halal status ${props.halal}`);
      else if (StringToLowercase(props.halal) === value.name) toast.error(`${props.halal} tag cannot be deleted because the link page has halal status ${props.halal}`);
      else if (props.persistTag === value.name) toast.error(`Place name ( ${props.persistTag} ) tag cannot be deleted`);
      else findAndRemoveTag([], value.name);
    } catch (err) {
      toast.error(Error.ReportToIT);
      toast.error(err);
    }
  };

  const inputDebounced = _.debounce(onInputChanged, 500);

  return (
    <div className="d-flex flex-column">
      <Autocomplete
        limitTags={props.limitTags}
        multiple
        options={suggestedTags}
        getOptionLabel={(option) => option.name}
        value={addedTags}
        disableCloseOnSelect
        filterSelectedOptions
        freeSolo
        renderTags={(value, getTagProps) => value.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            variant="outlined"
            label={option.name}
            style={postStyle.chip}
            onDelete={() => handleDelete(option)}
          />
        ))}
        onChange={(event, values) => handleChanges(values)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            onChange={(e) => inputDebounced(e.target.value)}
            placeholder="Type and hit 'Enter' to add"
            onBlur={() => onBlur(params.inputProps.value)}
          />
        )}
        disabled={props.disabled}
      />
    </div>
  );
};

Tagging.propTypes = {
  data:       PropTypes.arrayOf(PropTypes.string),
  disabled:   PropTypes.bool,
  limitTags:  PropTypes.number,
  onChange:   PropTypes.func.isRequired,
  title:      PropTypes.string,
  halal:      PropTypes.string,
  persistTag: PropTypes.string,
};

Tagging.defaultProps = {
  data:       [],
  disabled:   false,
  limitTags:  20,
  title:      '',
  halal:      '',
  persistTag: '',
};

export default Tagging;
