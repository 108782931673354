import { createTheme } from '@material-ui/core/styles';
import Colours from './colours';

const theme = createTheme({
  palette: {
    primary:   { main: Colours.Primary },
    secondary: { main: Colours.Secondary },
  },
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: 'Roboto',
    button:     {
      textTransform: 'none',
    },
  },
});

export default theme;
