import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Paper, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Status } from '../../../common/constants';
import Colours from '../../../styles/colours';
import PostAction from '../../../actions/post';
import postStyle from '../../../styles/post';

const Launch = (props) => {
  const [isPending, setIsPending] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    setIsLive(props.post.status === Status.Live);
    setIsDraft(props.post.status === Status.Draft);
    setIsPaused(props.post.status === Status.Paused);
    setIsPending(props.post.status === Status.Pending);
  }, [props.post]);

  return (
    <Paper className="pb-4 px-5" style={postStyle.paper}>
      <Typography variant="subtitle1" color="inherit" className="mt-3" style={postStyle.textHeader}>
        Select status:
      </Typography>

      <div className="px-3 mt-4">
        {[Status.Draft, Status.Pending].includes(props.post.currentPostStatus) && (
          <div className="mb-1 mt-3">
            <FormControl component="fieldset">
              <RadioGroup aria-label="Save as Draft" name="customized-radios">
                <FormControlLabel
                  control={<Radio checked={isDraft} color="primary" />}
                  label={(
                    <div className="ml-2">
                      <Typography variant="body1" color="inherit" style={{ fontWeight: isDraft ? 'bold' : 'normal' }}>
                        Save as Draft
                      </Typography>
                      <Typography variant="body2" color="inherit" style={{ color: Colours.Gray1 }}>
                        Continue editing later
                      </Typography>
                    </div>
                  )}
                  onClick={() => {
                    props.dispatchPostUpdate({ status: Status.Draft });
                  }}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}

        {[Status.Pending].includes(props.post.currentPostStatus) && (
          <>
            <hr className="my-4" />
            <div className="mt-4 mb-4 pt-2">
              <FormControl component="fieldset">
                <RadioGroup aria-label="Pending" name="customized-radios">
                  <FormControlLabel
                    control={<Radio checked={isPending} color="primary" />}
                    label={(
                      <div className="ml-2">
                        <Typography variant="body1" color="inherit" className="" style={{ fontWeight: isPending ? 'bold' : 'normal' }}>
                          Continue as Pending
                        </Typography>
                        <Typography variant="body2" color="inherit" className="" style={{ color: Colours.Gray1 }}>
                          Post wont be live and searchable in the app until the Page's status is approved.
                        </Typography>
                      </div>
                    )}
                    onClick={() => {
                      props.dispatchPostUpdate({ status: Status.Pending  });
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}

        {[Status.Draft, Status.Live, Status.Paused].includes(props.post.currentPostStatus) && (
          <>
            <hr className="my-4" />
            <div className="mt-4 mb-4 pt-2">
              <FormControl component="fieldset">
                <RadioGroup aria-label="Launch Now" name="customized-radios">
                  <FormControlLabel
                    control={<Radio checked={isLive} color="primary" />}
                    label={(
                      <div className="ml-2">
                        <Typography variant="body1" color="inherit" className="" style={{ fontWeight: isLive ? 'bold' : 'normal' }}>
                          {
                            props.post.currentPostStatus === Status.Draft
                              ? `Launch to Live`
                              : (
                                props.post.currentPostStatus === Status.Live
                                  ? 'Continue as Live'
                                  : 'Update back to Live'
                              )
                          }
                        </Typography>
                        <Typography variant="body2" color="inherit" className="" style={{ color: Colours.Gray1 }}>
                          Set/Update the post as Live and searchable in the app
                        </Typography>
                      </div>
                    )}
                    onClick={() => {
                      props.dispatchPostUpdate({ status: Status.Live  });
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}

        {[Status.Live, Status.Paused].includes(props.post.currentPostStatus) && (
          <>
            <hr className="my-4" />
            <div className="mt-4 mb-4 pt-2">
              <FormControl component="fieldset">
                <RadioGroup aria-label="Pause post" name="customized-radios">
                  <FormControlLabel
                    control={<Radio checked={isPaused} color="primary" />}
                    label={(
                      <div className="ml-2">
                        <Typography variant="body1" color="inherit" className="" style={{ fontWeight: isPaused ? 'bold' : 'normal' }}>
                          {
                            props.post.currentPostStatus === Status.Paused
                              ? 'Continue as Paused'
                              : 'Update Post as Paused'
                          }
                        </Typography>
                        <Typography variant="body2" color="inherit" className="" style={{ color: Colours.Gray1 }}>
                          Temporary take-down the post from the app. Post won't be live and searchable in the app until you update the post back to Live.
                        </Typography>
                      </div>
                    )}
                    onClick={() => {
                      props.dispatchPostUpdate({ status: Status.Paused });
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </>
        )}
      </div>
    </Paper>
  );
};

Launch.propTypes = {
  dispatchPostUpdate: PropTypes.func.isRequired,

  post: PropTypes.shape({
    status:            PropTypes.oneOf([Status.Draft, Status.Pending, Status.Live, Status.Rejected, Status.Paused]),
    currentPostStatus: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(Launch);
