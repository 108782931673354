import Colours from './colours';

const pageClaimStyle = {
  paper: {
    flexGrow: 1,
    margin:   'auto',
    maxWidth: '100%',
    padding:  '20px 30px',
  },
  textHeader: {
    color:      Colours.Black,
    fontWeight: 'bold',
  },
};

export default pageClaimStyle;
