import Constants, { TokenName } from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  token:           localStorage.getItem(TokenName),
  isAuthenticated: null,
  user:            {
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.Login:
      localStorage.setItem(TokenName, payload.token);
      return {
        ...state,
        token:           payload.token,
        isAuthenticated: true,
      };

    case ReduxAction.Signup:
      localStorage.setItem(TokenName, payload.token);
      return {
        ...state,
        token:           payload.token,
        isAuthenticated: true,
      };

    case ReduxAction.TokenExpired:
    case ReduxAction.AuthFailed:
    case ReduxAction.Logout:
      localStorage.removeItem(TokenName);
      return {
        ...state,
        token:           null,
        isAuthenticated: false,
        user:            null,
      };

    default:
      return state;
  }
}
