/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, IconButton, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';

import _ from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ReactPlayer from 'react-player';
import Colours from '../../../../../styles/colours';
import OverLoading from '../../../../../components/Loading';
import Constants, { AccountMaxImageUpload } from '../../../../../common/constants';
import { IsEmpty } from '../../../../../common/checks';
import ReviewAction from '../../../../../actions/review';
import ApiService from '../../../../../services/apiService';
import Tooltip from '../../../../../components/Common/ToolTip';

const maxFileSize = 2000000;
const videoFormats = ['video/mp4'];

const settings = {
  arrows:         true,
  dots:           false,
  infinite:       true,
  speed:          500,
  slidesToShow:   1,
  slidesToScroll: 1,
};

const VideoMobile = (props) => {
  const [loading, setLoading] = useState(false);
  const [reviewId, setReviewId] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [videoUrls, setVideoUrls] = useState([]);

  const [totalUploads, setTotalUploads] = useState(0);
  const [planModals, setPlanModals] = useState(false);
  const [accountType, setAccountType] = useState('Premium');
  const [maxUpload, setMaxUpload] = useState(0);

  const hiddenInputFileRef = React.createRef();

  useEffect(() => {
    if (props && props.reviewId) {
      setReviewId(props.reviewId);
    }

    const totalPhotoUpload = !IsEmpty(props.review.images) ? (props.review.images).length : 0;
    const totalVideoUpload = !IsEmpty(props.review.videos) ? (props.review.videos).length : 0;
    setTotalUploads(totalPhotoUpload + totalVideoUpload);

    const maxNoUpload = AccountMaxImageUpload[props.page.plan];
    setAccountType(props.page.plan);
    setMaxUpload(maxNoUpload);

    setVideoUrls(!IsEmpty(props.review.videos) ? props.review.videos : []);
  }, [props.review]);

  const handleConfirmation = (url) => {
    setSelectedVideo(url);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    const response = await ApiService.deleteReviewMedia(reviewId, { mediaData: selectedVideo, fileType: Constants.FileType.Video });
    if (response) {
      const videoRes = videoUrls.filter((video) => video !== selectedVideo);
      props.dispatchReviewUpdate({ videos: videoRes });
      setLoading(false);
      toast.success('Video successfully deleted.');
    }
    setSelectedVideo(null);
    setOpenModal(false);
    setLoading(false);
  };

  const handleErrorMessage = (file) => {
    if (file) {
      if (!videoFormats.includes(file.type)) {
        toast.error('Only MP4 files are supported.');
        return false;
      }

      if (file.size > maxFileSize) {
        toast.error('Maximum file size of 2MB exceeded.');
        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  const handleOpenPlanModals = () => {
    setPlanModals(true);
  };

  const handleClosePlanModals = () => {
    setPlanModals(false);
  };

  const handleInputUpload = () => {
    if (totalUploads >= maxUpload) {
      handleOpenPlanModals();
    } else {
      hiddenInputFileRef.current.click();
    }
  };

  const saveVideo = async (file) => {
    const isOk = handleErrorMessage(file);

    if (totalUploads >= maxUpload) {
      handleOpenPlanModals();
    } else if (file && isOk) {
      setLoading(true);
      const formData = new FormData();
        formData.append('video', file);
        formData.append('type', 'videos');
        const cloudfrontLink = await ApiService.uploadReviewMedia(reviewId, formData, Constants.FileType.Video);
        if (cloudfrontLink && cloudfrontLink.length > 0) {
          const urls = videoUrls.concat(cloudfrontLink);
          props.dispatchReviewUpdate({ videos: urls });
          setLoading(false);
          toast.success('Video successfully uploaded.');
        }
    }
  };

  return (
    <>
      { loading && <OverLoading /> }
      <div className="row">
        <div className="col-sm-12">
          { !IsEmpty(videoUrls) ? (
            <div style={{
              position: 'absolute',
              right:    5,
              top:      '-55px',
              zIndex:   1,
            }}
            >
              <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                <IconButton
                  disabled={props.disabled}
                  onClick={() => {
                    handleInputUpload();
                  }}
                >
                  <Typography variant="body1" color="inherit" style={{  color: ((props.disabled === false) ? Colours.Primary : Colours.Gray2), fontWeight: 'bold' }}>
                    UPLOAD
                  </Typography>
                </IconButton>
              </Tooltip>
              <input className="d-none" ref={hiddenInputFileRef} accept="video/mp4" id="icon-button-file" type="file" onChange={(e) => { saveVideo(e.currentTarget.files[0]); }} />
            </div>
          ) : (
            <div className="py-5 text-center">
              <Tooltip
                title={!props.disabled ? '' : 'Permission Denied'}
                isLink
              >
                <Typography
                  variant="h6"
                  color="inherit"
                  className="d-block text-center mx-auto"
                  style={{  color: ((props.disabled === false) ? Colours.Primary : Colours.Gray2), fontWeight: 'bold' }}
                  onClick={() => {
                    if (!props.disabled) {
                      handleInputUpload();
                    }
                  }}
                >
                  + Tap to add a video
                </Typography>
              </Tooltip>
              <input disabled={props.disabled} className="d-none" ref={hiddenInputFileRef} accept="video/mp4" id="icon-button-file" type="file" onChange={(e) => { saveVideo(e.currentTarget.files[0]); }} />
            </div>
          )}

          <>
            <Slider {...settings}>
              {
              _.map(videoUrls, (v, i) => (
                <div key={i} style={{ position: 'relative' }}>
                  <div style={{ background: ((props.disabled === false) ? Colours.Primary : Colours.Gray2),  width: '100%', zIndex: 9 }}>
                    <div
                      key={i}
                      style={{
                        background: Colours.Black,
                        display:    'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ReactPlayer
                        url={v}
                        muted
                        playing
                        width="100%"
                        height="100%"
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start" style={{ width: '30%' }}>
                        {!IsEmpty(videoUrls) && (
                          <Typography variant="body1" color="inherit" className="float-left ml-3" style={{ color: Colours.White, fontWeight: 'bold' }}>
                            {`${i + 1} of ${videoUrls.length}`}
                          </Typography>
                        )}
                      </div>

                      <div className="d-flex justify-content-end" style={{ width: '70%' }}>
                        <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                          <IconButton
                            className=""
                            onClick={() => {
                              handleConfirmation(v);
                            }}
                            disabled={props.disabled}
                          >
                            <Typography variant="body1" color="inherit" style={{ color: Colours.White, fontWeight: 'bold' }}>
                              DELETE
                            </Typography>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              }
            </Slider>
          </>
        </div>
        <Dialog
          fullWidth
          size="sm"
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove Video</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the video?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">Cancel</Button>
            <Button onClick={() => handleDelete()} color="primary">Confirm</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={planModals}
          fullWidth
          //TransitionComponent={transition}
          keepMounted
          onClose={() => handleClosePlanModals()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Maximum number of media upload reached</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Your current account plan is :
              {' '}
              <span style={{ fontWeight: 'bold', color: Colours.Black }}>{accountType}</span>
              <br />
              The limit of
              {' '}
              {accountType}
              {' '}
              plan is
              {' '}
              <span style={{ fontWeight: 'bold', color: Colours.Black }}>{maxUpload}</span>
              {' '}
              media.
              Upgrade account plan to increase limit.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClosePlanModals()} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  review: state.review.selected,
  page:   state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewUpdate: (review) => ReviewAction.reviewDataUpdate(dispatch, review),
});

VideoMobile.defaultProps = {
  reviewId: null,
  page:     {
    plan: '',
  },
};

VideoMobile.propTypes = {
  dispatchReviewUpdate: PropTypes.func.isRequired,
  disabled:             PropTypes.bool.isRequired,
  reviewId:             PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  page:                 PropTypes.shape({
    plan: PropTypes.string,
  }),
  review: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        display:  PropTypes.string,
        original: PropTypes.string,
      }),
    ),
    videos: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoMobile);
