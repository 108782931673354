/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { IconButton, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import FixedCropperMobile from './FixedCropperMobile';
import Tooltip from '../../../../../components/Common/ToolTip';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { convertDataURLtoBlob } from '../../../../../common/util';
import Colours from '../../../../../styles/colours';
import Constants, { AccountMaxImageUpload } from '../../../../../common/constants';
import { IsEmpty } from '../../../../../common/checks';
import ApiService from '../../../../../services/apiService';
import Icons from '../../../../../components/Common/Icons';
import reviewAction from '../../../../../actions/review';
import OverLoading from '../../../../../components/Loading';

const settings = {
  arrows:         true,
  dots:           false,
  infinite:       true,
  speed:          500,
  slidesToShow:   1,
  slidesToScroll: 1,
};

class PhotoMobile extends Component {
  constructor(props) {
    super(props);
    this.selectCategoryRef = null;
    this.dropZoneRef = null;
    this.state = {
      planModals:   false,
      accountType:  '',
      maxUpload:    0,
      totalUploads: 0,

      mediaData:         '',
      alert:             false,
      openCropDialog:    false,
      originalFile:      null,
      currentFile:       null,
      src:               null,
      type:              null,
      uploadUrl:         '',
      base64:            '',
      images:            [],
      cropping:          false,
      tempImgToDelete:   [],
      isImagePosChanged: false,
      loading:           false,
    };
    this.maxFileSize = 7000000;
    this.imageFormats = ['image/jpeg', 'image/jpg', 'image/png'];

    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getBase64Image = this.getBase64Image.bind(this);
    this.handleCrop = this.handleCrop.bind(this);

    this.hiddenInputFileRef = React.createRef();
  }

  componentDidMount = () => {
    const maxNoUpload = AccountMaxImageUpload[this.props.page.plan];
    this.setState({ accountType: this.props.page.plan, maxUpload: maxNoUpload });

    const fetchReview = async () => {
      const review = await ApiService.getReview(this.props.match.params.reviewId);
      if (review) {
        this.setState({ images: review.images });
      }
    };

    if (!IsEmpty(this.props.images)) {
      this.setState({ images: this.props.images });
    } else {
      fetchReview();
    }

    this.checkUploadCount();
  }

  componentDidUpdate(prevProps) {
    if (this.props.review !== prevProps.review) {
      this.checkUploadCount();
    }
  }

  checkUploadCount = () => {
    // checks all the media upload count.
    const totalPhotoUpload = (this.props.review.images && this.props.review.images.length > 0) ? this.props.review.images.length : 0;
    const totalVideoUpload = (this.props.review.videos && this.props.review.videos.length > 0) ? this.props.review.videos.length : 0;
    this.setState({ totalUploads: (totalPhotoUpload + totalVideoUpload) });
  }

  b64toBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: 'image/jpeg' });
  }

  handleCrop = (image) => {
    const temp = [];
    this.setState({ uploadUrl: image.original, cropping: true }, () => {
      temp.push(image);
      this.setState({ tempImgToDelete: temp }, () => {
        this.handleUploadUrl();
      });
    });
  }

  handleUploadUrl = () => {
    this.getBase64Image(this.state.uploadUrl, (base64image) => {
      this.setState({ openCropDialog: true, base64: base64image, type: 'url', currentFile: null },
        () => {
          const imgFile = this.b64toBlob(`data:image/*;base64,${this.state.base64}`);
          this.setState({ src: `data:image/*;base64,${this.state.base64}`, originalFile: new File([imgFile], 'o.jpeg', { type: 'image/jpeg' }) });
        });
    });
  }

  handleUpload = (files) => {
    if (this.state.totalUploads >= this.state.maxUpload) {
      this.handleOpenPlanModals();
    } else {
      const isOk = this.handleErrorMessage(files);
      if (isOk && files.length > 0) {
        this.setState({ openCropDialog: true, type: 'upload' }, () => {
          if (this.state.openCropDialog) {
            this.setState({ currentFile: files[files.length - 1] }, () => {
              const reader = new FileReader();
              reader.addEventListener('load', () => {
                this.setState({ src: reader.result });
                const originalImageBlob = convertDataURLtoBlob(reader.result);
                this.setState({ originalFile: new File([originalImageBlob], 'o.jpeg', { type: 'image/jpeg' }) });
              });
              reader.readAsDataURL(this.state.currentFile);
            });
          }
        });
      }
    }
  }

  getBase64Image = (imgUrl, callback) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      let dataURL = canvas.toDataURL('image/png');
      dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
      callback(dataURL); // the base64 string
    };

    if (img.complete) {
      img.crossOrigin = Constants.CrossOrigin;
      img.src = imgUrl;
    }
  }

  handleOpenPlanModals = () => {
    this.setState({ planModals: true });
  }

  handleClosePlanModals = () => {
    this.setState({ planModals: false });
  }

  handleInputUpload = () => {
    if (this.state.totalUploads >= this.state.maxUpload) {
      this.handleOpenPlanModals();
    } else {
      this.hiddenInputFileRef.current.click();
    }
  }

  handleSave = async (file) => {
    try {
      if (file) {
        const tempReviewImages = this.state.images;

        const formData = new FormData();
        formData.append('image', file);
        formData.append('image', this.state.originalFile);
        formData.append('type', 'images');

        if (this.state.cropping === true && this.state.tempImgToDelete.length > 0) {
          await this.handleDelete()
            .then(async (res) => {
              if (res && this.state.images.length > 0) {
                /*
                    * CROP MODE
                    * to get the difference from the state images take note that the state changes after it got deleted after upload, check code at Ln 158,
                    * we are getting the index of the element in the array that got omitted, created another temporary array - finalArrayOfImages to hold the current array
                    * then pushed the newly uploaded image (result) to finalArrayOfImages.
                  */
                const diff = _.differenceWith(tempReviewImages, this.state.images, _.isEqual);
                const index = _.findIndex(tempReviewImages, diff[0]);
                const finalArrayOfImages = this.state.images;

                  formData.append('imgIndex', index);
                  const result = await ApiService.uploadReviewMedia(this.props.match.params.reviewId, formData);
                  finalArrayOfImages.splice(index, 0, result);

                  if (result) {
                    const review = await ApiService.getReview(this.props.match.params.reviewId);
                    if (review) {
                      this.setState({
                        images:         review.images,
                        openCropDialog: false,
                        cropping:       false,
                        currentFile:    null,
                        src:            null,
                        type:           null,
                        uploadUrl:      '',
                        base64:         '',
                      }, () => {
                        this.props.dispatchReviewUpdate({ images: this.state.images });
                      });
                    }
                  }
              } else {
                  // if cropping and only single image
                  formData.append('imgIndex', false);
                  const result = await ApiService.uploadReviewMedia(this.props.match.params.reviewId, formData);
                  if (result) {
                    this.setState({
                      images:         this.state.images.concat(result),
                      openCropDialog: false,
                      cropping:       false,
                      currentFile:    null,
                      src:            null,
                      type:           null,
                      uploadUrl:      '',
                      base64:         '',
                    }, () => {
                      this.props.dispatchReviewUpdate({ images: this.state.images });
                    });
                  }
              }
            })
            .catch((err) => {
              toast.error(err.message);
              console.log(err);
            });
        } else {
            formData.append('imgIndex', false);
            const result = await ApiService.uploadReviewMedia(this.props.match.params.reviewId, formData);
            if (result) {
              this.setState({
                images:         this.state.images.concat(result),
                openCropDialog: false,
                cropping:       false,
                currentFile:    null,
                src:            null,
                type:           null,
                uploadUrl:      '',
                base64:         '',
              }, () => {
                this.props.dispatchReviewUpdate({ images: this.state.images });
                toast.success('Image uploaded successfully.');
              });
            }
        }
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  handleDelete = async () => {
    try {
      this.setState({ loading: true, alert: false });

      if (this.state.cropping && this.state.tempImgToDelete.length > 0) {
        this.setState({ mediaData: this.state.tempImgToDelete[0] });
      }

      const images = this.state.images.filter((image) => {
        if (image.display) {
          return image.display !== this.state.mediaData.display;
        } else {
          return image.original !== this.state.mediaData.original;
        }
      });

      await ApiService.deleteReviewMedia(this.props.match.params.reviewId, { mediaData: this.state.mediaData, fileType: Constants.FileType.Image });
      this.setState({ images }, () => {
        this.props.dispatchReviewUpdate({ images: this.state.images });

        if (this.state.cropping && this.state.tempImgToDelete.length > 0) {
          toast.success('Image cropped successfully.', { delay: 800 });
        } else {
          toast.success('Image successfully deleted.', { delay: 800 });
        }
      });
      this.setState({ loading: false });
      return images;
    } catch (err) {
      this.setState({ loading: false, alert: false });
      toast.error(err.message);
      console.log(err);
    }
  }

  handleConfirmation = (mediaData) => {
    this.setState({ alert: true, mediaData });
  }

  handleClose = () => {
    this.setState({ openCropDialog: false, currentFile: null, alert: false, base64: '',  type: null, uploadUrl: '', cropping: false, tempImgToDelete: [] });
  }

  handleSort = (images) => {
    this.setState({ images, isImagePosChanged: true });
    try {
      this.props.onSort(images);
    } catch (error) {
      toast.error('An unexpected error has occurred');
    }
  }

  handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        images: this.state.images,
      };
      await this.props.updateReview(this.props.match.params.reviewId, payload);
      this.setState({ isImagePosChanged: false });
    } catch (err) {
      toast.error(err.message);
    }
  }

  handleErrorMessage = (fileImage) => {
    const file = fileImage[0];
    if (file.size > this.maxFileSize) {
      toast.error('Maximum file size of 7MB exceeded.');
      return false;
    }
    if (!this.imageFormats.includes(file.type)) {
      toast.error('Only JPG, JPEG and PNG files are supported.');
      return false;
    }

    return true;
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div>
        { this.state.loading && <OverLoading /> }
        {
          !IsEmpty(this.state.images) ? (
            <div className="d-flex flex-row justify-content-end pb-2">
              <Tooltip title={!this.props.disabled ? '' : 'Permission Denied'}>
                <Button disabled={this.props.disabled} onClick={() => this.handleInputUpload()}>
                  <Icons.Add colour={((this.props.disabled === false) ? Colours.Primary : Colours.Gray2)} fontSize="1.5rem" />
                  <Typography className="mx-2" variant="body1" color="inherit" style={{ color: ((this.props.disabled === false) ? Colours.Primary : Colours.Gray2), fontWeight: 'bold' }}>
                    UPLOAD
                  </Typography>
                </Button>
              </Tooltip>
              <input
                className="d-none"
                ref={this.hiddenInputFileRef}
                accept="image/jpg,image/jpeg,image/png"
                id="icon-button-file"
                type="file"
                onChange={(e) => { this.handleUpload(e.target.files); }}
                onClick={(e) => { e.target.value = null; }}
              />
            </div>
          ) : (
            <div className="py-5 text-center">
              <Tooltip
                title={!this.props.disabled ? '' : 'Permission Denied'}
                isLink
              >
                <Typography
                  variant="h6"
                  color="inherit"
                  className="d-block text-center mx-auto"
                  style={{ color: ((this.props.disabled === false) ? Colours.Primary : Colours.Gray2), fontWeight: 'bold' }}
                  onClick={() => {
                    if (!this.props.disabled) {
                    this.handleInputUpload();
                    }
                  }}
                >
                  + Tap to add a photo
                </Typography>
              </Tooltip>
              <input
                disabled={this.props.disabled}
                className="d-none"
                ref={this.hiddenInputFileRef}
                accept="image/jpg,image/jpeg,image/png"
                id="icon-button-file"
                type="file"
                onChange={(e) => { this.handleUpload(e.target.files); }}
                onClick={(e) => { e.target.value = null; }}
              />
            </div>
          )
        }

        {this.state.images.length > 0
          ? (
            <div style={{ position: 'relative' }}>
              {this.state.images
                ? (
                  <>
                    <Slider {...settings}>
                      {
                        _.map(this.state.images, (image, i) => (
                          <div key={i} style={{ position: 'relative' }}>
                            <div style={{ background: ((this.props.disabled === false) ? Colours.Primary : Colours.Gray2),  width: '100%', zIndex: 9 }}>
                              <img src={image.display} alt="" />

                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-start" style={{ width: '30%' }}>
                                  {this.state.images.length > 0 && (
                                    <Typography variant="body1" color="inherit" className="float-left ml-3" style={{ color: Colours.White, fontWeight: 'bold' }}>
                                      {`${i + 1} of ${this.state.images.length}`}
                                    </Typography>
                                  )}
                                </div>

                                <div className="d-flex justify-content-end" style={{ width: '70%' }}>
                                  <Tooltip title={!this.props.disabled ? '' : 'Permission Denied'}>
                                    <IconButton
                                      className="px-3"
                                      onClick={() => {
                                        this.handleCrop(image);
                                      }}
                                      disabled={this.props.disabled}
                                    >
                                      <Icons.Crop colour={Colours.White} fontSize="1.3rem" />
                                      <Typography className="ml-1" variant="body2" color="inherit" style={{ color: Colours.White, fontWeight: 'bold' }}>
                                        CROP
                                      </Typography>
                                    </IconButton>
                                  </Tooltip>

                                  <Tooltip title={!this.props.disabled ? '' : 'Permission Denied'}>
                                    <IconButton
                                      className="px-3 mx-3"
                                      onClick={() => {
                                        this.handleConfirmation(image);
                                      }}
                                      disabled={this.props.disabled}
                                    >
                                      <Icons.DeleteBin colour={Colours.White} fontSize="1.3rem" />
                                      <Typography variant="body2" color="inherit" style={{ color: Colours.White, fontWeight: 'bold' }}>
                                        DELETE
                                      </Typography>
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </Slider>
                  </>
                )
                : ''}
            </div>
          )
          : ''}

        {(this.props.showSaveImgPosBtn && this.state.images.length > 0 && this.state.isImagePosChanged)
          ? (
            <center>
              <div style={{ marginTop: 50 }}>
                <Button
                  style={{ marginLeft: 30 }}
                  variant="contained"
                  onClick={(e) => { this.handleUpdate(e); }}
                >
                  Save Image Position

                </Button>
              </div>
            </center>
          ) : ''}

        <Dialog
          fullScreen
          open={this.state.openCropDialog}
          onClose={() => { this.handleClose(); }}
        >
          <FixedCropperMobile
            width={1}
            height={1}
            cropPixelWidth={400}
            cropPixelHeight={400}
            outputPixelWidth={900}
            outputPixelHeight={900}
            type={this.state.type}
            url={this.state.uploadUrl}
            src={this.state.src}
            file={this.state.currentFile}
            handleSave={this.handleSave}
            handleClose={this.handleClose}
            isCropping={this.state.cropping}
          />
        </Dialog>

        <Dialog
          fullWidth
          size="sm"
          open={this.state.alert}
          onClose={() => { this.handleClose(); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove Image</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the image?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mb-2">
            <Button className="px-2" onClick={() => { this.handleDelete(); }} color="default">Confirm</Button>
            <Button className="px-2 mx-3" onClick={() => { this.handleClose(); }} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.planModals}
          fullWidth
          keepMounted
          onClose={() => this.handleClosePlanModals}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Maximum number of media upload reached</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Your current account plan is :
              {' '}
              <span style={{ fontWeight: 'bold', color: Colours.Black }}>{this.state.accountType}</span>
              <br />
              The limit of
              {' '}
              {this.state.accountType}
              {' '}
              plan is
              {' '}
              <span style={{ fontWeight: 'bold', color: Colours.Black }}>{this.state.maxUpload}</span>
              {' '}
              media.
              Upgrade account plan to increase limit.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClosePlanModals} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

PhotoMobile.defaultProps = {
  match: {
    params: { reviewId: null },
  },

  updateReview: null,
  images:       [],
  videos:       [],

  page: {
    plan: '',
  },

  showSaveImgPosBtn: true,
};

PhotoMobile.propTypes = {
  onSort:               PropTypes.func.isRequired,
  updateReview:         PropTypes.func,
  dispatchReviewUpdate: PropTypes.func.isRequired,
  disabled:             PropTypes.bool.isRequired,
  review:               PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        display:  PropTypes.string,
        original: PropTypes.string,
      }),
    ),

    videos: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,

  page: PropTypes.shape({
    plan: PropTypes.string,
  }),

  images: PropTypes.arrayOf(
    PropTypes.shape({
      display:  PropTypes.string,
      original: PropTypes.string,
    }),
  ),
  videos: PropTypes.arrayOf(PropTypes.string),

  match: PropTypes.shape({
    params: PropTypes.shape({
      reviewId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),

  showSaveImgPosBtn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  review: state.review.selected,
  page:   state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewUpdate: (review) => reviewAction.reviewDataUpdate(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotoMobile);
