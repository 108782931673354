// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Internal Dependencies
import FoodReviewORGenericHighlight from './FoodReviewORGeneric';

const GenericHighlight = (props) => (
  <FoodReviewORGenericHighlight
    heading="Generic"
    selectedPage={props.selectedPage}
    isGeneric
  />
);

GenericHighlight.propTypes = {
  selectedPage: PropTypes.shape({
    id:        PropTypes.number,
    staffType: PropTypes.string,
    name:      PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  selectedPage: state.page.selected,
});

export default connect(mapStateToProps, null)(GenericHighlight);
