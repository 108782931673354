import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import layoutAction from '../../actions/layout';
import PageSideBarMobile  from './PageSideBarMobile';

const subMenuStyle = {
  backgroundColor:  '#fafafa',
  borderRightWidth: '2px',
  borderRightColor: '#ffffff',
  borderRightStyle: 'inset',
  height:           '100vh',
};

const MobileSideBar = (props) => {
  const onLinkClicked = () => {
    props.dispatchSideBarHide();
  };

  return (
    <div className="container-fluid">
      <div
        style={{
          position:       'fixed',
          top:            0,
          left:           0,
          right:          0,
          bottom:         0,
          zIndex:         1000,
          padding:        0,
          justifyContent: 'flex-start',
          alignItems:     'flex-start',
        }}
      >
        <PageSideBarMobile style={subMenuStyle} match={props.match} onLinkClicked={onLinkClicked} />
      </div>
    </div>
  );
};

MobileSideBar.propTypes = {
  dispatchSideBarHide: PropTypes.func.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      pageId: PropTypes.string,
    }),
  }),

  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchSideBarHide: () => layoutAction.sideBarHide(dispatch),
});

export default connect(null, mapDispatchToProps)(MobileSideBar);
