// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Typography, Avatar, Button, makeStyles } from '@material-ui/core';

// Internal Dependencies
import { IsEmpty } from '../common/checks';
import { Trim } from '../common/util';
import { PositionType } from '../common/constants';
import Colours from '../styles/colours';

const useStyles = makeStyles(() => ({
  details: {
    display:       'flex',
    flexDirection: 'row',
    position:      'relative',
    padding:       0,
  },
}));

function SearchCard({ data, onAdd, lastIndex, isPinLabel = true }) {
  const classes = useStyles();

  const pin = (payloadData) => {
    const { postId, position } = payloadData;
    const payload = {
      postId,
      position,
      positionType: PositionType.Rotatable,
    };

    onAdd(payload);
  };

  return data.map((v) => {
    const isVideo = !!(!IsEmpty(v.videos) && v.videos.length > 0);
    const media = isVideo
      // eslint-disable-next-line jsx-a11y/media-has-caption
      ? <video className="d-flex" controls style={{ width: 100, height: 100 }}><source src={v.videos[0]} /></video>
      : (v.images && v.images.length > 0 && v.images[0].display) && <CardMedia style={{ width: 100, height: 'auto' }} image={v.images[0].display} />;

    return (
      <div className="col-md-4" key={`card-${v.id}`}>
        <Card key={`searchCard-${v.id}`} className="d-flex mb-2">
          <div className={classes.details}>
            {media}
            <div className="d-flex flex-column">
              <CardContent style={{ paddingBottom: 10, flex: '1 0' }} className="d-flex flex-column py-2 pr-0 pl-2">
                <Typography variant="subtitle1" color="textSecondary">
                  <a target="_blank" rel="noopener noreferrer" href={`/page/${v.pageId}/post/${v.id}`}>
                    {
                      !IsEmpty(Trim(v.title)) ? ((v.title.length >= 50) ? `${v.title.substring(0, 50)}...` : v.title)
                        : '--'
                    }
                  </a>
                </Typography>
                <Typography>
                  {
                  IsEmpty(v.promoFromDate)
                    ? `${v.promoType} period unspecified`
                    : `${new Date(v.promoFromDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} to ${new Date(v.promoTillDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`
                  }
                </Typography>

                <div className="mb-3 mt-1 d-none d-md-block">
                  <a target="_blank" rel="noopener noreferrer" href={`/page/${v.pageId}`} className={classes.details} style={{ alignItems: 'center', fontSize: 12, color: Colours.DarkPurple }}>
                    <Avatar src={v.logo} sizes="small" />
                    <div style={{ marginLeft: 10 }}>{v.brand}</div>
                  </a>
                </div>

              </CardContent>
            </div>
            <Button
              className="d-none d-md-block ml-auto"
              disabled={v.isPinned}
              onClick={() => pin({ postId: v.id, position: lastIndex + 1 })}
              color="secondary"
            >
              {isPinLabel ? v.isPinned ? 'Pinned' : 'Pin' : v.isPinned ? 'Added' : 'Add'}
            </Button>
          </div>

          {/* extra button and brand label for mobile View */}

          <div className="d-xs-block d-sm-block d-md-none mx-auto mt-2">
            <a target="_blank" rel="noopener noreferrer" href={`/page/${v.pageId}`} className={classes.details} style={{  justifyContent: 'center', alignItems: 'center', fontSize: 12, color: Colours.DarkPurple }}>
              <Avatar src={v.logo} sizes="small" />
              <div style={{ marginLeft: 10 }}>{v.brand}</div>
            </a>
          </div>
          <div className={classes.details}>
            <div className="d-xs-block d-sm-block d-md-none mx-auto mt-2 w-100">
              <Button
                className="d-block mx-auto w-100"
                disabled={v.isPinned}
                onClick={() => pin({ postId: v.id, position: lastIndex + 1 })}
                color="secondary"
              >
                {isPinLabel ? v.isPinned ? 'Pinned' : 'Pin' : v.isPinned ? 'Added' : 'Add'}
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );
  });
}

export default SearchCard;

SearchCard.propTypes = {
  data:       PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  lastIndex:  PropTypes.number.isRequired,
  isPinLabel: PropTypes.bool.isRequired,
  onAdd:      PropTypes.func.isRequired,
};
