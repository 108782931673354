import Constants from '../common/constants';

const { ReduxAction } = Constants;

const reviewDataUpdate = (dispatch, data) => {
  dispatch({
    type:    ReduxAction.ReviewDataUpdate,
    payload: data,
  });
};

const reviewSelected = (dispatch, data) => {
  dispatch({
    type:    ReduxAction.ReviewSelected,
    payload: data,
  });
};

const reviewAction = {
  reviewDataUpdate,
  reviewSelected,
};

export default reviewAction;
