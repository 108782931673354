// React Dependencies
import React, { useState, useEffect } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImageIcon from '@material-ui/icons/FilterHdr';
import LockIcon from '@material-ui/icons/Lock';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, IconButton, TextField, FormControlLabel, Switch, Button, Divider, DialogContent } from '@material-ui/core';

// Internal Dependencies
import MText from '../../../../components/Common/MText';
import Colours from '../../../../styles/colours';
import useDebounce from '../../../../common/use-debounce';
import ApiService from '../../../../services/apiService';
import { IsEmpty } from '../../../../common/checks';
import { Trim } from '../../../../common/util';
import { Monstyr } from '../../../../common/constants';
import { titleTextSize, labelTextSize } from '../LoyaltyCardStyle';

const StampDialog = ({ onCloseDialog, onCloseDialogEnd, loyaltyStamps, onUpdateLoyaltyStamps, handleCustomizeInput, handleDialogSave, handleImageChange, imageFormats, fileInputRefs, isDeactivated }) => {
  const [existingPages, setExistingPages] = useState([]);
  const debouncedSearch = useDebounce(loyaltyStamps.dialog.linkPageName, 300);

  const setLinkPage = (linkPageId, linkPageName) => {
    onUpdateLoyaltyStamps({ dialog: { linkPageId, linkPageName } });
  };

  const searchPages = async (name) => {
    try {
      if (!IsEmpty(Trim(name))) {
        let pages = await ApiService.getPagesSuggest(name);
        // Prevent `Monstyr` from showing
        pages = pages.filter((d) => d.name !== Monstyr);
        setExistingPages(pages);
      } else {
        setExistingPages([]);
      }
    } catch (error) {
      console.log(error);
      setExistingPages([]);
    }
  };

  useEffect(() => {
    searchPages(loyaltyStamps.dialog.linkPageName);
  }, [debouncedSearch]);

  return (
    <Dialog
      onClose={onCloseDialog}
      open={loyaltyStamps.dialog.open}
      onTransitionEnd={onCloseDialogEnd}
      PaperProps={{ className: 'w-100 d-flex flex-column align-items-center mx-2' }}
    >
      <DialogContent dividers={false} className="px-1 px-md-4 py-3" style={{ overflowX: 'hidden' }}>
        <div className="w-100 p-sm-3">
          <div className="container ml-5 ml-sm-2">
            <div className="row mb-3">
              <div className="col-10 col-sm-12 pl-0 pl-sm-0 pr-1 pr-sm-0 d-flex align-items-center justify-content-between">
                <MText bold text="Customise Stamp" size={titleTextSize} className="pl-0 pl-sm-2" />
                <IconButton onClick={onCloseDialog} size="small">
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-sm-4 pl-0 pl-sm-2 mb-2 mb-sm-0 d-flex flex-column justify-content-center">
                <MText size={labelTextSize} text="Stamp image" />
                <MText size={labelTextSize} text="(Max 1MB)" />
              </div>
              <div className="col-12 col-sm-8 d-flex align-items-center pl-2">
                <input
                  hidden
                  name="stampImage"
                  accept={imageFormats}
                  type="file"
                  onChange={handleImageChange}
                  ref={fileInputRefs.stampImage}
                />
                <IconButton
                  onClick={() => fileInputRefs.stampImage.current.click()}
                  size="small"
                  fontSize={50}
                  disabled={isDeactivated}
                  className="mb-4 p-0"
                  style={{ width: 180, height: 180, backgroundColor: Colours.Gray4, fontSize: '40pt' }}
                >
                  {
                    loyaltyStamps.dialog.stampImage.data ? (
                      <img
                        src={loyaltyStamps.dialog.stampImage.data}
                        alt="Customized Stamp"
                        className="w-100 rounded-circle"
                      />
                    ) : <ImageIcon fontSize="large" htmlColor={Colours.Black} />
                  }
                </IconButton>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-sm-4 pl-0 pl-sm-2 d-flex align-items-center">
                <MText size={labelTextSize} text="Label:" />
              </div>
              <div className="col-12 col-sm-8 d-flex align-items-center pl-0 pl-sm-2">
                <TextField
                  onChange={handleCustomizeInput}
                  variant="outlined"
                  size="small"
                  className="w-75"
                  disabled={isDeactivated}
                  value={loyaltyStamps.dialog.name}
                  InputProps={{ style: { fontSize: labelTextSize } }}
                  name="name"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-sm-4 pl-0 pl-sm-2 d-flex align-items-center">
                <MText size={labelTextSize} text="Sub-label:" />
              </div>
              <div className="col-12 col-sm-8 d-flex align-items-center pl-0 pl-sm-2">
                <TextField
                  onChange={handleCustomizeInput}
                  variant="outlined"
                  size="small"
                  className="w-75"
                  disabled={isDeactivated}
                  value={loyaltyStamps.dialog.label}
                  InputProps={{ style: { fontSize: labelTextSize } }}
                  name="label"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-sm-4 mb-sm-4 pl-0 pl-sm-2 d-flex align-items-center">
                <MText size={labelTextSize} text="Linked to brand:" />
              </div>
              <div className="col-12 col-sm-8 d-flex align-items-center pl-0 pl-sm-2">
                <Autocomplete
                  className="w-75"
                  freeSolo
                  disabled
                  options={existingPages}
                  defaultValue=""
                  value={{ name: loyaltyStamps.dialog.linkPageName }}
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  onChange={(e, v) => {
                    if (!IsEmpty(v)) {
                    setLinkPage(v.id, v.name);
                    }
                  }}
                  renderInput={(params) => (
                  /* eslint-disable react/jsx-props-no-spreading */
                    <TextField
                      {...params}
                      className="w-100"
                      value={loyaltyStamps.dialog.linkPageName || ''}
                      onChange={(e) => setLinkPage(null, e.target.value)}
                      variant="outlined"
                      size="small"
                      InputProps={{ style: { fontSize: labelTextSize } }}
                      helperText={`Page ID: ${loyaltyStamps.dialog.linkPageId || '-'}`}
                      fullWidth
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 col-sm-4 pl-0 pl-sm-2 mt-0 mt-md-2">
                <MText size={labelTextSize} text="Issued by linked brand:" />
              </div>
              <div className="col-12 col-sm-8 d-flex flex-column pl-0 pl-sm-2">
                <div className="w-100 d-flex align-items-center">
                  <FormControlLabel
                    className="mr-1"
                    style={{ fontSize: labelTextSize }}
                    control={(
                      <Switch
                        checked={loyaltyStamps.dialog.isToBeIssueByLinkPageId}
                        color="secondary"
                        name="isToBeIssueByLinkPageId"
                        disabled
                      />
                    )}
                    label={<MText size={labelTextSize} text={loyaltyStamps.dialog.isToBeIssueByLinkPageId ? 'Yes' : 'No'} />}
                  />
                  {loyaltyStamps.dialog.isToBeIssueByLinkPageId && <LockIcon className="pb-2" fontSize="medium" />}
                </div>
                <MText
                  className="w-75"
                  text="Select yes only if staff from the linked brand’s participating outlets can issue the stamp."
                  size={11}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 pl-0 pl-sm-2 mt-0 mt-md-2">
                <Divider />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 pl-0 pl-sm-2 mt-0 mt-md-2">
                <MText bold text="Prize / Promotion" size={titleTextSize} className="align-self-start mb-2 pl-0 pl-sm-2" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-sm-4 pl-0 pl-sm-2 d-flex flex-column justify-content-center">
                <MText size={labelTextSize} text="Image" />
                <MText size={labelTextSize} text="(Max 2MB)" />
              </div>
              <div className="col-12 col-sm-8 d-flex flex-column pl-0 pl-sm-2">
                <input
                  hidden
                  name="prizeImage"
                  accept={imageFormats}
                  type="file"
                  onChange={handleImageChange}
                  ref={fileInputRefs.prizeImage}
                />
                <Button
                  onClick={() => fileInputRefs.prizeImage.current.click()}
                  className="p-0"
                  disabled={isDeactivated}
                  style={{
                    width:           '75%',
                    height:          '9rem',
                    backgroundColor: IsEmpty(loyaltyStamps.dialog.prizeImage.data) ? Colours.Gray4 : null,
                  }}
                >
                  {IsEmpty(loyaltyStamps.dialog.prizeImage.data)
                    ? <ImageIcon fontSize="large" htmlColor={Colours.Black} />
                    : (
                      <img
                        src={loyaltyStamps.dialog.prizeImage.data}
                        alt="Prize"
                        className="w-100 rounded"
                        style={{ height: '9rem', objectFit: 'cover' }}
                      />
                    )}
                </Button>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-sm-4 pl-0 pl-sm-2 d-flex align-items-center">
                <MText size={labelTextSize} text="Prize / Promo Text" />
              </div>
              <div className="col-10 col-sm-8 d-flex flex-column pl-0 pl-sm-2">
                <TextField
                  multiline
                  minRows={4}
                  onChange={handleCustomizeInput}
                  disabled={isDeactivated}
                  variant="outlined"
                  size="small"
                  className="w-100"
                  InputProps={{ style: { fontSize: labelTextSize } }}
                  value={loyaltyStamps.dialog.prize}
                  name="prize"
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 col-sm-4 pl-0 pl-sm-2 d-flex align-items-center">
                <MText size={labelTextSize} text="Terms & Conditions" />
              </div>
              <div className="col-10 col-sm-8 d-flex flex-column pl-0 pl-sm-2">
                <TextField
                  multiline
                  minRows={4}
                  onChange={handleCustomizeInput}
                  disabled={isDeactivated}
                  variant="outlined"
                  size="small"
                  className="w-100"
                  InputProps={{ style: { fontSize: labelTextSize } }}
                  value={loyaltyStamps.dialog.prizeTerms}
                  name="prizeTerms"
                />
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Button disabled={isDeactivated} variant="contained" color="secondary" className="w-50" onClick={handleDialogSave}>Save</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

StampDialog.propTypes = {
  onCloseDialog:         PropTypes.func.isRequired,
  onCloseDialogEnd:      PropTypes.func.isRequired,
  loyaltyStamps:         PropTypes.shape(Object).isRequired,
  onUpdateLoyaltyStamps: PropTypes.func.isRequired,
  handleCustomizeInput:  PropTypes.func.isRequired,
  handleDialogSave:      PropTypes.func.isRequired,
  handleImageChange:     PropTypes.func.isRequired,
  fileInputRefs:         PropTypes.shape(Object).isRequired,
  imageFormats:          PropTypes.arrayOf(PropTypes.string).isRequired,
  isDeactivated:         PropTypes.bool.isRequired,
};

export default StampDialog;
