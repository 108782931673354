import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import Colours from '../../../../styles/colours';
import Checkbox from '../../../../components/Common/form/CheckBox';
import Select from '../../../../components/Common/form/Select';
import SelectTo from '../../../../components/Common/form/SelectTo';
import { TIME_INTERVALS } from '../../../../common/time-intervals';
import Icons from '../../../../components/Common/Icons';
import { IsEmpty } from '../../../../common/checks';

function OpeningHours({ pageId, params, location, outlet, handleOpeningHoursChange, setdefaultRemovel }) {
  const [openingHours, setOpeningHours] = useState([]);

  useEffect(() => {
    if (outlet && outlet.openingHours) {
      const updatedArr = [
        ...outlet.openingHours.default,
        { day:       'Eve of Public Holiday',
          isOpen:    outlet.openingHours.eve.isOpen,
          is24Hours: outlet.openingHours.eve.is24Hours,
          hours:     outlet.openingHours.eve.hours },
        { day:       `Public Holiday`,
          isOpen:    outlet.openingHours.holiday.isOpen,
          is24Hours: outlet.openingHours.holiday.is24Hours,
          hours:     outlet.openingHours.holiday.hours },
      ];
      setOpeningHours(updatedArr);
    }
  }, [outlet.openingHours]);

  useEffect(() => {
    handleOpeningHoursChange(openingHours);
  }, [openingHours]);

  // interval handlers
  const pushInterval = (interval, day) => {
    setOpeningHours((prevState) => prevState.map((opHour) => {
      if (opHour.day === day) {
        return {
          ...opHour,
          hours: [...opHour.hours, interval],
        };
      } else {
        return {
          ...opHour,
          hours: [...opHour.hours],
        };
      }
    }));
  };

  const setCurrentHourByPreviousDay = (currentDay) => {
    const defaultInterval = {
      from: '09:00 AM',
      to:   '21:00 PM',
    };
    const foundIndex = openingHours.findIndex((item) => item.day === currentDay.day);
    const indexToUse = foundIndex - 1;
    if (indexToUse >= 0) {
      if (openingHours[indexToUse].hours.length > 0) {
        const intervalToPush = openingHours[indexToUse].hours[0];
        return intervalToPush;
      } else {
        /**
         * If there is no hours in previous one
         * Use one in an array
         */
        const foundDay = openingHours.filter((day) => day.hours.length > 0);
        const lastFoundDay = foundDay.pop();
        const dayWithHours = lastFoundDay ? lastFoundDay.hours[0] : defaultInterval;
        return dayWithHours;
      }
    } else {
      return defaultInterval;
    }
  };

  const removeInterval = (opHourIndex, hourIndex) => {
    setOpeningHours((prevState) => prevState.map((opHour) => ({
      ...opHour,
      hours: opHour.hours.filter((item) => item !== prevState[opHourIndex].hours.slice(hourIndex)[0]),
    })));
  };

  const checkDefaultInterval = (opHourIndex, hourIndex, hourItem) => {
    const isDefaultInterval = hourItem.from === '00:00 AM' && hourItem.to === '00:00 AM';
    if (isDefaultInterval) {
      setOpeningHours((prevState) => {
        prevState[opHourIndex].is24Hours = !isDefaultInterval;
        return [
          ...prevState,

        ];
      });
      setdefaultRemovel(!isDefaultInterval);
    }
  };

  const handleCheckboxChange = (value, opHourIndex) => {
    setOpeningHours((prevState) => {
      prevState[opHourIndex].isOpen = value;
      return [
        ...prevState,

      ];
    });
  };

  const handleSelectChange = ({
    value,
    opHourIndex,
    hourIndex,
    type,
  }) => {
    setOpeningHours((prevState) => {
      prevState[opHourIndex].hours[hourIndex][type] = value;
      return [
        ...prevState,

      ];
    });
  };

  const renderDays = () => (
    <div>
      {
        openingHours && openingHours.length > 0 && openingHours.map((opening_hour, index) => (
          <Paper
            className="p-3 m-3 d-flex flex-row justify-content-start align-items-start"
            elevation={1}
            key={index}
          >
            <div className="mx-3 pt-2">
              <Checkbox
                name={`openingHours.${index}.isOpen`}
                value={openingHours[index].isOpen}
                handleChange={(value) => {
                    handleCheckboxChange(value, index);
                }}
                style={{
                  color:        Colours.Primary,
                  borderRadius: '50%',
                  transform:    'scale(1.3)',
                }}
              />
            </div>

            {/** Fixed width for day is required for Eve of PH and PH to wrap to new line */}
            <div className="ml-4 mr-2" style={{ paddingTop: '20px', width: '100px' }}>
              <Typography variant="body2" style={{  }}>{openingHours && openingHours[index].day}</Typography>
            </div>
            {
              opening_hour.isOpen ? (
                <div className="flex-grow-1 d-flex flex-column align-items-center">
                  {
                  openingHours[index].hours.map(
                    (value, val_index) => {
                      const lastTimeIntervalTo =  openingHours[index].hours.slice(-1)[0].to;
                      const indexOfLastTimeIntervalTo = TIME_INTERVALS.find((item) => item.value === lastTimeIntervalTo).index || 0;

                      return (
                        <div
                          className="pl-1 pr-2 mb-2 d-flex flex-row justify-content-center align-items-center"
                          key={`${Math.random()}${val_index}`}
                          style={{
                            border:       '1.4px solid #555555',
                            borderRadius: '20px',
                          }}
                        >
                          <Select
                            name={`openingHours.${index}.hours.${val_index}.from`}
                            hours={openingHours[index].hours || []}
                            options={TIME_INTERVALS}
                            index={indexOfLastTimeIntervalTo}
                            value={openingHours[index].hours[val_index].from}
                            handleChange={(e) => {
                              handleSelectChange({
                                value:       e.target.value,
                                opHourIndex: index,
                                hourIndex:   val_index,
                                type:        'from',
                              });
                            }}
                            className="px-4 py-2 m-1"
                            style={{
                              color:   Colours.Primary,
                              border:  '0px',
                              outline: '0px',
                            }}
                          />

                          <Typography className="ml-3 mr-2" variant="body2" color="inherit" style={{ color: Colours.Gray }}>to</Typography>

                          <SelectTo
                            name={`openingHours.${index}.hours.${val_index}.to`}
                            options={TIME_INTERVALS}
                            index={indexOfLastTimeIntervalTo}
                            hours={openingHours[index].hours || []}
                            value={openingHours[index].hours[val_index].to}
                            handleChange={(e) => {
                              handleSelectChange({
                                value:       e.target.value,
                                opHourIndex: index,
                                hourIndex:   val_index,
                                type:        'to',
                              });
                            }}
                            className="pl-2 pr-4 py-2 m-1"
                            style={{
                              color:   Colours.Primary,
                              border:  '0px',
                              outline: '0px',
                            }}
                          />
                          <IconButton
                            aria-label="remove hours"
                            onClick={() => {
                              checkDefaultInterval(index, val_index, openingHours[index].hours[val_index]);
                              removeInterval(index, val_index);
                            }}
                          >
                            <Icons.Cross fontSize="1.5rem" />
                          </IconButton>
                        </div>
                      );
                    },
                      )
                  }

                  <Button
                    className="px-3"
                    variant="text"
                    style={IsEmpty(openingHours[index].hours) ? { marginTop: '15px' } : {}}
                    onClick={() => {
                      if (openingHours[index].hours.length > 0) {
                        const lastTimeIntervalTo =  openingHours[index].hours.slice(-1)[0].to;
                        const indexOfLastTimeIntervalTo = TIME_INTERVALS.find((item) => item.value === lastTimeIntervalTo).index || 0;
                        if (lastTimeIntervalTo === '00:00 AM' || lastTimeIntervalTo === '23:30 PM' || lastTimeIntervalTo === '23:00 PM') {
                            toast.info(`You've reach the maximum hour interval`);
                        } else {
                          pushInterval(
                            {
                              from: TIME_INTERVALS[indexOfLastTimeIntervalTo + 1].value || setCurrentHourByPreviousDay(openingHours[index]).from,
                              to:   TIME_INTERVALS[indexOfLastTimeIntervalTo + 2].value || setCurrentHourByPreviousDay(openingHours[index]).to,
                            },
                              openingHours[index].day,
                            );
                        }
                      } else {
                        pushInterval(
                         {
                           from: setCurrentHourByPreviousDay(openingHours[index]).from,
                           to:   setCurrentHourByPreviousDay(openingHours[index]).to,
                         },
                          openingHours[index].day,
                        );
                      }
                    }}
                  >
                    <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>+ hours</Typography>
                  </Button>
                </div>
              ) : (
                <div className="flex-grow-1 d-flex flex-column align-items-center" style={{ paddingTop: '20px' }}>
                  <Typography variant="body2" color="inherit" style={{ color: Colours.Primary, fontWeight: 'bold' }}>
                    CLOSED
                  </Typography>
                </div>
              )
            }
          </Paper>
        ))
      }
    </div>
  );

  return (
    <div className="container-fluid p-1">
      <div className="col-12 col-xl-9">
        <Paper className="row p-2">
          <div className="col-12 d-flex flex-row justify-content-end">
            <Typography className="mr-3 mt-2" variant="subtitle2" color="inherit" style={{ color: Colours.Gray }}>
              * For 24 hours : select 00:00 AM to 00:00 AM
            </Typography>
          </div>

          <div className="col-12">
            {renderDays()}
          </div>
        </Paper>
      </div>
    </div>
  );
}

OpeningHours.propTypes = {
  pageId: PropTypes.number.isRequired,
  params: PropTypes.shape({
    action: PropTypes.string,
    view:   PropTypes.string,

  }).isRequired,
};

export default OpeningHours;
