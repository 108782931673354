import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import Api from '../services/apiService';
import Constants, { Error } from '../common/constants';
import authAction from '../actions/auth';
import { Sleep } from '../common/util';
import { IsEmpty } from '../common/checks';

const UserVerification = (props) => {
  const [verifyingInProgress, setVerifiyinInProgress] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    const query = queryString.parse(props.location.search);
    if (IsEmpty(query.id)) {
      setErrorMsg('Looks like the link is invalid. Please contact support for assistance on this matter.');
      setVerifySuccess(false);
      setVerifiyinInProgress(false);
    } else {
      Api.verifyUser(query.id)
        .then(async ({ token }) => {
          setVerifiyinInProgress(false);
          toast.success('Verification success');
          props.dispatchLogin(token);
          await Sleep(1000);
          setVerifySuccess(true);
        })
        .catch((err) => {
          if (err.message === Error.RecordNotFound) {
            setErrorMsg('The link is invalid or has expired.');
          } else {
            setErrorMsg(err.message);
          }
          setVerifiyinInProgress(false);
          setVerifySuccess(false);
        });
    }
  }, []);

  return verifySuccess
    ? <Redirect to={Constants.Url.Main} />
    : (
      <div className="container" style={{ paddingTop: '5%' }}>
        {
          verifyingInProgress && (
            <div className="row d-flex flex-column align-items-center">
              <Typography variant="h5">Verification in progress...</Typography>
              <Typography className="mt-4" variant="subtitle1">Please wait</Typography>
            </div>
          )
        }

        {
          !verifyingInProgress && verifySuccess && (
            <div className="row d-flex flex-column align-items-center">
              <Typography variant="h5">Verification Successful</Typography>
              <Typography variant="caption">Redirecting... Please wait</Typography>
            </div>
          )
        }

        {
          (verifySuccess === false) && (
            <div className="row d-flex flex-column align-items-center">
              <Typography variant="h5">Verification Unsuccessful</Typography>
              <Typography className="mt-4">{errorMsg}</Typography>
            </div>
          )
        }
      </div>
    );
};

UserVerification.propTypes = {
  dispatchLogin: PropTypes.func.isRequired,
  location:      PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (token) => authAction.login(dispatch, token),
});

export default connect(null, mapDispatchToProps)(UserVerification);
