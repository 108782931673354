/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, IconButton, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import captureVideoFrame from 'capture-video-frame';
import ReactPlayer from 'react-player';
import { getThumbnailFromVideoURL } from '../../../../../common/util';
import FixedCropperMobile from '../photo/FixedCropperMobile';
import Colours from '../../../../../styles/colours';
import OverLoading from '../../../../../components/Loading';
import Constants, { AccountMaxImageUpload } from '../../../../../common/constants';
import { IsEmpty } from '../../../../../common/checks';
import PostAction from '../../../../../actions/post';
import ApiService from '../../../../../services/apiService';
import Tooltip from '../../../../../components/Common/ToolTip';
import PostMediaLimitModal from '../PostMediaLimitModal';

const maxFileSize = 3072000;
const videoFormats = ['video/mp4'];

const settings = {
  arrows:         true,
  dots:           false,
  infinite:       true,
  speed:          500,
  slidesToShow:   1,
  slidesToScroll: 1,
};

const VideoMobile = (props) => {
  const [loading, setLoading] = useState(false);
  const [postId, setPostId] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [videoUrls, setVideoUrls] = useState([]);
  const [videoLocalFile, setVideoLocalFile] = useState(null);
  const [recropThumbnail, setRecropThumbnail] = useState(false);

  const [totalUploads, setTotalUploads] = useState(0);
  const [planModals, setPlanModals] = useState(false);
  const [accountType, setAccountType] = useState('Free');
  const [maxUpload, setMaxUpload] = useState(0);

  const hiddenInputFileRef = React.createRef();

  const [showVideoThumbModal, setShowVideoThumbModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const [hasThumbnail, setHasThumbnail] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [src, setSrc] = useState(null);

  const playerRef = useRef(null);

  useEffect(() => {
    if (props && props.postId) {
      setPostId(props.postId);
    }

    const totalPhotoUpload = !IsEmpty(props.post.images) ? (props.post.images).length : 0;
    const totalVideoUpload = !IsEmpty(props.post.videos) ? (props.post.videos).length : 0;
    setTotalUploads(totalPhotoUpload + totalVideoUpload);

    const maxNoUpload = AccountMaxImageUpload[props.page.plan];
    setAccountType(props.page.plan);
    setMaxUpload(maxNoUpload);

    setVideoUrls(!IsEmpty(props.post.videos) ? props.post.videos : []);
  }, [props.post]);

  const handleConfirmation = (url) => {
    setSelectedVideo(url);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    try {
      setOpenModal(false);
      setLoading(true);
      const response = await ApiService.deletePostMedia(postId, { mediaData: selectedVideo, fileType: Constants.FileType.Video });
      if (response) {
        const videoRes = videoUrls.filter((video) => video !== selectedVideo);
        props.dispatchPostUpdate({ videos: videoRes });
        setLoading(false);
        toast.success('Video successfully deleted.');
      }
      setSelectedVideo(null);
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setSelectedVideo(null);
      setOpenModal(false);
      setLoading(false);
    }
  };

  const handleErrorMessage = (file) => {
    if (file) {
      if (!videoFormats.includes(file.type)) {
        toast.error('Only MP4 files are supported.');
        return false;
      }

      if (file.size > maxFileSize) {
        toast.error('Maximum file size of 3MB exceeded.');
        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  const handleOpenPlanModals = () => {
    setPlanModals(true);
  };

  const handleClosePlanModals = () => {
    setPlanModals(false);
  };

  const handleInputUpload = () => {
    if (totalUploads >= maxUpload) {
      handleOpenPlanModals();
    } else {
      hiddenInputFileRef.current.click();
    }
  };

  const saveVideo = async (file) => {
    const isOk = handleErrorMessage(file);

    if (totalUploads >= maxUpload) {
      handleOpenPlanModals();
    } else if (isOk) {
      setLoading(true);

      // WE SET THE THUMBNAIL FIRST.
      const blob = URL.createObjectURL(file);
      setVideoUrl(blob);

      setShowVideoThumbModal(true);
      setVideoLocalFile(file);
    }
  };

  const handCloseThumbModal = () => {
    setShowVideoThumbModal(false);
    setHasThumbnail(false);
    setVideoLocalFile(null);
    setRecropThumbnail(null);
    setLoading(false);
  };

  const onCaptureThumbnail = () => {
    const frame = captureVideoFrame(playerRef.current.getInternalPlayer());
    handleSaveThumbnail(frame);
  };

  const handleSaveThumbnail = async (vidFile) => {
    if (!IsEmpty(vidFile)) {
      const file = new File([vidFile.blob], `${(videoUrl.split('/')[videoUrl.split('/').length - 1]).split('.')[0]}.jpg`, { type: 'image/jpeg' });

      if (file) {
        setShowCropper(true);
        setShowVideoThumbModal(false);
        setThumbnailFile(file);

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setSrc(reader.result);
          setHasThumbnail(false);
        });
        reader.readAsDataURL(file);
      }
    }
  };

  const onUploadThumbnail = async (file) => {
    const maxLoadTimer = 7;
    let uploadDurCount = 0;

    const interval = setInterval(() => {
      uploadDurCount += 1;
      if (uploadDurCount > maxLoadTimer) {
        toast.info('The upload is taking longer than usual, please wait.', {
          position:        'top-right',
          autoClose:       4000,
          hideProgressBar: false,
          closeOnClick:    true,
          pauseOnHover:    true,
          theme:           'colored',
        });
        clearInterval(interval);
      }
    }, 1000);

    if (file) {
      if (!recropThumbnail) {
        const formattedVideoFile = new File([videoLocalFile], `${file.name.replace('.jpg', '.mp4')}`, { type: 'video/mp4' });
        const formDataVideo = new FormData();
        formDataVideo.append('video', formattedVideoFile);
        formDataVideo.append('type', 'videos');
        const cloudfrontLink = await ApiService.uploadPostMedia(props.postId, formDataVideo, Constants.FileType.Video);

        if (cloudfrontLink && cloudfrontLink.length > 0) {
          // UPLOADING NEW THUMBNAIL
          const formattedFile = new File([file], `${(cloudfrontLink.split('/')[cloudfrontLink.split('/').length - 1]).split('.')[0]}.jpg`, { type: 'image/jpeg' });

          const formDataPhoto = new FormData();
          formDataPhoto.append('image', formattedFile);
          formDataPhoto.append('imageType', Constants.ImageType.VideoThumbnail);
          await ApiService.uploadPostMedia(props.postId, formDataPhoto, Constants.FileType.Image)
            .then(() => {
              setLoading(false);
              setShowCropper(false);
              handCloseThumbModal();
              setVideoLocalFile(null);
              setRecropThumbnail(null);
              props.dispatchPostUpdate({ videos: videoUrls.concat(cloudfrontLink) });
              toast.success('Video successfully uploaded.');
              clearInterval(interval);
            });
        }
      } else {
        // RE-CROPPING THUMBNAIL
        const formattedFile = new File([file], `${(videoUrl.split('/')[videoUrl.split('/').length - 1]).split('.')[0]}.jpg`, { type: 'image/jpeg' });

        const formDataPhoto = new FormData();
        formDataPhoto.append('image', formattedFile);
        formDataPhoto.append('imageType', Constants.ImageType.VideoThumbnail);
        await ApiService.uploadPostMedia(props.postId, formDataPhoto, Constants.FileType.Image)
          .then(() => {
            setLoading(false);
            setShowCropper(false);
            handCloseThumbModal();
            setVideoLocalFile(null);
            setRecropThumbnail(null);
            toast.success('Video thumbnail has been updated.');
            clearInterval(interval);
          });
      }
    }
  };

  const handleCloseCropper = () => {
    setShowCropper(false);
    setSrc(null);
    setShowVideoThumbModal(true);
    setVideoLocalFile(null);
    setRecropThumbnail(null);
  };

  return (
    <>
      { loading && <OverLoading /> }
      <div className="row">
        <div className="col-sm-12">
          { !IsEmpty(videoUrls) ? (
            <div style={{
              position: 'absolute',
              right:    5,
              top:      '-55px',
              zIndex:   1,
            }}
            >
              <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                <IconButton
                  disabled={props.disabled}
                  onClick={() => {
                    handleInputUpload();
                  }}
                >
                  <Typography variant="body1" color="inherit" style={{  color: ((props.disabled === false) ? Colours.Primary : Colours.Gray2), fontWeight: 'bold' }}>
                    UPLOAD
                  </Typography>
                </IconButton>
              </Tooltip>
              <input
                className="d-none"
                ref={hiddenInputFileRef}
                accept="video/mp4"
                id="icon-button-file"
                type="file"
                onChange={(e) => { saveVideo(e.currentTarget.files[0]); }}
              />
            </div>
          ) : (
            <div className="py-5 text-center">
              <Tooltip
                title={!props.disabled ? '' : 'Permission Denied'}
                isLink
              >
                <Typography
                  variant="h6"
                  color="inherit"
                  className="d-block text-center mx-auto"
                  style={{  color: ((props.disabled === false) ? Colours.Primary : Colours.Gray2), fontWeight: 'bold' }}
                  onClick={() => {
                    if (!props.disabled) {
                      handleInputUpload();
                    }
                  }}
                >
                  + Tap to add a video
                </Typography>
              </Tooltip>
              <input
                disabled={props.disabled}
                className="d-none"
                ref={hiddenInputFileRef}
                accept="video/mp4"
                id="icon-button-file"
                type="file"
                onChange={(e) => {
                  saveVideo(e.currentTarget.files[0]);

                  // this is a reset that allows to select the same file again.
                  e.target.value = '';
                }}
              />
            </div>
          )}

          <>
            <Slider {...settings}>
              {
              _.map(videoUrls, (v, i) => (
                <div key={i} style={{ position: 'relative' }}>
                  <div style={{ background: ((props.disabled === false) ? Colours.Primary : Colours.Gray2),  width: '100%', zIndex: 9 }}>
                    <div
                      key={i}
                      style={{
                        background: Colours.Black,
                        display:    'flex',
                        alignItems: 'center',
                      }}
                    >

                      {!showVideoThumbModal && getThumbnailFromVideoURL(v)
                        ? (
                          <div>
                            <img
                              alt={`${getThumbnailFromVideoURL(v)}?${Math.random()}`}
                              src={`${getThumbnailFromVideoURL(v)}?${Math.random()}`}
                              style={{
                                margin:  0,
                                padding: 0,
                                width:   '100%',
                                height:  '100%',
                              }}
                            />
                          </div>
                        )
                        : (
                          <ReactPlayer
                            url={v}
                            muted
                            playing
                            width="100%"
                            height="100%"
                          />
                        )}
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="d-flex justify-content-start" style={{ width: '30%' }}>
                        {!IsEmpty(videoUrls) && (
                          <Typography variant="body1" color="inherit" className="float-left ml-3" style={{ color: Colours.White, fontWeight: 'bold' }}>
                            {`${i + 1} of ${videoUrls.length}`}
                          </Typography>
                        )}
                      </div>

                      <div className="d-flex justify-content-end" style={{ width: '70%' }}>
                        <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                          <IconButton
                            className=""
                            onClick={() => {
                              setVideoUrl(v);
                              setShowVideoThumbModal(true);
                              setRecropThumbnail(true);
                            }}
                            disabled={props.disabled}
                          >
                            <Typography variant="body1" color="inherit" style={{ color: Colours.White, fontWeight: 'bold' }}>
                              SET THUMBNAIL
                            </Typography>
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={!props.disabled ? '' : 'Permission Denied'}>
                          <IconButton
                            className=""
                            onClick={() => {
                              handleConfirmation(v);
                            }}
                            disabled={props.disabled}
                          >
                            <Typography variant="body1" color="inherit" style={{ color: Colours.White, fontWeight: 'bold' }}>
                              DELETE
                            </Typography>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              }
            </Slider>
          </>
        </div>
        <Dialog
          fullWidth
          size="sm"
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove Video</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the video?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">Cancel</Button>
            <Button onClick={() => handleDelete()} color="primary" disabled={loading}>Confirm</Button>
          </DialogActions>
        </Dialog>

        <PostMediaLimitModal
          open={planModals}
          close={() => handleClosePlanModals()}
          accountType={accountType}
          page={props.page}
        />

        <Dialog
          id="videoDialog"
          fullWidth
          maxWidth="md"
          open={showVideoThumbModal}
          disableBackdropClick
          onClose={() => handCloseThumbModal()}
        >
          <DialogContent>
            <Typography variant="body1">Setting thumbnail</Typography>
            <hr />
            {!hasThumbnail && (
              <>
                <div className="d-block text-center pt-4 pb-2 overflow-hidden" style={{ width: 'auto' }}>
                  <ReactPlayer
                    muted
                    ref={playerRef}
                    url={videoUrl}
                    controls
                    volume={0}
                    width="100%"
                    height="100%"
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: 'anonymous',
                        },
                      },
                    }}
                    style={{
                      display: 'block',
                      margin:  '0 auto',
                    }}
                  />
                </div>

                <div className="d-block mx-auto mb-4 text-center">
                  <Typography color="primary" variant="body2" className="d-block my-3">
                    Click and drag the handle to the video frame that you want to use for the thumbnail, then hit the button ‘Next’ below.
                  </Typography>
                  <Button onClick={() => handCloseThumbModal()} style={{ width: '100%', marginBottom: 10, marginRight: 10 }} variant="outlined" color="secondary">Cancel</Button>
                  <Button onClick={() => onCaptureThumbnail()} style={{ width: '100%' }} variant="contained" color="secondary">Next</Button>
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={showCropper}
          onClose={() => handleCloseCropper()}
        >
          <FixedCropperMobile
            width={4}
            height={3}
            cropPixelWidth={400}
            cropPixelHeight={400}
            outputPixelWidth={900}
            outputPixelHeight={900}
            type="upload"
            src={src}
            file={thumbnailFile}
            fileName={`${(videoUrl.split('/')[videoUrl.split('/').length - 1]).split('.')[0]}.jpg`}
            handleSave={(file) => onUploadThumbnail(file)}
            handleClose={() => handleCloseCropper()}
            isCropping
          />
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

VideoMobile.defaultProps = {
  postId: null,
  page:   {
    plan: '',
  },
};

VideoMobile.propTypes = {
  dispatchPostUpdate: PropTypes.func.isRequired,
  disabled:           PropTypes.bool.isRequired,
  postId:             PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  page:               PropTypes.shape({
    plan: PropTypes.string,
  }),
  post: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        display:  PropTypes.string,
        original: PropTypes.string,
      }),
    ),
    videos: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoMobile);
