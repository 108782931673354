import Constants from '../common/constants';

const { ReduxAction } = Constants;

const businessSelected = (dispatch, business) => {
  dispatch({
    type:    ReduxAction.BusinessSelected,
    payload: business,
  });
};

const businessesAccess = (dispatch, businesses) => {
  dispatch({
    type:    ReduxAction.BusinessesAccess,
    payload: businesses,
  });
};

const currentView = (dispatch, view) => {
  dispatch({
    type:    ReduxAction.BusinessCurrentView,
    payload: view,
  });
};

const businessAction = {
  businessesAccess,
  businessSelected,
  currentView,
};

export default businessAction;
