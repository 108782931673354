import Constants from '../common/constants';

const { ReduxAction } = Constants;

const pageClaims = (dispatch, claims) => {
  dispatch({
    type:    ReduxAction.PageClaims,
    payload: claims,
  });
};

const pageClaimSelected = (dispatch, pageClaim) => {
  dispatch({
    type:    ReduxAction.PageClaimSelected,
    payload: pageClaim,
  });
  // clear any page & invitation selected (affects the sideBar display)
  dispatch({
    type:    ReduxAction.PageSelected,
    payload: {},
  });
  dispatch({
    type:    ReduxAction.InviteSelected,
    payload: {},
  });
};

const pageClaimUpdate = (dispatch, claim) => {
  dispatch({
    type:    ReduxAction.PageClaimUpdate,
    payload: claim,
  });
};

const pageClaimNew = (dispatch) => {
  pageClaimSelected(dispatch, {});
  dispatch({
    type: ReduxAction.PageClaimNew,
  });
};

const pageClaimDelete = (dispatch, id) => {
  dispatch({
    type:    ReduxAction.PageClaimDelete,
    payload: id,
  });
};

const pageClaimAction = {
  pageClaims,
  pageClaimSelected,
  pageClaimUpdate,
  pageClaimNew,
  pageClaimDelete,
};

export default pageClaimAction;
