import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import MaterialTable from 'material-table';
import { IsEmpty, IsAdminOrContributor } from '../../../../common/checks';
import { Trim } from '../../../../common/util';
import { Error } from '../../../../common/constants';
import AddressSearch from '../../../../components/AddressSearch';
import PostAction from '../../../../actions/post';
import Colours from '../../../../styles/colours';
import postStyle from '../../../../styles/post';
import Tooltip from '../../../../components/Common/ToolTip';

const OtherLocations = (props) => {
  const [openAddress, setOpenAddress] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [locations, setLocations] = useState([]);
  const [locationToDelete, setLocationToDelete] = useState();

  useEffect(() => {
    const filteredLocations = _.filter(props.post.promoLocations, (loc) => loc.outletId === null);
    setLocations(filteredLocations);
  }, [props.post]);

  const handleAddPromoLocations = (loc) => {
    if (IsEmpty(Trim(loc.postalCode))) {
      setOpenAddress(true);
      return toast.error(Error.PostalCodeRequired);
    }

    if (IsEmpty(Trim(loc.address))) {
      setOpenAddress(true);
      return toast.error(Error.AddressRequired);
    }

    if (!IsEmpty(loc)) {
      const newLocation = {
        postId:     props.post.id,
        outletId:   null,
        address:    loc.address || null,
        postalCode: loc.postalCode || null,
        remarks:    loc.remarks || '',
        lat:        loc.lat || null,
        lng:        loc.lng || null,
        location:   null,
        locatedIn:  loc.name || '',
      };
      props.dispatchPostUpdate({ promoLocations: props.post.promoLocations.concat(newLocation) });
    }
    setOpenAddress(false);
  };

  const onDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const onDeleteClick = () => {
    if (!IsEmpty(locationToDelete)) {
      const updatedLocations = _.filter(props.post.promoLocations, (loc) => loc.id !== locationToDelete.id);
      props.dispatchPostUpdate({ promoLocations: updatedLocations });
      setLocationToDelete(null);
    }
    setOpenDeleteDialog(false);
  };

  return (
    <div className={`${props.className}`}>
      <Typography variant="body1" color="inherit" className="mt-2 mb-2" style={postStyle.textHeader}>
        Other Locations
      </Typography>

      <Typography variant="body2" color="inherit" className="ml-3" style={{ color: Colours.Gray1 }}>
        Specify all other locations except your outlet(s) here.
      </Typography>
      <Typography variant="body2" color="inherit" className="ml-3 mb-3" style={{ color: Colours.Gray1 }}>
        E.g. You have an event in a mall location at the atrium.
      </Typography>

      <Tooltip title={props.disabled ? `Cannot be updated because post is ${props.post.currentPostStatus}` : IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}>
        <Button
          size="small"
          className="px-5 ml-2 mb-3"
          variant="contained"
          color="primary"
          onClick={() => setOpenAddress(true)}
          disabled={!IsAdminOrContributor(props.page.staffType) || props.disabled}
        >
          + Add Location
        </Button>
      </Tooltip>
      <MaterialTable
        title=""
        columns={[
          { title: 'Located In', field: 'locatedIn' },
          { title: 'Address', field: 'address' },
          { title: 'Postal Code', field: 'postalCode' },
          { title: 'Remarks', field: 'remarks' },
        ]}
        data={locations}
        actions={[
          {
            icon:     'delete',
            tooltip:  props.disabled ? `Cannot be updated because post is ${props.post.currentPostStatus}` : 'Delete Location',
            disabled: !IsAdminOrContributor(props.page.staffType) || props.disabled,
            onClick:  (event, loc) => {
              setOpenDeleteDialog(true);
              setLocationToDelete(loc);
            },
          },
        ]}
        options={{
          actionsColumnIndex:  -1,
          sorting:             true,
          paging:              true,
          emptyRowsWhenPaging: false,
        }}
      />

      {
        openAddress && (
          <AddressSearch
            open={openAddress}
            onCancel={() => setOpenAddress(false)}
            onDone={(data) => handleAddPromoLocations(data)}
            remarks
          />
        )
      }

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        size="sm"
      >
        <DialogTitle id="alert-dialog-title">Delete Location</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this location?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="px-2" onClick={onDeleteClick} color="default">Delete</Button>
          <Button className="px-2 mx-3" onClick={onDeleteCancel} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

OtherLocations.propTypes = {
  dispatchPostUpdate: PropTypes.func.isRequired,
  disabled:           PropTypes.bool.isRequired,
  post:               PropTypes.shape({
    id:                PropTypes.number,
    currentPostStatus: PropTypes.string,
    promoLocations:    PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  page: PropTypes.shape({
    id:        PropTypes.number,
    staffType: PropTypes.string,
  }).isRequired,

  className: PropTypes.string,
};

OtherLocations.defaultProps = {
  className: '',
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherLocations);
