import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import layoutAction from '../actions/layout';
import Icons from './Common/Icons';
import Colours from '../styles/colours';

const Header = (props) => (
  <>
    <AppBar position="relative" style={{ height: 60, width: '100vw', zIndex: 3, backgroundColor: Colours.White, color: Colours.Black }}>
      <Toolbar
        disableGutters
        style={{
          padding:          0,
          position:         'relative',
          '--parentHeight': '14vw',
          minHeight:        'var(--parentHeight)',
          height:           'var(--parentHeight)',

        }}
      >
        <div style={{
          position:  'absolute',
          width:     'calc(var(--parentHeight)*1.35)',
          left:      'calc(var(--parentHeight) * 0.2)',
          marginTop: 'calc(var(--parentHeight) * 0.2)',

        }}
        >
          <IconButton
            size="small"
            onClick={() => props.dispatchSideBarToggle()}
          >
            <Icons.Menu style={{ height: 60 }} />
          </IconButton>
        </div>
        <div
          style={{
            display:        'flex',
            flexDirection:  'column',
            justifyContent: 'flex-start',
            alignItems:     'center',
            marginTop:      8,
            height:         '100%',
            width:          '100%',
          }}
        >
          <Typography style={{ fontWeight: 'bold'  }} variant="subtitle1">Monstyr Dashboard</Typography>
          <Typography
            className="text-truncate ml-1 pl-1"
            style={{
              // we set fixed width and if text exceed this width . it will be truncated
              width:     '60%',
              textAlign: 'center',
            }}
            variant="body1"
          >
            {props.page ? props.page.name : ''}
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  </>
);

Header.propTypes = {
  dispatchSideBarToggle: PropTypes.func.isRequired,
  page:                  PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSideBarToggle: () => layoutAction.sideBarToggle(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
