import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';

import pageClaimStyle from '../../../styles/pageClaim';
import PageClaimView from './PageClaimView';

const Summary = (props) => (
  <>
    <Typography variant="h6" style={pageClaimStyle.textHeader}>Summary of Page Claim</Typography>
    <PageClaimView pageClaimData={props.pageClaim} />

    <div className="my-3 d-flex flex-row justify-content-end">
      <Button
        className="px-4"
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => props.onBackClick()}
      >
        Back
      </Button>
      <Button
        className="ml-3"
        variant="contained"
        size="large"
        color="secondary"
        onClick={() => props.onSubmitClick()}
      >
        Submit
      </Button>
    </div>
  </>
);

Summary.propTypes = {
  onBackClick:   PropTypes.func.isRequired,
  onSubmitClick: PropTypes.func.isRequired,
  pageClaim:     PropTypes.shape({}).isRequired,
};
const mapStateToProps = (state) => ({
  pageClaim: state.pageClaim.claim,
});

export default connect(mapStateToProps, null)(Summary);
