import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles, Button, Typography, Paper, TextField, RadioGroup, Radio, FormControlLabel, FormControl, Select, MenuItem }
  from '@material-ui/core';
import { toast } from 'react-toastify';
import { IfEmptyThrowError, IsEmpty } from '../../../common/checks';
import { PageType } from '../../../common/constants';
import Colours from '../../../styles/colours';
import AddressSearch from '../../../components/AddressSearch';
import SelectCategories from '../../../components/SelectCategories';
import pageClaimStyle from '../../../styles/pageClaim';
import pageClaimAction from '../../../actions/pageClaim';
import Icons from '../../../components/Common/Icons';

const useStyles = makeStyles((theme) => ({
  rootFirstSelect: {
    padding:   12,
    alignSelf: 'start',
  },
}));

const Step2 = (props) => {
  const classes = useStyles();

  const [openAddress, setOpenAddress] = useState(false);
  const [pageName, setPageName] = useState('');
  const [isClaimingPage, setIsClaimingPage] = useState(true);
  const [pageRename, setPageRename] = useState('');
  const [isKeepData, setIsKeepData] = useState(null);
  const [pageType, setPageType] = useState('');
  const [pageCategories, setPageCategories] = useState([]);
  const [postalCode, setPostalCode] = useState(null);
  const [address, setAddress] = useState('');
  const [locatedIn, setLocatedIn] = useState(null);

  useEffect(() => {
    if (props.pageClaim) {
      const p = props.pageClaim;
      setPageName(p.pageName ? p.pageName : '');
      setPageRename(p.pageRename ? p.pageRename : '');
      setIsClaimingPage(!IsEmpty(p.pageId));
      if (IsEmpty(p.pageId)) {
        setIsKeepData(null);
      } else {
        setIsKeepData(p.isKeepData === undefined ? true : p.isKeepData);
      }
      setPageType(p.pageType ? p.pageType : null);
      setPageCategories(p.pageCategories ? p.pageCategories : []);
      setPostalCode(p.postalCode ? p.postalCode : null);
      setAddress(p.address ? p.address : null);
      setLocatedIn(p.locatedIn ? p.locatedIn : null);
    }
  }, [props.pageClaim]);

  const closeAddress = () => setOpenAddress(false);

  const populateAddress = (result) => {
    props.dispatchPageClaimUpdate({ pageClaim: { ...props.pageClaim, locatedIn: result.name || null, address: result.address, postalCode: result.postalCode } });
    closeAddress();
  };

  const onNext = () => {
    try {
      if (!isClaimingPage) {
        IfEmptyThrowError(pageType, 'Please select page type');
        if (pageType === PageType.Mall) {
          IfEmptyThrowError(address, `Please provide mall's address`);
        }
        IfEmptyThrowError(pageCategories, 'Please select at least one relevant category for your page');
      }

      if (props.pageClaim && !IsEmpty(props.pageClaim.pageId) && IsEmpty(props.pageClaim.isKeepData)) {
        props.dispatchPageClaimUpdate({ pageClaim: { ...props.pageClaim, isKeepData: true } });
      }

      props.onNextClick();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderOptionForPageClaim = () => (
    <>
      <div className="d-flex flex-column mt-4">
        <Typography variant="body1" color="inherit" style={{ color: Colours.Gray, marginBottom: 10 }}>
          If you'd like to rename the existing page, enter the new name here
        </Typography>

        <TextField
          className="mt-1 block"
          type="text"
          value={pageRename}
          onChange={(e) => {
            const newName = e.target.value;
            props.dispatchPageClaimUpdate({ pageClaim: { ...props.pageClaim, pageRename: newName } });
          }}
          variant="outlined"
          size="small"
          disabled={props.disabled}
        />
      </div>

      <div className="d-flex flex-column mt-4">
        <Typography variant="body1" color="inherit" style={{ color: Colours.Gray, marginBottom: 10 }}>
          Keep the existing page's data (posts, outlets and etc)
        </Typography>

        <RadioGroup style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            control={<Radio color="primary" checked={isKeepData} />}
            label="Yes"
            onChange={() => {
              setIsKeepData(true);
              props.dispatchPageClaimUpdate({ pageClaim: { ...props.pageClaim, isKeepData: true } });
            }}
            disabled={props.disabled}
          />
          <FormControlLabel
            control={<Radio color="primary" checked={!isKeepData} />}
            label="No"
            onChange={() => {
              setIsKeepData(false);
              props.dispatchPageClaimUpdate({ pageClaim: { ...props.pageClaim, isKeepData: false } });
            }}
            disabled={props.disabled}
          />
        </RadioGroup>
      </div>
    </>
  );

  const renderOptionForNonPageClaim = () => (
    <>
      <div className="d-flex flex-column mt-4">
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Select page type
          {' '}
          <span style={{ color: 'red' }}>*</span>
        </Typography>

        <FormControl className="mt-3 block" variant="outlined">
          <Select
            classes={{ root: classes.rootFirstSelect }}
            variant="outlined"
            value={pageType || ''}
            onChange={(e) => {
              props.dispatchPageClaimUpdate({
                pageClaim: {
                  ...props.pageClaim,
                  pageType:   e.target.value,
                  address:    null,
                  postalCode: null,
                },
              });
            }}
            disabled={props.disabled}
          >
            <MenuItem value="" key="default">Select one</MenuItem>
            {_.map(_.omit(PageType, ['View', 'HawkerCentre', 'FoodReview']), (ptype, index) => <MenuItem key={index} value={ptype || ''}>{ptype || ''}</MenuItem>)}
          </Select>
        </FormControl>
      </div>

      {
        pageType === PageType.Mall && (
          <div className="mt-4 d-flex flex-column">
            <div className="d-flex flex-row">
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Address
              </Typography>
              {
                !props.disabled && (
                  <Button
                    className="ml-2"
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => { setOpenAddress(true); }}
                  >
                    <Typography variant="caption">search</Typography>
                  </Button>
                )
              }
            </div>
            <TextField
              className="mt-2 block"
              type="text"
              value={IsEmpty(address) ? '' : `${(locatedIn) ? `${locatedIn}\n` : ''}${address}\nSingapore ${postalCode}`}
              multiline
              maxRows={4}
              disabled
              variant="outlined"
              size="small"
              style={{
                background: Colours.Gray5,
              }}
            />
          </div>
        )
      }

      <div className="d-flex flex-column mt-4">
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Select the relevant categories
          {' '}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <div className="ml-3">
          <SelectCategories
            value={pageCategories}
            onChange={(cats) => {
              props.dispatchPageClaimUpdate({ pageClaim: { ...props.pageClaim, pageCategories: cats } });
            }}
            disabled={props.disabled}
          />
        </div>
      </div>
    </>
  );

  return (
    <>
      {openAddress && <AddressSearch onCancel={closeAddress} onDone={(e) => populateAddress(e)} />}

      <Paper style={pageClaimStyle.paper}>
        <div className="d-flex flex-column pb-2">
          <Typography variant="subtitle1" style={pageClaimStyle.textHeader}>Page Name</Typography>
          <Typography variant="body1">{pageName}</Typography>
          {
            isClaimingPage
              ? renderOptionForPageClaim()
              : renderOptionForNonPageClaim()
          }
        </div>
      </Paper>

      {
        !props.disabled && (
          <div className="my-3 d-flex flex-row justify-content-end">
            <Button
              className="px-4"
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => props.onBackClick()}
            >
              Back
            </Button>
            <Button
              className="ml-3"
              variant="contained"
              size="large"
              color="primary"
              onClick={() => onNext()}
            >
              <div className="d-flex flex-row align-items-center">
                <Typography className="mr-2" variant="body1">Next: Step 3</Typography>

                <Icons.ArrowFoward colour={Colours.White} fontSize="1.5rem" />
              </div>
            </Button>
          </div>
        )
      }
    </>
  );
};

Step2.propTypes = {
  disabled:                PropTypes.bool,
  dispatchPageClaimUpdate: PropTypes.func.isRequired,
  pageClaim:               PropTypes.shape().isRequired,
  onBackClick:             PropTypes.func,
  onNextClick:             PropTypes.func,
};

Step2.defaultProps = {
  disabled:    false,
  onBackClick: () => { },
  onNextClick: () => { },
};

const mapStateToProps = (state) => ({
  pageClaim: state.pageClaim.claim.pageClaim,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageClaimUpdate: (data) => pageClaimAction.pageClaimUpdate(dispatch, data),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
