// React Dependencies
import React, { useEffect, useRef, useState } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, makeStyles } from '@material-ui/core';

// Internal Dependencies
import ApiService from '../../../services/apiService';
import Loading from '../../../components/Loading';
import Util, { formatDateToDayMonthNameYear, showDateInSGTime } from '../../../common/util';
import { Url } from '../../../common/constants';
import { IsEmpty } from '../../../common/checks';
import { containerHeaderTextStyle } from '../../../styles/common';
import PreCreateModal from './PreCreateModal';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingLeft:  '2rem',
    paddingRight: '2rem',

    [theme.breakpoints.down('sm')]: {
      paddingLeft:  '1rem',
      paddingRight: '1rem',
    },
  },
}));

const PageHighlightListing = (props) => {
  const classes = useStyles();

  const tableRef = useRef(null);
  const [pageNo] = useState(1);
  const [keyForReload, setKeyForReload] = useState(false);

  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [isSpecial, setIsSpecial] = useState(false);
  const [isExclusive, setIsExclusive] = useState(false);
  const [pageId, setPageId] = useState(null);
  const [openPreCreateModal, setOpenPreCreateModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  useEffect(() => {
    try {
      const query = Util.ExtractParamsFromUrlQueryStr(props.location.search);
      if (!IsEmpty(query)) {
        setIsSpecial(query.isSpecial === 'true' || false);
        setIsExclusive(query.isExclusive === 'true' || false);
      }

      setPageId(props.selectedPage.id || null);
      setKeyForReload(!keyForReload);
    } catch (error) {
      console.log('error', error);
    }
  }, [props.location.search]);

  const onNewHighlightBtnClicked = () => {
    setOpenPreCreateModal(true);
  };

  const executeCreatePageHighlight = async ({ title }) => {
    try {
      setLoading(true);
      const pageHighlight = await ApiService.createPageHighlight({ pageId, title, isSpecial, isExclusive });

      setOpenPreCreateModal(false);
      setLoading(false);

      // navigate to the pageHighlight site
      let thisRedirectUrl = Url.PageHighlight.replace(':pageId', pageId).replace(':pageHighlightId', pageHighlight.id);
      thisRedirectUrl += isSpecial ? '?isSpecial=true' : isExclusive ? '?isExclusive=true' : '';
      setRedirectUrl(thisRedirectUrl);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await ApiService.deletePageHighlight(pageId, idToDelete);
      toast.success('Highlight deleted');
      setIdToDelete(null);

      // refresh table
      tableRef.current.onQueryChange();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return redirectUrl ? <Redirect to={redirectUrl} /> : (
    <>
      {loading && <Loading />}

      <div className={`container-fluid ${classes.mainContainer}`}>
        <div className="mt-4 d-flex flex-row justify-content-start">
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>
            {isSpecial ? `Special ` : isExclusive ? `Exclusive ` : ''}
            Highlights
          </Typography>

          <Button
            variant="contained"
            className="ml-3 mb-3"
            style={{ position: 'relative', top: -7 }}
            color="primary"
            onClick={onNewHighlightBtnClicked}
          >
            Add New
          </Button>
        </div>

        <div className="row d-flex flex-column" key={keyForReload}>
          <div className="col-12 my-3">
            <MaterialTable
              tableRef={tableRef}
              title=""
              columns={[
                { title: 'ID', field: 'id', width: '5%' },
                { title: 'Title', field: 'title', width: '30%' },
                {
                  title:  'Start Date', field:  'startDate', width:  '10%',
                  render: (d) => (d.startDate !== null ? `${formatDateToDayMonthNameYear(showDateInSGTime(d.startDate))}` : '-'),
                },
                {
                  title:  'End Date', field:  'endDate', width:  '10%',
                  render: (d) => (d.endDate !== null ? `${formatDateToDayMonthNameYear(showDateInSGTime(d.endDate))}` : '-'),
                },
                { title: 'Status', field: 'status', width: '5%' },
              ]}
              actions={[
                {
                  icon:    'edit',
                  tooltip: 'Edit Highlight',
                  onClick: (event, d) => {
                    window.open(`/page/${pageId}/highlight/${d.id}${isSpecial ? '?isSpecial=true' : isExclusive ? '?isExclusive=true' : ''}`, '_blank');
                  },
                },
                {
                  icon:    'delete',
                  tooltip: 'Delete Highlight',
                  onClick: (event, d) => {
                    setIdToDelete(d.id);
                  },
                },
              ]}
              options={{
                pageSize:            25,
                pageSizeOptions:     [25, 50, 80],
                actionsColumnIndex:  -1,
                emptyRowsWhenPaging: false,
                search:              false,
                sorting:             false,
              }}
              data={(query) => new Promise(async (resolve, reject) => {
                const { rows, totalCount } = await ApiService.getPageHighlights(
                  { pageId, isSpecial, isExclusive, pageNo: pageNo - 1, pageSize: query.pageSize },
                );
                resolve({
                  data: rows || [],
                  page: pageNo - 1,
                  totalCount,
                });
              })}
            />
          </div>
        </div>
      </div>

      {
        openPreCreateModal
          ? (<PreCreateModal onComplete={executeCreatePageHighlight} onClose={() => setOpenPreCreateModal(false)} />)
          : <div />
      }

      {/* <Modal isOpen={!IsEmpty(idToDelete)} toggle={() => setIdToDelete(null)} autoFocus={false}>
        <ModalHeader toggle={() => setIdToDelete(null)}>Delete Confirmation</ModalHeader>
        <ModalBody>
          <h5>
            Deleting this highlight ( id=
            {idToDelete}
            {' '}
            ) will delete all posts created under it as well.
          </h5>
          <h5>Are you sure?</h5>
        </ModalBody>
        <ModalFooter>
          <Button variant="contained" onClick={() => setIdToDelete(null)}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" className="mx-3" onClick={handleDelete}>
            Delete
          </Button>
        </ModalFooter>
      </Modal> */}
      <Dialog
        fullWidth
        size="sm"
        open={!IsEmpty(idToDelete)}
        onClose={() => setIdToDelete(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Deleting this highlight (id=
          {' '}
          {idToDelete}
          ) will delete all posts created under it as well.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-2">
          <Button className="px-2" onClick={handleDelete} variant="contained" color="primary">Confirm</Button>
          <Button className="px-2 mx-3" variant="contained" onClick={() => setIdToDelete(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

PageHighlightListing.propTypes = {
  selectedPage: PropTypes.shape({
    id:        PropTypes.number,
    staffType: PropTypes.string,
  }).isRequired,

  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  selectedPage: state.page.selected,
});

export default connect(mapStateToProps, null)(PageHighlightListing);
