import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import MaterialTable from 'material-table';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import PostAction from '../../../../actions/post';
import Colours from '../../../../styles/colours';
import { IsEmpty } from '../../../../common/util';

const theme = createTheme({
  palette: {
    primary: {
      main: Colours.Primary,
    },
    secondary: {
      // required to change the MaterialTable's checkbox and header colour to our primary colour
      main: Colours.Primary,
    },
  },
  overrides: {
    // required to change MaterialTable's 'k row(s) selected' text size
    MuiTypography: {
      h6: {
        fontSize: '1rem',
      },
    },
  },
});

const AvailOutlets = (props) => {
  const [pageOutlets, setPageOutlets] = useState([]);

  useEffect(() => {
    if (props.post) {
      const outlets = !IsEmpty(props.page.locations) ? props.page.locations.slice() : [];
      let isSelected = false;

      for (const pageOutlet of outlets) {
        isSelected = false;
        for (const promoLoc of props.post.promoLocations) {
          if (promoLoc.outletId === pageOutlet.id) {
            isSelected = true;
            break;
          }
        }
        pageOutlet.tableData = { checked: isSelected };
      }
      setPageOutlets(outlets);
    }
  }, []);

  const convertOutletToPromoLocation = (d) => _.assign({
    postId:     props.post.id,
    outletId:   d.id,
    address:    d.address,
    postalCode: d.postalCode,
    remarks:    d.unitNo,
    lat:        d.lat,
    lng:        d.lng,
    location:   d.location,
    locatedIn:  d.locatedIn,
  });

  const onRowClick = (outletData) => {
    const index = pageOutlets.indexOf(outletData);
    const updatedOutlets = pageOutlets.slice();

    // update table checkbox
    const isChecked = !updatedOutlets[index].tableData.checked;
    updatedOutlets[index].tableData.checked = isChecked;
    setPageOutlets(updatedOutlets);

    // update promoLocations
    let promoLocations = [];
    if (isChecked) {
      // add
      const pl = convertOutletToPromoLocation(outletData);
      promoLocations = _.concat(props.post.promoLocations, [pl]);
    } else {
      // remove
      promoLocations = _.filter(props.post.promoLocations, (pl) => pl.outletId !== outletData.id);
    }
    props.dispatchPostUpdate({ promoLocations });
  };

  return (
    <div className={`container-fluid ${props.className}`}>
      <div className="row">
        <div className="col-12 p-0">
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              title=""
              columns={[
                { title: 'Located In', field: 'locatedIn' },
                { title: 'Address', field: 'address' },
                { title: 'Postal Code', field: 'postalCode' },
              ]}
              options={{
                paginationPosition:    'both',
                selection:             true,
                showSelectAllCheckbox: !props.disabled,
                selectionProps:        {
                  disabled: props.disabled,
                },
              }}
              data={pageOutlets}
              onRowClick={(evt, rowData) => !props.disabled && onRowClick(rowData)}
              onSelectionChange={(data) => {
                const promoLocations = _.map(data, (d) => convertOutletToPromoLocation(d));
                props.dispatchPostUpdate({ promoLocations });
              }}
            />
          </MuiThemeProvider>
        </div>
      </div>
    </div>
  );
};

AvailOutlets.propTypes = {
  dispatchPostUpdate: PropTypes.func.isRequired,
  disabled:           PropTypes.bool.isRequired,
  page:               PropTypes.shape({
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id:         PropTypes.number,
        address:    PropTypes.string,
        postalCode: PropTypes.string,
        locatedIn:  PropTypes.string,
        lat:        PropTypes.number,
        lng:        PropTypes.number,
      }),
    ),
  }).isRequired,

  post: PropTypes.shape({
    id: PropTypes.number,

    promoLocations: PropTypes.arrayOf(
      PropTypes.shape({
        outletId:   PropTypes.number,
        address:    PropTypes.string,
        postalCode: PropTypes.string,
        locatedIn:  PropTypes.string,
        lat:        PropTypes.number,
        lng:        PropTypes.number,
      }),
    ),
  }).isRequired,

  className: PropTypes.string,
};

AvailOutlets.defaultProps = {
  className: '',
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailOutlets);
