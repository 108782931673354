import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FormControl, Typography } from '@material-ui/core';
import Colours from '../../styles/colours';
import { IsEmpty } from '../../common/util';

function TotalCircleComp({ totalCount = 0, title = 'Total', dimension = '150px', selectable, setDuration }) {
  const handleChange = (e) => {
    setDuration(e.target.value);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center text-center my-2"
      style={{
        // backgroundColor: Colours.Primary,
        borderWidth:  5,
        borderStyle:  'solid',
        borderColor:  Colours.Primary,
        borderRadius: '100%',
        height:       dimension,
        width:        dimension,
      }}
    >
      <div className="d-flex justify-content-center align-items-center flex-column">
        <Typography className="text-black" variant="h4">{totalCount}</Typography>

        { !IsEmpty(selectable)
          ? (
            <FormControl variant="standard">
              <select
                onChange={handleChange}
                style={{ textAlign: 'center', width: 100, fontSize: 13, border: 0, marginTop: 15, color: Colours.Gray1, outline: 'none' }}
              >
                {
                  _.map(selectable, (value) => <option key={value} value={value}>{value}</option>)
                }
              </select>
            </FormControl>
          )
          : <Typography className="mt-3" color="inherit" style={{ color: Colours.Gray1 }} variant="body2">{title}</Typography>}
      </div>
    </div>
  );
}

TotalCircleComp.propTypes = {
  totalCount:  PropTypes.number,
  title:       PropTypes.string,
  dimension:   PropTypes.string,
  selectable:  PropTypes.arrayOf(PropTypes.string),
  setDuration: PropTypes.func,
};

TotalCircleComp.defaultProps = {
  totalCount:  0,
  title:       'Total',
  dimension:   '150px',
  selectable:  [],
  setDuration: () => null,
};

export default TotalCircleComp;
