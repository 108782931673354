import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import  { WindowSize, Url } from '../../common/constants';

import Colours from '../../styles/colours';
import AppMenuSidebar from '../../components/Sidebar/AppMenuSidebar';
import Logout from './Logout';
import BusinessRoutes from './Routes';
import MobileSideBar from '../../components/Sidebar/MobileSideBar';

import ApiService from '../../services/apiService';

import businessAction from '../../actions/business';
import inviteAction from '../../actions/invite';
import pageClaimAction from '../../actions/pageClaim';
import pageAction from '../../actions/page';
import layoutAction from '../../actions/layout';
import SlidingPanel from '../../components/Common/SlidingPanel';
import SlidingPanelMobile from '../../components/Common/SlidingPanelMobile';
import MobileHeader from '../../components/MobileHeader';
import { IsEmpty } from '../../common/checks';
import Icons from '../../components/Common/Icons';

const BusinessContainer = ({
  dispatchBusinessesAccess,
  dispatchInvites,
  dispatchInviteSelected,
  dispatchPageClaims,
  dispatchPageClaimSelected,
  dispatchPageSelected,
  dispatchPagesAccess,
  dispatchSideBarShow,
  dispatchSideBarHide,
  dispatchWindowSizeChanged,
  match,
  history,
  auth,
  layout,
}) => {
  const [isUserAccountLoaded, setIsUserAccountLoaded] = useState(false);
  const [menuStyle, setMenuStyle] = useState({});

  // listen for window size changes and update the style of the menu side bar
  useEffect(() => {
    if (layout.windowSize === WindowSize.Mobile) {
      setMenuStyle({ top: 0, left: 0, paddingTop: '17vw', zIndex: 10, height: '100vh' });
    } else {
      setMenuStyle({ top: 0, left: 0, zIndex: 10, height: '100vh' });
    }
  }, [layout.windowSize]);

  useEffect(() => {
    function handleResize() {
      if (auth.isAuthenticated && window.innerWidth > 768) {
        // If viewing on large screen, always show the sidebar
        dispatchSideBarShow();
        dispatchWindowSizeChanged(WindowSize.Desktop);
      } else {
        dispatchSideBarHide();
        dispatchWindowSizeChanged(WindowSize.Mobile);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        // fetch the businesses and pages the user can manage
        ApiService.getUserManagedAccounts()
          .then((data) => {
            const { pages, invites, pageClaims } = data;
            dispatchPagesAccess(pages);
            dispatchInvites(invites);
            dispatchPageClaims(pageClaims);

            // in the event user refreshes the browser,
            // we need to find out what is the url path to go to
            // and set the pages specific to the business in redux
            // E.g user refreshes url /page/:pageId
            if (!IsEmpty(match.params.pageId)) {
              for (const page of pages) {
                if (String(page.id) === String(match.params.pageId)) {
                  dispatchPageSelected(page);
                  break;
                }
              }
            }

            if (!IsEmpty(match.params.inviteId)) {
              for (const invite of invites) {
                if (String(invite.id) === String(match.params.inviteId)) {
                  dispatchInviteSelected(invite);
                  break;
                }
              }
            }

            if (!IsEmpty(match.params.pageClaimId)) {
              for (const pageClaim of pageClaims) {
                if (String(pageClaim.id) === String(match.params.pageClaimId)) {
                  dispatchPageClaimSelected(pageClaim);
                  break;
                }
              }
            }

            // When a user reload the page we will check if pageId exist in our page(s)
            // If not found, we will redirect the user to the /business route.
            // If props pageId exist on of the the pages and '/page/:pageId' path found on props match.path
            // then dont need to redirect otherwise redirect.
            const matchedPageId = Number(match.params.pageId);
            const isExist =  pages.map((item) => item.id).includes(matchedPageId);
            const isPageSubBarRoute = match.path.includes('/page/:pageId');

            if (!isExist && isPageSubBarRoute) {
              history.push(Url.Business);
            } else if (match.path === '/business' && IsEmpty(pages) && IsEmpty(invites) && IsEmpty(pageClaims)) {
              // auto route to create page claim for new user logging in
              history.push(Url.CreatePageClaim);
            }

            setIsUserAccountLoaded(true);
          })
          .catch((err) => {
            console.log('Error retrieving getUserManagedAccounts(): ', err);
            toast.error(err.message);
          });
      } else {
        // user is not authenticated or has logged out,
        // clear all the businesses and pages data managed by the user in redux state
        dispatchBusinessesAccess([]);
        dispatchPagesAccess([]);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, [auth.isAuthenticated]);

  const renderBusinessRouteSite = () => (
    <div
      className="flex-grow-1 d-flex flex-column"
      style={{
        margin:             0,
        padding:            0,
        overscrollBehavior: 'contain',
        overflow:           'auto',
      }}
    >
      {
        isUserAccountLoaded && <BusinessRoutes match={match} history={history} />
      }
    </div>
  );

  const renderHeader = () => (
    <div
      className="d-flex flex-row mr-2 align-items-end mx-2 ml-3 mt-1"
      style={{ backgroundColor: Colours.LightGray, borderRadius: '10px', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}
    >
      <div
        className="d-flex flex-column"
        style={{
          // padding:         '5px',
          height:     '60px',
          alignItems: 'center',
        }}
      >
        <Icons.Menu style={{ height: '60px', width: '60px' }} />
      </div>
      <Typography className="ml-2" variant="inherit" style={{ fontSize: '1.8rem', fontWeight: 'bolder' }}>Monstyr Dashboard</Typography>
    </div>
  );

  return auth.isAuthenticated
    ? (
      <div
        className="container-fluid d-flex"
        style={{
          flexDirection:   'column',
          backgroundColor: Colours.DefaultBkgrd,
          height:          '100%',
          paddingRight:    0,
          paddingLeft:     0,
          justifyContent:  'flex-start',
        }}
      >
        {
          (layout.windowSize === WindowSize.Desktop)
            ? (
              <>
                { renderHeader() }

                <div className="mx-2 ml-3 flex-grow-1 d-flex flex-row" style={{ position: 'fixed', top: '60px', left: 0, right: 0, bottom: 0  }}>
                  <AppMenuSidebar
                    match={match}
                    // style={menuStyle}
                  />
                  {renderBusinessRouteSite()}
                </div>
                <SlidingPanel />
              </>
            )
            : (
              <>
                <div className="d-flex flex-column">
                  <MobileHeader />
                  {
                    layout.showSideBar && (
                      <MobileSideBar
                        match={match}
                        history={history}
                        style={menuStyle}
                      />
                    )
                  }
                  {renderBusinessRouteSite()}
                </div>
                <SlidingPanelMobile />
              </>
            )
        }

        <Logout />
      </div>
    )
    : (
      <Redirect to={Url.Login} />
    );

  // return auth.isAuthenticated
  //   ? (
  //     <div
  //       className="container-fluid d-flex"
  //       style={{
  //         flexDirection:   layout.windowSize === WindowSize.Mobile ? 'column' : 'row',
  //         backgroundColor: Colours.DefaultBkgrd,
  //         height:          '100%',
  //         paddingLeft:     0,
  //         paddingRight:    0,
  //         justifyContent:  'flex-start',
  //       }}
  //     >
  //       {
  //         (layout.windowSize === WindowSize.Desktop)
  //           ? (
  //             <AppMenuSidebar
  //               match={match}
  //               style={menuStyle}
  //             />
  //           )
  //           : layout.showSideBar && (
  //             <MobileSideBar
  //               match={match}
  //               history={history}
  //               style={menuStyle}
  //             />
  //           )
  //       }

  //       {
  //         layout.windowSize === WindowSize.Mobile && auth.isAuthenticated
  //         && (
  //           <>
  //             <MobileHeader />
  //           </>
  //         )
  //       }

  //       <div
  //         className="flex-grow-1 d-flex"
  //         style={{
  //           margin:             0,
  //           padding:            0,
  //           overscrollBehavior: 'contain',
  //           overflow:           'auto',
  //         }}
  //       >
  //         {
  //           isUserAccountLoaded && <BusinessRoutes match={match} history={history} />
  //         }
  //         <SlidingPanel />
  //         <SlidingPanelMobile />
  //       </div>

  //       <Logout />
  //     </div>
  //   )
  //   : (
  //     <Redirect to={Constants.Url.Login} />
  //   );
};

BusinessContainer.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    token:           PropTypes.string,
  }).isRequired,

  dispatchBusinessesAccess: PropTypes.func.isRequired,

  dispatchInvites:        PropTypes.func.isRequired,
  dispatchInviteSelected: PropTypes.func.isRequired,

  dispatchPageClaims:        PropTypes.func.isRequired,
  dispatchPageClaimSelected: PropTypes.func.isRequired,

  dispatchPageSelected: PropTypes.func.isRequired,
  dispatchPagesAccess:  PropTypes.func.isRequired,

  dispatchSideBarHide:       PropTypes.func.isRequired,
  dispatchSideBarShow:       PropTypes.func.isRequired,
  dispatchWindowSizeChanged: PropTypes.func.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      businessId:  PropTypes.string,
      pageClaimId: PropTypes.string,
      pageId:      PropTypes.string,
      inviteId:    PropTypes.string,
    }),
    path: PropTypes.string,
  }).isRequired,

  layout: PropTypes.shape({
    showSideBar: PropTypes.bool.isRequired,
    windowSize:  PropTypes.string.isRequired,
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

};

const mapStateToProps = (state) => ({
  auth:   state.auth,
  layout: state.layout,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchBusinessesAccess: (businesses) => businessAction.businessesAccess(dispatch, businesses),

  dispatchInvites:        (invites) => inviteAction.invites(dispatch, invites),
  dispatchInviteSelected: (invites) => inviteAction.inviteSelected(dispatch, invites),

  dispatchPageClaims:        (pageClaims) => pageClaimAction.pageClaims(dispatch, pageClaims),
  dispatchPageClaimSelected: (pageClaim) => pageClaimAction.pageClaimSelected(dispatch, pageClaim),

  dispatchPageSelected: (page) => pageAction.pageSelected(dispatch, page),
  dispatchPagesAccess:  (pages) => pageAction.pagesAccess(dispatch, pages),

  dispatchSideBarHide:       () => layoutAction.sideBarHide(dispatch),
  dispatchSideBarShow:       () => layoutAction.sideBarShow(dispatch),
  dispatchWindowSizeChanged: (windowSize) => layoutAction.windowSizeChanged(dispatch, windowSize),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessContainer);
