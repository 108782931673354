import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Checkbox from '../../../../components/Common/form/CheckBox';
import Select from '../../../../components/Common/form/Select';
import SelectTo from '../../../../components/Common/form/SelectTo';
import { TIME_INTERVALS } from '../../../../common/time-intervals';
import Colours from '../../../../styles/colours';
import { IsEmpty } from '../../../../common/checks';
import Icons from '../../../../components/Common/Icons';

function Exceptions({ pageId, params, location, outlet, handleExceptionsChange, setdefaultRemovel }) {
  const [startDate, setStartDate] = useState(new Date());
  const [exceptions, setExceptions] = useState(outlet && outlet.openingHours ? outlet.openingHours.exceptions : []);

  useEffect(() => {
    /**
     * When reload , we need to check outlet changes and update state.
     */
    const exceptionData = outlet && outlet.openingHours ? outlet.openingHours.exceptions : [];
    setExceptions(exceptionData);
  }, [outlet.openingHours]);

  useEffect(() => {
      handleExceptionsChange(exceptions);
  }, [exceptions]);

  const checkDateAlreadyExist = (items) => {
    const selectedDate = moment(startDate).format('DD-MM-YYYY');
    const foundDate = items.find((each) => each.date === selectedDate);
    if (foundDate) {
      toast.warn('Date Already Added');
      return false;
    } else {
      return true;
    }
  };

  // exceptions handlers
  const pushException = (item) => {
    setExceptions((prevState) => ([...prevState, item]));
  };

  const removeException = (date) => {
    setExceptions((prevState) => ([...prevState.filter((item) => item.date !== date)]));
  };

  // interval handlers
  const pushInterval = (interval, date) => {
    setExceptions((prevState) => prevState.map((exception) => {
      if (exception.date === date) {
        return {
          ...exception,
          hours: [...exception.hours, interval],
        };
      } else {
        return {
          ...exception,
          hours: [...exception.hours],
        };
      }
    }));
  };

  const removeInterval = (exceptionIndex, hourIndex) => {
    setExceptions((prevState) => prevState.map((exception) => ({
      ...exception,
      hours: exception.hours.filter((item) => item !== prevState[exceptionIndex].hours.slice(hourIndex)[0]),
    })));
  };

  const checkDefaultInterval = (opHourIndex, hourIndex, hourItem) => {
    const isDefaultInterval = hourItem.from === '00:00 AM' && hourItem.to === '00:00 AM';
    if (isDefaultInterval) {
      setExceptions((prevState) => {
        prevState[opHourIndex].is24Hours = !isDefaultInterval;
        return [
          ...prevState,

        ];
      });
      setdefaultRemovel(!isDefaultInterval);
    }
  };

  const handleCheckboxChange = (value, exceptionIndex) => {
    setExceptions((prevState) => {
      prevState[exceptionIndex].isOpen = value;
      return [
        ...prevState,

      ];
    });
  };

  const handleSelectChange = ({
    value,
    exceptionIndex,
    hourIndex,
    type,
  }) => {
    setExceptions((prevState) => {
      prevState[exceptionIndex].hours[hourIndex][type] = value;
      return [
        ...prevState,
      ];
    });
  };

  return (
    <div className="container-fluid p-1">
      <Paper elevation={1} className="col-12 col-lg-9 col-xl-9 col-md-9 p-2">
        <div className="p-3">
          <div className="d-flex flex-column">
            <Typography variant="body2" style={{ fontWeight: 'bold' }}>
              Indicate exception opening hours here.
            </Typography>
            <Typography className="mt-1" variant="body2" color="inherit" style={{ color: Colours.Gray  }}>
              Exceptions indicated here will over-write the hours stated in Opening Hours Tab on the particular date.
            </Typography>

            <div className="d-flex my-3  align-items-center">
              <Typography variant="body2" style={{ fontWeight: 'bold' }} className="my-3">Select Date </Typography>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="px-2 mx-3"
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                style={{
                  padding: '10px',
                }}
              />
              <Button
                className="px-2 ml-3"
                type="button"
                variant="contained"
                size="small"
                style={{
                  color:           Colours.White,
                  backgroundColor: Colours.Primary,
                  width:           '100px',
                }}
                onClick={() => {
                  if (exceptions.length > 9) {
                    toast.info('Maximum 10 exceptions reached. Please remove existing exceptions to add new exception.');
                  } else if (checkDateAlreadyExist(exceptions)) {
                    pushException(
                      {
                        date:      moment(startDate || new Date()).format('DD-MM-YYYY'),
                        isOpen:    true,
                        is24Hours: false,
                        hours:     [],
                      },
                    );
                  }
                }}
              >
                Add
              </Button>
            </div>
            <Typography className="mt-2 ml-3" variant="body2">
              You can specify up to
              {' '}
              <span style={{ fontWeight: 'bold' }}>10 exceptions</span>
              . Delete those you don&apos;t need.
              {' '}
            </Typography>
          </div>

          <div className="mt-2">
            {
              exceptions && exceptions.map((exception, index) => (
                <Paper
                  className="p-3 m-3 d-flex flex-row justify-content-start align-items-start"
                  elevation={2}
                  key={index}
                >
                  <div className="mx-3 pt-2">
                    <Checkbox
                      name={`exceptions.${index}.isOpen`}
                      value={exceptions[index].isOpen}
                      handleChange={(value) => {
                      handleCheckboxChange(value, index);
                      }}
                      style={{
                        color:        Colours.Primary,
                        borderRadius: '50%',
                        transform:    'scale(1.3)',
                      }}
                    />
                  </div>

                  <div className="ml-4 mr-2" style={{ paddingTop: '20px', width: '100px' }}>
                    <Typography variant="body2">
                      {exceptions && exceptions[index].date}
                    </Typography>
                  </div>

                  {
                    exceptions[index].isOpen ? (
                      <div className="flex-grow-1 d-flex flex-column align-items-center">
                        {exceptions[index].hours && exceptions[index].hours.map(
                           (value, val_index) => {
                             const lastTimeIntervalTo =  exceptions[index].hours.slice(-1)[0].to;
                             const indexOfLastTimeIntervalTo = TIME_INTERVALS.find((item) => item.value === lastTimeIntervalTo).index || 0;
                             return (
                               <div
                                 className="pl-1 pr-2 mb-2 d-flex flex-row justify-content-center align-items-center"
                                 key={`${Math.random()}${val_index}`}
                                 style={{
                                   border:       '1.4px solid #555555',
                                   borderRadius: '20px',
                                 }}
                               >
                                 <Select
                                   name={`exceptions.${index}.hours.${val_index}.from`}
                                   hours={exceptions[index].hours || []}
                                   options={TIME_INTERVALS}
                                   index={indexOfLastTimeIntervalTo}
                                   value={exceptions[index].hours[val_index].from}
                                   handleChange={(e) => {
                                     handleSelectChange({
                                       value:          e.target.value,
                                       exceptionIndex: index,
                                       hourIndex:      val_index,
                                       type:           'from',
                                     });
                                   }}
                                   className="px-4 py-2 m-1"
                                   style={{
                                     color:   Colours.Primary,
                                     border:  '0px',
                                     outline: '0px',
                                   }}
                                 />
                                 <Typography className="ml-3 mr-2" variant="body2" color="inherit" style={{ color: Colours.Gray }}>to</Typography>
                                 <SelectTo
                                   name={`exceptions.${index}.hours.${val_index}.to`}
                                   options={TIME_INTERVALS}
                                   index={indexOfLastTimeIntervalTo}
                                   hours={exceptions[index].hours || []}
                                   value={exceptions[index].hours[val_index].to}
                                   handleChange={(e) => {
                                     handleSelectChange({
                                       value:          e.target.value,
                                       exceptionIndex: index,
                                       hourIndex:      val_index,
                                       type:           'to',
                                     });
                                   }}
                                   className="pl-2 pr-4 py-2 m-1"
                                   style={{
                                     color:   Colours.Primary,
                                     border:  '0px',
                                     outline: '0px',

                                   }}
                                 />
                                 <div>
                                   <IconButton
                                     aria-label="remove hours"
                                     onClick={() => {
                                      checkDefaultInterval(index, val_index, exceptions[index].hours[val_index]);
                                       removeInterval(index, val_index);
                                     }}
                                   >
                                     <Icons.Cross fontSize="1.5rem" />
                                   </IconButton>
                                 </div>
                               </div>
                             );
                           },
                                  )}
                        <Button
                          className="px-3"
                          variant="text"
                          style={IsEmpty(exceptions[index].hours) ? { marginTop: '15px' } : {}}
                          onClick={() => {
                            if (exceptions[index].hours.length > 0) {
                              const lastTimeIntervalTo =  exceptions[index].hours.slice(-1)[0].to;
                              const indexOfLastTimeIntervalTo = TIME_INTERVALS.find((item) => item.value === lastTimeIntervalTo).index || 0;
                              if (lastTimeIntervalTo === '00:00 AM' || lastTimeIntervalTo === '23:30 PM' || lastTimeIntervalTo === '23:00 PM') {
                                toast.info('You reach the maximum hour interval');
                              } else {
                                pushInterval(
                                  { from: TIME_INTERVALS[indexOfLastTimeIntervalTo + 1].value || '09:00 AM',
                                    to:   TIME_INTERVALS[indexOfLastTimeIntervalTo + 2].value || '21:00 PM',
                                  },
                                  exceptions[index].date,
                                );
                              }
                            } else {
                              pushInterval(
                                { from: '09:00 AM',
                                  to:   '21:00 PM',
                                },
                                exceptions[index].date,
                              );
                            }
                          }}
                        >
                          <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>+ hours</Typography>
                        </Button>
                      </div>
                    ) : (
                      <div className="flex-grow-1 d-flex flex-column align-items-center" style={{ paddingTop: '20px' }}>
                        <Typography variant="body2" color="primary" style={{ color: Colours.Primary, fontWeight: 'bold' }}>
                          CLOSED
                        </Typography>
                      </div>
                    )
                  }

                  <div>
                    <IconButton
                      aria-label="remove hours"
                      onClick={() => {
                        removeException(exceptions[index].date);
                      }}
                    >
                      <Icons.Cross fontSize="1.5rem" />
                    </IconButton>
                  </div>

                </Paper>
              ))
            }
          </div>
        </div>

      </Paper>
    </div>

  );
}

Exceptions.propTypes = {
  pageId: PropTypes.number.isRequired,
  params: PropTypes.shape({
    action: PropTypes.string,
    view:   PropTypes.string,

  }).isRequired,
};

export default Exceptions;
