// React Dependencies
import React, { useEffect, useState } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// Internal Dependencies
import PaymentHistory from './PaymentHistory';

import ApiService from '../../../services/apiService';

import { containerHeaderTextStyle } from '../../../styles/common';

const Account = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [plan, setPlan] = useState(null);

  const fetchPage = async () => {
    try {
      setPlan(props.selectedPage.plan);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchPage();
  }, []);

  const handlePay = async (paymentPlan, amount) => {
    try {
      const stripeSession = await ApiService.createPaymentSession(
        { paymentPlan, amount, pageId: props.selectedPage.id, redirectUrl: window.location.href },
      );
      window.location.href = stripeSession.url;
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <div className="m-3 mt-4 ml-4">
        <div className="row">
          <div className="d-flex flex-row col-12">
            <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Account & Billing</Typography>
          </div>
          <div className="mt-3 col-12 d-flex flex-row">
            <Typography variant="body1">Current Plan: </Typography>
            {
              isLoaded
                ? <Typography className="ml-2 font-weight-bold" variant="body1">{plan}</Typography>
                : <CircularProgress className="ml-2 mt-1" size="1.2rem" />
            }
          </div>
        </div>

        {/** Uncomment when we enable payment */}
        {/* <div className="row">
          <div className="mt-3 col-12 d-flex flex-row">
            <Box component="span" m={1}>
              <Typography variant="body1">Payment $5 </Typography>
              <Button
                variant="outlined"
                color="default"
                className="px-4 mr-3"
                onClick={() => handlePay('payment_5', 5)}
              >
                Pay $5
              </Button>
            </Box>
          </div>
        </div> */}
      </div>

      {/** Uncomment when we enable payment */}
      {/* <PaymentHistory /> */}
    </>
  );
};

Account.propTypes = {
  selectedPage: PropTypes.shape({
    id:        PropTypes.number,
    plan:      PropTypes.string,
    staffType: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  selectedPage: state.page.selected,
});

export default connect(mapStateToProps, null)(Account);
