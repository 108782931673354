import { IsEmpty } from '../common/checks';
import Constants from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  selected: {},
  list:     [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.ReviewSelected:
      return {
        ...state,
        selected: IsEmpty(payload)
          ? {}
          : payload,
      };

    case ReduxAction.ReviewDataUpdate:
      return {
        ...state,
        selected: {
          ...state.selected,
          /**
           * 'payload' to contain the fields we are updating.
           * This allows us to update specific info
           *
           * E.g
           * payload = {
           *    title: '',
           * }
           *
           */
          ...payload,
        },
      };

    default:
      return state;
  }
}
