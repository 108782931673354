// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';

// Internal Dependencies
import SortableCard from './SortableCard';
import ToolTip from '../../../components/Common/ToolTip';
import PageHighlightConst from './PageHighlightConst';

const { statusesAvailToSet } = PageHighlightConst;

function PostsMainView({ pageHighlight, title, sortableCardRef, onEvent, onClickAdd, onDelete, onUpdatePosType, cards, isSortable, cardType }) {
  return (
    <div className="mt-2 mx-4">
      <div className="d-flex">
        <Typography variant="h4" style={{ fontSize: 18, fontWeight: 'bold' }}>
          {title}
        </Typography>

        <ToolTip
          title={!statusesAvailToSet.includes(pageHighlight?.status) ? `Cannot add post to highlight because highlight's status is '${pageHighlight?.status}'` : ''}
        >
          <Button
            variant="contained"
            className="position-relative ml-3 mb-3"
            style={{ top: -7 }}
            color="primary"
            onClick={onClickAdd}
            disabled={!statusesAvailToSet.includes(pageHighlight?.status)}
          >
            Add Post
          </Button>
        </ToolTip>
      </div>

      <div className="mx-3">
        <SortableCard
          ref={sortableCardRef}
          onEvent={onEvent}
          onDelete={(postId) => onDelete(postId)}
          onUpdatePosType={(data) => onUpdatePosType(data)}
          cards={cards}
          isSortable={isSortable}
          cardType={cardType}
        />
      </div>
    </div>
  );
}

export default PostsMainView;

PostsMainView.propTypes = {
  pageHighlight: PropTypes.shape({
    status: PropTypes.string,
  }),
  title:           PropTypes.string.isRequired,
  sortableCardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape(Object)]),
  cards:           PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  isSortable:      PropTypes.bool,
  cardType:        PropTypes.string.isRequired,

  onClickAdd:      PropTypes.func.isRequired,
  onDelete:        PropTypes.func.isRequired,
  onEvent:         PropTypes.func,
  onUpdatePosType: PropTypes.func,
};

PostsMainView.defaultProps = {
  pageHighlight:   {},
  sortableCardRef: null,
  isSortable:      false,
  onEvent:         () => {},
  onUpdatePosType: () => {},
};
