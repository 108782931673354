import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Button, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import TextInputMU from '../../../components/Common/form/TextInputMU';
import Colours from '../../../styles/colours';
import { ChangePasswordSchema } from '../../../common/validationSchemas';
import { WindowSize } from '../../../common/constants';
import { Trim } from '../../../common/util';
import ApiService from '../../../services/apiService';

const ChangePasswordForm = (props) => {
  const password = {
    current: '',
    newPass: '',
    retype:  '',
  };
  return (
    <Formik
      className="d-flex"
      enableReinitialize
      initialValues={password}
      validationSchema={ChangePasswordSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        await ApiService.changePassword(values).catch((error) => {
          toast.error(error.message);
        }).then((res) => {
          if (res) {
            toast.success('Your password has changed');
            resetForm();
            setSubmitting(false);
          }
        });
      }}

    >
      {({ isSubmitting, dirty }) => (

        <Form className="mt-2 pb-5">
          <Paper className="p-2 my-2">
            <Typography className="mt-3 ml-3" variant="h5" component="h5" gutterBottom>Change Password</Typography>

            <div className="p-3">
              <label>Current Password</label>
              <Field
                name="current"
                type="password"
                style={{
                  width: '100%',
                }}
                onChange={(e) => {
                  e.target.value = Trim(e.target.value);
                }}
                component={TextInputMU}
              />
              <ErrorMessage
                name="current"
                render={(msg) => (
                  <span style={{
                    color:    'red',
                    fontSize: '0.7rem',
                  }}
                  >
                    {msg}
                  </span>
                )}
              />
            </div>
            <div className="p-3">
              <label>New Password </label>
              <Field
                name="newPass"
                type="password"
                style={{
                  width: '100%',
                }}
                onChange={(e) => {
                  e.target.value = Trim(e.target.value);
                }}
                component={TextInputMU}
              />
              <ErrorMessage
                name="newPass"
                render={(msg) => (
                  <span style={{
                    color:    'red',
                    fontSize: '0.7rem',
                  }}
                  >
                    {msg}
                  </span>
                )}
              />
            </div>

            <div className="p-3">
              <label>Retype Password</label>
              <Field
                onChange={(e) => {
                  e.target.value = Trim(e.target.value);
                }}
                component={TextInputMU}
                name="retype"
                type="password"
                style={{
                  width: '100%',
                }}

              />
              <ErrorMessage
                name="retype"
                render={(msg) => (
                  <span style={{
                    color:    'red',
                    fontSize: '0.7rem',
                  }}
                  >
                    {msg}
                  </span>
                )}
              />
            </div>
            <div
              className="p-2 my-2"
              style={{
                display:        'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                className="px-5"
                style={{
                  color: Colours.White,
                  width: (props.windowSize === WindowSize.Mobile) && '100%',
                }}
              >
                {isSubmitting ? 'Please Wait' : 'Update'}
              </Button>
            </div>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

ChangePasswordForm.propTypes = {
  windowSize: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(ChangePasswordForm);
