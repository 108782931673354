import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Input, Typography } from '@material-ui/core';
import Colours from '../../../styles/colours';
import ApiService from '../../../services/apiService';
import { IsEmpty } from '../../../common/util';
import pageAction from '../../../actions/page';
import Tooltip from '../../../components/Common/ToolTip';
import Icons from '../../../components/Common/Icons';
import OverLoading from '../../../components/Loading';

const PageLogo = (props) => {
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState('');

  useEffect(() => {
    setPreview(props.currentPhoto || '');
  }, [props]);

  const handleUpload = async (file) => {
    try {
      if (file) {
        setLoading(true);
        const formData = new FormData();
        formData.append('image', file);
        formData.append('mediaType', 'Logo');

        const cloudfrontLink = await ApiService.uploadPageMedia(props.pageId, formData);

        if (cloudfrontLink && cloudfrontLink.length > 0) {
          props.setLogo(cloudfrontLink);
          const pageIndex = props.pages.findIndex((el) => el.id === props.page.id);
          props.pages[pageIndex].pageLogo = cloudfrontLink;
          props.dispatchPagesAccess(props.pages);
          props.dispatchPageSelected({ ...props.page, pageLogo: cloudfrontLink });

          toast.success('Image uploaded successfully.');
        } else {
          toast.error('An unexpected error has occurred');
        }
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await ApiService.deletePageMedia(props.pageId,  { mediaData: props.currentPhoto, mediaType: 'logo' });
      if (response === 'Success') {
        setPreview('');
        props.setLogo('');
        const pageIndex = props.pages.findIndex((el) => el.id === props.page.id);
        props.pages[pageIndex].pageLogo = null;
        props.dispatchPagesAccess(props.pages);
        props.dispatchPageSelected({ ...props.page, pageLogo: null });
      } else {
        toast.error('Error occured while deleting image');
      }
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const handleImageSelect = async (evt) => {
    try {
      const { files } = evt.target;

      if (files) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          const tmp = {
            file: files[0],
            name: files[0].name,
            type: files[0].type,
            uri:  reader.result,
          };

          setPreview(tmp.uri);
        });

        if (!IsEmpty(props.currentPhoto) && !IsEmpty(files[0])) {
          // delete the current one.
          await handleDelete();

          // upload the new image.
          await handleUpload(files[0]);
        } else {
          // just perform normal upload since no prev image.
          await handleUpload(files[0]);
        }
        if (files[0]) {
          reader.readAsDataURL(files[0]);
        }
      }
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <>
      { loading && <OverLoading />}

      { preview && (
        <>
          <div className="d-flex align-items-center justify-content-center text-center mx-auto" style={{ background: Colours.Black, overflow: 'hidden', position: 'relative', width: 200, height: 200, border: `1px solid ${Colours.Gray3}`, borderRadius: 200   }}>
            <img src={preview} alt="logo" className="d-block mx-auto" style={{ width: 200, height: 'auto', maxWidth: '100%'  }} />
          </div>
        </>
      )}

      <label htmlFor="file-selector" className="d-block mx-auto">
        <Input
          id="file-selector"
          type="file"
          inputProps={{ multiple: false, accept: ['image/jpg', 'image/jpeg', 'image/png'] }}
          onChange={(e) => handleImageSelect(e)}
          style={{ display: 'none' }}
          disabled={props.disabled}
        />

        {!preview ? (
          <>
            <Tooltip
              title={!props.disabled ? '' : 'Permission Denied'}
              isLink
            >
              <div
                className="d-flex mx-auto text-center"
                style={{
                  cursor:       !props.disabled && 'pointer',
                  marginTop:    10,
                  alignItems:   'center',
                  width:        200,
                  height:       200,
                  border:       `1px dotted ${Colours.Gray2}`,
                  borderWidth:  2, borderRadius: 5,
                }}
              >
                <div style={{ width: '100%' }}>
                  <Icons.Wallpaper fontSize="50px" />
                  <p className="d-block mt-3">+ Upload Logo</p>
                </div>
              </div>
            </Tooltip>
          </>
        )
          : (
            <>
              <div className="d-block mx-auto mt-3 text-center" style={{ cursor: 'pointer' }}>
                <Tooltip
                  title={!props.disabled ? '' : 'Permission Denied'}
                  isLink
                >
                  <Typography variant="subtitle1">Change Logo</Typography>
                </Tooltip>
              </div>
            </>
          )}
      </label>
    </>
  );
};

PageLogo.propTypes = {
  pageId:               PropTypes.number.isRequired,
  currentPhoto:         PropTypes.string.isRequired,
  setLogo:              PropTypes.func.isRequired,
  page:                 PropTypes.objectOf(PropTypes.any).isRequired,
  pages:                PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatchPageSelected: PropTypes.func.isRequired,
  dispatchPagesAccess:  PropTypes.func.isRequired,
  disabled:             PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  page:  state.page.selected,
  pages: state.page.pages,

});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageSelected: (page) => pageAction.pageSelected(dispatch, page),
  dispatchPagesAccess:  (pages) => pageAction.pagesAccess(dispatch, pages),

});

export default connect(mapStateToProps, mapDispatchToProps)(PageLogo);
