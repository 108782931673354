import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Chip, Tooltip, Typography } from '@material-ui/core';
import PostAction from '../../../../actions/post';
import ApiService from '../../../../services/apiService';
import Colours from '../../../../styles/colours';
import postStyle from '../../../../styles/post';
import Icons from '../../../../components/Common/Icons';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#000',
    color:           '#FFF',
    maxWidth:        220,
    marginTop:       -20,
    fontSize:        theme.typography.pxToRem(15),
    border:          '1px solid #dadde9',
  },
}))(Tooltip);

const checkStyleOn = {
  position:     'absolute',
  right:        5,
  top:          5,
  background:   '#FFF',
  color:        Colours.Primary,
  borderRadius: '100%',
  // boxShadow:    '0px 0px 1px 1px rgb(222, 0, 111)',
};

const checkStyleOff = {
  position:     'absolute',
  right:        5,
  top:          5,
  background:   'rgb(255 255 255 / 0.70)',
  color:        Colours.Gray3,
  borderRadius: '100%',
  borderColor:  Colours.Black,
};

const selectedItems = [];
const groupCreditCards = [];
const totalCreditCards = [];

class PromoCreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCreditCards:      [],
      parentCreditCards:     [],
      groupChildCardDisplay: [],
      chipSelectedIndex:     0,
    };
  }

  componentDidMount() {
    if (this.props.page.parentId === null && (this.props.page.type !== 'Credit Card' || this.props.page.parentId !== null) && this.props.page.type !== 'Credit Card') {
      // IS PARENT/NON-PARENT && NON-CC PAGE TYPE
      const fetchAllCreditCards = async () => {
        const fetchAllParentPages = await ApiService.getParents(); //this.props.getParents();
        const parentCreditCards = fetchAllParentPages.length > 0 ? _.filter(fetchAllParentPages, (data) => data.type === 'Credit Card') : [];
        const creditCardPagesData = this.props.post.promoCreditCards; //this.props.promoCreditCardsData;

        // set credit cards parent pages
        this.setState({ parentCreditCards });

        if (parentCreditCards.length > 0) {
          const self = this;
          _.map(this.state.parentCreditCards, (creditCard) => {
            const promise = new Promise((resolve) => {
              const res = ApiService.getCreditCardPagesUnderPage(creditCard.id); //self.props.getCreditCardPagesUnderPage(creditCard.id);
              resolve(res);
            });

            promise.then((data) => {
              const key = creditCard.name;
              const findObj = _.some(groupCreditCards, { [key]: data });
              if (findObj === false) {
                groupCreditCards.push({ [key]: data });
                totalCreditCards.push(data.length);
                //self.props.dispatchPostUpdate({ promoCreditCards: selectedItems });
                self.onCreditCardChanged(selectedItems);
              }

              // populate the default display of ccards on load.
              _.map(groupCreditCards[0], (cc) => {
                self.setState({ groupChildCardDisplay: cc });
              });
            });
          });

          // pass a postId to fetch existing data.
          if (creditCardPagesData.length > 0) {
            _.forEach(creditCardPagesData, (value) => {
              // check if fetch data exist in state if yes, push as Selected item.
              const checkIfFetchDataExist = _.some(creditCardPagesData, { id: value.id, name: value.name });
              if (checkIfFetchDataExist) {
                if (_.some(selectedItems, { id: value.id, name: value.name }) === false) {
                  selectedItems.push({ parentId: value.parentId, id: value.id, name: value.name, logo: value.logo });
                }
              }
              //self.props.dispatchPostUpdate({ promoCreditCards: selectedItems });
              self.onCreditCardChanged(selectedItems);
            });
          }
        }
      };

      fetchAllCreditCards(this.props.page.id);
    } else {
      // IS PARENT && CC PAGE TYPE
      const fetchCreditCards = async (id) => {
        const allCreditCardsPagesByCCParent = await ApiService.getCreditCardPagesUnderPage(id); //this.props.getCreditCardPagesUnderPage(id);
        const data = _.map(allCreditCardsPagesByCCParent, _.partialRight(_.pick, ['id', 'name', 'logo']));
        const creditCardPagesData = this.props.promoCreditCardsData;

        // set credit cards data.
        this.setState({ promoCreditCards: data });

        if (data.length > 0) {
          const self = this;
          // pass a postId to fetch existing data.
          if (creditCardPagesData.length > 0) {
            _.forEach(creditCardPagesData, (value) => {
              // check if fetch data exist in state if yes, push as Selected item.
              const checkIfFetchDataExist = _.some(data, { id: value.id, name: value.name });
              if (checkIfFetchDataExist) {
                if (_.some(selectedItems, { id: value.id, name: value.name }) === false) {
                  selectedItems.push({ id: value.id, name: value.name, logo: value.logo });
                }
              }
              self.onCreditCardChanged(selectedItems);
            });
          }
        }
      };
      fetchCreditCards(this.props.page.id);
    }
  }

  onCreditCardChanged = (data) => {
    this.props.dispatchPostUpdate({ promoCreditCards: data });
  }

  handleSelected = (data) => {
    const isDataAlrSelected = _.some(selectedItems, { id: data.id, name: data.name });
    if (isDataAlrSelected === true) {
      _.remove(selectedItems, { id: data.id });
    } else {
      selectedItems.push(data);
    }

    this.onCreditCardChanged(selectedItems);
  }

  render() {
    const statePromoCreditCards = groupCreditCards.length > 0 ? groupCreditCards : this.state.promoCreditCards;

    return (
      <>
        <Typography variant="body1" color="inherit" className="ml-3 mb-3" style={{ color: Colours.Gray }}>
          {`Selected ( ${selectedItems.length} / ${groupCreditCards.length > 0 ? _.sum(totalCreditCards) : this.state.promoCreditCards.length} )`}
        </Typography>

        {groupCreditCards.length > 0
          ? (
            <div>
              <Paper variant="elevation" style={postStyle.paper}>
                {
                groupCreditCards.map((cc, index) => {
                  const parentCardName = _.keys(cc);

                  let count = 0;
                  _.map(cc, (c) => {
                    _.map(selectedItems, (item) => {
                      if (_.some(c, item)) {
                        count += 1;
                      }
                    });
                  });

                  return (
                    <Chip
                      key={index}
                      label={`${parentCardName} ${count > 0 ? `(${count})` : ''}`}
                      onClick={() => {
                        this.setState({
                          chipSelectedIndex:     index,
                          groupChildCardDisplay: cc[parentCardName],
                        });
                      }}
                      className="mr-3 mb-1"
                      style={{ borderColor: count > 0 ? Colours.Primary : Colours.White, borderWidth: '2px' }}
                      variant={
                        (this.state.chipSelectedIndex === index) ? 'default' : 'outlined'
                      }
                      color={this.state.chipSelectedIndex === index ? 'primary' : 'default'}
                    />
                  );
                })
                }

                <hr />

                <div className="container">
                  <div className="row" style={{ width: '100%' }}>
                    {
                    this.state.groupChildCardDisplay.map((card) => (
                      <>
                        <Tooltip title={this.props.disabled ? this.props.disabledMsg : card.name} key={card.id}>
                          <div
                            aria-hidden="true"
                            onClick={() => (this.props.disabled ? null : this.handleSelected(card))}
                            style={{ position: 'relative' }}
                            className="col-sm-6 col-md-4 col-lg-3 mb-5 pr-3"
                          >
                            <div style={{ position: 'relative', background: '#EEE', width: 100, height: 75, borderRadius: 5 }}>
                              <img src={card.logo} style={{ borderRadius: 5, cursor: 'pointer', width: 100, height: 75 }} alt="img" />
                              {
                              _.some(selectedItems, { id: card.id }) === true ? <Icons.Checked style={checkStyleOn} /> : <Icons.UnChecked style={checkStyleOff} />
                              }
                            </div>
                            <Typography variant="caption" color="inherit" style={{ display: 'block', marginTop: 10, color: Colours.Gray }}>{card.name}</Typography>
                          </div>
                        </Tooltip>
                      </>
                    ))
                    }
                  </div>
                </div>
              </Paper>
            </div>
          )

          : (
            <div>
              {statePromoCreditCards.length > 0
                ? (
                  <div className="row" style={{ width: '100%' }}>
                    {statePromoCreditCards.map((card, index) => (
                      <>
                        <Tooltip title={this.props.disabled ? this.props.disabledMsg : card.name} key={index}>
                          <div aria-hidden="true" onClick={() => this.handleSelected(card)} className="col-md-2 mb-5">
                            <div style={{ position: 'relative', background: '#EEE', width: 85, height: 85, borderRadius: 5 }}>
                              {card.logo && <img src={card.logo} alt={card.name} style={{ borderRadius: 5, cursor: 'pointer', width: 85, height: 'auto' }} />}
                              {_.some(selectedItems, { id: card.id, name: card.name }) === true ? <Icons.Checked style={checkStyleOn} /> : <Icons.UnChecked style={checkStyleOff} />}
                            </div>
                            <Typography variant="caption" color="inherit" style={{ display: 'block', marginTop: 10, color: Colours.Gray }}>{card.name}</Typography>
                          </div>
                        </Tooltip>
                      </>
                    ))}
                  </div>
                ) : 'There are no subsidiaries under this brand.'}
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  post: state.post.selected,
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

PromoCreditCard.defaultProps = {
  disabledMsg: '',
};

PromoCreditCard.propTypes = {
  dispatchPostUpdate:   PropTypes.func.isRequired,
  post:                 PropTypes.shape(Object).isRequired,
  page:                 PropTypes.shape(Object).isRequired,
  promoCreditCardsData: PropTypes.shape(Object).isRequired,
  disabled:             PropTypes.bool.isRequired,
  disabledMsg:          PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCreditCard);
