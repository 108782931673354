// React Dependencies
import React, { useState } from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Typography, TextField, FormControl, Select, makeStyles } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

// Internal Dependencies
import Colours from '../../../../../styles/colours';
import Constants, { Status } from '../../../../../common/constants';
import { Trim } from '../../../../../common/util';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  w200: {
    width: '200px',
  },
  w250: {
    width: '250px',
  },
  bgWhite: {
    backgroundColor: Colours.White,
  },
}));

function FilterGift(props) {
  const classes = useStyles();

  const [status, setStatus] = useState('');
  const [type, setType] = useState('');

  const onDescriptionChange = (data) => {
    // to replace double whitespace before & after each word with ''
    const trimmedDescription = Trim(data);
    props.updateDescriptionFilter(trimmedDescription);
  };

  const debounceDescription = _.debounce(onDescriptionChange, 700);

  const onTypeChange = (data) => {
    setType(data);
    props.updateTypeFilter(data);
  };

  const onStatusChange = (data) => {
    setStatus(data);
    props.updateStatusFilter(data);
  };

  return (
    <div className={`mt-1 mb-2 ${isMobile ? `d-block` : `d-flex flex-row justify-content-start`}`}>
      <div>
        <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>
          Description
        </Typography>
        <TextField
          className={isMobile ? `mt-1 ${classes.fullWidth} ${classes.bgWhite}` : `mt-1 ${classes.w250} ${classes.bgWhite}`}
          variant="outlined"
          size="small"
          defaultValue=""
          multiline
          maxRows={8}
          inputProps={{ 'aria-label': 'naked' }}
          onChange={(e) => debounceDescription(e.target.value)}
        />
      </div>

      <div className={isMobile ? `mt-3` : `ml-3`}>
        <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>
          Type
        </Typography>
        <FormControl size="small" className={isMobile ? `${classes.fullWidth} mt-1` : `${classes.w200} mt-1`} variant="outlined">
          <Select
            native
            value={type}
            onChange={(e) => onTypeChange(e.target.value)}
            className={classes.bgWhite}
          >
            <option aria-label="None" value="">All</option>
            <option value={Constants.GiftType.Coupon}>Coupon</option>
            <option value={Constants.GiftType.Voucher}>Voucher</option>
          </Select>
        </FormControl>
      </div>

      <div className={isMobile ? `mt-3` : `ml-3`}>
        <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>
          Status
        </Typography>
        <FormControl size="small" className={isMobile ? `${classes.fullWidth} mt-1` : `${classes.w200} mt-1`} variant="outlined">
          <Select
            native
            value={status}
            onChange={(e) => onStatusChange(e.target.value)}
            className={classes.bgWhite}
          >
            <option aria-label="None" value="">All</option>
            <option value={Status.Draft}>Draft</option>
            <option value={Status.Activated}>Activated</option>
            <option value={Status.Deactivated}>Deactivated</option>
          </Select>
        </FormControl>
      </div>
    </div>
  );
}

FilterGift.propTypes = {
  updateDescriptionFilter: PropTypes.func.isRequired,
  updateTypeFilter:        PropTypes.func.isRequired,
  updateStatusFilter:      PropTypes.func.isRequired,
};

export default FilterGift;
