import Colours from './colours';

const postStyle = {
  chip: {
    border:       'solid',
    borderColor:  Colours.Primary,
    borderRadius: '30,',
    borderWidth:  1.5,
    fontSize:     15,
  },
  paper: {
    flexGrow: 1,
    margin:   'auto',
    maxWidth: '100%',
    padding:  '20px 30px',
  },
  textHeader: {
    color: Colours.Black,
  },
};

export default postStyle;
