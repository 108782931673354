import Colours from './colours';

export const containerHeaderTextStyle = {
  variant: 'h4',
  style:   {
    fontWeight: 'bold',
    fontSize:   '1.2rem',
  },
};

export const sideBarMenuItemFontSize = 14;

// export default commonStyle;
