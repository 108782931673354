import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography, Paper, TextField, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Colours from '../../../styles/colours';
import { EmailIsValid, IfEmptyThrowError, IfFalseThrowError } from '../../../common/checks';
import { AcraFormatCheck, Trim } from '../../../common/util';
import pageClaimStyle from '../../../styles/pageClaim';
import pageClaimAction from '../../../actions/pageClaim';

const Step3 = (props) => {
  const [name, setName] = useState('');
  const [regNo, setRegNo] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [contactDesignation, setContactDesignation] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactNo, setContactNo] = useState('');

  useEffect(() => {
    if (props.businessProfile) {
      const p = props.businessProfile;
      setName(p.name ? p.name : '');
      setRegNo(p.regNo ? p.regNo : '');
      setAddress(p.address ? p.address : '');
      setPostalCode(p.postalCode ? p.postalCode : '');
      setContactPerson(p.contactPerson ? p.contactPerson : '');
      setContactDesignation(p.contactDesignation ? p.contactDesignation : '');
      setContactEmail(p.contactEmail ? p.contactEmail : '');
      setContactNo(p.contactNo ? p.contactNo : '');
    }
  }, [props.businessProfile]);

  const handleInputChange = (key, value) => {
    props.dispatchPageClaimUpdate({ businessProfile: { ...props.businessProfile, [key]: value } });
  };

  const onSummary = () => {
    try {
      IfEmptyThrowError(name, 'Company name is required');
      IfEmptyThrowError(regNo, 'ACRA Registration No. is required');
      IfFalseThrowError(AcraFormatCheck(regNo), 'Acra Registration No. (UEN) format is incorrect.');
      IfEmptyThrowError(address, 'Company Address is required');
      IfEmptyThrowError(postalCode, 'Postal Code is required');
      IfEmptyThrowError(contactPerson, 'Contact Person is required');
      IfEmptyThrowError(contactDesignation, 'Designation is required');
      IfEmptyThrowError(contactEmail, 'Contact Email is required');
      IfFalseThrowError(EmailIsValid(contactEmail), 'Contact Email provided is invalid');
      IfEmptyThrowError(contactNo, 'Contact No. is required');
      props.onSummaryClick();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <Paper style={pageClaimStyle.paper}>
        <div className="d-flex flex-column">
          <Typography variant="h5" component="h5" gutterBottom>Business Profile</Typography>
          <div className="mt-2">
            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
              Company Name
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              className="mt-2 block"
              name="name"
              type="text"
              value={name}
              variant="outlined"
              size="small"
              onChange={(e) => handleInputChange('name', e.target.value)}
              disabled={props.disabled}
            />
          </div>

          <div className="mt-4">
            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
              ACRA Registration No. (UEN)
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              className="mt-2 block"
              name="regNo"
              type="text"
              value={regNo}
              variant="outlined"
              size="small"
              onChange={(e) => handleInputChange('regNo', Trim(_.toUpper(e.target.value)))}
              disabled={props.disabled}
            />
          </div>

          <div className="mt-4">
            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
              Company Address
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              className="mt-2 block"
              name="address"
              multiline
              maxRows={5}
              type="text"
              value={address}
              onChange={(e) => handleInputChange('address', e.target.value)}
              variant="outlined"
              size="small"
              disabled={props.disabled}
            />
          </div>

          <div className="mt-4">
            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
              Postal Code
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              className="mt-2 block"
              name="postalCode"
              type="text"
              value={postalCode}
              onChange={(e) => handleInputChange('postalCode', e.target.value)}
              variant="outlined"
              size="small"
              disabled={props.disabled}
            />
          </div>

          <div className="mt-4">
            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
              Contact Person
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              className="mt-2 block"
              name="contactPerson"
              type="text"
              value={contactPerson}
              onChange={(e) => handleInputChange('contactPerson', e.target.value)}
              variant="outlined"
              size="small"
              disabled={props.disabled}
            />
          </div>

          <div className="mt-4">
            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
              Designation
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              className="mt-2 block"
              name="contactDesignation"
              type="text"
              value={contactDesignation}
              onChange={(e) => handleInputChange('contactDesignation', e.target.value)}
              variant="outlined"
              size="small"
              disabled={props.disabled}
            />
          </div>

          <div className="mt-4">
            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
              Contact Email
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              className="mt-2 block"
              name="contactEmail"
              type="email"
              value={contactEmail}
              onChange={(e) => handleInputChange('contactEmail', Trim(e.target.value))}
              variant="outlined"
              size="small"
              disabled={props.disabled}
            />
          </div>

          <div className="mt-4">
            <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
              Contact Phone Number (SG number only)
              {' '}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <div className="d-flex flex-row align-items-center mt-2">
              <Typography variant="body2" color="inherit" style={{ color: Colours.Black }}>+65</Typography>
              <TextField
                className="ml-2 block"
                name="contactNo"
                type="text"
                value={contactNo}
                onChange={(e) => handleInputChange('contactNo', e.target.value)}
                variant="outlined"
                size="small"
                disabled={props.disabled}
              />
            </div>
          </div>
        </div>
      </Paper>

      {
        !props.disabled && (
          <div className="my-3 d-flex flex-row justify-content-end">
            <Button
              className="px-4"
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => props.onBackClick()}
            >
              Back
            </Button>
            <Button
              className="ml-3"
              variant="contained"
              size="large"
              color="primary"
              onClick={() => onSummary()}
            >
              Next: Summary
            </Button>
          </div>
        )
      }
    </>
  );
};

Step3.propTypes = {
  disabled:                PropTypes.bool,
  dispatchPageClaimUpdate: PropTypes.func.isRequired,
  businessProfile:         PropTypes.shape().isRequired,
  onBackClick:             PropTypes.func,
  onSummaryClick:          PropTypes.func,
};

Step3.defaultProps = {
  disabled:       false,
  onBackClick:    () => { },
  onSummaryClick: () => { },
};

const mapStateToProps = (state) => ({
  businessProfile: state.pageClaim.claim.businessProfile,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchPageClaimUpdate: (data) => pageClaimAction.pageClaimUpdate(dispatch, data),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
