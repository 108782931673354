// React Dependencies
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

// External Dependencies
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {  IconButton, Select, MenuItem, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

// Internal Dependencies
import OverLoading from '../../../components/Loading';
import ApiService from '../../../services/apiService';
import MText from '../../../components/Common/MText';
import MButton from '../../../components/MButton';
import Colours from '../../../styles/colours';
import { Url, Status } from '../../../common/constants';
import { titleTextSize } from './LoyaltyCardStyle';

const LoyaltyCards = ({ match }) => {
  const tableRef = useRef(null);
  const history = useHistory();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [id, setId] = useState(null);

  const defaultColumns = [
    { title: 'ID', field: 'id', filtering: true, editable: 'never', cellStyle: { width: '15%' } },
    { title: 'Name', field: 'name', filtering: true, editable: 'never', cellStyle: { width: '45%' } },
    {
      title:           'Status',
      field:           'status',
      filtering:       true,
      editable:        'never',
      cellStyle:       { width: '25%' },
      filterComponent: (props) => (
        <Select
          className="w-100"
          // eslint-disable-next-line react/prop-types
          onChange={(e) => props.onFilterChanged(props.columnDef.tableData.id, e.target.value)}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value={Status.Draft}>{Status.Draft}</MenuItem>
          <MenuItem value={Status.Live}>{Status.Live}</MenuItem>
          <MenuItem value={Status.Deactivated}>{Status.Deactivated}</MenuItem>
        </Select>
      ),
    },
    {
      title:     'Actions',
      cellStyle: { width: '15%' },
      render:    (row) => (
        <div className="w-100 d-flex align-items-center">
          <IconButton
            style={{ visibility: row.status === Status.Draft ? 'visible' : 'hidden' }}
            onClick={() => {
              setIsDeleteModalOpen(true);
              setId(row.id);
            }}
          >
            <DeleteIcon htmlColor={Colours.Black} />
          </IconButton>
          <IconButton onClick={() => history.push(Url.LoyaltyCard.replace(':pageId', match.params.pageId).replace(':loyaltyCardId', row.id))} className="mr-2">
            <EditIcon htmlColor={Colours.Black} />
          </IconButton>
        </div>
      ),
    },
  ];

  // prevent unwanted re-rendering for table filters
  const [columns] = useState(defaultColumns);
  const [loading, setLoading] = useState(false);

  const fetchTableData = (query) => new Promise(async (resolve) => {
    setLoading(true);
    try {
      query.pageNo = query.page;
      const { page } = query;

      const queryFilters = Object.fromEntries(query.filters.map((filters) => [filters.column.field, filters.value]));

      const result = await ApiService.getLoyaltyCards(match.params.pageId, {
        ...query,
        ...queryFilters,
      });
      resolve({ data: result.rows, page, totalCount: result.totalCount });
    } catch (err) {
      toast.error(err.message);
      resolve({ data: [], page: 0, totalCount: 0 });
    } finally {
      setLoading(false);
    }
  });

  const addNewLoyaltyCard = async () => {
    try {
      setLoading(true);
      setIsOpenModal(false);

      const payload = { pageId: match.params.pageId };

      const loyaltyCard = await ApiService.addLoyaltyCard(payload.pageId, payload);
      history.push(Url.LoyaltyCard.replace(':pageId', match.params.pageId).replace(':loyaltyCardId', loyaltyCard.id));
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteLoyaltyCard = async () => {
    try {
      const payload = { pageId: match.params.pageId, id };

      await ApiService.deleteLoyaltyCard({ pageId: payload.pageId, loyaltyCardId: id  }, payload);
      tableRef.current.onQueryChange();
      toast.success('Loyalty card deleted');
      setId(null);
      setIsDeleteModalOpen(false);
    } catch (err) {
      setId(null);
      setIsDeleteModalOpen(false);
      toast.error(err.message);
    }
  };

  return (
    <>
      {loading && <OverLoading />}
      <div className="container-fluid mt-4 pl-4">
        <Dialog
          fullWidth
          maxWidth="xs"
          open={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
          }}
        >
          <DialogContent className="mt-3">
            <MText size={titleTextSize} bold className="mb-3" text="Create a new loyalty card?" />
          </DialogContent>

          <DialogActions>
            <MButton
              text="Cancel"
              className="px-3"
              onClick={() => setIsOpenModal(false)}
              textColour={Colours.Primary}
            />
            <MButton
              text="Add"
              className="px-3 ml-4"
              onClick={() => addNewLoyaltyCard()}
              style={{
                backgroundColor: Colours.Primary,
              }}
              textColour={Colours.White}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={isDeleteModalOpen}
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
        >
          <DialogTitle>Delete Loyalty Card</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this loyalty card?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MButton
              text="Confirm"
              className="px-2"
              onClick={() => deleteLoyaltyCard(id)}
              textColour={Colours.Gray1}
            />
            <MButton
              text="Cancel"
              className="mx-3 px-2"
              onClick={() => { setIsDeleteModalOpen(false); setId(null); }}
              textColour={Colours.Primary}
            />
          </DialogActions>
        </Dialog>
        <div className="row mb-4">
          <div className="col-12 d-flex align-items-center">
            <MText size={20} bold text="Loyalty Cards" />
            <MButton
              text="+ Add"
              className="ml-4 px-3"
              onClick={() => {
                setIsOpenModal(true);
              }}
              style={{
                backgroundColor: Colours.Primary,
              }}
              textColour={Colours.White}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <MaterialTable
              tableRef={tableRef}
              title=""
              columns={columns}
              components={{ Toolbar: () => null }}
              options={{ filtering: true, search: false, debounceInterval: 500, emptyRowsWhenPaging: false }}
              data={fetchTableData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

LoyaltyCards.propTypes = {
  match: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = (state) => ({
  pageSelected: state.page.selected,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyCards);
