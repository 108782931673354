import Constants from '../common/constants';

const { ReduxAction } = Constants;

const postDataUpdate = (dispatch, data) => {
  dispatch({
    type:    ReduxAction.PostDataUpdate,
    payload: data,
  });
};

const postSelected = (dispatch, data) => {
  dispatch({
    type:    ReduxAction.PostSelected,
    payload: data,
  });
};

const postAction = {
  postDataUpdate,
  postSelected,
};

export default postAction;
