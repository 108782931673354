/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { IsEmpty } from '../../../common/checks';
import { WindowSize } from '../../../common/constants';
import Loading from '../../../components/Loading';
import PostAction from '../../../actions/post';
import PageAction from '../../../actions/page';
import ApiService from '../../../services/apiService';
import DesktopUpdate from './DesktopUpdate';
import MobileUpdate from './MobileUpdate';
import { showDateInSGTime } from '../../../common/util';

const Post = (props) => {
  const [postData, setPostData] = useState(null);
  const [pageData, setPageData] = useState(null);

  const fetchPost = async () => {
    try {
      const { postId } = props.match.params;
      await ApiService.getPost(postId)
        .then((p) => {
          setPostData(p);
          const payload = {
            ...p,
            currentPostStatus: p.status,
            promoFromDate:     showDateInSGTime(p.promoFromDate),
            promoTillDate:     showDateInSGTime(p.promoTillDate),
          };

          props.dispatchPostSelected(payload);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchPage = async () => {
    try {
      const { pageId } = props.match.params;
      let page = await ApiService.getPageById(pageId);
      page = { ...page, plan: props.page.plan, staffType: props.page.staffType };
      setPageData(page);
      props.dispatchPageSelected(page);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchPage();
    fetchPost();
  }, []);

  if (IsEmpty(postData) && IsEmpty(pageData)) {
    return (<Loading />);
  } else if (props.windowSize === WindowSize.Mobile) {
    return (
      <MobileUpdate {...props} getPost={fetchPost} />
    );
  } else {
    return (
      <DesktopUpdate {...props} getPost={fetchPost} />
    );
  }
};

const mapStateToProps = (state) => ({
  business: state.business.selected,
  page:     state.page.selected,

  windowSize: state.layout.windowSize,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostSelected: (post) => PostAction.postSelected(dispatch, post),
  dispatchPageSelected: (page) => PageAction.pageSelected(dispatch, page),
});

export default connect(mapStateToProps, mapDispatchToProps)(Post);
