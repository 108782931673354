import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Chip, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import { toast } from 'react-toastify';
import postStyle from '../../../../styles/post';
import postAction from '../../../../actions/post';
import { IsEmpty, Trim } from '../../../../common/util';
import ApiService from '../../../../services/apiService';

const OtherPartners = (props) => {
  const [partners, setPartners] = useState([]);
  const [suggest, setSuggest] = useState([]);

  const onInputChanged = async (value) => {
    try {
      // search only for Live pages for suggestions
      value = Trim(value);
      if (!IsEmpty(value)) {
        const pages = await ApiService.getPagesSuggest(value);
        const options = _.map(pages, (p) => _.assign({
          pageId: p.id,
          brand:  p.name,
        }));
        setSuggest(options);
      } else {
        setSuggest([]);
      }
    } catch (error) {
      console.log('Error getting suggestions: ', error);
      setSuggest([]);
    }
  };

  const inputDebounced = _.debounce(onInputChanged, 500);

  useEffect(() => {
    if (IsEmpty(props.post.promoPartners)) {
      setPartners([]);
    } else {
      // we need to convert the promoPartners to the options for the table
      const convertedData = _.map(props.post.promoPartners, (partner) => _.assign({
        pageId: partner.pageId,
        brand:  partner.brand,
      }));
      setPartners(convertedData);
    }
  }, [props.post]);

  const addValueIfNew = (lastValue) => {
    // user added a new partner, but we need to check if we already
    // have it before adding it in
    // Note: if value is not selected from the suggestion list, the value
    // will be just a string and not of format { pageId, brand } so we need to handle it
    let brand = null;
    let pageId = null;
    if (_.isString(lastValue)) {
      brand = Trim(lastValue);
    } else {
      brand = lastValue.brand;
      pageId = lastValue.pageId;
    }

    const promoPartners = props.post.promoPartners.slice();
    let found = false;
    for (const partner of promoPartners) {
      if (partner.brand === brand) {
        found = true;
        break;
      }
    }
    if (!found) {
      // add
      promoPartners.push({ pageId, brand });
      props.dispatchPostUpdate({ promoPartners });
    }
  };

  const findAndRemovePartner = (values) => {
    // user deleted one of the partner. Find out which is deleted
    const promoPartners = props.post.promoPartners.slice();
    for (const index in promoPartners) {
      if (!IsEmpty(index)) {
        let deleted = true;
        for (const value of values) {
          if (value.brand === promoPartners[index].brand) {
            deleted = false;
            break;
          }
        }
        if (deleted) {
          // found the partner index to delete
          promoPartners.splice(index, 1);
          break;
        }
      }
    }
    // update
    props.dispatchPostUpdate({ promoPartners });
  };

  const handleChanges = (values) => {
    try {
      // values will contain the distinct and updated array
      // Note that the data is an array of { pageId, brand }
      // but we don't want to remove the existing data (that's not deleted)
      // which contains other info such as { id, postId, tags} refer to backend model
      if (IsEmpty(values)) {
        props.dispatchPostUpdate({ promoPartners: [] });
      } else if (values.length > props.post.promoPartners.length) {
        // new value added will always be the last time
        addValueIfNew(values[values.length - 1]);
      } else {
        findAndRemovePartner(values);
      }
    } catch (err) {
      console.log('handleChange() error: ', err);
      toast.error('Oops, something went wrong. Please report this issue with us.');
    }
  };

  return (
    <Autocomplete
      disabled={props.disabled}
      id="tags"
      className="mb-3"
      limitTags={5}
      multiple
      options={suggest}
      getOptionLabel={(option) => option.brand}
      value={partners}
      filterSelectedOptions
      freeSolo
      renderTags={(value, getTagProps) => value.map((option, index) => (
        <Chip
          {...getTagProps({ index })}
          variant="outlined"
          label={option.brand}
          style={postStyle.chip}
        />
      ))}
      renderInput={(params) => (
        <Tooltip
          title={props.disabled ? props.disabledMsg : ''}
          isLink
        >
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => inputDebounced(e.target.value)}
            placeholder="Type and hit 'Enter' to add tag"
          // onBlur={() => addInputAsUserTag(params.inputProps.value)}
          />
        </Tooltip>
      )}
      onChange={(event, values, reason) => handleChanges(values)}
    />
  );
};

OtherPartners.defaultProps = {
  disabledMsg: '',
};

OtherPartners.propTypes = {
  disabled:    PropTypes.bool.isRequired,
  disabledMsg: PropTypes.string,
  post:        PropTypes.shape({
    promoPartners: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,

  dispatchPostUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => postAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(OtherPartners);
