import * as Yup from 'yup';

const ChangePasswordSchema = Yup.object().shape({
  current: Yup.string().required('*Required'),
  newPass: Yup.string().required('*Required').min(8, 'New Password must be at least 8 characters'),
  retype:  Yup.string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.newPass === value;
    }),
});

const ProfileDetailsSchema = Yup.object().shape({
  email: Yup.string().required('*Required'),
  name:  Yup.string().required('*Required'),
});

const BusinessProfileSchema = Yup.object().shape({
  address:       Yup.string().trim().required(`address-*required`),
  postalCode:    Yup.string().trim().required(`postalCode-*required`),
  contactEmail:  Yup.string().required(`contactEmail-*required`).email(`contactEmail-Invalid email`),
  contactPerson: Yup.string().trim().required(`contactPerson-*required`),
  contactNo:     Yup.string().trim().required(`contactNo-*required`),
}).required();

const BusinessStaffSchema = Yup.object().shape({
  inviteEmail: Yup.string().required(`inviteEmail-*required`).email(`inviteEmail-Invalid email`),
}).required();

export {
  BusinessProfileSchema,
  BusinessStaffSchema,
  ChangePasswordSchema,
  ProfileDetailsSchema,

};
