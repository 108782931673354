// React Dependencies
import React from 'react';

// External Dependencies
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    background:     'rgba(255,255,255,0.4)',
    backdropFilter: 'blur(5px)',
    display:        'flex',
    flexDirection:  'column',
    justifyContent: 'center',
    alignItems:     'center',

    position:        'fixed',
    top:             0,
    left:            0,
    width:           '100%',
    height:          '100vh',
    backgroundColor: (props) => props.backgroundColor,
    color:           '#FFFFFF',
    zIndex:          999,
  },
});

const Loading = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <CircularProgress className="mt-3" color="secondary" />
    </div>
  );
};

Loading.defaultProps = {
  backgroundColor: 'none',
};

export default Loading;
