import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function AlertModal({ open, setOpen, alert : { title, description, newLineDescription }, action : { func, name } }) {
  useEffect(() => () => {

  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      size="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {
            description
          }
          {newLineDescription && (
            <div style={{ marginTop: '1em' }}>
              {newLineDescription}
            </div>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mb-2">
        <Button
          className="px-4"
          onClick={() => {
            func();
          }}
          color="default"
        >
          {name}
        </Button>
        <Button className="px-4 mx-1" onClick={handleClose} color="primary" autoFocus>
          Cancel
        </Button>

      </DialogActions>
    </Dialog>

  );
}

AlertModal.propTypes = {
  open:    PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  alert:   PropTypes.shape({
    title:              PropTypes.string,
    description:        PropTypes.string,
    newLineDescription: PropTypes.string,
  }),
  action: PropTypes.shape({
    func: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
};

AlertModal.defaultProps = {
  open:  false,
  alert: {
    title:              '',
    description:        '',
    newLineDescription: '',
  },
};

export default AlertModal;
