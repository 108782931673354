import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Chip, TextField, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import postAction from '../../../../actions/post';
import { IsEmpty } from '../../../../common/util';
import postStyle from '../../../../styles/post';

const PromoCodes = (props) => {
  const [promoCodes, setPromoCodes] = useState([]);

  useEffect(() => {
    setPromoCodes(props.post.promoCodes || []);
  }, [props.post]);

  return (
    <Autocomplete
      disabled={props.disabled}
      className="mb-3"
      limitTags={10}
      multiple
      options={[]}
      value={promoCodes}
      disableCloseOnSelect
      freeSolo
      renderTags={(value, getTagProps) => value.map((option = '', index) => (
        <Chip
          {...getTagProps({ index })}
          variant="outlined"
          label={String(option).toUpperCase()}
          style={postStyle.chip}
        />
      ))}
      onChange={(event, values, reason) => {
        // values will contain the distinct and updated array
        props.dispatchPostUpdate({ promoCodes: values });
      }}
      renderInput={(params) => (
        <Tooltip
          title={props.disabled ? props.disabledMsg : ''}
          isLink
        >
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Type and hit 'Enter' to add code"
            onBlur={() => {
              const { value } = params.inputProps;
              if (!IsEmpty(value) && !promoCodes.includes(value)) {
                props.dispatchPostUpdate({ promoCodes: [...promoCodes, value] });
              }
            }}
          />
        </Tooltip>
      )}
    />
  );
};

PromoCodes.defaultProps = {
  disabled:    false,
  disabledMsg: 'Cannot be updated',
};

PromoCodes.propTypes = {
  disabled:           PropTypes.bool,
  disabledMsg:        PropTypes.string,
  dispatchPostUpdate: PropTypes.func.isRequired,
  post:               PropTypes.shape({
    promoCodes:        PropTypes.arrayOf(PropTypes.string),
    currentPostStatus: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => postAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);
