import axios from 'axios';
import { store } from '../store';
import Constants, { Error as ConstError, Status, TokenName } from '../common/constants';

const API_URL = `${process.env.REACT_APP_BACKEND_API_URL}/business`;
const { CancelToken } = axios;
const cancelRequestList = [];

const cancelApiRequest = () => {
  try {
    if (cancelRequestList && cancelRequestList.length > 0) {
      const size = cancelRequestList.length;
      for (let i = 0; i < size; i += 1) {
        const cancelRequest = cancelRequestList.pop();
        cancelRequest();
      }
    }
  } catch (error) {
    console.log('Error in cancelRequest()');
    console.log(error);
  }
};

const createAxiosConfig = (params, contentType = Constants.ContentType.ApplicationJson) => ({
  headers: {
    'Content-Type': contentType,
    'x-auth-token': localStorage.getItem(TokenName) || '',
  },
  params,
  validateStatus(status) { return status < 400; },
  cancelToken: new CancelToken((c) => { cancelRequestList.push(c); }),
  timeout:     15000,
});

const isNetworkError = (err) => {
  const hasError = false;
  const message = String(err.message).toLowerCase();
  if (message.includes('timeout')) {
    throw new Error('The connection has timed out. Server taking too long to respond');
  } else if (message.includes('network')) {
    throw new Error('Unable to connect to server. Please try again later');
  }
  return hasError;
};

const repackageError = (err) => {
  isNetworkError(err);

  // the cause cannot read data of undefined is becaused of err.response is empty for some error, and err only has name and messages.
  // therefore need to check err.response value first before assign the data and status and statusCode
  const { data, status } = err.response;
  const statusCode = parseInt(status, 10);

  //TODO: To check for internet connection

  if (statusCode === 500) {
    throw new Error(ConstError.UnexpectedError);
  } else if (statusCode === 401) {
    store.dispatch({
      type: Constants.ReduxAction.TokenExpired,
    });
    throw new Error(ConstError.SessionExpired);
  } else if (statusCode === 400) {
    throw new Error(data);
  } else {
    throw err;
  }

  //Guide: Swapped the sequence as first case is more specific and second case if more generic
  // if (err && err.response && err.response.status && (err.response.status === 500)) {
  //   throw new Error(Constants.UnexpectedError);
  // } else if (err && err.response && err.response.status
  //   && err.response.status >= 400 && err.response.status < 500 && err.response.data
  // ) {
  //   throw new Error(err.response.data);
  // } else if (err && err.message && isNetworkError(err)) {
  //   throw new NetworkError(Constants.NetworkError);
  // } else if (axios.isCancel(err)) { // Check if the error is due to axios call cancellation
  //   throw new ApiCancelError(err.message);
  // } else { // Throw exception from 'then()' block
  //   throw err;
  // }
};

const getRequest = async (path = '/', params = {}) => axios
  .get(`${API_URL}${path}`, createAxiosConfig(params))
  .then((res) => res.data)
  .catch((err) => repackageError(err));

const postRequest = (path = `/`, body = {}) => axios
  .post(`${API_URL}${path}`, body, createAxiosConfig({}))
  .then((res) => res.data)
  .catch((err) => repackageError(err));

const putRequest = (path = `/`, body = {}) => axios
  .put(`${API_URL}${path}`, body, createAxiosConfig({}))
  .then((res) => res.data)
  .catch((err) => repackageError(err));

const deleteRequest = (path = `/`, body = {}) => axios
  .delete(`${API_URL}${path}`, { data: body, ...createAxiosConfig({}) })
  .then((res) => res.data)
  .catch((err) => repackageError(err));

/**
 *
 * get address by postal code
 *
 * @param {*} postalCode
 */
const findAddressByPostalCode = async (postalCode) => getRequest(`/address/${postalCode}`);

const getPagePosts = async (id, params) => getRequest(`/page/${id}/posts`, params);

const getPagesSuggest = async (name) => getRequest(`/pages/suggest`, { name });

const addPost = async (data) => postRequest(`/post`, data);
const getPost = async (postId) => getRequest(`/post/${postId}`);
const deletePost = async (postId) => deleteRequest(`/post/${postId}`);

const getProxyImageUrl = async (url) => getRequest(`/image`, url);
const uploadPostMedia = async (id, file) => postRequest(`/post/${id}/media`, file);
const deletePostMedia = async (id, data) => deleteRequest(`/post/${id}/media`,  data);

const updatePost = async (id, payload) => putRequest(`/post/${id}`, payload);

const getTags = async (params) => getRequest(`/tags`,  params);
const getTagsByNames = async (params) => getRequest(`/tags/byNames`, params);

const getUserManagedAccounts = async () => getRequest('/userManagedAccounts');

const getUserInfo = async () => getRequest(`/user`, {});
const updateUserInfo = async (formData) => putRequest(`/user`, formData);

const changePassword = async (formData) => putRequest(`/user/changePassword`, formData);

/**
 * Page Account API Services
**/

const getPageAccount = async (pageId) => getRequest(`/page/${pageId}/account`, {});
const getPageAccountPlanLimit = async (pageId, type) => getRequest(`/page/${pageId}/plan`, { type });

/**
 * BusinessProfile API Services
 */

const getBusinessProfile = async (pageId) => getRequest(`/page/${pageId}/businessProfile`, {});

const updateBusinessProfile = async ({ pageId, formData }) => putRequest(`/page/${pageId}/businessProfile`, formData);

// page
const updatePage = async (id, formData) => putRequest(`/page/${id}`, formData);
const getPageById = async (id) => getRequest(`/page/${id}`);
const uploadPageMedia = async (id, file) => postRequest(`/page/${id}/media`, file);
const deletePageMedia = async (id, data) => deleteRequest(`/page/${id}/media`, data);

const getParents = async () => getRequest(`/pages/parents`);
const getCreditCardPagesUnderPage = async (id) => getRequest(`/page/${id}/creditcard`);

// page claim
const createPageClaim = async (data) => postRequest(`/page/claim`, data);
const getPageClaim = async (pageClaimId) => getRequest(`/page/claim/${pageClaimId}`);
const getApprovedPageClaimByPageName = async (pageName) => getRequest(`/page/claim?pageName=${pageName}&status=${Status.Live}`);
const deletePageClaim = async (pageClaimId) => deleteRequest(`/page/claim/${pageClaimId}`);

// categories
const getCategories = async () => getRequest(`/categories`);

const login = async ({ email, password }) => postRequest('/user/login', { email, password });
const resendVerifyUserEmail = async (email) => putRequest('/email/resendVerification', { email });
const signup = async ({ email, password }) => postRequest('/user/signup', { email, password });

const verifyUser = async (emailVerificationId) => putRequest('/email/verify', { emailVerificationId });
const resetPassword = (id, password) => postRequest('/resetPassword', { id, password });
const forgetPassword = async ({ email }) => postRequest('/forgetPassword', { email, accountType: 'Business' });

// reviews
const getReviews = async (id, params) => getRequest(`/page/${id}/reviews`, params);

const addReview = async (data) => postRequest(`/review`, data);
const updateReview = async (reviewId, payload) => putRequest(`/review/${reviewId}`, payload);
const getReview = async (reviewId, params) => getRequest(`/review/${reviewId}`, params);
const deleteReview = async (reviewId, params) => deleteRequest(`/review/${reviewId}`, params);

const uploadReviewMedia = async (id, file) => postRequest(`/review/${id}/media`, file);
const deleteReviewMedia = async (id, data) => deleteRequest(`/review/${id}/media`,  data);

//review place location
const addReviewPlaceLocation = async (reviewId, data) => postRequest(`/review/${reviewId}/placeLocation`, data);
const updateReviewPlaceLocation = async (id, reviewId, payload) => putRequest(`/review/${reviewId}/placeLocation/${id}`, payload);
const deleteReviewPlaceLocation = async (id, reviewId, payload) => deleteRequest(`/review/${reviewId}/placeLocation/${id}`, payload);

const getReviewPlaceOutlets = async (pageId) => getRequest(`/review/page/${pageId}/outlets`);

const getAddresses = async (params) => getRequest(`/addresses`, params);

/**
 * BUSINESS ACCESS API SERVICES
 */
const acceptRejectStaffInvite = async (staffId, inviteStatus) => putRequest(`/invite/${staffId}/acceptReject`, { inviteStatus });

const getBusinessStaff = async ({ pageId }) => getRequest(`/page/${pageId}/staffs`);

const createBusinessStaff = async (pageId, formData) => postRequest(`/page/${pageId}/staff/invite`, formData);

const deleteOrDeactivateBusinessStaff = async (id, pageId) => deleteRequest(`/page/${pageId}/staff/${id}`);

const updateBusinessStaff = async (id, formData, pageId) => putRequest(`/page/${pageId}/staff/${id}`, formData);

/**
 * OUTLET API SERVICES
 */
const createOutlet = async (outlet) => postRequest(`/outlet`, outlet);

const updateOutlet = async ({ dataToUpdate, outletId }) => putRequest(`/outlet/${outletId}`,  dataToUpdate);

const deleteOutlet = async ({ outletId, lastUpdatedBy }) => deleteRequest(`/outlet/${outletId}`, { lastUpdatedBy });

const getOutlets = async (pageId) => getRequest(`/page/${pageId}/outlets`);

const getOutlet = async ({ outletId }) => getRequest(`/outlet/${outletId}`);

/**
 * ANALYTICS API SERVICES
 */
//page analytics
const getAnalyticPageViewCountByDuration =  async (pageId, duration) => getRequest(`/analytics/page/${pageId}/views?noOfDays=${duration}`);
const getAnalyticPageEngagementCountByDuration =  async (pageId, duration) => getRequest(`/analytics/page/${pageId}/engagements?noOfDays=${duration}`);
const getAnalyticPageImpressionCountById =  async (pageId) => getRequest(`/analytics/page/${pageId}/impressions`);
const getAnalyticPageShareCountById =  async (pageId) => getRequest(`/analytics/page/${pageId}/shares`);
const getAnalyticPageFollowerCountById =  async (pageId) => getRequest(`/analytics/page/${pageId}/followers`);

//post analytics
const getPostAnalyticsCount = async (postId) => getRequest(`/analytics/post/${postId}`);
const engagementTypesByPostId = async (postId) => getRequest(`/analytics/post/${postId}/engagementTypes`);
const getAnalyticsPagePostsList = async (pageId, { pageNo, pageSize }) => getRequest(`/analytics/page/${pageId}/posts?pageNo=${pageNo}&pageSize=${pageSize}`);

/**
 * PAYMENTS
 */
const getPaymentHistory = async (pageId) => getRequest(`/payments/page/${pageId}`);
const createPaymentSession = async (data) => postRequest(`/payment/session/page/${data.pageId}`, data);

/**
 * BUSINESS SUPPORT
 */
const sendEmail = async ({ formData }) => postRequest(`/support/email`, formData);

/**
 * Gift
 */
const getAllGifts = (pageId, params) => getRequest(`/page/${pageId}/gifts`, params);
const getGift = ({ pageId, giftId }) => getRequest(`/page/${pageId}/gift/${giftId}`);
const createGift = (pageId, data) => postRequest(`/page/${pageId}/gift`, data);
const updateGift = ({ pageId, giftId }, data) => putRequest(`/page/${pageId}/gift/${giftId}`, data);
const uploadGiftMedia = ({ pageId, giftId }, data) => postRequest(`/page/${pageId}/gift/${giftId}/media`, data);
const deleteGiftMedia = ({ pageId, giftId }, data) => deleteRequest(`/page/${pageId}/gift/${giftId}/media`, data);

/**
 * PAGE HIGHLIGHT
 */
const getPageHighlights = async (params) => getRequest(`/pageHighlights`, params);
const getPageHighlight = async (id, data) => getRequest(`/pageHighlight/${id}`, data);
const createPageHighlight = async (data) => postRequest(`/pageHighlight`, data);
const updatePageHighlight = (pageHighlightId, data) => putRequest(`/pageHighlight/${pageHighlightId}`, data);
const uploadPageHighlightMedia = (pageHighlightId, data) => putRequest(`/pageHighlight/${pageHighlightId}/media`, data);
const deletePageHighlightMedia = (pageHighlightId, data) => deleteRequest(`/pageHighlight/${pageHighlightId}/media`, data);
const deletePageHighlight = async (pageId, pageHighlightId) => deleteRequest(`/pageHighlight`, { pageId, pageHighlightId });

/**
 * SPECIAL HIGHLIGHT - SPECIAL POST
 */
const getPostPageHighlights = (params) => getRequest(`/postPageHighlights`, params);
const addPostPageHighlight = (pageId, pageHighlightId, postId) => postRequest(`/postPageHighlight`, { pageId, pageHighlightId, postId });
const removePostPageHighlightPositionType = (pageId, pageHighlightId, postId) => deleteRequest(`/postPageHighlight`, { pageId, pageHighlightId, postId });
const updatePostPageHighlight = (postPageHighlightId, data) => putRequest(`/postPageHighlight/${postPageHighlightId}`, data);

/**
 * SPECIAL HIGHLIGHT - PINNED POST
 */
const getPinnedPosts = (params) => getRequest(`/pinnedPosts`, params);
const addPinnedPost = (pageId, pageHighlightId, postPageHighlightId, position, positionType) => postRequest(`/pinnedPost`, { pageId, pageHighlightId, postPageHighlightId, position, positionType });
const updatePinnedPostOrderPosition = (pageId, pageHighlightId, postPageHighlightIds) => putRequest(`/pinnedPost/pinnedPostOrder`, { pageId, pageHighlightId, postPageHighlightIds });
const updatePinnedPostPositionType = (pageId, pageHighlightId, postPageHighlightId, positionType) => putRequest(`/pinnedPost/pinnedPostPositionType`, { pageId, pageHighlightId, postPageHighlightId, positionType });
const removePinnedPostPositionType = (pageId, pageHighlightId, postPageHighlightId) => deleteRequest(`/pinnedPost`, { pageId, pageHighlightId, postPageHighlightId });
const getPostsByBrandAndCategoryGroup = (params) => getRequest(`/posts/brandAndCategoryGroup`, params);

/**
 * Map Bound
 */
const getMapBound = (pageId) => getRequest(`/page/${pageId}/mapBound`);
const createMapBound = (pageId, data) => postRequest(`/page/${pageId}/mapBound`, data);
const updateMapBound = (pageId, data) => putRequest(`/page/${pageId}/mapBound`, data);

/**
 * Loyalty Program
 */
const getLoyaltyCards = (pageId, params) => getRequest(`/page/${pageId}/loyaltyCards`, params);
const getLoyaltyCard = (pageId, loyaltyCardId) => getRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}`);
const getStampInfos = (pageId, loyaltyCardId) => getRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/stampInfos`);
const addLoyaltyCard = (pageId, data) => postRequest(`/page/${pageId}/loyaltyCard`, data);
const uploadDefaultStampMedia = ({ pageId, loyaltyCardId }, data) => postRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/defaultStampMedia`, data);
const uploadStampsHolderMedia = ({ pageId, loyaltyCardId }, data) => postRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/stampsHolderMedia`, data);
const uploadRedeemStampMedia = ({ pageId, loyaltyCardId }, data) => postRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/redeemStampMedia`, data);
const uploadStampPrizeMedia = ({ pageId, loyaltyCardId, stampInfoId }, data) => postRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/stampInfo/${stampInfoId}/prizeMedia`, data);
const uploadStampInfoMedia = ({ pageId, loyaltyCardId, stampInfoId }, data) => postRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/stampInfo/${stampInfoId}/media`, data);
const uploadLoyaltyCardMedia = (pageId, loyaltyCardId, data) => postRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/media`, data);
const updateLoyaltyCard = (pageId, loyaltyCardId, data) => putRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}`, data);
const updateLoyaltyCardStamps = ({ pageId, loyaltyCardId }, data) => putRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/stampInfos`, data);
const updateStampInfo = ({ pageId, loyaltyCardId, stampInfoId }, data) => putRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/stampInfo/${stampInfoId}`, data);
const deleteLoyaltyCard = ({ pageId, loyaltyCardId }, data) => deleteRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}`, data);
const getParticipateOutlets = (pageId, loyaltyCardId, params) => getRequest(`/page/${pageId}/loyaltyCard/${loyaltyCardId}/participatingOutlets`, params);

const ApiService = {
  addPost,
  addReview,
  addReviewPlaceLocation,
  acceptRejectStaffInvite,
  createPageClaim,
  deletePageClaim,
  createOutlet,
  changePassword,
  createBusinessStaff,
  createPaymentSession,
  deletePageMedia,
  deletePost,
  deletePostMedia,
  deleteOrDeactivateBusinessStaff,
  deleteOutlet,
  deleteReview,
  deleteReviewMedia,
  deleteReviewPlaceLocation,
  engagementTypesByPostId,
  findAddressByPostalCode,
  forgetPassword,
  getAddresses,
  getBusinessProfile,
  getBusinessStaff,
  getCategories,
  getPageById,
  getPageClaim,
  getApprovedPageClaimByPageName,
  getPagesSuggest,
  getParents,
  getCreditCardPagesUnderPage,
  getOutlets,
  getOutlet,
  getPageAccount,
  getPageAccountPlanLimit,
  getPagePosts,
  getPaymentHistory,
  getPost,
  getTags,
  getTagsByNames,
  getReviews,
  getReview,
  getReviewPlaceOutlets,
  getUserManagedAccounts,
  getUserInfo,
  getPostAnalyticsCount,
  getAnalyticPageViewCountByDuration,
  getAnalyticPageEngagementCountByDuration,
  getPostsByBrandAndCategoryGroup,
  //getAnalyticPageViewCountById,
  //getAnalyticPageEngagementCountById,
  getAnalyticPageImpressionCountById,
  getAnalyticPageShareCountById,
  getAnalyticPageFollowerCountById,
  login,
  getProxyImageUrl,
  getAnalyticsPagePostsList,
  //postImpressionsByPostId,
  //postEngagementsByPostId,
  //postViewsCountsByPostId,
  resendVerifyUserEmail,
  resetPassword,
  signup,
  sendEmail,
  updatePage,
  uploadPageMedia,
  uploadPostMedia,
  uploadReviewMedia,
  updateBusinessStaff,
  updateOutlet,
  updatePost,
  updateReview,
  updateReviewPlaceLocation,
  updateUserInfo,
  updateBusinessProfile,
  verifyUser,

  getAllGifts,
  getGift,
  createGift,
  updateGift,
  uploadGiftMedia,
  deleteGiftMedia,

  getPageHighlights,
  getPageHighlight,
  createPageHighlight,
  updatePageHighlight,
  uploadPageHighlightMedia,
  deletePageHighlightMedia,
  deletePageHighlight,

  getPostPageHighlights,
  addPostPageHighlight,
  updatePostPageHighlight,
  removePostPageHighlightPositionType,

  getPinnedPosts,
  addPinnedPost,
  updatePinnedPostOrderPosition,
  updatePinnedPostPositionType,
  removePinnedPostPositionType,

  getMapBound,
  createMapBound,
  updateMapBound,

  addLoyaltyCard,
  deleteLoyaltyCard,
  getParticipateOutlets,
  getLoyaltyCards,
  getLoyaltyCard,
  getStampInfos,
  uploadLoyaltyCardMedia,
  uploadStampInfoMedia,
  uploadDefaultStampMedia,
  uploadStampsHolderMedia,
  uploadRedeemStampMedia,
  uploadStampPrizeMedia,
  updateLoyaltyCard,
  updateLoyaltyCardStamps,
  updateStampInfo,
};

export default ApiService;
