import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  Button,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
}
from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import postAction from '../../../../actions/post';
import Loading from '../../../../components/Loading';
import apiService from '../../../../services/apiService';
import { IsEmpty, IsAdminOrContributor } from '../../../../common/checks';
import Colours from '../../../../styles/colours';
import Constants, {  Status } from '../../../../common/constants';
import Tooltip from '../../../../components/Common/ToolTip';
import Icons from '../../../../components/Common/Icons';
import { containerHeaderTextStyle } from '../../../../styles/common';
import dateStrToFormat from '../../../../common/convert';
import FilterPost from '../../../../components/Common/FilterPost';
import DesktopPostCard from './DesktopPostCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding:  theme.spacing(2),
    margin:   'auto',
    maxWidth: '100%',
    flexGrow: 1,
  },
  image: {
    width:  '100%',
    height: 'fit-content',
  },
  img: {
    margin:    'auto',
    display:   'block',
    maxWidth:  '100%',
    maxHeight: '100%',
  },
}));

const PostsListing = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [postToDelete, setPostToDelete] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const [posts, setPosts] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [totalPostsCount, setTotalPostsCount] = useState(0);
  const [filterTitle, setFilterTitle] = useState('');
  const [filterPostDate, setFilterPostDate] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterStatus, setFilterStatus] = useState('');

  const [goToPostId, setGoToPostId] = useState(false);

  const classes = useStyles();

  const getPagePosts = () => {
    setPageSize(5);
    setIsLoading(true);

    const order = { col: 'updatedAt', dir: 'DESC' };
    apiService.getPagePosts(props.page.id, { pageId: props.page.id, pageNo: pageNo - 1, pageSize, order, title: filterTitle, status: filterStatus, startDate, endDate })
      .then(({ rows, totalCount }) => {
        setPosts(rows);

        // Check if whole number. returns true otherwise false.
        if (Number.isInteger(totalCount / pageSize)) {
          setTotalPages(Math.floor(totalCount / pageSize));
        } else {
          // we add +1 from the total count for the remainder.
          setTotalPages(Math.floor(totalCount / pageSize) + 1);
        }

        setTotalPostsCount(totalCount);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const updateTitleFilter = (data) => setFilterTitle(data);

  const updatePostDateFilter = (data) => setFilterPostDate(data);

  const updateStatusFilter = (data) => setFilterStatus(data);

  useEffect(() => {
    if (!IsEmpty(filterPostDate)) {
      // The date should be convert to string because from date-range-picker is an object
      setStartDate(dateStrToFormat(filterPostDate.startDate));
      setEndDate(dateStrToFormat(filterPostDate.endDate));
    } else {
      setStartDate();
      setEndDate();
    }
  }, [filterPostDate]);

  useEffect(() => {
    getPagePosts();
  }, []);

  useEffect(() => {
    getPagePosts();
  }, [pageNo, filterTitle, filterStatus, startDate, endDate]);

  const editPost = (post) => {
    props.dispatchPostSelected(post);
    props.history.push(Constants.Url.Post.replace(':pageId', props.match.params.pageId).replace(':postId', post.id));
  };

  const deletePost = async () => {
    try {
      if (!IsEmpty(postToDelete)) {
        const softDeleteStatus = [Status.Live, Status.Paused];
        if (softDeleteStatus.includes(postToDelete.status)) {
          await apiService.updatePost(postToDelete.id, { status: Status.Deleted });
        } else {
          await apiService.deletePost(postToDelete.id);
        }
        getPagePosts();
        props.dispatchPostUpdate({ status: Status.Deleted });
        toast.success('Post deleted');
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOpenDeleteDialog(false);
    }
  };

  const handleChange = (event, value) => {
    setPageNo(value);
  };

  if (goToPostId) {
    return (
      /** The props 'push' is required to main the history state so we can navigate back and forth */
      // <Redirect push to={`/business/${props.match.params.businessId}/page/${props.match.params.pageId}/post/${goToPostId}`} />
      <Redirect push to={`/page/${props.match.params.pageId}/post/${goToPostId}`} />
    );
  } else {
    return (
      <>
        {isLoading && <Loading />}

        <div className="container-fluid">
          <div className="row px-4 mt-4 d-flex flex-row justify-content-start align-items-center">
            <Typography className="" variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Posts</Typography>

            <Tooltip title={IsAdminOrContributor(props.page.staffType) ? 'Create a new Post' : 'Permission Denied'}>
              <Button
                variant="outlined"
                color="primary"
                className="ml-4"
                disableElevation
                disabled={!IsAdminOrContributor(props.page.staffType)}
                style={{ marginRight: '2px', height: '2rem', borderColor: Colours.Gray5 }}
                onClick={() => props.addPost()}
              >
                + Create Post
              </Button>
            </Tooltip>
          </div>

          <div className="row px-4 mt-3">
            <FilterPost
              updateTitleFilter={updateTitleFilter}
              updateStatusFilter={updateStatusFilter}
              updatePostDateFilter={updatePostDateFilter}
            />
          </div>

          { !IsEmpty(posts) ? (
            <div className="container-fluid px-0 mx-0">
              <div className="row">
                <List className="col-lg-12 col-12 px-2" style={{ marginBottom: 120 }}>
                  {
                    posts.map((p) => (
                      <ListItem key={p.id} style={{ flex: 1, width: '100%' }}>
                        <DesktopPostCard
                          post={p}
                          editPost={() => editPost(p)}
                          deletePost={() => {
                            setOpenDeleteDialog(true);
                            setPostToDelete(p);
                          }}
                        />
                      </ListItem>
                    ))
                  }
                </List>
              </div>

              <div
                className="position-fixed ml-2 py-2 pr-3 d-flex flex-row align-items-center"
                style={{
                  background:   Colours.White,
                  bottom:       5,
                  width:        '100%',
                  borderRadius: '10px',
                }}
              >
                {totalPostsCount > 0 && (
                  <Pagination
                    color="primary"
                    size="large"
                    count={totalPages}
                    page={pageNo}
                    defaultPage={1}
                    onChange={handleChange}
                    className="float-left"
                  />
                )}
                <Typography variant="body1" className="ml-3" color="inherit" style={{ color: Colours.Black }}>
                  Total count:
                  {' '}
                  {totalPostsCount}
                </Typography>
              </div>
            </div>
          )
            : (
              <div className="container-fluid mt-3">
                <div className="row pt-4">
                  <Button className="ml-1" onClick={() => { props.addPost(); }}>
                    <Paper className="d-flex flex-row p-1 pr-5 align-items-center" style={{ borderRadius: 10 }}>
                      <img
                        src={require('../../../../assets/images/post.png')}
                        alt="Create a post"
                        width={150}
                      />
                      <div className="mb-4 d-flex flex-row align-items-center">
                        {/* <Icons.Send colour={Colours.Gray5} fontSize="1.8rem" /> */}
                        <Typography variant="h6" className="ml-2" color="inherit" style={{ color: Colours.Gray, fontWeight: 'normal', textDecoration: 'underline' }}>
                          + Create post
                        </Typography>
                      </div>
                    </Paper>
                  </Button>
                </div>
              </div>
            )}

        </div>

        <Dialog
          fullWidth
          size="sm"
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Post</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mb-2">
            <Button className="px-2" onClick={() => deletePost()} color="default">Confirm</Button>
            <Button className="px-2 mx-3" onClick={() => setOpenDeleteDialog(false)} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostSelected: (post) => postAction.postSelected(dispatch, post),
  dispatchPostUpdate:   (post) => postAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostsListing);
