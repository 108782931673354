// React Dependencies
import React, { useEffect, useState } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Paper, Typography } from '@material-ui/core';
import _ from 'lodash';

// Internal Dependencies
import Photos from './Photo';
import Loading from '../../../components/Loading';
import Tooltip from '../../../components/Common/ToolTip';
import RadioBtnSelect from '../../../components/Common/RadioBtnSelect';

import ApiService from '../../../services/apiService';
import { IfTrueThrowError } from '../../../common/checks';
import { IsEmpty } from '../../../common/util';
import { containerHeaderTextStyle } from '../../../styles/common';
import PageHighlightConst from './PageHighlightConst';

const { statusesAvailToSet } = PageHighlightConst;

const FoodReviewORGenericHighlight = ({ heading, selectedPage, isGeneric }) => {
  const pageId = selectedPage.id;

  const [loading, setLoading] = useState(true);
  const [pageHighlight, setPageHighlight] = useState(null);

  const getPageHighlight = async () => {
    try {
      setLoading(true);
      let payload = {};

      if (isGeneric) {
        payload = { pageId, isGeneric: true };
      } else {
        payload = { pageId, isFoodReview: true };
      }

      const data = await ApiService.getPageHighlights(payload);
      if (data.totalCount >= 1) {
        setPageHighlight(data.rows[0]);
      }
    } catch (error) {
      toast.error('Error while fetching data for page highlights');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const createPageHighlight = async () => {
    try {
      setLoading(true);
      let payload = {};

      if (isGeneric) {
        payload = { pageId, title: selectedPage.name, isGeneric: true };
      } else {
        payload = { pageId, title: 'Food Review', isFoodReview: true };
      }

      const data = await ApiService.createPageHighlight(payload);
      setPageHighlight(data);
    } catch (error) {
      toast.error(`Error while saving data for 'food review' page highlight: ${error.message}`);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePageHighlight = () => {
    IfTrueThrowError(IsEmpty(pageHighlight.title), 'Title is required');

    if (!IsEmpty(pageHighlight.images)) {
      const hasEmptyDisplay = pageHighlight.images.some((val) => IsEmpty(val.display));
      IfTrueThrowError(!IsEmpty(pageHighlight.images) && hasEmptyDisplay, 'Please crop all image(s) before saving the pageHighlight');
    }
  };

  const updatePageHighlight = async () => {
    try {
      setLoading(true);
      validatePageHighlight();
      await ApiService.updatePageHighlight(pageHighlight.id, pageHighlight);
      toast.success(`${isGeneric ? 'Generic' : 'Food Review'} Updated`);
    } catch (error) {
      toast.error(error.message, { autoClose: 7000 });
    } finally {
      setLoading(false);
    }
  };

  const onPageHighlightImageChange = (images) => {
    if (images !== pageHighlight.images) {
      pageHighlight.images = images;
      setPageHighlight({ ...pageHighlight });
    }
  };

  useEffect(() => {
    getPageHighlight();
  }, []);

  return (
    <>
      {loading && <Loading />}

      <div className="container-fluid px-4">
        <div className="mt-4">
          <Typography className="ml-1" variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>
            {heading}
            {' '}
            Highlight
          </Typography>

          { IsEmpty(pageHighlight) ? (
            <Tooltip title="Click to add food review highlight">
              <Button
                variant="contained"
                className="mt-3 ml-1 mb-3 position-relative"
                style={{ top: -7 }}
                color="primary"
                onClick={createPageHighlight}
              >
                + Create
              </Button>
            </Tooltip>
          ) : (
            <div className="row d-flex flex-column">
              <div className="col-12 my-3">
                <Paper className="p-4">
                  <form className="container-fluid px-0" autoComplete="off">
                    <div className="form-group">
                      <Typography>Title *</Typography>
                      <input
                        type="text"
                        className="form-control mt-2"
                        name="title"
                        value={pageHighlight.title}
                        onChange={(e) => setPageHighlight({ ...pageHighlight, title: e.target.value })}
                        required
                      />
                    </div>
                    <div className="form-group mt-3 mb-0">
                      <Typography>
                        Status *
                        {`${!statusesAvailToSet.includes(pageHighlight.status) ? (
                          ` (${pageHighlight.status})`
                        ) : ''}`}
                      </Typography>
                      <RadioBtnSelect
                        className="ml-2"
                        value={pageHighlight.status}
                        options={statusesAvailToSet.map((status) => _.assign({ value: status }))}
                        onChange={(status) => setPageHighlight({ ...pageHighlight, status })}
                        disabled={!statusesAvailToSet.includes(pageHighlight.status)}
                      />
                    </div>
                    <div className="form-group mb-1 pb-2" style={{ borderBottom: '1px solid gray' }}>
                      <Typography>Type</Typography>
                      <RadioBtnSelect
                        className="ml-2"
                        value={`${isGeneric ? 'Generic' : 'Food Review'} Highlight`}
                        options={[
                          { value: `${isGeneric ? 'Generic' : 'Food Review'} Highlight` },
                        ]}
                      />
                    </div>
                    <div className="form-group mt-3">
                      <Photos
                        page={selectedPage}
                        pageHighlight={pageHighlight}
                        images={pageHighlight.images || []}
                        onSort={(images) => onPageHighlightImageChange(images)}
                        onPageHighlightImageChange={(images) => onPageHighlightImageChange(images)}
                        showSaveImgPosBtn={false}
                        disabled={!statusesAvailToSet.includes(pageHighlight.status)}
                      />
                    </div>
                    <div className="form-group text-center mt-5">
                      <Tooltip
                        title={!statusesAvailToSet.includes(pageHighlight.status)
                          ? `Cannot update highlight because its status is '${pageHighlight.status}'`
                          : ''}
                      >
                        <Button
                          variant="contained"
                          onClick={updatePageHighlight}
                          color="secondary"
                          disabled={!statusesAvailToSet.includes(pageHighlight.status)}
                        >
                          <Typography className="px-4" variant="body1">Update Info</Typography>
                        </Button>
                      </Tooltip>
                    </div>
                  </form>
                </Paper>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

FoodReviewORGenericHighlight.propTypes = {
  isGeneric:    PropTypes.bool.isRequired,
  heading:      PropTypes.string.isRequired,
  selectedPage: PropTypes.shape(Object).isRequired,
};

export default FoodReviewORGenericHighlight;
