import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Typography, TextField, Paper } from '@material-ui/core';
import { toast } from 'react-toastify';
import viewAction from '../../actions/view';
import apiService from '../../services/apiService';
import Colours from '../../styles/colours';
import { BusinessProfileSchema } from '../../common/validationSchemas';
import { Trim } from '../../common/util';
import { containerHeaderTextStyle } from '../../styles/common';
import Tooltip from '../../components/Common/ToolTip';
import { IsAdminOrContributor } from '../../common/checks';

const BusinessProfile = ({ pageSelected }) => {
  const [businessProfile, setBusinessProfile] = useState({
    id:            '',
    name:          '',
    regNo:         '',
    address:       '',
    postalCode:    '',
    contactEmail:  '',
    contactPerson: '',
    contactNo:     '',
  });
  const [errorObj, setErrorObj] = useState({
    address: {
      message: '',
      error:   false,
    },
    postalCode: {
      message: '',
      error:   false,
    },
    contactEmail: {
      message: '',
      error:   false,
    },
    contactPerson: {
      message: '',
      error:   false,
    },
    contactNo: {
      message: '',
      error:   false,
    },
  });

  const getBusinessProfile = async () => {
    try {
      const data = await apiService.getBusinessProfile(pageSelected.id);
      setBusinessProfile(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getBusinessProfile();
  }, []);

  const handleInputChange = (name, value) => {
    if (Trim(value) === '' || name === 'contactEmail') {
      value = Trim(value);
    }

    setBusinessProfile((prevState) => ({
      ...prevState,
      [name]: value,

    }));

    /**
     * INSTANCE VALIDATION WITH YUP SCHEMA
     */
    BusinessProfileSchema.validate({
      ...businessProfile,
      [name]: value,
    }, { abortEarly: false })
      .then(() => {
        setErrorObj((prevState) => ({
          ...prevState,
          [name]: {
            error:   false,
            message: '',
          },
        }));
      })
      .catch((error) => {
        const fieldError = error.errors.map((err) => {
          const errorName = err.split('-')[0];
          const errorMsg = err.split('-')[1];
          return { fieldName: errorName, msg: errorMsg };
        });
        // eslint-disable-next-line guard-for-in
        for (const fieldName in errorObj) {
          const index = fieldError.map((e) => e.fieldName).indexOf(fieldName);
          if (index !== -1) {
            const catchedErrorObj = {
              error:   true,
              message: fieldError[index].msg,
            };
              setErrorObj((prevState) => ({
                ...prevState,
                [fieldName]: catchedErrorObj,
              }));
          } else {
            setErrorObj((prevState) => ({
              ...prevState,
              [fieldName]: {
                error:   false,
                message: '',
              },
            }));
          }
        }
      });
  };

  const handleSubmit =  async (e) => {
    e.preventDefault();

    try {
      const isValid = await BusinessProfileSchema.isValid(businessProfile);
      if (isValid) {
        await apiService.updateBusinessProfile({
          formData: businessProfile,
          pageId:   pageSelected.id,
        });
        toast.success('Business Profile updated');
      } else {
        toast.warning('One or more required data is missing or is invalid');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="container-fluid px-1">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-8 mx-md-4 mt-4 px-3">
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Business Profile</Typography>

          <Typography
            className="mt-3 font-weight-bold"
            variant="body1"
          >
            {`ID : ${businessProfile.id}`}
          </Typography>

          <form onSubmit={handleSubmit}>
            <Typography className="mt-2 ml-0" variant="caption" color="inherit" style={{ color: 'red' }}>*required</Typography>
            <Paper className="d-flex flex-column mt-2 pb-5 px-4" elevation={1}>
              <div className="mt-4">
                <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>Name</Typography>
                <Typography className="mt-1" variant="body1" color="inherit" style={{ color: Colours.Black }}>{businessProfile.name}</Typography>
              </div>

              <div className="mt-4">
                <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>ACRA Registration No.</Typography>
                <Typography className="mt-1" variant="body1" color="inherit" style={{ color: Colours.Black }}>{businessProfile.regNo}</Typography>
              </div>

              <div className="mt-4">
                <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                  Address
                  {' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  className="mt-2 block"
                  name="address"
                  type="text"
                  value={businessProfile.address}
                  onChange={(e) => handleInputChange('address', e.target.value)}
                  variant="outlined"
                  size="small"
                  helperText={errorObj.address.message}
                  error={errorObj.address.error}
                />
              </div>

              <div className="mt-4">
                <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                  Postal Code
                  {' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  className="mt-2 block"
                  name="postalCode"
                  type="text"
                  value={businessProfile.postalCode}
                  onChange={(e) => handleInputChange('postalCode', e.target.value)}
                  variant="outlined"
                  size="small"
                  helperText={errorObj.postalCode.message}
                  error={errorObj.postalCode.error}
                />
              </div>

              <div className="mt-4">
                <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                  Contact Email
                  {' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  className="mt-2 block"
                  name="contactEmail"
                  type="email"
                  value={businessProfile.contactEmail}
                  onChange={(e) => handleInputChange('contactEmail', e.target.value)}
                  variant="outlined"
                  size="small"
                  helperText={errorObj.contactEmail.message}
                  error={errorObj.contactEmail.error}
                />
              </div>

              <div className="mt-4">
                <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                  Contact Person
                  {' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  className="mt-2 block"
                  name="contactPerson"
                  type="text"
                  value={businessProfile.contactPerson}
                  onChange={(e) => handleInputChange('contactPerson', e.target.value)}
                  variant="outlined"
                  size="small"
                  helperText={errorObj.contactPerson.message}
                  error={errorObj.contactPerson.error}
                />
              </div>

              <div className="mt-4">
                <Typography variant="body2" color="inherit" style={{ color: Colours.Gray }}>
                  Contact Phone Number
                  {' '}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  className="mt-2 block"
                  name="contactNo"
                  type="text"
                  value={businessProfile.contactNo}
                  onChange={(e) => handleInputChange('contactNo', e.target.value)}
                  variant="outlined"
                  size="small"
                  helperText={errorObj.contactNo.message}
                  error={errorObj.contactNo.error}
                />
              </div>
            </Paper>

            <div className="row my-4 px-4 d-flex flex-row justify-content-center">
              <Tooltip
                title={IsAdminOrContributor(pageSelected.staffType) ? '' : 'Permission Denied'}
                style={{
                  width:     '100%',
                  textAlign: 'center',
                }}
              >
                <Button
                  className="mb-5 col-12 col-md-6"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={!IsAdminOrContributor(pageSelected.staffType)}
                >
                  Update
                </Button>
              </Tooltip>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

BusinessProfile.propTypes = {
  pageSelected: PropTypes.shape({
    id:        PropTypes.number,
    name:      PropTypes.string,
    staffType: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  pageSelected: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchViewChanged: (name) => viewAction.viewChanged(dispatch, name),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessProfile);
