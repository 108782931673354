import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import captureVideoFrame from 'capture-video-frame';
import ReactPlayer from 'react-player';
import Constants, { AccountMaxImageUpload } from '../../../../../common/constants';
import { IsEmpty, IsAdminOrContributor } from '../../../../../common/checks';
import FixedCropper from '../photo/FixedCropper';
import VideoGrid from './VideoGrid';
import Colours from '../../../../../styles/colours';
import OverLoading from '../../../../../components/Loading';
import Tooltip from '../../../../../components/Common/ToolTip';
import PostAction from '../../../../../actions/post';
import ApiService from '../../../../../services/apiService';
import Icons from '../../../../../components/Common/Icons';
import PostMediaLimitModal from '../PostMediaLimitModal';

const maxFileSize = 3072000;
const videoFormats = ['video/mp4'];

const Video = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [videoUrls, setVideoUrls] = useState([]);

  const [totalUploads, setTotalUploads] = useState(0);
  const [planModals, setPlanModals] = useState(false);
  const [accountType, setAccountType] = useState('Free');
  const [maxUpload, setMaxUpload] = useState(0);

  const [showVideoThumbModal, setShowVideoThumbModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoLocalFile, setVideoLocalFile] = useState(null);

  const [hasThumbnail, setHasThumbnail] = useState(false);
  const [recropThumbnail, setRecropThumbnail] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [src, setSrc] = useState(null);

  const playerRef = useRef(null);

  const hiddenInputFileRef = React.createRef();

  useEffect(() => {
    const totalPhotoUpload = !IsEmpty(props.post.images) ? (props.post.images).length : 0;
    const totalVideoUpload = !IsEmpty(props.post.videos) ? (props.post.videos).length : 0;
    setTotalUploads(totalPhotoUpload + totalVideoUpload);

    const maxNoUpload = AccountMaxImageUpload[props.page.plan];
    setAccountType(props.page.plan);
    setMaxUpload(maxNoUpload);

    setVideoUrls(!IsEmpty(props.post.videos) ? props.post.videos : []);
  }, [props.post]);

  const handleConfirmation = (url) => {
    setSelectedVideo(url);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    try {
      setOpenModal(false);
      setLoading(true);

      await ApiService.deletePostMedia(props.postId, { mediaData: selectedVideo, fileType: Constants.FileType.Video });
      const videoRes = videoUrls.filter((video) => video !== selectedVideo);
      props.dispatchPostUpdate({ videos: videoRes });
      toast.success('Video successfully deleted.');

      setLoading(false);
      setSelectedVideo(null);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const handleErrorMessage = (file) => {
    if (file) {
      if (!videoFormats.includes(file.type)) {
        toast.error('Only MP4 files are supported.');
        return false;
      }

      if (file.size > maxFileSize) {
        toast.error('Maximum file size of 3MB exceeded.');
        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  const handleOpenPlanModals = () => {
    setPlanModals(true);
  };

  const handleClosePlanModals = () => {
    setPlanModals(false);
  };

  const handleInputUpload = () => {
    if (totalUploads >= maxUpload) {
      handleOpenPlanModals();
    } else {
      hiddenInputFileRef.current.click();
    }
  };

  const saveVideo = async (file) => {
    const isOk = handleErrorMessage(file);

    if (totalUploads >= maxUpload) {
      handleOpenPlanModals();
    } else if (isOk) {
      setLoading(true);

      // WE SET THE THUMBNAIL FIRST.
      const blob = URL.createObjectURL(file);
      setVideoUrl(blob);

      setShowVideoThumbModal(true);
      setVideoLocalFile(file);
    }
  };

  const handCloseThumbModal = () => {
    setShowVideoThumbModal(false);
    setHasThumbnail(false);
    setVideoLocalFile(null);
    setRecropThumbnail(null);
    setLoading(false);
  };

  const handleSaveThumbnail = async (vidFile) => {
    if (!IsEmpty(vidFile)) {
      const file = new File([vidFile.blob], `${(videoUrl.split('/')[videoUrl.split('/').length - 1]).split('.')[0]}.jpg`, { type: 'image/jpeg' });

      if (file) {
        setShowCropper(true);
        setShowVideoThumbModal(false);
        setThumbnailFile(file);

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setSrc(reader.result);
          setHasThumbnail(false);
        });
        reader.readAsDataURL(file);
      }
    }
  };

  const onCaptureThumbnail = () => {
    const frame = captureVideoFrame(playerRef.current.getInternalPlayer());
    handleSaveThumbnail(frame);
  };

  const onUploadThumbnail = async (file) => {
    const maxLoadTimer = 7;
    let uploadDurCount = 0;

    const interval = setInterval(() => {
      uploadDurCount += 1;
      if (uploadDurCount > maxLoadTimer) {
        toast.info('The upload is taking longer than usual, please wait.', {
          position:        'top-right',
          autoClose:       4000,
          hideProgressBar: false,
          closeOnClick:    true,
          pauseOnHover:    true,
          theme:           'colored',
        });
        clearInterval(interval);
      }
    }, 1000);

    if (file) {
      if (!recropThumbnail) {
        const formattedVideoFile = new File([videoLocalFile], `${file.name.replace('.jpg', '.mp4')}`, { type: 'video/mp4' });
        const formDataVideo = new FormData();
        formDataVideo.append('video', formattedVideoFile);
        formDataVideo.append('type', 'videos');
        const cloudfrontLink = await ApiService.uploadPostMedia(props.postId, formDataVideo, Constants.FileType.Video);

        if (cloudfrontLink && cloudfrontLink.length > 0) {
          // UPLOADING NEW THUMBNAIL
          const formattedFile = new File([file], `${(cloudfrontLink.split('/')[cloudfrontLink.split('/').length - 1]).split('.')[0]}.jpg`, { type: 'image/jpeg' });

          const formDataPhoto = new FormData();
          formDataPhoto.append('image', formattedFile);
          formDataPhoto.append('imageType', Constants.ImageType.VideoThumbnail);
          await ApiService.uploadPostMedia(props.postId, formDataPhoto, Constants.FileType.Image)
            .then(() => {
              setLoading(false);
              setShowCropper(false);
              handCloseThumbModal();
              setVideoLocalFile(null);
              setRecropThumbnail(null);
              props.dispatchPostUpdate({ videos: videoUrls.concat(cloudfrontLink) });
              toast.success('Video successfully uploaded.');
              clearInterval(interval);
            });
        }
      } else {
        // RE-CROPPING THUMBNAIL
        const formattedFile = new File([file], `${(videoUrl.split('/')[videoUrl.split('/').length - 1]).split('.')[0]}.jpg`, { type: 'image/jpeg' });

        const formDataPhoto = new FormData();
        formDataPhoto.append('image', formattedFile);
        formDataPhoto.append('imageType', Constants.ImageType.VideoThumbnail);
        await ApiService.uploadPostMedia(props.postId, formDataPhoto, Constants.FileType.Image)
          .then(() => {
            setLoading(false);
            setShowCropper(false);
            handCloseThumbModal();
            setVideoLocalFile(null);
            setRecropThumbnail(null);
            toast.success('Video thumbnail has been updated.');
            clearInterval(interval);
          });
      }
    }
  };

  const handleCloseCropper = () => {
    setShowCropper(false);
    setSrc(null);
    setShowVideoThumbModal(true);
    setVideoLocalFile(null);
    setRecropThumbnail(null);
  };

  return (
    <>
      { loading && <OverLoading /> }
      <div className="row">
        <div className="col-sm-12 d-flex flex-row align-items-center">
          <Typography className="d-inline-block" variant="body1" color="inherit" style={{ color: Colours.Black }}>
            Videos
            <sup style={{ color: Colours.Red }}>*</sup>
            <span style={{ marginBottom: 5 }}> (max 3MB size)</span>
            <span style={{ marginBottom: 5 }}>
              <Typography variant="body2" color="inherit" style={{ color: Colours.Gray1 }}>If video is provided, it will be the cover image)</Typography>
            </span>
          </Typography>
          <Tooltip title={props.disabled ? props.disabledMsg : (IsAdminOrContributor(props.page.staffType) ? 'Click to upload' : 'Permission Denied')}>
            <Button
              variant="contained"
              className="d-inline-block ml-5"
              color="primary"
              component="span"
              onClick={() => handleInputUpload()}
              disabled={(!IsAdminOrContributor(props.page.staffType)) || (props.disabled)}
            >
              + Add Video
            </Button>
            {' '}
          </Tooltip>
          <input
            className="d-none"
            ref={hiddenInputFileRef}
            accept="video/mp4"
            id="icon-button-file"
            type="file"
            onClick={(e) => { e.target.value = null; }}
            onChange={(e) => { saveVideo(e.currentTarget.files[0]); }}
          />
        </div>
        <div className="col-sm-12">
          {
            (props.post.videos && props.post.videos.length > 0) ? (
              <>
                {
                  props.post.videos.length > 1 && (
                    <Typography color="primary" variant="body2" className="d-block mt-3" style={{ marginBottom: 17 }}>*Select &amp; hold and video to re-order its position</Typography>
                  )
                }

                <VideoGrid
                  disabled={!IsAdminOrContributor(props.page.staffType) || (props.disabled)}
                  onSort={(d) => props.onVideoSort(d)}
                  onDelete={(url) => handleConfirmation(url)}
                  onGenerateThumbnail={(url) => {
                    setRecropThumbnail(true);
                    setVideoUrl(url);
                    setShowVideoThumbModal(true);
                  }}
                />
              </>
            ) : (
              <div className="py-5 d-flex flex-row justify-content-center">
                <Tooltip title={props.disabled ? props.disabledMsg : (IsAdminOrContributor(props.page.staffType) ? 'Click to upload' : 'Permission Denied')}>
                  <Button
                    className="px-5"
                    style={{ height: 'auto', borderRadius: 10 }}
                    disabled={(!IsAdminOrContributor(props.page.staffType)) || (props.disabled)}
                    onClick={() => handleInputUpload()}
                  >
                    <div className="d-flex flex-column align-items-center">
                      <Icons.Video fontSize={100} colour={Colours.Gray3} />
                      <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Gray2 }}>Upload Video</Typography>
                    </div>
                  </Button>
                </Tooltip>
              </div>
            )
          }
        </div>
        <Dialog
          fullWidth
          size="sm"
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Remove Video</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the video?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="mb-2">
            <Button className="px-2" onClick={() => handleDelete()} color="default">Confirm</Button>
            <Button className="px-2 mx-3" onClick={() => setOpenModal(false)} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>

        <PostMediaLimitModal
          open={planModals}
          close={() => handleClosePlanModals()}
          accountType={accountType}
          page={props.page}
        />

        <Dialog
          id="videoDialog"
          fullWidth
          maxWidth="md"
          open={showVideoThumbModal}
          disableBackdropClick
          onClose={() => handCloseThumbModal()}
        >
          <DialogContent>
            <Typography variant="h5">Setting thumbnail</Typography>
            <hr />
            {!hasThumbnail && (
              <>
                <div className="d-block text-center pb-2">
                  <ReactPlayer
                    muted
                    ref={playerRef}
                    url={videoUrl}
                    controls
                    volume={0}
                    config={{
                      file: {
                        attributes: {
                          crossOrigin: 'anonymous',
                        },
                      },
                    }}
                    style={{
                      display:  'block',
                      margin:   '0 auto',
                      width:    '100%',
                      maxWidth: 400,
                      height:   'auto !important',
                    }}
                  />
                </div>

                <div className="d-block mx-auto text-center">
                  <Typography color="primary" variant="body2" className="d-block my-3">
                    Click and drag the handle to the video frame that you want to use for the thumbnail, then hit the button ‘Next’ below.
                  </Typography>
                  <Button onClick={() => handCloseThumbModal()} style={{ width: 225, marginTop: 10, marginBottom: 10, marginRight: 10 }} variant="outlined" color="secondary">Cancel</Button>
                  <Button onClick={() => onCaptureThumbnail()} style={{ width: 225, marginTop: 10, marginBottom: 10 }} variant="contained" color="secondary">Next</Button>
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen
          open={showCropper}
          onClose={() => handleCloseCropper()}
        >
          <FixedCropper
            width={4}
            height={3}
            cropPixelWidth={400}
            cropPixelHeight={400}
            outputPixelWidth={900}
            outputPixelHeight={900}
            type="upload"
            src={src}
            file={thumbnailFile}
            fileName={`${(videoUrl.split('/')[videoUrl.split('/').length - 1]).split('.')[0]}.jpg`}
            handleSave={(file) => onUploadThumbnail(file)}
            handleClose={() => handleCloseCropper()}
            isCropping
          />
        </Dialog>
      </div>
    </>
  );
};

Video.defaultProps = {
  disabled:    false,
  disabledMsg: '',
  page:        {
    plan:      '',
    staffType: '',
  },
};

Video.propTypes = {
  disabled:           PropTypes.bool,
  dispatchPostUpdate: PropTypes.func.isRequired,
  onVideoSort:        PropTypes.func.isRequired,
  postId:             PropTypes.number.isRequired,
  disabledMsg:        PropTypes.string,

  page: PropTypes.shape({
    plan:      PropTypes.string,
    staffType: PropTypes.string,
  }),

  post: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        display:  PropTypes.string,
        original: PropTypes.string,
      }),
    ),

    videos: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
  page: state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
