import Constants, { WindowSize } from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  showSideBar:               true,
  showLoyaltyProgramSideBar: false,
  showLogout:                false,
  windowSize:                WindowSize.Desktop,
  slidingPanel:              {
    open:    false,
    content: null,
    onClose: null,
  },
  slidingPanelMobile: {
    open:    false,
    content: null,
    onClose: null,
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.LogoutModalToggle:
      return {
        ...state,
        showLogout: !state.showLogout,
      };

    case ReduxAction.SideBarHide:
      return {
        ...state,
        showSideBar: false,
      };

    case ReduxAction.SideBarShow:
      return {
        ...state,
        showSideBar: true,
      };

    case ReduxAction.SideBarToggle:
      return {
        ...state,
        showSideBar: !state.showSideBar,
      };

    case ReduxAction.SlidingPanel:
      return {
        ...state,
        slidingPanel: {
          ...payload,
        },
      };

    case ReduxAction.SlidingPanelMobile:
      return {
        ...state,
        slidingPanelMobile: {
          ...payload,
        },
      };

    case ReduxAction.WindowSizeChanged:
      return {
        ...state,
        windowSize: (payload === WindowSize.Mobile)
          ? WindowSize.Mobile
          : WindowSize.Desktop,
      };

    case ReduxAction.LoyaltyProgramSideBarShow:
      return {
        ...state,
        showLoyaltyProgramSideBar: true,
      };

    case ReduxAction.LoyaltyProgramSideBarHide:
      return {
        ...state,
        showLoyaltyProgramSideBar: false,
      };

    default:
      return state;
  }
}
