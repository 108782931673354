// React Dependencies
import React, { useEffect, useState, useRef } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Typography, createTheme, Chip, ThemeProvider,
  Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable, { MTableBody } from 'material-table';
import _ from 'lodash';
import { toast } from 'react-toastify';

// Internal Dependencies
import LinkPageOrLocation from './LinkPageOrLocation';

import { IsArray } from '../../../../common/checks';
import { IsEmpty } from '../../../../common/util';

import reviewAction from '../../../../actions/review';
import ApiService from '../../../../services/apiService';
import Colours from '../../../../styles/colours';
import postStyle from '../../../../styles/post';

const themeTable = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        '&:hover, &:nth-child(odd):hover': {
          transition:      '0.3s ease',
          backgroundColor: '#ffecb3',
        },
        '&:nth-child(odd)': {
          backgroundColor: '#eeeeee',
        },
      },
    },
  },
});

const Locations = (props) => {
  const tableRef = useRef(null);
  const columns = [
    {
      title:     'Page/Location',
      filtering: false,
      width:     '3%',
      render:    (placesLocations) => (
        !IsEmpty(placesLocations.placePageId) ? <span>Linked to a Page</span> : <span>Custom Location</span>
      ),
    },
    {
      title:     'Page ID',
      field:     'placePageId',
      filtering: false,
      width:     '5%',
    },
    {
      title:     'Page Name',
      field:     'name', filtering: false,
      width:     '5%',
      render:    (placesLocations) => (
        !IsEmpty(placesLocations.placePageId) ? <span>{placesLocations.name}</span> : <span>{' '}</span>
      ),
    },
    {
      title:     'Applicable Outlets',
      field:     'isAllOutlets',
      filtering: false,
      render:    (placesLocations) => (
        placesLocations.isAllOutlets === false ? (
          <span>
            {placesLocations.outlets.length}
            {' '}
            outlet(s) selected
          </span>
        )
          : placesLocations.isAllOutlets === true ? <span>Review is for all outlets</span>
            : <span>{' '}</span>
      ),
    },
    {
      title:     'Place Name',
      field:     'name',
      filtering: false,
      width:     '5%',
      render:    (placesLocations) => (
        !IsEmpty(placesLocations.placePageId) ? <span>{' '}</span> : <span>{placesLocations.name}</span>
      ),
    },
    {
      title:     'Address',
      filtering: false,
      width:     '5%',
      render:    (placesLocations) => (
        !IsEmpty(placesLocations.placePageId) ? <span>{' '}</span>
          : !IsEmpty(placesLocations.isHomeBased) && IsEmpty(placesLocations.address) ? <span>{' '}</span>
            : (
              <span>
                {placesLocations.locatedIn}
                {' '}
                {placesLocations.address}
                {' '}
                {!IsEmpty(placesLocations.unitNo) && `#${placesLocations.unitNo}`}
                {' '}
                S(
                {placesLocations.postalCode}
                )
              </span>
            )
      ),
    },
    {
      title:     'Tags',
      field:     'tags',
      filtering: false,
      width:     '15%',
      render:    (placesLocations) => (
        <Autocomplete
          disabled
          limitTags={3}
          multiple
          disableCloseOnSelect
          disablePortal
          id="tags-outlined"
          options={props.review.tags}
          value={placesLocations.tags}
          renderTags={(value, getTagProps) => value.map((tag, tagIndex) => (
            <Chip
              {...getTagProps({ tagIndex })}
              key={tag || `tag-${tagIndex}`}
              variant="outlined"
              label={tag}
              className="mr-2 mb-2"
              style={{ borderColor: Colours.Black, fontSize: 12, background: Colours.Black, color: Colours.White }}
            />
          ))}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
            />
          )}
        />
      ),
    },
    {
      title:     'URL',
      field:     'url',
      filtering: false,
      width:     '5%',
      render:    (placesLocations) => (
        !IsEmpty(placesLocations.placePageId) ? <span>{' '}</span> : <span>{placesLocations.url}</span>
      ),
    },
    { title:     'Contact Info',
      field:     'contactInfo',
      filtering: false,
      width:     '5%',
      render:    (placesLocations) => (
        !IsEmpty(placesLocations.placePageId) ? <span>{' '}</span> : <span>{placesLocations.phoneNumber}</span>
      ),
    },
    {
      title:     'Rating',
      field:     'rating',
      filtering: false,
      width:     '5%',
    },
  ];

  const [editPlaceOrLocation, setEditPlaceOrLocation] = useState(null);
  const [editPlaceLocationIndex, setEditPlaceOrLocationIndex] = useState(-1);
  const [placeLocationDialog, setPlaceLocationDialog] = useState(false);
  const [deletePlaceLocationPopUp, setDeletePlaceLocationPopUp] = useState(false);
  const [placeLocationToDelete, setPlaceLocationToDelete] = useState([]);
  const [placesAndLocations, setPlacesAndLocations] = useState([]);

  const [pageSize, setPageSize] = useState(10);

  const fetchReviewPlacesLocations = async (reviewId, pageId) => {
    try {
      const data = await ApiService.getReview(reviewId, { pageId });
      const reviewPlaces = IsArray(data.reviewPlaces) ? data.reviewPlaces : [];
      const reviewLocations = IsArray(data.reviewLocations) ? data.reviewLocations : [];

      setPlacesAndLocations(_.concat(reviewPlaces, reviewLocations));
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    try {
      fetchReviewPlacesLocations(props.review.id, props.review.pageId);
    } catch (err) {
      toast.error(err.message);
    }
  }, [props.review]);

  const updateReviewTagsWithCustomLocationOrLinkedPlaceTags = (locationOrPlaceTags = []) => {
    // we will just add new tags from custom location or linked place's tags to review tags
    const cuisineTags = _.filter(locationOrPlaceTags, (tag) => String(tag).endsWith('cuisine'));
    const otherTags = _.difference(locationOrPlaceTags, cuisineTags);

    const cuisineTagsToAddToReview = _.difference(cuisineTags, props.review.cuisineTags);
    const otherTagsToAddToReview = _.difference(otherTags, props.review.tags);

    const copyCuisineTags = props.review.cuisineTags.slice();
    for (const tag of cuisineTagsToAddToReview) {
      copyCuisineTags.push(tag);
    }

    const copyTags = props.review.tags.slice();
    for (const tag of otherTagsToAddToReview) {
      copyTags.push(tag);
    }

    props.onChangeSetTags(copyTags, copyCuisineTags);
  };

  const updateReviewTagsWithAllCustomLocationsAndLinkedPlacesTags = () => {
    // collate all tags from linked places' tags and custom locations' tags
    let allTags = [];
    for (const place of props.review.reviewPlaces) {
      allTags = _.concat(place.tags || [], allTags);
    }
    for (const location of props.review.reviewLocations) {
      allTags = _.concat(location.tags || [], allTags);
    }

    const cuisineTags = _.uniq(_.filter(allTags, (tag) => String(tag).endsWith('cuisine')));
    const tags = _.uniq(_.difference(allTags, cuisineTags));

    props.onChangeSetTags(tags, cuisineTags);
  };

  const handleAddNewPlaceOrLocation = async (data) => {
    try {
      updateReviewTagsWithCustomLocationOrLinkedPlaceTags(data.tags || []);

      //Run fetch review again to set the places and locations after adding a new one.
      fetchReviewPlacesLocations(props.review.id, props.review.pageId);

      // close dialog
      setPlaceLocationDialog(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleUpdatePlaceOrLocation = (data) => {
    // if data has 'placePageId' => it is a Page; else it is a custom location
    try {
      if ('placePageId' in data) {
        const copy = props.review.reviewPlaces.slice();
        for (let i = 0; i < copy.length; i += 1) {
          if (data.placePageId === copy[i].placePageId) {
            // found
            copy[i] = data;
            break;
          }
        }
        props.review.reviewPlaces = copy;
      } else {
        const copy = props.review.reviewLocations.slice();
        const compareTempId = 'tempId' in data;
        for (let i = 0; i < copy.length; i += 1) {
          if ((compareTempId && data.tempId === copy[i].tempId) || (!compareTempId && data.id === copy[i].id)) {
            // found
            copy[i] = data;
            break;
          }
        }
        props.review.reviewLocations = copy;
      }

      updateReviewTagsWithAllCustomLocationsAndLinkedPlacesTags();

      // update table
      const tableCopy = placesAndLocations.slice();
      tableCopy[editPlaceLocationIndex] = data;
      setPlacesAndLocations(tableCopy);

      // close dialog
      setPlaceLocationDialog(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const deleteReviewPlace = (data) => {
    try {
      for (let index = 0; index < props.review.reviewPlaces.length; index += 1) {
        if (props.review.reviewPlaces[index].placePageId === data.placePageId) {
          props.review.reviewPlaces.splice(index, 1);
          break;
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteReviewLocation = (data) => {
    try {
      for (let index = 0; index < props.review.reviewLocations.length; index += 1) {
        if (!IsEmpty(data.tempId) && data.tempId === props.review.reviewLocations[index].tempId) {
          props.review.reviewLocations.splice(index, 1);
          break;
        } else if (!IsEmpty(data.id) && data.id === props.review.reviewLocations[index].id) {
          props.review.reviewLocations.splice(index, 1);
          break;
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deletePlaceOrLocation = async (data) => {
    try {
      const isReviewPlace = ('placePageId' in data);
      let indexToDel = -1;

      // eslint-disable-next-line guard-for-in
      for (let index = 0; index < placesAndLocations.length; index += 1) {
        const row = placesAndLocations[index];
        if (isReviewPlace) {
          if (row.placePageId === data.placePageId) {
            indexToDel = index;
            // found
            break;
          }
        } else if (IsEmpty(row.placePageId)) {
          if (!IsEmpty(data.id)) {
            // deleting a reviewLocation in db
            if (row.id === data.id) {
              indexToDel = index;
              break;
            }
          } else if (row.tempId === data.tempId) {
            indexToDel = index;
            break;
          }
        }
      }

      if (indexToDel === -1) {
        toast.error('unable to delete row due to invalid index');
        return;
      }
      // delete
      if (isReviewPlace) {
        deleteReviewPlace(data);
      } else {
        deleteReviewLocation(data);
      }

      await ApiService.deleteReviewPlaceLocation(data.id, props.review.id, data);
      const copy = placesAndLocations.slice().filter((loc, index) => index !== indexToDel);

      setPlacesAndLocations(copy);
      updateReviewTagsWithAllCustomLocationsAndLinkedPlacesTags();

      toast.success(`Review Place/Location removed.`, { autoClose: 6000 });
      setDeletePlaceLocationPopUp(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const askDeleteConfirmation = async (data) => {
    setDeletePlaceLocationPopUp(true);
    setPlaceLocationToDelete(data);
  };

  return (
    <>
      <div>
        <div className="col-12 mt-4 mb-4">
          <div className="d-flex flex-row">
            <Typography variant="subtitle1" color="inherit" className="mt-1" style={postStyle.textHeader}>
              Link Page(s) / Location(s) to this Review
            </Typography>
            <Button
              className="ml-3 px-4"
              variant="contained"
              color="primary"
              onClick={() => {
              setEditPlaceOrLocation(null);
              setPlaceLocationDialog(true);
              }}
            >
              <span className="px-4">+ Add</span>
            </Button>
          </div>
        </div>
        <ThemeProvider theme={themeTable}>
          <MaterialTable
            tableRef={tableRef}
            className="material-table"
            title=""
            columns={columns}
            onChangeRowsPerPage={() => {
              setPageSize(pageSize);
            }}
            data={placesAndLocations}
            actions={[
              {
                icon:    'edit',
                tooltip: 'Edit Review',
                onClick: (event, rowData) => {
                  setEditPlaceOrLocationIndex(rowData.tableData.id);
                  setEditPlaceOrLocation(rowData);
                  setPlaceLocationDialog(true);
                },
              },
              {
                icon:    'delete',
                tooltip: 'Delete Review',
                onClick: (event, data) => {
                  askDeleteConfirmation(data);
                },
              },
            ]}
            components={{
              Body: (data) => (
                <>
                  <MTableBody {...data} />
                </>
              ),
            }}
            options={{
              pageSize,
              pageSizeOptions:     [10, 20, 50],
              actionsColumnIndex:  -1,
              emptyRowsWhenPaging: false,
              sorting:             false,
            }}
          />
        </ThemeProvider>
      </div>

      <Dialog
        maxWidth="md"
        open={placeLocationDialog}
        onClose={() => setPlaceLocationDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">Add Page / Location</DialogTitle>
        <DialogContent>
          <LinkPageOrLocation
            existingPlaces={placesAndLocations}
            editData={editPlaceOrLocation}
            reviewId={(IsEmpty(props.review)) ? null : props.review.id}
            pageId={(IsEmpty(props.review)) ? null : props.review.pageId}
            reviewTags={(IsEmpty(props.review)) ? [] : _.concat(props.review.tags, props.review.cuisineTags)}
            onAdd={handleAddNewPlaceOrLocation}
            onCancel={() => setPlaceLocationDialog(false)}
            onUpdate={handleUpdatePlaceOrLocation}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        size="sm"
        open={deletePlaceLocationPopUp}
        onClose={() => setDeletePlaceLocationPopUp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation?</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="inherit">Delete this record?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deletePlaceOrLocation(placeLocationToDelete)} color="default" className="mr-4">Yes, Delete</Button>
          <Button onClick={() => setDeletePlaceLocationPopUp(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Locations.defaultProps = {
  onChangeSetTags: [],
};

Locations.propTypes = {
  review: PropTypes.shape({
    id:           PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pageId:       PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tags:         PropTypes.arrayOf(PropTypes.string),
    cuisineTags:  PropTypes.arrayOf(PropTypes.string),
    reviewPlaces: PropTypes.arrayOf(PropTypes.shape({
      placePageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    reviewLocations: PropTypes.arrayOf(PropTypes.shape({
      id:     PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      tempId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
  }).isRequired,
  onChangeSetTags: PropTypes.arrayOf(PropTypes.shape()),
  placesLocations: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  review: state.review.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewUpdate: (review) => reviewAction.reviewDataUpdate(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
