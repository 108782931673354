import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slider, Button } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
import OverLoading from '../../../../../components/Loading';
import Constants from '../../../../../common/constants';
import { convertDataURLtoBlob } from '../../../../../common/util';
import ApiService from '../../../../../services/apiService';

const createImage = (url) => new Promise((resolve, reject) => {
  const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', Constants.CrossOrigin);
    image.src = url;
});

class FixedCropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minZoom:           1,
      zoom:              1,
      type:              props.type,
      label:             props.label,
      format:            '',
      crop:              { x: 0, y: 0 },
      imageSrc:          props.file ? URL.createObjectURL(props.file) : null,
      base64Data:        '',
      croppedAreaPixels: null,
      blob:              null,
      photoMinResMet:    false,
      uploading:         false,
    };
    this.cropWidth = this.props.cropPixelWidth ? this.props.cropPixelWidth : 300;
    this.cropHeight = this.props.cropPixelHeight ? this.props.cropPixelHeight : 300;
    // adjust width to aspect ratio
    // this.cropWidth = this.cropHeight / this.height * this.width;
  }

  componentDidMount() {
    if (this.props.url) {
      this.getImageByProxy(this.props.url);
    }

    switch (this.state.label) {
      case 'Logo':
        this.setState({ format: 'png' });
        break;
      default:
        // save in jpeg instead of jpg because the file size is smaller for jpeg
        this.setState({ format: 'jpeg' });
    }
  }

  async getImageByProxy(url) {
    const base64Data = await ApiService.getProxyImageUrl({ url });
    if (base64Data) {
      this.setState({ base64Data: `data:image/*;base64,${base64Data}` });
    }
  }

  async getCroppedImg(imageBase, pixelCrop) {
    const { label } = this.state;
    const image = await createImage(imageBase);
    const canvas = document.createElement('canvas');
    canvas.width = this.props.outputPixelWidth ? this.props.outputPixelWidth : 300;
    canvas.height = this.props.outputPixelHeight ? this.props.outputPixelHeight : (pixelCrop.height / pixelCrop.width) * 300;

    const ctx = canvas.getContext('2d');

    if (label === 'Logo') {
      ctx.fillStyle = 'rgba(0,0,0,0)';
    } else {
      ctx.fillStyle = '#ffffff';
    }

    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      canvas.width, // to save image in specific pixel width,
      canvas.height, // to save image in specific pixel height,
    );
    return new Promise((resolve) => {
      // using canvas.toDataUrl instead of canvas.toBlob so that we can adjust
      // the quality to save in smaller file size.
      const imageData = canvas.toDataURL('image/'.concat(this.state.format), 1.0);
      const blob = convertDataURLtoBlob(imageData);
      this.setState({ blob });
      resolve(blob);
    });
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  }

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  }

  onClose = () => {
    this.props.handleClose();
  }

  saveCroppedImage = async () => {
    try {
      this.setState({ uploading: true });

      let imageBase = this.state.base64Data;
      if (this.props.type && this.props.type === 'upload') {
        imageBase = this.props.src;
      }

      await this.getCroppedImg(
        imageBase,
        this.state.croppedAreaPixels,
      )
        .then(() => {
          const file = new File([this.state.blob], 'image.'.concat(this.state.format),
            { type: 'image/'.concat(this.state.format), lastModified: new Date() });

          const promise = new Promise((resolve, reject) => {
            const save = this.props.handleSave(file);
            if (save) {
              resolve(save);
            } else {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('An unexpected error has occured while saving the image.');
            }
          });

          promise.then(() => {
            this.setState({ uploading: false });
          }).catch((err) => {
            toast.error(err.message);
            this.setState({ uploading: false });
          });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.log('error: ', error);
      toast.error('An unexpected error has occured while uploading the image.');
      this.setState({ uploading: false });
    }
  }

  render() {
    return (
      <div>
        <div className="crop-container">
          <Cropper
            onMediaLoaded={(mediaSize) => {
              const minWidth = this.props.minUploadPixelWidth ? this.props.minUploadPixelWidth : 0;
              const minHeight = this.props.minUploadPixelHeight ? this.props.minUploadPixelHeight : 0;
              this.setState({ photoMinResMet: (mediaSize.naturalWidth >= minWidth && mediaSize.naturalHeight >= minHeight) }, () => {
                if (!this.state.photoMinResMet) {
                  toast.error('Uploaded image does not meet minimum resolution required');
                }
              });
              // modify minZoom so that when we zoom out the crop area will still be within the image.
              const minWidthToUploadWidthRatio = this.cropWidth / mediaSize.width;
              const minHeightToUploadHeightRatio = this.cropHeight / mediaSize.height;
              this.setState({ minZoom: (minWidthToUploadWidthRatio > minHeightToUploadHeightRatio) ? minWidthToUploadWidthRatio : minHeightToUploadHeightRatio },
              () => {
                this.setState({ zoom: this.state.minZoom });
              });
            }}
            //image={this.state.type === "upload" ? this.state.imageSrc : this.state.imageUrl}
            image={this.state.type === 'upload' ? this.state.imageSrc : this.state.base64Data}
            crop={this.state.crop}
            cropShape={this.state.label === 'Logo' ? 'round' : 'rect'}
            minZoom={this.state.minZoom}
            maxZoom={3}
            zoom={this.state.zoom}
            showGrid
            restrictPosition
            aspect={this.props.width / this.props.height}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.state.uploading ? false : this.onZoomChange}
            cropSize={{
              width:  this.cropWidth,
              height: this.cropHeight,
            }}
          />
        </div>
        <div className="controls">
          <Slider
            value={this.state.zoom}
            min={this.state.minZoom}
            max={3}
            step={0.01}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => this.onZoomChange(zoom)}
            disabled={this.state.uploading}
          />
          <Button
            style={{ marginLeft: 50 }}
            onClick={() => { this.saveCroppedImage(); }}
            variant="contained"
            color="primary"
            disabled={this.state.uploading || !this.state.photoMinResMet}
          >
            { !this.state.uploading && this.props.isCropping ? 'Crop' : this.props.isCropping && this.state.uploading ? 'Loading' : 'Upload' }
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            onClick={() => this.onClose()}
            disabled={this.state.uploading}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
        </div>

        { this.state.uploading && <OverLoading /> }
      </div>
    );
  }
}

FixedCropper.defaultProps = {
  label:                '',
  file:                 '',
  type:                 '',
  src:                  '',
  url:                  '',
  cropPixelWidth:       300,
  cropPixelHeight:      300,
  outputPixelWidth:     300,
  outputPixelHeight:    300,
  minUploadPixelWidth:  0,
  minUploadPixelHeight: 0,
};

FixedCropper.propTypes = {
  handleSave:           PropTypes.func.isRequired,
  handleClose:          PropTypes.func.isRequired,
  label:                PropTypes.string,
  file:                 PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  type:                 PropTypes.string,
  src:                  PropTypes.string,
  url:                  PropTypes.string,
  cropPixelWidth:       PropTypes.number,
  cropPixelHeight:      PropTypes.number,
  outputPixelWidth:     PropTypes.number,
  outputPixelHeight:    PropTypes.number,
  minUploadPixelWidth:  PropTypes.number,
  minUploadPixelHeight: PropTypes.number,
  width:                PropTypes.number.isRequired,
  height:               PropTypes.number.isRequired,
  isCropping:           PropTypes.bool.isRequired,
};

export default FixedCropper;
