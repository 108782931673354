import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { WindowSize } from '../../../common/constants';
import { IsAdminOrContributor } from '../../../common/checks';
import ApiService from '../../../services/apiService';
import DesktopUpdate from './DesktopUpdate';
import MobileUpdate from './MobileUpdate';
import pageAction from '../../../actions/page';

const Page = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchPage = async () => {
    try {
      let page = await ApiService.getPageById(props.selectedPage.id);
      page = { ...page, plan: props.selectedPage.plan };
      props.dispatchPageUpdate(page);
      setIsLoaded(true);
    } catch (err) {
      setIsLoaded(true);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchPage();
  }, []);

  if (isLoaded) {
    return props.windowSize === WindowSize.Mobile
      ? <MobileUpdate disabled={!IsAdminOrContributor(props.selectedPage.staffType)} />
      : <DesktopUpdate disabled={!IsAdminOrContributor(props.selectedPage.staffType)} />;
  } else {
    return (
      <div className="container-fluid d-flex flex-column align-items-center" style={{ paddingTop: '33%' }}>
        <CircularProgress color="primary" />
      </div>
    );
  }
};

Page.propTypes = {
  selectedPage: PropTypes.shape({
    id:        PropTypes.number,
    staffType: PropTypes.string,
  }).isRequired,
  windowSize: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  selectedPage: state.page.selected,
  windowSize:   state.layout.windowSize,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageUpdate: (page) => pageAction.pageUpdate(dispatch, page),
});
export default connect(mapStateToProps, mapDispatchToProps)(Page);
