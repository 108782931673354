import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Colours from '../styles/colours';
import ApiService from '../services/apiService';
import useDebounce from '../common/use-debounce';
import { IsEmpty, Sleep } from '../common/util';

const AddressSearch = (props) => {
  const [loading, setLoading] = useState(false);
  const [postalCode, setPostalCode] = useState('');
  const [address, setAddress] = useState('');
  const [remarks, setRemarks] = useState('');
  const [addressData, setAddressData] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selected, setSelected] = useState(null);
  const debouncedSearch = useDebounce(postalCode, 500);
  const [isSearching, setIsSearching] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [displayAddress, setDisplayAddress] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      if (postalCode && postalCode.length === 6) {
        const data = await ApiService.findAddressByPostalCode(postalCode);

        setDisplayAddress(true);
        if (!IsEmpty(data.rows) && data.rows.length > 1) {
          // multiple address
          setAddress('');
          setAddressData({ postalCode });
          setAddresses(data.rows);
          setIsSearching(false);
          setHelperText('');
        } else if (!IsEmpty(data.rows)) {
          // single address
          setAddresses([]);
          setAddress(data.rows[0].address);
          setAddressData(data.rows[0]);
          setIsSearching(false);
          setHelperText('');
        } else {
          // no address found
          setAddresses([]);
          setAddress('');
          setHelperText('(No address found with the given postal code)');
          setAddressData({ postalCode });
          setIsSearching(false);
        }

        // clear selected address
        setSelected(null);

        setLoading(false);
      } else {
        if (!IsEmpty(postalCode)) {
          await Sleep(1000);
        }

        setLoading(false);
        setSelected(null);
        setIsSearching(false);
        setAddresses([]);
        setAddress('');
        setAddressData({ postalCode });
        setHelperText('');
        setDisplayAddress(false);
      }
    } catch (err) {
      console.log('Error in AddressSearch.handleSearch(): ', err);
      setSelected(null);
      setIsSearching(false);
      setAddress('');
      setAddressData({ postalCode });
      setLoading(false);
    }
  };

  const handleAddressSelection = (data, index) => {
    if (!IsEmpty(data) && !IsEmpty(index)) {
      setSelected(index);
      setAddress(data.address);
      setAddressData({ ...data, name: data.name });
    }
  };

  useEffect(() => {
    handleSearch();
  }, [debouncedSearch]);

  const handleClose = () => {
    props.onCancel();
  };

  const onDoneClick = () => {
    props.onDone({ ...addressData, address, remarks });
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="px-5 pt-5" id="alert-dialog-title">
        Address Search
      </DialogTitle>
      <DialogContent className="px-5">
        <div className="container p-0 m-0">
          <div className="row mb-3">
            <div className="col-md-4">
              <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Black }}>Enter Postal Code</Typography>
            </div>
            <div className="col-md-8">
              <TextField
                id="standard-multiline-flexible"
                value={postalCode}
                onChange={(e) => {
                  setIsSearching(true);
                  setPostalCode(e.target.value);
                }}
                helperText={helperText}
                variant="outlined"
                size="small"
                fullWidth
              />
            </div>

            {loading && (
              <div className="col-md-12 text-center">
                <hr />
                <CircularProgress />
              </div>
            ) }
          </div>

          { !IsEmpty(addresses) && addresses.length > 1 && (
            <div className="row my-3">
              <div className="col-md-12">
                <hr />
                <p className="">
                  We found multiple addresses for the postal code. Please select one.
                </p>
                <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                  {
                    _.map(addresses, (addr, index) => (
                      <div
                        key={_.uniqueId('outlet_')}
                        className="py-2 px-3 mb-2"
                        aria-hidden
                        onClick={() => handleAddressSelection(addr, index)}
                        style={{
                          background:   Colours.White,
                          cursor:       'pointer',
                          borderRadius: 4,
                          border:       (index === selected) ? `1px solid ${Colours.Primary}` : `1px solid ${Colours.Gray}`,
                          color:        (index === selected) ? Colours.Primary : Colours.Gray,
                        }}
                      >
                        { !IsEmpty(addr.name) && <p className="m-0"><strong>{ addr.name }</strong></p> }
                        <p className="m-0">
                          { addr.address }
                          {`, Singapore ${addr.postalCode}`}
                        </p>
                      </div>
                    ))
                  }
                </div>
                <hr />
              </div>
            </div>
          ) }

          {
            (displayAddress) && (
              <div className="row">
                <div className="col-md-4">
                  <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Black }}>Address</Typography>
                </div>
                <div className="col-md-8">
                  <TextField
                    id="standard-multiline-flexible"
                    multiline
                    maxRows={4}
                    value={isSearching ? 'searching...' : address}
                    onChange={(e) => {
                      setSelected(null);
                      setAddress(e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </div>
              </div>
            )
          }

          { props.remarks ? (
            <div className="row mt-3">
              <div className="col-md-4">
                <Typography variant="subtitle1" color="inherit" style={{ color: Colours.Black }}>Remarks</Typography>
              </div>
              <div className="col-md-8">
                <TextField
                  id="standard-multiline-flexible"
                  multiline
                  maxRows={4}
                  value={remarks || ''}
                  onChange={(e) => setRemarks(e.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </div>
            </div>
          ) : '' }
        </div>

      </DialogContent>
      <DialogActions className="my-3 pb-3 px-5">
        <Button
          variant="outlined"
          color="default"
          className="px-4 mr-3"
          onClick={handleClose}
        >
          Cancel
        </Button>
          &nbsp;
        <Button
          className="px-4"
          variant="contained"
          style={{ background: isSearching ? Colours.Gray4 : Colours.Primary, color: Colours.White }}
          disabled={isSearching}
          onClick={onDoneClick}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddressSearch.defaultProps = {
  remarks: false,
};

AddressSearch.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDone:   PropTypes.func.isRequired,
  remarks:  PropTypes.bool,
};

export default AddressSearch;
