import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import { AccountMaxInvitedStaff } from '../../../common/constants';
import Colours from '../../../styles/colours';
import MButton from '../../../components/MButton';

const ChangePlanModal = ({ open, onClose, pageSelected }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    keepMounted
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">Maximum number of staff invite is reached</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Your current account plan is :
        {' '}
        <span style={{ fontWeight: 'bold', color: Colours.Black }}>
          {pageSelected.plan}
          {' '}
          Tier
        </span>
        <br />
        Staff invite quota:
        {' '}
        <span style={{ fontWeight: 'bold', color: Colours.Black }}>{AccountMaxInvitedStaff[pageSelected.plan]}</span>
        {' '}
        <br />
        <br />
        To find out more or upgrade your subscription plan, click
        {' '}
        <a href={`/support?upgradePlan=true&pageName=${pageSelected.name}&pageId=${pageSelected.id}`}>here</a>
        .
      </DialogContentText>
    </DialogContent>
    <DialogActions className="mb-2">
      <MButton className="px-3 mx-3" onClick={onClose} textColour={Colours.Primary} text="Okay" />
    </DialogActions>
  </Dialog>
);

ChangePlanModal.propTypes = {
  onClose:      PropTypes.func.isRequired,
  open:         PropTypes.bool.isRequired,
  pageSelected: PropTypes.shape({
    id:        PropTypes.number,
    name:      PropTypes.string,
    staffType: PropTypes.string,
    plan:      PropTypes.string,
  }).isRequired,
};

export default ChangePlanModal;
