import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { WindowSize } from '../../../../common/constants';
import MenuItem from './MenuItem';

const Menu = (props) => {
  const [selected, setSelected] = useState(props.selected);

  return (
    <div
      className="col-12 text-center mt-4 mt-md-2 mt-lg-0 justify-content-center"
      style={{}}
    >
      {
        _.map(props.data, (d) => (
          <MenuItem
            key={d.text}
            icon={d.icon}
            text={d.text}
            width={props.windowSize === WindowSize.Mobile ? 130 : 160}
            selected={d.text === selected}
            onClick={() => {
              setSelected(d.text);
              props.onChange(d.text);
            }}
          />
        ))
      }
    </div>
  );
};

Menu.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
  })),
  selected:   PropTypes.string,
  onChange:   PropTypes.func,
  windowSize: PropTypes.string.isRequired,
};

Menu.defaultProps = {
  data:     [],
  selected: '',
  onChange: () => {},
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(Menu);
