// State to store the pending or rejected PageClaims the Business User has created
import Constants from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  pageClaims: [],
  selected:   {},

  claim: {
    pageClaim:       {},
    businessProfile: {},
  },
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.PageClaims:
      return {
        ...state,
        pageClaims: payload,
      };

    case ReduxAction.PageClaimSelected:
      return {
        ...state,
        selected: payload,
      };

    case ReduxAction.PageClaimUpdate:
      return {
        ...state,
        claim: { ...state.claim, ...payload },
      };

    case ReduxAction.PageClaimNew:
      return {
        ...state,
        claim: { pageClaim: {}, businessProfile: {} },
      };

    case ReduxAction.PageClaimDelete:
      return {
        ...state,
        pageClaims: [...state.pageClaims.filter((x) => x.id !== payload)],
      };

    default:
      return state;
  }
}
