import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Typography, IconButton } from '@material-ui/core';
import Constants from '../../../../common/constants';
import { TabLinkStyled } from '../../../../styles/components/TabLink';
import Info from './Info';
import OpeningHours from './OpeningHours';
import OpeningHoursMobile from './OpeningHoursMobile';
import Exceptions from './Exceptions';
import ExceptionsMobile from './ExceptionsMobile';
import apiService from '../../../../services/apiService';
import Loading from '../../../../components/Loading';
import { IfEmptyThrowError, IsAdminOrContributor } from '../../../../common/checks';
import { Trim } from '../../../../common/util';
import Icons from '../../../../components/Common/Icons';
import Colours from '../../../../styles/colours';

function Outlet({ page, history, windowSize }) {
  const [outlet, setOutlet] = useState(null);
  const [view, setView] = useState('info');
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [loading, setLoading] = useState(true);
  const [defaultRemovel, setdefaultRemovel] = useState(true);

  const links = [
    {
      view: 'info',
      name: 'Info',
      to:   `${Constants.Url.Outlet.replace(':pageId', page.id)}?view=info`,
    },
    {
      view: 'openinghours',
      name: 'Opening Hours',
      to:   `${Constants.Url.Outlet.replace(':pageId', page.id)}?view=openinghours`,
    },
    {
      view: 'exceptions',
      name: 'Exceptions',
      to:   `${Constants.Url.Outlet.replace(':pageId', page.id)}?view=exceptions`,
    },
  ];

  useEffect(() => {
    async function getOutlet() {
      try {
        if (params.action === 'edit') {
          const data = await apiService.getOutlet({
            outletId: params.outletId,
          });
          setLoading(false);
          // some existing outlets have openingHours as null value. we need to set default for these outlets.
          setOutlet({
            ...data,
            openingHours: data.openingHours ? data.openingHours : {
              default: [
                { day: 'Monday', isOpen: true, is24Hours: false, hours: [] },
                { day: 'Tuesday', isOpen: true, is24Hours: false, hours: [] },
                { day: 'Wednesday', isOpen: true, is24Hours: false, hours: [] },
                { day: 'Thursday', isOpen: true, is24Hours: false, hours: [] },
                { day: 'Friday', isOpen: true, is24Hours: false, hours: [] },
                { day: 'Saturday', isOpen: true, is24Hours: false, hours: [] },
                { day: 'Sunday', isOpen: true, is24Hours: false, hours: [] },
              ],
              eve:        { isOpen: true, is24Hours: false, hours: [] },
              holiday:    { isOpen: true, is24Hours: false, hours: [] },
              exceptions: [],
            },
          });
        } else {
          setLoading(false);
          setOutlet(
            {
              id:           outlet ? outlet.id : '',
              name:         outlet ? outlet.name : page.name,
              address:      outlet ? outlet.address : '',
              postalCode:   outlet ? outlet.postalCode : '',
              locatedIn:    outlet ? outlet.locatedIn : '',
              unitNo:       outlet ? outlet.unitNo : '',
              tel:          outlet ? outlet.tel : '',
              openingHours: outlet
                ? outlet.openingHours
                : {
                  default: [
                    { day: 'Monday', isOpen: true, is24Hours: false, hours: [] },
                    { day: 'Tuesday', isOpen: true, is24Hours: false, hours: [] },
                    { day: 'Wednesday', isOpen: true, is24Hours: false, hours: [] },
                    { day: 'Thursday', isOpen: true, is24Hours: false, hours: [] },
                    { day: 'Friday', isOpen: true, is24Hours: false, hours: [] },
                    { day: 'Saturday', isOpen: true, is24Hours: false, hours: [] },
                    { day: 'Sunday', isOpen: true, is24Hours: false, hours: [] },
                  ],
                  eve:        { isOpen: true, is24Hours: false, hours: [] },
                  holiday:    { isOpen: true, is24Hours: false, hours: [] },
                  exceptions: [],

                },
            },
          );
        }
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    }
    getOutlet();
  }, []);

  useEffect(() => {
    setView(params.view);
  }, [location.search]);

  const handleInputChange = (name, value) => {
    setOutlet((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleExceptionsChange = (exceptions) => {
    if (exceptions) {
      /**
       * Loop hours
       * check 00:00 AM case , if exist . is24Hours set true
       */
      exceptions.forEach((op) => {
        if (op.hours.length > 0) {
          const is24Hours = op.hours[0].from === '00:00 AM' && op.hours[0].to === '00:00 AM';
          op.is24Hours = is24Hours;
        } else if (op.hours.length === 0 && op.is24Hours && defaultRemovel) {
          op.hours.push({ from: '00:00 AM', to: '00:00 AM' });
        }
      });

      setOutlet((prevState) => {
        if (prevState) {
          prevState.openingHours.exceptions = exceptions;
          return ({
            ...prevState,
          });
        }
      });
    }
  };

  const handleOpeningHoursChange = (openingHours) => {
    if (openingHours) {
      const eve = openingHours.find((opHour) => opHour.day === 'Eve of Public Holiday');
      const holiday = openingHours.find((opHour) => opHour.day === 'Public Holiday');
      const openingHoursForUpdate = openingHours.filter((opHour) => opHour.day !== 'Eve of Public Holiday' && opHour.day !== 'Public Holiday');
      /**
       * Loop hours
       * check 00:00 AM case , if exist . is24Hours set true
       */
      openingHoursForUpdate.forEach((op) => {
        if (op.hours.length > 0) {
          const is24Hours = op.hours[0].from === '00:00 AM' && op.hours[0].to === '00:00 AM';
          op.is24Hours = is24Hours;
        } else if (op.hours.length === 0 && op.is24Hours && defaultRemovel) {
          op.hours.push({ from: '00:00 AM', to: '00:00 AM' });
        }
      });

      if (eve) {
        if (eve.hours.length > 0) {
          const found = eve.hours.find((item) => item.from === '00:00 AM' && item.to === '00:00 AM');
          eve.is24Hours = !!found;
        } else if (eve.hours.length === 0 && eve.is24Hours && defaultRemovel) {
          eve.hours.push({ from: '00:00 AM', to: '00:00 AM' });
        }
      }

      if (holiday) {
        if (holiday.hours.length > 0) {
          const found = holiday.hours.find((item) => item.from === '00:00 AM' && item.to === '00:00 AM');
          holiday.is24Hours = !!found;
        } else if (holiday.hours.length === 0 && holiday.is24Hours && defaultRemovel) {
          holiday.hours.push({ from: '00:00 AM', to: '00:00 AM' });
        }
      }

      setOutlet((prevState) => {
        if (prevState) {
          prevState.openingHours.default = openingHoursForUpdate;
          prevState.openingHours.eve = _.omit(eve, 'day');
          prevState.openingHours.holiday = _.omit(holiday, 'day');
          return ({
            ...prevState,
          });
        }
      });
    }
  };

  const validate = (o) => {
    o.name = Trim(o.name);
    o.unitNo = Trim(o.unitNo);
    o.tel = Trim(o.tel);
    setOutlet({ ...o });
    IfEmptyThrowError(o.name, 'Name is required');
    IfEmptyThrowError(o.address, 'Address is required');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      validate(outlet);
      if (params.action === 'edit') {
        const updatedOutlet = _.omit(outlet, 'id');
        await apiService.updateOutlet({
          dataToUpdate: {
            pageId: page.id,
            ...updatedOutlet,
            lat:    updatedOutlet.lat || null,
            lng:    updatedOutlet.lng || null,
          },
          outletId: params.outletId,
        });

        toast.success('Outlet updated');
        history.push(`${Constants.Url.Outlets.replace(':pageId', page.id)}`);
      } else if (params.action === 'add') {
        const createdOutlet = _.omit(outlet, 'id');
        await apiService.createOutlet({
          ...createdOutlet,
          lat:    createdOutlet.lat || null,
          lng:    createdOutlet.lng || null,
          pageId: page.id,
        });
        history.push(`${Constants.Url.Outlets.replace(':pageId', page.id)}`);
        toast.success('Outlet created');
      } else {
        toast.error('Unknown action. Please contact support for assistance');
        history.push(`${Constants.Url.Outlets.replace(':pageId', page.id)}`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderLinks = () => links.map((link, i) => (
    <Fragment key={i}>
      <TabLinkStyled
        to={`${link.to}&action=${params.action}&outletId=${params.outletId}`}
        checked={link.view === view}
      >
        {link.name}
      </TabLinkStyled>
      {
        i !== 2 && (
          <div style={{
            display:        'flex',
            justifyContent: 'center',
            alignItems:     'center',
          }}
          >
            <div>
              &gt;&gt;
            </div>
          </div>
        )
      }
    </Fragment>
  ));

  const renderTabsByView = () => {
    switch (view) {
      case 'info':
        return (
          <Info
            outlet={outlet || {}}
            action={params.action}
            pageId={page.id}
            history={history}
            handleInputChange={handleInputChange}
            disabled={!IsAdminOrContributor(page.staffType)}
          />
        );

      case 'openinghours':
        if (windowSize === 'Mobile') {
          return (
            <OpeningHoursMobile
              pageId={page.id}
              history={history}
              params={params}
              location={location}
              outlet={outlet || {}}
              handleOpeningHoursChange={handleOpeningHoursChange}
              setdefaultRemovel={setdefaultRemovel}
            />
          );
        } else {
          return (
            <OpeningHours
              pageId={page.id}
              history={history}
              params={params}
              location={location}
              outlet={outlet || {}}
              handleOpeningHoursChange={handleOpeningHoursChange}
              setdefaultRemovel={setdefaultRemovel}
            />
          );
        }

      case 'exceptions':
        if (windowSize === 'Mobile') {
          return (
            <ExceptionsMobile
              pageId={page.id}
              history={history}
              params={params}
              location={location}
              outlet={outlet || {}}
              handleExceptionsChange={handleExceptionsChange}
              setdefaultRemovel={setdefaultRemovel}
            />
          );
        } else {
          return (
            <Exceptions
              pageId={page.id}
              history={history}
              params={params}
              location={location}
              outlet={outlet || {}}
              handleExceptionsChange={handleExceptionsChange}
              setdefaultRemovel={setdefaultRemovel}
            />
          );
        }

      default:
        return (
          <div>
            DON&apos;T EDIT YOUR URL
          </div>
        );
    }
  };

  return (
    <div className="container-fluid py-1">
      {loading && <Loading />}

      <div className="d-inline-flex align-items-center">
        <Link
          to={`${Constants.Url.Outlets.replace(':pageId', page.id)}`}
          style={{
            color: 'inherit',
          }}
        >
          <IconButton style={{ width: 50, height: 50 }}>
            <Icons.ArrowBack colour={Colours.Black} />
          </IconButton>
        </Link>

        <Typography variant="body1" className="ml-2" color="inherit" style={{ color: Colours.Gray }}>
          {params.outletId !== 'undefined' ? 'Edit ' : 'Add '}
          Outlet
        </Typography>
      </div>

      <div style={{ display: 'flex' }}>
        {renderLinks()}
      </div>
      <form
        className="d-flex flex-column"
        onSubmit={(e) => onSubmit(e)}
        autoComplete="off"
      >
        {renderTabsByView()}

        <div className="row d-flex flex-row justify-content-start">
          <div className="col-12 col-md-10 col-xl-9 col-lg-8 d-flex flex-column align-items-center">
            <div className="col-12 col-md-6 col-lg-4">
              <Button
                className="block mt-3 mb-4"
                variant="contained"
                color="primary"
                type="submit"
                disabled={!IsAdminOrContributor(page.staffType)}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

Outlet.propTypes = {
  page: PropTypes.shape({
    id:        PropTypes.number.isRequired,
    name:      PropTypes.string,
    staffType: PropTypes.string,
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  windowSize: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  page:       state.page.selected,
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps)(Outlet);
