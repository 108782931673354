/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { IsEmpty } from '../../../common/checks';
import { WindowSize } from '../../../common/constants';
import Loading from '../../../components/Loading';
import ReviewAction from '../../../actions/review';

import PageAction from '../../../actions/page';
import ApiService from '../../../services/apiService';
import DesktopUpdate from './DesktopUpdate';
import MobileUpdate from './MobileUpdate';
import { showDateInSGTime } from '../../../common/util';

const Review = (props) => {
  const [reviewData, setReviewData] = useState(null);
  const [pageData, setPageData] = useState(null);

  const fetchReviewAndPage = async () => {
    try {
      const { reviewId, pageId } = props.match.params;
      await ApiService.getReview(reviewId, { pageId })
        .then((r) => {
          setReviewData(r);
          const payload = {
            ...r,
            currentReviewStatus: r.status,
            reviewDate:          showDateInSGTime(r.reviewDate),
            expireDate:          showDateInSGTime(r.expireDate),
          };
          props.dispatchReviewSelected(payload);
        })
        .catch((err) => {
          toast.error(err.message);
        });

      let page = await ApiService.getPageById(pageId);
      page = { ...page, plan: props.page.plan, staffType: props.page.staffType };
      setPageData(page);
      props.dispatchPageSelected(page);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchReviewAndPage();
  }, []);

  if (IsEmpty(reviewData) && IsEmpty(pageData)) {
    return (<Loading />);
  } else if (props.windowSize === WindowSize.Mobile) {
    return (
      <MobileUpdate {...props} getReview={fetchReviewAndPage} />
    );
  } else {
    return (
      <DesktopUpdate {...props} getReview={fetchReviewAndPage} />
    );
  }
};

const mapStateToProps = (state) => ({
  business: state.business.selected,
  page:     state.page.selected,

  windowSize: state.layout.windowSize,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewSelected: (review) => ReviewAction.reviewSelected(dispatch, review),
  dispatchPageSelected:   (page) => PageAction.pageSelected(dispatch, page),
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
