// React Dependencies
import React, { useState, useEffect } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography, Button, TextField, TextareaAutosize, makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';

// Internal Dependencies
import Loading from '../../../components/Loading';
import { IfEmptyThrowError, IsEmpty, IsValidHexColor } from '../../../common/checks';
import ApiService from '../../../services/apiService';
import AreaMap from '../../../components/AreaMap';
import Colours from '../../../styles/colours';
import { Trim } from '../../../common/util';
import { PageType } from '../../../common/constants';
import { containerHeaderTextStyle } from '../../../styles/common';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '85%',

    [theme.breakpoints.down('sm')]: {
      width: '94%',
    },
  },
}));

const GeographicBoundary = ({ selectedPage }) => {
  const classes = useStyles();

  const pageId = selectedPage.id;
  const defaultColour = selectedPage && selectedPage.pageType === PageType.AreaManagement ? 'FF6699' : 'FF0000';

  const [loading, setLoading] = useState(true);
  const [colour, setColour] = useState(defaultColour);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [coords, setCoords] = useState([]);
  const [calloutCoord, setCalloutCoord] = useState({});
  const [data, setData] = useState(null);
  const [calloutMapBound, setCalloutMapBound] = useState([]);

  const getGeographicBoundaryDataHandler = async () => {
    try {
      setLoading(true);
      const mapBound = await ApiService.getMapBound(pageId);

      if (mapBound) {
        setColour(mapBound.colour);
        setTitle(mapBound.calloutTitle || '');
        setContent(mapBound.calloutContent || '');
        setCoords(mapBound.coords);
        setCalloutCoord(mapBound.calloutCoord);
        setData(mapBound);
      }
    } catch (error) {
      toast.error('Error while fetching data for geographic boundary');
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSaveHandler = async () => {
    try {
      IfEmptyThrowError(coords, 'Please select region in map as geographic boundary');

      setLoading(true);
      const calloutContent = Trim(content);

      if (calloutContent.length > 100) {
        return toast.warn('The content should have maximum 100 characters');
      }

      const payload = {
        pageId:       Number(pageId),
        coords,
        colour:       IsValidHexColor(colour) ? colour : defaultColour,
        calloutTitle: title,
        calloutContent,
        calloutCoord,
      };

      if (IsEmpty(data)) { // CREATE
        await ApiService.createMapBound(pageId, payload);
        toast.success('Geographic boundary successfully created');
      } else { // UPDATE
        payload.id = data.id;
        await ApiService.updateMapBound(pageId, payload);
        toast.success('Geographic boundary successfully updated');
      }

      // fetch latest data
      await getGeographicBoundaryDataHandler();
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGeographicBoundaryDataHandler();
  }, []);

  useEffect(() => {
    setCalloutMapBound([...coords]);
  }, [coords]);

  return (
    <div className="mb-5 pb-4">
      {loading && <Loading />}
      <div className="container-fluid pt-2">
        <div className={`mx-2 my-3 ${classes.container}`}>
          <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style} color="inherit">
            Geographic Boundary
          </Typography>

          { selectedPage && selectedPage.pageType === PageType.AreaManagement && (
            <div className="mt-2">
              <Typography variant="subtitle1" color="inherit" style={{ fontSize: '13px' }}>
                Colour (the colour that will be displayed in mobile app under Food Reviews and Deals Maps)
              </Typography>

              <div className="mt-0 pl-2 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <span style={{ fontWeight: 'bold' }}>#</span>
                  <TextField
                    className="ml-1 mr-4 p-2"
                    variant="outlined"
                    style={{ width: '110px' }}
                    inputProps={{ style: { backgroundColor: Colours.White, fontSize: '13px', padding: '10px 14px' } }}
                    value={colour}
                    onChange={(e) => setColour(e.target.value)}
                  />
                </div>
                { IsValidHexColor(colour) && <span className="d-block" style={{ width: '140px', height: '35px', backgroundColor: `#${colour}` }} /> }
              </div>
            </div>
          ) }

          { !loading && (
            <div>
              <AreaMap
                style={{ padding: 0 }}
                buttonContainerStyles={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}
                buttonSubContainerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                Heading={() => (
                  <Typography variant="subtitle1" color="inherit" style={{ fontSize: '13px' }}>
                    Select region in map as geographic boundary for this page
                  </Typography>
                )}
                colour={IsValidHexColor(colour) ? `#${colour}` : ''}
                defaultColour={`#${defaultColour}`}
                setMapCoor={(c) => {
                  setCoords([...c]);
                }}
                mapCoordinates={coords}
              />
            </div>
          )}
          <hr />

          { selectedPage && selectedPage.pageType === PageType.AreaManagement && (
            <div>
              <Typography variant="subtitle1" color="inherit" style={{ maxWidth: '360px', fontSize: '13px' }}>
                Enter the title and content that will be shown in Deals Map for the geographic boundary
              </Typography>

              <div className="geographic_boundary__formContentContainer">
                <div className="geographic_boundary__formContentSubContainer">
                  <div className="mt-3">
                    <h6 style={{ fontSize: '13px' }}>Title</h6>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={title}
                      inputProps={{ style: { fontSize: '13px' } }}
                      className="bg-white"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="mt-3">
                    <h6 className="mb-2" style={{ fontSize: '13px' }}>Content (Max 100 characters)</h6>
                    <TextareaAutosize
                      variant="outlined"
                      minRows={6}
                      className="w-100"
                      style={{
                        fontSize:     '13px',
                        borderRadius: '4px',
                        padding:      '12px',
                        border:       content.length > 100 ? `2px solid ${Colours.Red}` : `1.5px solid ${Colours.Gray5}`,
                        outlineWidth: content.length > 100 ? '0' : '2px',
                      }}
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                    />
                  </div>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: '65%' }}>
                  <img
                    // eslint-disable-next-line global-require
                    src={require('../../../assets/images/sampleAreaMarker.jpeg')}
                    alt="map"
                    style={{ width: '300px', height: '250px', objectFit: 'cover' }}
                  />
                  <Typography style={{ textAlign: 'center', fontStyle: 'italic' }} variant="subtitle1" color="inherit">Example</Typography>
                </div>
              </div>

              { !loading && (
                <div className="mt-3">
                  <AreaMap
                    areaType={selectedPage.pageType}
                    style={{ padding: 0 }}
                    buttonContainerStyles={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}
                    buttonSubContainerStyle={{ display: 'flex', justifyContent: 'flex-end' }}
                    Heading={() => (
                      <Typography variant="subtitle1" color="inherit" style={{ fontSize: '13px' }}>
                        Select callout coord in map for this page
                      </Typography>
                    )}
                    colour={IsValidHexColor(colour) ? `#${colour}` : ''}
                    defaultColour={`#${defaultColour}`}
                    isCalloutCoord
                    setCalloutCoord={(c) => setCalloutCoord(c)}
                    calloutCoord={calloutCoord}
                    title={title}
                    content={content}
                    mapCoordinates={calloutMapBound}
                  />
                </div>
              )}
            </div>
          )}

          <div className="d-flex mt-3 mb-3">
            <Button onClick={onSaveHandler} variant="contained" color="primary">
              {!IsEmpty(data) ? 'Update' : 'Add'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

GeographicBoundary.propTypes = {
  selectedPage: PropTypes.shape({
    id:       PropTypes.number,
    name:     PropTypes.string,
    pageType: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  selectedPage: state.page.selected,
});

export default connect(mapStateToProps, null)(GeographicBoundary);
