import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Constants, { PageType, WindowSize } from '../../../../common/constants';
import Loading from '../../../../components/Loading';
import ApiService from '../../../../services/apiService';
import DesktopList from './DesktopList';
import MobileList from './MobileList';
import reviewAction from '../../../../actions/review';
import { IfTrueThrowError } from '../../../../common/checks';

const ReviewListing = (props) => {
  const [loading, setLoading] = useState(false);

  const createReview = async () => {
    try {
      setLoading(true);
      const payload = {
        pageId: props.match.params.pageId,
      };
      const getPage = await ApiService.getPageById(payload.pageId);
      console.log(getPage);
      IfTrueThrowError(getPage.type !== PageType.FoodReview, 'Page Type is not Food Review');

      const review = await ApiService.addReview(payload);
      setLoading(false);
      props.dispatchReviewSelected(review);
      props.history.push(Constants.Url.Review.replace(':pageId', review.pageId).replace(':reviewId', review.id));
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  /* eslint-disable react/jsx-props-no-spreading */
  if (loading) {
    return <Loading />;
  } else if (props.windowSize === WindowSize.Mobile) {
    return (
      <MobileList {...props} addReview={() => createReview()} />
    );
  } else {
    return (
      <DesktopList {...props} addReview={() => createReview()} />
    );
  }
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
  page:       state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewSelected: (review) => reviewAction.reviewSelected(dispatch, review),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewListing);
