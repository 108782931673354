import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import Colours from '../../styles/colours';

const DateRangeInput = (props) => {
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate:   new Date(),
      key:       'selection',
    },
  ]);

  useEffect(() => {
    if (props.startDate !== null) {
      setDate([{
        startDate: new Date(props.startDate),
        endDate:   new Date(props.endDate),
        key:       'selection',
      }]);
    }
  }, [props.startDate]);

  const onDateChange = (data) => {
    setDate(data);
    props.onDateRangeChange({ startDate: String(data[0].startDate), endDate: String(data[0].endDate) });
  };

  return (
    <DateRange
      editableDateInputs
      onChange={(item) => onDateChange([item.selection])}
      moveRangeOnFirstSelection={false}
      ranges={date}
      showDateDisplay={false}
      rangeColors={[Colours.Primary]}
    />
  );
};

DateRangeInput.propTypes = {
  onDateRangeChange: PropTypes.func.isRequired,
  startDate:         PropTypes.string,
  endDate:           PropTypes.string,
};

DateRangeInput.defaultProps = {
  startDate: '',
  endDate:   '',
};

export default DateRangeInput;
