import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Paper, Typography, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Colours from '../../../styles/colours';
import Card from './Card';
import Icons from '../../../components/Common/Icons';
import ApiService from '../../../services/apiService';
import { IsEmpty } from '../../../common/util';
import Constants from '../../../common/constants';

const _paperRadius = 14;
const loadingStyle = {
  borderRadius:   14,
  top:            0,
  left:           0,
  width:          '100%',
  height:         '100%',
  background:     'rgba(0,0,0,0.5)',
  backdropFilter: 'blur(2px)',
  color:          Colours.White,
  zIndex:         10,
};

const PageAnalytics = (props) => {
  const [loading, setLoading] = useState(false);
  const [viewDuration, setViewDuration] = useState(7);
  const [engagementDuration, setEngagementDuration] = useState(7);

  const [viewCount, setViewCount] = useState(0);
  const [engagementCount, setEngagementCount] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);

  const getPageAnalytics = async (pageId) => {
    try {
      if (!IsEmpty(pageId)) {
        setLoading(true);

        const getAnalyticPageView = await ApiService.getAnalyticPageViewCountByDuration(pageId, viewDuration);
        const getAnalyticPageEngagement = await ApiService.getAnalyticPageEngagementCountByDuration(pageId, engagementDuration);
        const getAnalyticFollower = await ApiService.getAnalyticPageFollowerCountById(pageId);

        setViewCount(getAnalyticPageView.count);
        setEngagementCount(getAnalyticPageEngagement.count);
        setFollowerCount(getAnalyticFollower.count);

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const getDays = (str) => {
    let noOfDays = 7;

    if (!IsEmpty(str)) {
      switch (str) {
        case Constants.Duration.ThisWeek:
          noOfDays = 7;
          break;
        case Constants.Duration.Last2Weeks:
          noOfDays = 14;
          break;
        case Constants.Duration.LastMonth:
          noOfDays = 30;
          break;
        default:
          noOfDays = 7;
      }
    }

    return noOfDays;
  };

  const getAnalyticPageViewCountByDuration = async (pageId, duration) => {
    try {
      setLoading(true);
      const noOfDays = getDays(duration);
      const res = await ApiService.getAnalyticPageViewCountByDuration(pageId, noOfDays);
      setViewCount(res.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const getAnalyticPageEngagementCountByDuration = async (pageId, duration) => {
    try {
      setLoading(true);
      const noOfDays = getDays(duration);
      const res = await ApiService.getAnalyticPageEngagementCountByDuration(pageId, noOfDays);
      setEngagementCount(res.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (props.page) {
      getPageAnalytics(props.page);
    }
  }, []);

  useEffect(() => {
    if (props.page && viewDuration) {
      getAnalyticPageViewCountByDuration(props.page, viewDuration);
    }
  }, [viewDuration]);

  useEffect(() => {
    if (props.page && engagementDuration) {
      getAnalyticPageEngagementCountByDuration(props.page, engagementDuration);
    }
  }, [engagementDuration]);

  return (
    <div className="position-relative">
      <Paper className="d-flex flex-column pt-4" style={{ borderRadius: _paperRadius }}>

        {loading && (
          <div className="position-absolute d-flex flex-column justify-content-center align-items-center" style={loadingStyle}>
            <Typography variant="h5">Loading page analytics</Typography>
            <CircularProgress className="mt-3" color="inherit" />
          </div>
        )}

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 pl-4">
              <Typography className="px-4" variant="body1" color="inherit" style={{ color: Colours.Gray1 }}>Page Analytics</Typography>
            </div>
          </div>

          <div className="row mt-4 px-4">
            <div className="col-md-6 col-lg-6 col-xl-4 mb-3 position-relative overflow-hidden">
              <Card
                icon={Icons.Eye}
                title="Views"
                subtitle="Number of page views"
                subtitle2="(updated daily)"
                count={viewCount}
                selectable={[Constants.Duration.ThisWeek, Constants.Duration.Last2Weeks, Constants.Duration.LastMonth]}
                setSelected={(value) => setViewDuration(value)}
              />
              <div className="position-absolute border-right d-none d-md-block d-lg-block" style={{ width: 5, height: '100vh', top: 0, right: 0 }} />
            </div>

            <div className="col-md-6 col-lg-6 col-xl-4 mb-3 position-relative overflow-hidden">
              <Card
                icon={Icons.Engagement}
                title="Engagements"
                count={engagementCount}
                subtitle="Number of consumer engagements recorded for this page"
                subtitle2="(updated daily)"
                selectable={[Constants.Duration.ThisWeek, Constants.Duration.Last2Weeks, Constants.Duration.LastMonth]}
                setSelected={(value) => setEngagementDuration(value)}
              />
              <div className="position-absolute border-right d-none d-xl-block" style={{ width: 5, height: '100vh', top: 0, right: 0 }} />
            </div>

            <div className="col-md-6 col-lg-6 col-xl-4 mb-3 position-relative overflow-hidden">
              <Card
                icon={Icons.Follow}
                title="Followers"
                subtitle="Number of consumers following this page"
                subtitle2="(updated every 15mins)"
                count={followerCount}
              />
              {/* <div className="position-absolute border-right d-none d-xl-block" style={{ width: 5, height: '100vh', top: 0, right: 0 }} /> */}
            </div>

            {/*<div className="col-md-6 col-lg-6 col-xl-4 mb-3 position-relative overflow-hidden">
              <Card
                icon={Icons.Impression}
                title="Impressions"
                subtitle="Number of impressions for this post"
                count={impressionCount}
              />
              <div className="position-absolute border-right d-none d-xl-none d-md-block" style={{ width: 5, height: '100vh', top: 0, right: 0 }} />
            </div>

            <div className="col-md-6 col-lg-6 col-xl-4 mb-3 position-relative overflow-hidden">
              <Card
                icon={Icons.Share}
                title="Shares"
                subtitle="Number of times consumer shared this page"
                count={shareCount}
              />
              <div className="position-absolute border-right d-none d-xl-block" style={{ width: 5, height: '100vh', top: 0, right: 0 }} />
            </div>*/}
          </div>
        </div>
      </Paper>
    </div>
  );
};

PageAnalytics.propTypes = {
  page: PropTypes.number.isRequired,
};

PageAnalytics.defaultProps = {
};

export default connect(null, null)(PageAnalytics);
