import Constants from '../common/constants';

const { ReduxAction } = Constants;

const login = (dispatch, token) => {
  dispatch({
    type:    ReduxAction.Login,
    payload: {
      token,
    },
  });
};

const logout = (dispatch) => {
  dispatch({
    type: ReduxAction.Logout,
  });
};

const signup = (dispatch, token) => {
  dispatch({
    type:    ReduxAction.Signup,
    payload: {
      token,
    },
  });
};

const tokenExpired = (dispatch) => {
  dispatch({
    type: ReduxAction.TokenExpired,
  });
};

const AuthAction = {
  login,
  logout,
  signup,
  tokenExpired,
};

export default AuthAction;
