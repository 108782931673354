import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Typography } from '@material-ui/core';
import { Redirect } from 'react-router';
import { IsEmpty } from '../../../common/checks';
import Loading from '../../../components/Loading';
import Step1 from './Step1';
import Step2 from './Step2';
import ApiService from '../../../services/apiService';
import Step3 from './Step3';
import Summary from './Summary';
import Constants from '../../../common/constants';
import pageClaimAction from '../../../actions/pageClaim';
import { containerHeaderTextStyle } from '../../../styles/common';

const CreatePageClaim = (props) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [redirectUrl, setRedirectUrl] = useState('');

  const handleSave = async () => {
    try {
      setLoading(true);
      const { pageClaim, businessProfile } = props.claim;
      const data = {
        pageClaim,
        businessProfile: {
          ...businessProfile,
          country: 'SG',
        },
      };
      const result = await ApiService.createPageClaim(data);

      // Add the new page claim to the existing page claims (if any) of the BU
      const updatedPageClaims = !IsEmpty(props.pageClaims) ? props.pageClaims.slice() : [];
      updatedPageClaims.push(result);
      props.dispatchPageClaims(updatedPageClaims);

      // Reset page claim submission data in redux
      props.dispatchPageClaimNew();

      // Update redux we are selecting this page claim and navigate to view it
      props.dispatchPageClaimSelected(result);
      setRedirectUrl(`${Constants.Url.PageClaimView.replace(':pageClaimId', result.id)}?showSuccess=true`);
    } catch (err) {
      setLoading(false);
      if (err.message === 'Page has been claimed') {
        toast.error(`The page you're requesting is not available. Please contact support for assistance.`);
      } else {
        toast.error(err.message);
      }
    }
  };

  const setContent = () => {
    switch (step) {
      case 1:
        return <Step1 onNextClick={() => setStep(2)} />;
      case 2:
        return <Step2 onBackClick={() => setStep(1)} onNextClick={() => setStep(3)} />;
      case 3:
        return <Step3 onBackClick={() => setStep(2)} onSummaryClick={() => setStep(4)} />;
      case 4:
        return <Summary onBackClick={() => setStep(3)} onSubmitClick={() => handleSave()} />;
      default:
        break;
    }
  };

  return !IsEmpty(redirectUrl) ? <Redirect to={redirectUrl} /> : (
    <>
      {loading && <Loading /> }
      <div className="container-fluid mt-4">
        <div className="row d-flex flex-column align-items-center">
          <div className="col-12 col-md-7 row">
            <div className="col-12 mb-3">
              <Typography variant={containerHeaderTextStyle.variant} style={containerHeaderTextStyle.style}>Create/Claim a Page</Typography>
            </div>

            {
              step !== 4 && (
                <div className="col-12 mb-1">
                  <Typography variant="subtitle1">
                    Step
                    {' '}
                    {step}
                    /3
                  </Typography>
                  <Typography className="ml-1" variant="caption" color="inherit" style={{ color: 'red' }}>*required</Typography>
                </div>
              )
            }

            <div className="col-12 pb-3">
              {setContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CreatePageClaim.propTypes = {
  claim:      PropTypes.shape().isRequired,
  pageClaims: PropTypes.arrayOf(PropTypes.shape()).isRequired,

  dispatchPageClaimNew:      PropTypes.func.isRequired,
  dispatchPageClaimSelected: PropTypes.func.isRequired,
  dispatchPageClaims:        PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  claim:      state.pageClaim.claim,
  pageClaims: state.pageClaim.pageClaims,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPageClaimNew:      () => pageClaimAction.pageClaimNew(dispatch),
  dispatchPageClaimSelected: (pageClaim) => pageClaimAction.pageClaimSelected(dispatch, pageClaim),
  dispatchPageClaims:        (pageClaims) => pageClaimAction.pageClaims(dispatch, pageClaims),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePageClaim);
