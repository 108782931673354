import { makeStyles, Typography, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { IsEmpty } from '../../../common/checks';
import { datePeriodToString } from '../../../common/util';
import Colours from '../../../styles/colours';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding:  10,
    margin:   'auto',
    maxWidth: '100%',
    flexGrow: 1,
  },
}));

const PreviewMobile = (props) => {
  const classes = useStyles();
  const [cover, setCover] = useState('');
  const [promoDate, setPromoDate] = useState('');

  useEffect(() => {
    if (!IsEmpty(props.post)) {
      if (!IsEmpty(props.post.videos)) {
        setCover(props.post.videos[0]);
      } else if (!IsEmpty(props.post.images)) {
        setCover(props.post.images[0].display);
      } else {
        setCover('');
      }

      if (!IsEmpty(props.post.promoFromDate) && !IsEmpty(props.post.promoTillDate)) {
        const formatDate = datePeriodToString(props.post.promoFromDate, props.post.promoTillDate);
        if (!IsEmpty(formatDate)) {
          setPromoDate(formatDate);
        } else {
          setPromoDate('');
        }
      } else {
        setPromoDate('Period unspecified');
      }
    }
  }, [props.post]);

  const cloudfrontUrl = 'https://d1aq8vpj94zs31.cloudfront.net/';
  const videoUrl = String(props.post.videos);
  const s3Key = videoUrl.substring(cloudfrontUrl.length);
  const thumbnailUrl = `${(`${cloudfrontUrl}thumbnails/${s3Key}`).substring(0, (`${cloudfrontUrl}thumbnails/${s3Key}`).length - 3)}jpg`;

  return (
    <div className="container d-flex flex-column justify-content-start align-items-center" style={{ height: '100vh', margin: 0, padding: 0 }}>
      <div
        className="container d-flex flex-column justify-content-start overflow-hidden"
        style={{
          background:    Colours.Gray4,
          paddingBottom: 50,
          borderWidth:   2,
          borderStyle:   'solid',
          borderColor:   Colours.Gray4,
          width:         '100%',
          position:      'relative',
        }}
      >
        <div className="row">
          <div className="col-md-12 p-0 m-0">
            { IsEmpty(props.post.images) && !IsEmpty(props.post.videos) && (
              <>
                <div style={{
                  width:  '100%',
                  height: 'auto',
                }}
                >
                  <ReactPlayer
                    url={cover}
                    light={thumbnailUrl}
                    muted
                    playing
                    width="100%"
                    height={280}
                  />
                </div>
              </>
            ) }

            { IsEmpty(props.post.videos) && !IsEmpty(props.post.images) && (
              <img src={cover} style={{ display: 'block', width: '100%', height: '100%' }} alt="" />
            ) }
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 py-3">
            {props.page.name && (
              <Typography
                variant="caption"
                style={{
                  border:       `1px solid ${Colours.Gray2}`,
                  color:        Colours.Black,
                  padding:      '5px 10px',
                  borderRadius: 15,
                }}
              >
                {props.page.name}
              </Typography>
            ) }

            <Paper variant="elevation" className={`${classes.paper} mt-3`}>
              <div className="row">
                <div className="col-md-12">
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 'bold',
                      color:      Colours.Purple,
                      wordBreak:  'break-all',
                    }}
                  >
                    {props.post.title || ''}
                  </Typography>
                </div>

                <div className="col-md-12">
                  <Typography
                    variant="subtitle1"
                    noWrap
                    style={{
                      color: Colours.Amber,
                    }}
                  >
                    {promoDate}
                  </Typography>
                </div>

                <div className="col-md-12">
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: Colours.Gray2,
                    }}
                  >
                    {props.post.description || ''}
                  </Typography>
                </div>
              </div>
            </Paper>

          </div>
        </div>

      </div>
    </div>
  );
};

PreviewMobile.defaultProps = {
  business: {
    name: '',
  },

  page: {
    name: '',
  },

  post: {
    images: [],
    videos: [],
  },
};

PreviewMobile.propTypes = {
  business: PropTypes.shape({
    name: PropTypes.string,
  }),

  page: PropTypes.shape({
    name: PropTypes.string,
  }),

  post: PropTypes.shape({
    title:         PropTypes.string,
    description:   PropTypes.string,
    promoFromDate: PropTypes.string,
    promoTillDate: PropTypes.string,
    images:        PropTypes.arrayOf(
      PropTypes.shape({
        display:  PropTypes.string,
        original: PropTypes.string,
      }),
    ),

    videos: PropTypes.arrayOf(PropTypes.string),
  }),
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
  page: state.page.selected,
});

export default connect(mapStateToProps, null)(PreviewMobile);
