import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Icons from '../Icons';

function Checkboxes({ value, handleChange, style }) {
  return (
    <div>
      <Checkbox
        checked={value}
        onChange={(e) => {
          handleChange(e.target.checked);
        }}
        style={style}
        inputProps={{ 'aria-label': 'primary checkbox' }}
        icon={<Icons.UnChecked />}
        checkedIcon={<Icons.Checked colour={style.color} />}

      />

    </div>
  );
}

Checkboxes.defaultProps = {
  style: {},
};

Checkboxes.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value:        PropTypes.string.isRequired,
  style:        PropTypes.shape(Object),

};

export default Checkboxes;
