// eslint-disable-next-line import/prefer-default-export
export const TIME_INTERVALS = [
  {
    label:    '00:00 AM',
    value:    '00:00 AM',
    index:    0,
    disabled: false,

  },
  {
    label:    '00:30 AM',
    value:    '00:30 AM',
    index:    1,
    disabled: false,
  },
  {
    label:    '01:00 AM',
    value:    '01:00 AM',
    index:    2,
    disabled: false,
  },
  {
    label:    '01:30 AM',
    value:    '01:30 AM',
    index:    3,
    disabled: false,
  },
  {
    label:    '02:00 AM',
    value:    '02:00 AM',
    index:    4,
    disabled: false,
  },
  {
    label:    '02:30 AM',
    value:    '02:30 AM',
    index:    5,
    disabled: false,
  },
  {
    label:    '03:00 AM',
    value:    '03:00 AM',
    index:    6,
    disabled: false,
  },
  {
    label:    '03:30 AM',
    value:    '03:30 AM',
    index:    7,
    disabled: false,
  },
  {
    label:    '04:00 AM',
    value:    '04:00 AM',
    index:    8,
    disabled: false,
  },
  {
    label:    '04:30 AM',
    value:    '04:30 AM',
    index:    9,
    disabled: false,
  },
  {
    label:    '05:00 AM',
    value:    '05:00 AM',
    index:    10,
    disabled: false,
  },
  {
    label:    '05:30 AM',
    value:    '05:30 AM',
    index:    11,
    disabled: false,
  },
  {
    label:    '06:00 AM',
    value:    '06:00 AM',
    index:    12,
    disabled: false,
  },
  {
    label:    '06:30 AM',
    value:    '06:30 AM',
    index:    13,
    disabled: false,
  },
  {
    label:    '07:00 AM',
    value:    '07:00 AM',
    index:    14,
    disabled: false,
  },
  {
    label:    '07:30 AM',
    value:    '07:30 AM',
    index:    15,
    disabled: false,
  },
  {
    label:    '08:00 AM',
    value:    '08:00 AM',
    index:    16,
    disabled: false,
  },
  {
    label:    '08:30 AM',
    value:    '08:30 AM',
    index:    17,
    disabled: false,

  },
  {
    label:    '09:00 AM',
    value:    '09:00 AM',
    index:    18,
    disabled: false,
  },
  {
    label:    '09:30 AM',
    value:    '09:30 AM',
    index:    19,
    disabled: false,
  },
  {
    label:    '10:00 AM',
    value:    '10:00 AM',
    index:    20,
    disabled: false,
  },
  {
    label:    '10:30 AM',
    value:    '10:30 AM',
    index:    21,
    disabled: false,
  },
  {
    label:    '11:00 AM',
    value:    '11:00 AM',
    index:    22,
    disabled: false,
  },
  {
    label:    '11:30 AM',
    value:    '11:30 AM',
    index:    23,
    disabled: false,
  },
  {
    label:    '12:00 PM',
    value:    '12:00 PM',
    index:    24,
    disabled: false,
  },
  {
    label:    '12:30 PM',
    value:    '12:30 PM',
    index:    25,
    disabled: false,
  },
  {
    label:    '13:00 PM',
    value:    '13:00 PM',
    index:    26,
    disabled: false,
  },
  {
    label:    '13:30 PM',
    value:    '13:30 PM',
    index:    27,
    disabled: false,
  },
  {
    label:    '14:00 PM',
    value:    '14:00 PM',
    index:    28,
    disabled: false,
  },
  {
    label:    '14:30 PM',
    value:    '14:30 PM',
    index:    29,
    disabled: false,
  },
  {
    label:    '15:00 PM',
    value:    '15:00 PM',
    index:    30,
    disabled: false,
  },
  {
    label:    '15:30 PM',
    value:    '15:30 PM',
    index:    31,
    disabled: false,
  },
  {
    label:    '16:00 PM',
    value:    '16:00 PM',
    index:    32,
    disabled: false,
  },
  {
    label:    '16:30 PM',
    value:    '16:30 PM',
    index:    33,
    disabled: false,
  },
  {
    label:    '17:00 PM',
    value:    '17:00 PM',
    index:    34,
    disabled: false,
  },
  {
    label:    '17:30 PM',
    value:    '17:30 PM',
    index:    35,
    disabled: false,
  },
  {
    label:    '18:00 PM',
    value:    '18:00 PM',
    index:    36,
    disabled: false,
  },
  {
    label:    '18:30 PM',
    value:    '18:30 PM',
    index:    37,
    disabled: false,
  },
  {
    label:    '19:00 PM',
    value:    '19:00 PM',
    index:    38,
    disabled: false,
  },
  {
    label:    '19:30 PM',
    value:    '19:30 PM',
    index:    39,
    disabled: false,
  },
  {
    label:    '20:00 PM',
    value:    '20:00 PM',
    index:    40,
    disabled: false,
  },
  {
    label:    '20:30 PM',
    value:    '20:30 PM',
    index:    41,
    disabled: false,

  },
  {
    label:    '21:00 PM',
    value:    '21:00 PM',
    index:    42,
    disabled: false,
  },
  {
    label:    '21:30 PM',
    value:    '21:30 PM',
    index:    43,
    disabled: false,
  },
  {
    label:    '22:00 PM',
    value:    '22:00 PM',
    index:    44,
    disabled: false,
  },
  {
    label:    '22:30 PM',
    value:    '22:30 PM',
    index:    45,
    disabled: false,
  },
  {
    label:    '23:00 PM',
    value:    '23:00 PM',
    index:    46,
    disabled: false,
  },
  {
    label:    '23:30 PM',
    value:    '23:30 PM',
    index:    47,
    disabled: false,
  },

];
