import React, { useEffect, useState } from 'react';
import { Paper, Typography, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import PropTypes from 'prop-types';
import Colours from '../../../../styles/colours';
import Card from '../Card';
import Icons from '../../../../components/Common/Icons';
import { IsEmpty } from '../../../../common/util';
import ApiService from '../../../../services/apiService';

const SelectedPostAnalytics = (props) => {
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState({});
  const [analyticsData, setAnalyticsData] = useState(null);

  const fetchPostAnalytics = async (postId) => {
    try {
      setLoading(true);
      const res = await ApiService.getPostAnalyticsCount(postId);
      setAnalyticsData(res);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (!IsEmpty(props.post) && Object.keys(props.post).length > 0) {
      setPost(props.post);
      fetchPostAnalytics(props.post.id);
    }
  }, []);

  return (
    !IsEmpty(analyticsData) ? (
      <>
        <div className="mt-2d-flex align-items-center">
          <div className="d-inline-block" style={{ width: 45, height: 45, border: `1px solid ${Colours.Gray}` }}>
            { !IsEmpty(post.images)
              ? <img src={post.images[0].display} alt="post-img" style={{ width: 45, height: 45 }} />
              : '' }
          </div>
          <div className="d-inline-block ml-2">
            <Typography variant="subtitle2" style={{ color: Colours.Black }}>
              { post.title || '--' }
            </Typography>
          </div>
        </div>

        <div className="mt-3 pt-2">
          <Typography variant="body1" style={{ color: Colours.Gray }}>
            Analytics Period:
            {`   `}
            <span style={{ fontWeight: 'bold', color: Colours.Black }}>
              {!IsEmpty(analyticsData.analyticsPeriod.fromDate) ? moment(analyticsData.analyticsPeriod.fromDate).format('YYYY/MM/DD') : ''}
              {' - '}
              { moment(analyticsData.analyticsPeriod.fromDate).format('YYYY/MM/DD') === moment().format('YYYY/MM/DD') ? moment().format('YYYY/MM/DD')
                : !IsEmpty(analyticsData.analyticsPeriod.toDate) ? moment(analyticsData.analyticsPeriod.toDate).format('YYYY/MM/DD') : ''}
            </span>
          </Typography>
        </div>

        <Paper className="d-flex flex-column" elevation={0}>
          <div className="container-fluid mx-0 px-0">
            <div className="row mt-4">
              <div className="col-md-6 mb-4 position-relative overflow-hidden">
                <Card
                  icon={Icons.Eye}
                  title="Views"
                  subtitle="Number of post views"
                  count={analyticsData.postViews || 0}
                />
                <div className="position-absolute border-right d-none d-lg-block d-md-block" style={{ width: 5, height: '100vh', top: 0, right: 0 }} />
              </div>

              <div className="col-md-6 mb-4">
                <Card
                  icon={Icons.Engagement}
                  title="Engagements"
                  subtitle="Number of consumer engagements recorded for this post"
                  count={analyticsData.postEngagements || 0}
                />
              </div>

              <div className="col-md-6 mb-4 position-relative overflow-hidden">
                <Card
                  icon={Icons.Share}
                  title="Shares"
                  subtitle="Number of times consumer shared this post"
                  count={analyticsData.postShares || 0}
                />
                <div className="position-absolute border-right d-none d-lg-block d-md-block" style={{ width: 5, height: '100vh', top: 0, right: 0 }} />
              </div>

              <div className="col-md-6 mb-4">
                <Card
                  icon={Icons.Impression}
                  title="Impressions"
                  subtitle="Number of impressions for this post"
                  count={analyticsData.postImpressions || 0}
                />
              </div>

              <div className="col-md-6 mb-4 position-relative overflow-hidden">
                <Card
                  icon={Icons.Like}
                  title="Likes"
                  subtitle="How many consumers liked this post"
                  count={analyticsData.postLikes || 0}
                />
                <div className="position-absolute border-right d-none d-lg-block d-md-block" style={{ width: 5, height: '100vh', top: 0, right: 0 }} />
              </div>

              <div className="col-md-6 mb-4">
                <Card
                  icon={Icons.Calendar}
                  title="Calendar"
                  subtitle="How many consumers added this post to their calendar"
                  count={analyticsData.postCalendar || 0}
                />
              </div>
            </div>
          </div>
        </Paper>
      </>
    ) : loading ? (
      <div className="d-flex align-items-center justify-content-center text-center" style={{ width: '100%', height: '100%' }}>
        <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: 'column', minWidth: 320, minHeight: 320 }}>
          <CircularProgress />
          <br />
          <Typography className="d-block" variant="subtitle1" style={{ color: Colours.Gray1 }}>
            Fetching post's analytics, please wait
          </Typography>
        </div>
      </div>
    )
      : ''
  );
};

SelectedPostAnalytics.propTypes = {
  post: PropTypes.shape({
    id:            PropTypes.number,
    postDate:      PropTypes.string,
    promoFromDate: PropTypes.string,
    promoTillDate: PropTypes.string,
  }).isRequired,
};

SelectedPostAnalytics.defaultProps = {
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(SelectedPostAnalytics);
