import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography } from '@material-ui/core';
import { IsEmpty } from '../../common/checks';

function ToolTip(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(!IsEmpty(props.title));
  };

  const renderBasedOnType = () => {
    let Comp = props.children;
    if (props.isLink) {
      Comp = props.children;
    } else {
      Comp = (
        <span style={props.style}>
          {props.children}
        </span>
      );
    }
    return Comp;
  };

  /**
   * When we apply Tooltip for disabled components ( buttons / links)
   * We need to add wrapper like span tag so that it can show up the tooltip.
   * that is official work around from material-ui team for disabled solution
   * leaveTouchDelay and enterTouchDelay props are also needed to work in disabled situation
   */
  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={<Typography variant="caption">{props.title}</Typography>}
      leaveTouchDelay={3000}
      enterTouchDelay={50}
      arrow
    >
      {
       renderBasedOnType()
      }
    </Tooltip>
  );
}

ToolTip.defaultProps = {
  isLink: false,
  style:  {},
};

ToolTip.propTypes = {
  title:    PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style:    PropTypes.objectOf(PropTypes.any),
  isLink:   PropTypes.bool,
};

export default ToolTip;
