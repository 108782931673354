import Constants from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  categories: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.CategoriesSet:
      return {
        ...state,
        categories: payload,
      };

    default:
      return state;
  }
}
