// React Dependencies
import React from 'react';

// External Dependencies
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const HeadingAndSubText = ({ heading, text, children }) => (
  <div>
    <Typography variant="body2">{heading}</Typography>
    { !_.isEmpty(text) && <Typography variant="body2" className="font-weight-bold">{text}</Typography>}
    {children}
  </div>
);

HeadingAndSubText.propTypes = {
  heading:  PropTypes.string.isRequired,
  text:     PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

HeadingAndSubText.defaultProps = {
  text:     '',
  children: null,
};

export default HeadingAndSubText;
