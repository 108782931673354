// React Dependencies
import React from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import { connect } from 'react-redux';

// Internal Dependencies
import Icons from '../../../components/Common/Icons';
import Colours from '../../../styles/colours';
import { WindowSize } from '../../../common/constants';
import MText from '../../../components/Common/MText';
import sideBarStyle from '../../../styles/sidebar';
import { sideBarMenuItemFontSize } from '../../../styles/common';

const LoyaltyProgramSidebarMenu = (props) => (
  <Box style={{
    position:   'fixed',
    top:        props.windowSize === WindowSize.Mobile ? '120px' : '130px',
    left:       props.windowSize === WindowSize.Mobile ? '85px' : '86px',
    zIndex:     '1000',
    background: '#fff',
    width:      props.windowSize === WindowSize.Mobile ? '215px' : sideBarStyle.width,
    height:     'calc(85vh - 70px)',
  }}
  >
    <Button
      className="block d-flex"
      onClick={props.onCloseMenuHandler}
      style={{ height: 50 }}
    >
      <div className="sub-menu-item pl-1 flex-row justify-content-start align-items-center">
        <Icons.ArrowBack colour={Colours.Gray2} />
        <MText className="pl-2" text="Loyalty Cards" colour={Colours.Gray2} size={sideBarMenuItemFontSize} />
      </div>
    </Button>

    {
      props.menuItems.map((item) => (
        <Button
          key={item.url}
          className="block p-0"
          onClick={() => props.onSelectMenuHandler(item)}
        >
          <div className="sub-menu-item">
            <MText
              text={item.name}
              size={sideBarMenuItemFontSize}
              bold={props.selectedMenuItem && item.url === props.selectedMenuItem.url}
            />
          </div>
        </Button>
      ))
    }
  </Box>
);

LoyaltyProgramSidebarMenu.propTypes = {
  menuItems:           PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectedMenuItem:    PropTypes.shape(Object).isRequired,
  onSelectMenuHandler: PropTypes.func.isRequired,
  onCloseMenuHandler:  PropTypes.func.isRequired,
  windowSize:          PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps)(LoyaltyProgramSidebarMenu);
