const Colours = {
  Amber:        '#FF9100',
  Black:        '#000',
  Blue:         '#00F',
  DarkTint:     '#333333aa',
  Disabled:     '#888888',
  DefaultBkgrd: '#fafafa',
  Gray:         '#555555',
  Gray1:        '#7C7C7C',
  Gray2:        '#A9A9A9',
  Gray3:        '#e9ecef',
  Gray4:        '#DBDBDB',
  Gray5:        '#C4C4C4',
  Gray6:        '#484848',
  Green:        '#00b300',
  LightGray:    '#f1f1f1',
  LightGray1:   '#F8F8F8',
  LightPrimary: '#F9E7E7',
  Orange:       '#ffa200',
  Primary:      '#0080ad',
  Purple:       '#652483',
  DarkPurple:   '#552181',
  Red:          '#F00',
  Secondary:    '#DE006F',
  White:        '#fff',
};

export default Colours;
