/* eslint-disable no-case-declarations */
// State to store the Staff invitation the Business User is invited to
import Constants from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  invites:  [],
  selected: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.Invites:
      return {
        ...state,
        invites: payload,
      };

    case ReduxAction.InviteSelected:
      return {
        ...state,
        selected: payload,
      };

    case ReduxAction.InviteRemove:
      // remove the invite
      const updated = state.invites.slice();
      for (const index in updated) {
        if (updated[index].id === payload.id) {
          updated.splice(index, 1);
          break;
        }
      }
      return {
        ...state,
        invites: updated,
      };

    case ReduxAction.DeclineInvite:
      return {
        ...state,
        selected: payload,
      };

    default:
      return state;
  }
}
