import React, { forwardRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  Dialog, IconButton, Typography } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import { WindowSize } from '../../common/constants';
import Colours from '../../common/colours';
import layoutAction from '../../actions/layout';
import Icons from './Icons';

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
}));

const SlidingPanelMobile = (props) => {
  const classes = useStyles();
  useEffect(() => {
    if (props.slidingPanelMobile.open) {
      window.addEventListener('keyup', (e) => {
        if (e.which === 27) {
          props.slidingPanelMobile.onClose();
        }
      });
    } else {
      window.removeEventListener('keyup', this);
    }
  }, [props.slidingPanelMobile]);

  const close = () => {
    props.dispatchCloseSlidingPanelMobile();
  };

  return (
    <Dialog
      className={classes.root}
      open={props.slidingPanelMobile.open}
      TransitionComponent={Transition}
      keepMounted
      fullScreen
      style={{ marginLeft: props.windowSize === WindowSize.Mobile ? 0 : '20vw', marginTop: 0, zIndex: 5 }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent style={{
        height:  '100vh',
        padding: 0,
      }}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography variant="body1" style={{ display: 'block', margin: '10px auto', padding: '10px 15px', fontWeight: 'bold', textAlign: 'center' }}>{props.slidingPanelMobile.title}</Typography>
          <IconButton onClick={close} style={{ position: 'absolute', right: 0 }}>
            <Icons.Cross color={Colours.Black} fontSize={props.windowSize === WindowSize.Mobile ? '20px' : '41px'} />
          </IconButton>
        </div>
        {props.slidingPanelMobile.content}
      </DialogContent>
    </Dialog>
  );
};

SlidingPanelMobile.propTypes = {
  windowSize:         PropTypes.string.isRequired,
  showBackBtn:        PropTypes.bool,
  slidingPanelMobile: PropTypes.shape({
    open:    PropTypes.bool,
    onClose: PropTypes.shape({
      text:   PropTypes.string,
      action: PropTypes.func,
    }),
    title:     PropTypes.string,
    onConfirm: PropTypes.shape({
      text:   PropTypes.string,
      action: PropTypes.func,
    }),
    content: PropTypes.node,
  }).isRequired,
};

SlidingPanelMobile.defaultProps = {
  showBackBtn: false,
};

const mapStateToProps = (state) => ({
  windowSize:         state.layout.windowSize,
  slidingPanelMobile: state.layout.slidingPanelMobile,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseSlidingPanelMobile: () => layoutAction.slidingPanelMobile(dispatch, { open: false }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlidingPanelMobile);
