import React from 'react';
import PropTypes from 'prop-types';

function SelectTo({ index, value, name, options = [], handleChange, ...props }) {
  const lastFive = name.substr(name.length - 4);

  const renderOptions = () => options.map((option) => {
    let isDisabled = option.index < index;
    if (lastFive === '0.to') {
      isDisabled = false;
    }

    return <option value={option.value} label={option.label} disabled={isDisabled} key={option.index} />;
  });

  return (
    <select
      onChange={handleChange}
      value={value}
      {...props}
    >
      {renderOptions()}
    </select>
  );
}

SelectTo.propTypes = {
  index:        PropTypes.number.isRequired,
  value:        PropTypes.string.isRequired,
  name:         PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options:      PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

export default SelectTo;
