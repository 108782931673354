import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Constants, { WindowSize, PostType, PromoType } from '../../../../common/constants';
import Loading from '../../../../components/Loading';
import postAction from '../../../../actions/post';
import ApiService from '../../../../services/apiService';
import DesktopList from './DesktopList';
import MobileList from './MobileList';

const PostListing = (props) => {
  const [loading, setLoading] = useState(false);

  const createPost = async () => {
    try {
      setLoading(true);
      const payload = {
        pageId:    props.match.params.pageId,
        type:      PostType.Promo,
        promoType: PromoType.Deals,
      };

      const post = await ApiService.addPost(payload);
      setLoading(false);
      props.dispatchPostSelected(post);
      props.history.push(Constants.Url.Post.replace(':pageId', post.pageId).replace(':postId', post.id));
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  /* eslint-disable react/jsx-props-no-spreading */
  if (loading) {
    return <Loading />;
  } else if (props.windowSize === WindowSize.Mobile) {
    return (
      <MobileList {...props} addPost={() => createPost()} />
    );
  } else {
    return (
      <DesktopList {...props} addPost={() => createPost()} />
    );
  }
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
  page:       state.page.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostSelected: (post) => postAction.postSelected(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostListing);
