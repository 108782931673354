import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Chip, Typography, Slide } from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import SortableMedia from '../../../components/Common/SortableMedia/SortableMedia';
import Constants, { AccountMaxImageUpload, Error, WindowSize } from '../../../common/constants';
import { convertDataURLtoBlob, IsEmpty } from '../../../common/util';
import ApiService from '../../../services/apiService';
import Colours from '../../../styles/colours';
import PhotoCropper from '../../../components/Common/photos/PhotoCropper';
import Tooltip from '../../../components/Common/ToolTip';
import Icons from '../../../components/Common/Icons';
import Loading from '../../../components/Loading';

class PageImagesContainer extends Component {
  constructor(props) {
    super(props);
    this.hiddenInputFileRef = React.createRef();
    // eslint-disable-next-line react/jsx-props-no-spreading
    this.transition = React.forwardRef((transProps, ref) => <Slide direction="up" ref={ref} {...transProps} />);
    this.state = {
      mediaData:         '',
      alert:             false,
      openCropDialog:    false,
      originalFile:      null,
      currentFile:       null,
      src:               null,
      type:              null,
      uploadUrl:         '',
      base64:            '',
      images:            [],
      view:              false,
      viewImage:         '',
      cropping:          false,
      tempImgToDelete:   [],
      isImagePosChanged: false,
      planModals:        false,
      accountType:       null,
      maxPhotoUpload:    0,
      loading:           false,
    };
    this.maxFileSize = 7000000;
    this.imageFormats = ['image/jpeg', 'image/jpg', 'image/png'];

    this.handleSave = this.handleSave.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.getBase64Image = this.getBase64Image.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
    this.handleClosePlanModals = this.handleClosePlanModals.bind(this);
  }

  componentDidMount() {
    const maxPhotoUpload = AccountMaxImageUpload[this.props.accountType];
    this.setState({ images: this.props.images, accountType: this.props.accountType, maxPhotoUpload });
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    if (this.props.images !== prevProps.images) {
      const maxPhotoUpload = AccountMaxImageUpload[this.props.accountType];
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ images: this.props.images, accountType: this.props.accountType, maxPhotoUpload });
    }

    // Image position changed
    if (!_.isEqual(this.state.images, prevState.images) && this.state.images.length > 1 && this.state.isImagePosChanged) {
      this.handleImagePositionUpdate();
    }
  }

  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: 'image/jpeg' });
  }

  handleCrop(image) {
    const temp = [];
    this.setState({ uploadUrl: image.original, cropping: true }, () => {
      temp.push(image);
      this.setState({ tempImgToDelete: temp }, () => {
        this.handleUploadUrl();
      });
    });
  }

  handleUploadUrl() {
    this.getBase64Image(this.state.uploadUrl, (base64image) => {
      this.setState({ openCropDialog: true, base64: base64image, type: 'url', currentFile: null },
        () => {
          const imgFile = this.b64toBlob(`data:image/*;base64,${this.state.base64}`);
          this.setState((prevState) => ({ src: `data:image/*;base64,${prevState.base64}`, originalFile: new File([imgFile], 'o.jpeg', { type: 'image/jpeg' }) }));
        });
    });
  }

  handleInputUpload() {
    // If current total Photos equal to Maximal Number Photos allowed to upload, Show Modal
    // Check Maximal Number Photos to Upload
    if (this.state.images.length >= this.state.maxPhotoUpload) {
      this.handleOpenPlanModals();
    } else {
      //Else Allow upload Photos
      this.hiddenInputFileRef.current.click();
    }
  }

  handleUpload(files) {
    if (files.length > 0) {
      this.setState({ openCropDialog: true, type: 'upload' }, () => {
        if (this.state.openCropDialog) {
          this.setState({ currentFile: files[files.length - 1] }, () => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
              this.setState({ src: reader.result });
              const originalImageBlob = convertDataURLtoBlob(reader.result);
              this.setState({ originalFile: new File([originalImageBlob], 'o.jpeg', { type: 'image/jpeg' }) });
            });
            reader.readAsDataURL(this.state.currentFile);
          });
        }
      });
    }
  }

  getBase64Image(imgUrl, callback) {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);

      let dataURL = canvas.toDataURL('image/png', 0.9);
      dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
      callback(dataURL); // the base64 string
    };

    if (img.complete) {
      img.crossOrigin = Constants.CrossOrigin;
      img.src = imgUrl;
    }
  }

  async handleSave(file) {
    try {
      if (file) {
        this.setState({ loading: true });

        const formData = new FormData();
        formData.append('image', file);
        formData.append('mediaType', 'Photo');

        if (this.state.cropping === true && this.state.tempImgToDelete.length > 0) {
          try {
            /**
             * Handle re cropping image, get the index of image changed, then upload the new display image only to prevent increasing image size
             */
            const index = this.state.images.findIndex((el) => el.original === this.state.tempImgToDelete[0].original);

            this.state.images.splice(index, 1);

            this.setState((prevState) => ({ images: prevState.images }));

            formData.append('imgIndex', index);
            const result = await ApiService.uploadPageMedia(this.props.pageId, formData);
            if (result) {
              const page = await ApiService.getPageById(this.props.pageId);
              if (page) {
                this.setState({
                  images:         page.images,
                  openCropDialog: false,
                  cropping:       false,
                  currentFile:    null,
                  src:            null,
                  type:           null,
                  uploadUrl:      '',
                  base64:         '',
                  loading:        false,
                });
              }
            }
          } catch (err) {
            this.setState({ loading: false });
          }
        } else {
          formData.append('imgIndex', false);
          formData.append('image', this.state.originalFile);

          const result = await ApiService.uploadPageMedia(this.props.pageId, formData);
          if (result) {
            this.setState((prevState) => ({
              images:         prevState.images.concat(result),
              openCropDialog: false,
              cropping:       false,
              currentFile:    null,
              src:            null,
              type:           null,
              uploadUrl:      '',
              base64:         '',
              loading:        false,
            }), () => {
              toast.success('Image uploaded successfully.');
              console.log('this place is called!');
              this.hiddenInputFileRef.current.value = null;
            });
          }
        }
      }
    } catch (err) {
      toast.error(err.message);
      if (err.message === Error.ExceedMaxImageUpload) {
        this.handleOpenPlanModals();
      }
      this.setState({
        openCropDialog: false,
        cropping:       false,
        currentFile:    null,
        src:            null,
        type:           null,
        uploadUrl:      '',
        base64:         '',
        loading:        false,
      });
    }
  }

  async handleDelete() {
    try {
      this.setState({ alert: false, loading: true });

      if (this.state.cropping && this.state.tempImgToDelete.length > 0) {
        this.setState((prevState) => ({ mediaData: prevState.tempImgToDelete[0] }));
      }

      const images = this.state.images.filter((image) => {
        if (image.display) {
          return image.display !== this.state.mediaData.display;
        } else {
          return image.original !== this.state.mediaData.original;
        }
      });

      await ApiService.deletePageMedia(this.props.pageId, { mediaData: this.state.mediaData, fileType: Constants.FileType.Image, mediaType: 'Photo' });
      this.setState({ images, alert: false, loading: false }, () => {
        if (this.state.cropping && this.state.tempImgToDelete.length > 0) {
          toast.success('Image cropped successfully.');
        } else {
          toast.success('Image successfully deleted.');
        }
      });

      return images;
    } catch (err) {
      this.setState({ alert: false, loading: false });
      toast.error(err.message);
    }
  }

  handleConfirmation(mediaData) {
    this.setState({ alert: true, mediaData });
  }

  handleClose() {
    this.setState({
      openCropDialog:  false,
      currentFile:     null,
      alert:           false,
      base64:          '',
      view:            false,
      type:            null,
      uploadUrl:       '',
      cropping:        false,
      tempImgToDelete: [],
    }, () => {
      this.hiddenInputFileRef.current.value = null;
    });
  }

  handleSort(images) {
    this.setState({ images, isImagePosChanged: true });
    try {
      this.props.onSort(images);
    } catch (error) {
      console.log(error);
    }
  }

  handleView(image) {
    this.setState({ view: true, viewImage: image.original });
  }

  async handleImagePositionUpdate() {
    try {
      const payload = {
        images: this.state.images,
      };
      await ApiService.updatePage(this.props.pageId, payload);
      this.setState({ isImagePosChanged: false });
    } catch (err) {
      toast.error(err.message);
    }
  }

  handleErrorMessage(file) {
    if (!this.imageFormats.includes(file.type)) {
      toast.error('Only JPG, JPEG and PNG files are supported.');
      return;
    }
    if (file.size > this.maxFileSize) {
      toast.error('Maximum file size of 7MB exceeded.');
    }
  }

  handleOpenPlanModals() {
    this.setState({ planModals: true });
  }

  handleClosePlanModals() {
    this.setState({ planModals: false });
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <>
        { this.state.loading && <Loading /> }
        <div>
          <div>
            {this.props.windowSize === WindowSize.Mobile
              ? (
                <div>
                  <Typography className="mb-2" variant="body1" color="inherit" style={{ color: Colours.Black }}>
                    1. Photos
                    <sup style={{ color: Colours.Red }}>*</sup>
                    <span style={{  marginBottom: 5 }}> (min 500 x 500 resolution)</span>
                  </Typography>
                  <Tooltip title={!this.props.disabled ? '' : 'Permission Denied'}>
                    <Button
                      variant="contained"
                      className="ml-3"
                      color="primary"
                      component="span"
                      onClick={() => this.handleInputUpload()}
                      disabled={this.props.disabled}
                    >
                      + Add Photos
                    </Button>
                  </Tooltip>
                </div>
              )
              : (
                <div>
                  <Typography variant="body1" color="inherit" style={{ color: Colours.Gray }}>
                    1. Photos
                    <sup style={{ color: Colours.Red }}>* </sup>
                    (min 500 x 500 resolution)
                    <Tooltip title={!this.props.disabled ? '' : 'Permission Denied'}>
                      <Button
                        variant="contained"
                        className="ml-3"
                        color="primary"
                        component="span"
                        onClick={() => this.handleInputUpload()}
                        disabled={this.props.disabled}
                      >
                        + Add Photo
                      </Button>
                    </Tooltip>
                  </Typography>
                </div>
              )}
            <div>
              <input
                style={{ display: 'none' }}
                ref={this.hiddenInputFileRef}
                accept={['image/jpeg', 'image/jpg', 'image/png']}
                id="icon-button-file"
                type="file"
                onChange={(e) => { this.handleUpload(e.target.files); }}
              />
            </div>
          </div>
          {
            IsEmpty(this.state.images) && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: 200 }}>
                <Tooltip title={!this.props.disabled ? 'Click to upload photo' : 'Permission Denied'}>
                  <Button
                    component="span"
                    onClick={() => this.handleInputUpload()}
                    disabled={this.props.disabled}
                    style={{ height: 'auto' }}
                    className="px-5"
                  >
                    <div className="d-flex flex-column align-items-center">
                      <Icons.Impression fontSize={125} colour={Colours.Gray5} />
                      <Typography variant="body1" color="inherit" style={{ color: Colours.Gray5 }}>Click to upload</Typography>
                    </div>
                  </Button>
                </Tooltip>
              </div>
            )
          }
          {this.state.images.length > 0
            ? (
              <div style={{ position: 'relative' }}>
                <Chip style={{
                  backgroundColor: '#c0c0c0',
                  width:           '288px',
                  height:          '170px',
                  verticalAlign:   'bottom',
                  position:        'absolute',
                  top:             '25px',
                  left:            '20px',
                }}
                />
                {this.state.images
                  ? (
                    <SortableMedia
                      maxHt={150}
                      minHt={150}
                      maxWd={266.67}
                      minWd={266.67}
                      hasMinWidth={!!isMobile}
                      images={this.state.images}
                      disabled={this.props.disabled}
                      handleCrop={this.handleCrop}
                      handleDelete={(mediaData) => this.handleConfirmation(mediaData)}
                      handleSort={this.handleSort}
                    />
                  )
                  : ''}
              </div>
            )
            : ''}

          <Dialog
            fullScreen
            open={this.state.openCropDialog}
            onClose={() => { this.handleClose(); }}
          >
            <PhotoCropper
              width={16}
              height={9}
              cropPixelWidth={400}
              cropPixelHeight={225}
              outputPixelWidth={900}
              outputPixelHeight={506.25}
              type={this.state.type}
              url={this.state.uploadUrl}
              src={this.state.src}
              file={this.state.currentFile}
              handleSave={this.handleSave}
              handleClose={this.handleClose}
              isCropping={this.state.cropping}
            />
          </Dialog>

          <Dialog
            fullScreen
            open={this.state.view}
            onClose={() => { this.handleClose(); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">View Image</DialogTitle>
            <DialogContent>
              <div style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <img
                  src={this.state.viewImage}
                  alt="images"
                  style={{
                    maxWidth:        '100%',
                    minWidth:        '400px',
                    maxHeight:       'auto',
                    minHeight:       'auto',
                    resizeMode:      'contain',
                    backgroundColor: 'blue',
                    boxShadow:       '0 1px 3px 1px rgba(0, 0, 0, .3)',
                    borderRadius:    '10px',
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { this.handleClose(); }} color="primary">Close</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            size="sm"
            open={this.state.alert}
            onClose={() => { this.handleClose(); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Remove Image</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the image?
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mb-2">
              <Button className="px-3" onClick={() => { this.handleDelete(); }} color="default">Confirm</Button>
              <Button className="px-3 mx-3" onClick={() => { this.handleClose(); }} color="primary">Cancel</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.planModals}
            fullWidth
            TransitionComponent={this.transition}
            keepMounted
            onClose={() => this.handleClosePlanModals}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">Maximum number of image upload reached</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Your current account plan is :
                {' '}
                <span style={{ fontWeight: 'bold', color: Colours.Black }}>{this.state.accountType}</span>
                <br />
                The limit of
                {' '}
                {this.state.accountType}
                {' '}
                plan is
                {' '}
                <span style={{ fontWeight: 'bold', color: Colours.Black }}>{this.state.maxPhotoUpload}</span>
                {' '}
                photo.
                Upgrade account plan to increase limit.
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mb-2">
              <Button className="px-3 mx-3" onClick={this.handleClosePlanModals} color="primary">
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}

PageImagesContainer.propTypes = {
  showSaveImgPosBtn: PropTypes.bool,
  pageId:            PropTypes.number.isRequired,
  accountType:       PropTypes.string.isRequired,
  images:            PropTypes.arrayOf(PropTypes.object).isRequired,
  onSort:            PropTypes.func,
  windowSize:        PropTypes.string.isRequired,
  disabled:          PropTypes.bool.isRequired,
};

PageImagesContainer.defaultProps = {
  showSaveImgPosBtn: true,
  onSort:            () => {},
};

const mapDispatchToProps = () => ({ });

export default connect(null, mapDispatchToProps)(PageImagesContainer);
