import Constants from '../common/constants';

const { ReduxAction } = Constants;

const categoriesSet = (dispatch, categories) => {
  dispatch({
    type:    ReduxAction.CategoriesSet,
    payload: categories,
  });
};

const categoryAction = {
  categoriesSet,
};

export default categoryAction;
