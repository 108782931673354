import React from 'react';
import { Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AccountMaxImageUpload, WindowSize } from '../../../../common/constants';
import Colours from '../../../../styles/colours';

const PostMediaLimitModal = (props) => (
  <Dialog
    open={props.open}
    fullWidth
    keepMounted
    onClose={() => props.close()}
    aria-labelledby="alert-dialog-slide-title"
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogTitle id="alert-dialog-slide-title">Maximum number of media upload(s) reached</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        Your current subscription plan :
        {' '}

        {props.windowSize === WindowSize.Mobile && <br />}

        <span style={{ fontWeight: 'bold', color: Colours.Black }}>
          {props.accountType}
          {' '}
          Tier
        </span>

        <br />
        {props.windowSize === WindowSize.Mobile && <br />}

        Media upload(s) allowed per post :
        {' '}
        <span style={{ fontWeight: 'bold', color: Colours.Black }}>{AccountMaxImageUpload[props.accountType]}</span>
        {' '}

        <br />
        <br />
        To find out more or upgrade your subscription plan, click
        {' '}
        <a href={`/support?upgradePlan=true&pageName=${props.page.name}&pageId=${props.page.id}`}>here</a>
        .
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.close()} color="primary">
        Okay
      </Button>
    </DialogActions>
  </Dialog>
);

PostMediaLimitModal.propTypes = {
  accountType: PropTypes.string.isRequired,
  close:       PropTypes.func.isRequired,
  open:        PropTypes.bool,
  page:        PropTypes.shape({
    id:   PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
  windowSize: PropTypes.string.isRequired,
};

PostMediaLimitModal.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(PostMediaLimitModal);
