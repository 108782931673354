import Colours from '../styles/colours';

export const Monstyr = 'Monstyr';

export const AccountMaxImageUpload = {
  Free:     2,
  Premium:  5,
  Ultimate: 15,
};

export const AccountMaxInvitedStaff = {
  Free:     2,
  Premium:  5,
  Ultimate: 15,
};

export const AccountMaxPostPublished = {
  Free:     1,
  Premium:  5,
  Ultimate: 15,
};

export const AccountMaxReviewPublished = {
  Free:     2,
  Premium:  5,
  Ultimate: 15,
};

export const AreaType = {
  MRT: 'MRT',
};

const BinaryOneMBSize = 1048576; // In binary (base 2), 1 MB is defined as 1,048,576 bytes (1024^2 bytes).

const ContentType = {
  FormData:        'multipart/form-data',
  ApplicationJson: 'application/json',
};

const CrossOrigin = 'anonymous';

const Duration = {
  ThisWeek:   'This week',
  Last2Weeks: 'Last 2 Weeks',
  LastMonth:  'Last Month',
};

const EngagementTypeColors =  {
  Media:        '#0088FE',
  MaybeExpired: '#000000',
  Page:         '#FFBB28',
  PromoCode:    '#FF8042',
  Calendar:     '#0088FE',
  Source:       '#00C49F',
  Online:       '#FF0B55',
  Outlets:      '#5D54A4',
  Direction:    '#EFA8E4',
  Like:         '#962D2D',
  Share:        '#FFE459',
  ViewPost:     '#91D18B',
};

const EngagementTypes = [
  {
    name:        'Media',
    icon:        'play_circle_filled',
    description: `This engagement is logged when user clicks the image cover of the post.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/MediaEngagement.png',
  },
  {
    name:        'MaybeExpired',
    icon:        'thumb_up_alt',
    description: `This engagement is logged when user clicks the MAY BE EXPIRED label of the post.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/MayBeExpiredEngagement.png',
  },
  {
    name:        'Page',
    icon:        'article',
    description: `This engagement is logged when user clicks the brand name of the post which will redirect to home page of the brand.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/PageEngagement.png',
  },
  {
    name:        'PromoCode',
    icon:        'local_offer',
    description: `This engagement is logged when user clicks the Promo Code button of the post.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/PromoCodeEngagement.png',
  },
  {
    name:        'Calendar',
    icon:        'calendar_today',
    description: `This engagement is logged when user clicks the Calendar button of a post.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/CalendarEngagement.png',
  },
  {
    name:        'Source',
    icon:        'source',
    description: `This engagement is logged when user clicks the Source button where it redirects user to the original link of the promotion.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/SourceEngagement.png',
  },
  {
    name:        'Online',
    icon:        'cloud_queue',
    description: `This engagement is logged when user clicks the Online button where it redirects user to website.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/OnlineEngagement.png',
  },
  {
    name:        'Outlets',
    icon:        'location_on',
    description: `This engagement is logged when user clicks the outlet button to view the outlet list.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/OutletEngagement.png',
  },
  {
    name:        'Direction',
    icon:        'near_me',
    description: `This engagement is logged when user clicks the Direction button in the outlets of the post.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/DirectionEngagement.png',
  },
  {
    name:        'Like',
    icon:        'thumb_up_alt',
    description: `This engagement is logged when user clicks the Like button of the post.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/LikeEngagement.png',
  },
  {
    name:        'Share',
    icon:        'share',
    description: `This engagement is logged when user clicks Share button of the post. It allows user to share post in many medium.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/ShareEngagement.png',
  },
  {
    name:        'ViewPost',
    icon:        'dynamic_feed',
    description: `This engagement is logged when user clicks the post item card in promotion screen or brand home page, which redirects user to view the post's detail.`,
    gif:         'https://estore.sgp1.cdn.digitaloceanspaces.com/engagement/PostEngagement.png',
  },
];

export const Error = {
  AccountWithEmailExist:    'An account with the email has been registered. Please login.',
  AccountPlanIsInvalid:     `Account's plan is invalid`,
  EmailNotVerified:         'Email is not verified',
  ExceedMaxImageUpload:     'Maximum number of image upload reached',
  RecordNotFound:           'Record does not exist',
  SessionExpired:           'Session expired. Please re-login.',
  UnexpectedError:          'Aww.. an unexpected error has occurred. Please reach out to us on this matter.',
  PermissionDenied:         'Permission denied',
  MaxNumberPublishedPost:   'Maximum number of live post(s) reached',
  MaxNumberPublishedReview: 'Maximum number of live review(s) published reached',
  MaxNumberLiveGifts:       'Maximum number of live gift(s) reached',
  AddressRequired:          'Address is required',
  PostalCodeRequired:       'Postal code is required',
};

const FileType = {
  Image: 'Image',
  Video: 'Video',
};

const GiftType = {
  Coupon:  'Coupon',
  Voucher: 'Voucher',
};

export const HalalStatus = {
  No:             'No',
  HalalCertified: 'Halal Certified',
  MuslimOwned:    'Muslim Owned',
};

const ImageType = {
  VideoThumbnail: 'VideoThumbnail',
};

const MealTags = ['breakfast', 'lunch', 'high tea', 'dinner', 'supper'];
const NotApplicable = 'Not applicable';

export const PageType = {
  AreaManagement: 'Area Management',
  CreditCard:     'Credit Card',
  ECommerce:      'E-Commerce',
  EventsCompany:  'Events Company',
  FoodReview:     'Food Review',
  HawkerCentre:   'Hawker Centre',
  Mall:           'Mall',
  FNB:            'F&B',
  Retailer:       'Retailer',
  View:           'View',
};

export const PaymentStatus = {
  AmountCapturableUpdated: 'Amount Capturable Updated',
  Cancelled:               'Cancelled',
  Failed:                  'Failed',
  Paid:                    'Paid',
  Pending:                 'Pending',
  PartiallyFunded:         'Partially Funded',
  RequiresAction:          'Requires Action',
};

export const PostType = {
  Article: 'Article',
  Promo:   'Promo',
  Others:  'Others',
};

export const PromoType = {
  Coupon:     'Coupon',
  CreditCard: 'Credit Card',
  Deals:      'Deals',
  Events:     'Events',
  LuckyDraw:  'Lucky Draw / Contest',
  NewLaunch:  'New Launch',
  PromoCode:  'Promo Code',
  Vouchers:   'Voucher',
};

const Radian = Math.PI / 180;

const ReduxAction = {
  AuthFailed:                'AuthFailed',
  BusinessesAccess:          'BusinessesAccess',
  BusinessSelected:          'BusinessSelected',
  BusinessCurrentView:       'BusinessCurrentView',
  CategoriesSet:             'CategoriesSet',
  Invites:                   'Invites',
  InviteSelected:            'InviteSelected',
  InviteRemove:              'InviteRemove',
  Login:                     'LogIn',
  Logout:                    'Logout',
  LoyaltyProgramSideBarHide: 'LoyaltyProgramSideBarHide',
  LoyaltyProgramSideBarShow: 'LoyaltyProgramSideBarShow',
  LogoutModalToggle:         'LogoutModalToggle',
  PagesAccess:               'PagesAccess',
  PagesAccessAdd:            'PagesAccessAdd',
  PageSelected:              'PageSelected',
  PageUpdate:                'PageUpdate',
  PageClaims:                'PageClaims',
  PageClaimNew:              'PageClaimNew',
  PageClaimSelected:         'PageClaimSelected',
  PageClaimUpdate:           'PageClaimUpdate',
  PageClaimDelete:           'PageClaimDelete',
  PostDataUpdate:            'PostDataUpdate',
  PostSelected:              'PostSelected',
  ReviewDataUpdate:          'ReviewDataUpdate',
  ReviewSelected:            'ReviewSelected',
  SideBarHide:               'SideBarHide',
  SideBarShow:               'SideBarShow',
  SideBarToggle:             'SideBarToggle',
  Signup:                    'Signup',
  SlidingPanel:              'SlidingPanel',
  SlidingPanelMobile:        'SlidingPanelMobile',
  ViewChanged:               'ViewChanged',
  TokenExpired:              'TokenExpired',
  WindowSizeChanged:         'WindowSizeChanged',
};

export const StaffType = {
  Admin:       'Admin',
  Contributor: 'Contributor',
  Observer:    'Observer',
  Finance:     'Finance',
};

export const Status = {
  Accepted:          'Accepted',
  Activated:         'Activated',
  Add:               'Add',
  All:               'All',
  Cancelled:         'Cancelled',
  Deactivated:       'Deactivated',
  Deleted:           'Deleted',
  DeletedByBusiness: 'Deleted by Business',
  Deduct:            'Deduct',
  Draft:             'Draft',
  Expired:           'Expired',
  Live:              'Live',
  No:                'No',
  Pending:           'Pending',
  Paused:            'Paused',
  Redeem:            'Redeem',
  Rejected:          'Rejected',
  Yes:               'Yes',
};

export const Url = {
  Access:             '/page/:pageId/Access',
  Account:            '/page/:pageId/account',
  Analytics:          '/page/:pageId/analytics',
  Analytic:           '/page/:pageId/analytic',
  BusinessProfile:    '/page/:pageId/businessProfile',
  Business:           '/business',
  CouponsAndVouchers: '/page/:pageId/vouchers-coupons',
  CreatePageClaim:    '/createPageClaim',
  External:           {
    Website: 'https://www.monstyr.com',
  },
  ForgetPassword:       '/forgetpassword',
  Invitation:           '/invitation/:inviteId',
  Login:                '/login',
  Main:                 '/',
  GeographicBoundary:   '/page/:pageId/geographicBoundary',
  Outlets:              '/page/:pageId/outlets',
  Outlet:               '/page/:pageId/outlet',
  Page:                 '/page/:pageId',
  PageInfo:             '/page/:pageId/info',
  PageClaimView:        '/pageClaim/:pageClaimId',
  PlaceLocation:        '/page/:pageId/review/:reviewId/placeLocation/:placeLocationId',
  Post:                 '/page/:pageId/post/:postId',
  Posts:                '/page/:pageId/posts',
  FoodReviewHighlight:  '/page/:pageId/foodReviewHighlight',
  GenericHighlight:     '/page/:pageId/genericHighlight',
  Gift:                 '/page/:pageId/vouchers-coupons/:giftId',
  PageHighlightListing: '/page/:pageId/highlights',
  PageHighlight:        '/page/:pageId/highlight/:pageHighlightId',
  PageHighlightPosts:   '/page/:pageId/highlight/:pageHighlightId/posts',
  PageHighlights:       '/page/:pageId/highlights',
  Profile:              '/profile',
  ResetPassword:        '/reset-password',
  Reviews:              '/page/:pageId/reviews',
  Review:               '/page/:pageId/review/:reviewId',
  Support:              '/support',
  Signup:               '/signup',
  UserVerificationSent: '/userVerificationSent',
  UserVerification:     '/userVerification',

  // Loyalty Cards
  LoyaltyCards:       '/page/:pageId/loyaltyCards',
  LoyaltyCard:        '/page/:pageId/loyaltyCard/:loyaltyCardId',
  ParticipateOutlets: '/page/:pageId/loyaltyCard/:loyaltyCardId/participatingOutlets',
  StampSettings:      '/page/:pageId/loyaltyCard/:loyaltyCardId/stampSettings',
};

export const IMAGE_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];

const View = {
  BusinessBilling:    'business_billing',
  BusinessPageSelect: 'business_pages',
  BusinessStaff:      'business_staff',
  BusinessProfile:    'business_profile',
  PageAnalytics:      'page_analytics',
  PagePosts:          'page_posts',
  PageOutlets:        'page_outlets',
  PageInfo:           'page_info',
};

export const selectStyles = {
  menu: (styles) => ({
    ...styles,
    zIndex:   999,
    minWidth: '160px',
    fontSize: '0.8rem',
  }),
  placeholder: () => ({
    fontSize: '0.8rem',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? Colours.Primary : null,

  }),
};

const StaffTypeOptions = [
  { value: StaffType.Admin, label: StaffType.Admin },
  { value: StaffType.Observer, label: StaffType.Observer },
  { value: StaffType.Contributor, label: StaffType.Contributor },
];

export const WindowSize = {
  Mobile:  'Mobile',
  Desktop: 'Desktop',
};

export const PositionType = {
  Fixed:     'Fixed',
  Rotatable: 'Rotatable',
};

export const SpecialHighlightPostType = {
  PicMarker: 'PicMarker',
  Pinned:    'Pinned',
  Special:   'Special',
};

export const TokenName = 'b(2ghruA@kf(5hgu24%Fm)';

const Constants = {
  AccountMaxImageUpload,
  ContentType,
  CrossOrigin,
  BinaryOneMBSize,
  Duration,
  Error,
  EngagementTypeColors,
  EngagementTypes,
  FileType,
  GiftType,
  HalalStatus,
  ImageType,
  MealTags,
  NotApplicable,
  Radian,
  ReduxAction,
  StaffType,
  StaffTypeOptions,
  Url,
  View,
  PositionType,
  SpecialHighlightPostType,
};

export default Constants;
