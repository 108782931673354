// React Dependencies
import React from 'react';

// External Dependencies
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// Internal Dependencies
import Colours from '../../styles/colours';
import { IsEmpty } from '../../common/checks';

const MText = ({ bold, className, colour, size, text, variant, children }) => (
  <Typography
    className={className}
    variant={variant}
    color="inherit"
    style={{
      color:      colour,
      fontSize:   size,
      fontWeight: bold ? 'bold' : 'normal',
    }}
  >
    {!IsEmpty(text) ? text : children}
  </Typography>
);

MText.propTypes = {
  children:  PropTypes.shape(Object),
  bold:      PropTypes.bool,
  className: PropTypes.string,
  colour:    PropTypes.string,
  size:      PropTypes.number,
  text:      PropTypes.string,
  variant:   PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline']),
};

MText.defaultProps = {
  children:  null,
  bold:      false,
  className: '',
  colour:    Colours.Black,
  size:      12,
  text:      '',
  variant:   'body1',
};

export default MText;
