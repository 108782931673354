// React Dependencies
import React, { createRef } from 'react';

// External Dependencies
import { Map, Polygon, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import PropTypes from 'prop-types';

// Internal Dependencies
import { AreaType } from '../common/constants';
import { computeOffset } from '../common/util';
import { IsEmpty } from '../common/checks';

const inside = require('point-in-polygon');

const GoogleKey = process.env.REACT_APP_GOOGLE_API_KEY;

const defaultCoord = {
  lat: 1.27978,
  lng: 103.864868,
};

class AreaMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coords: [],
      zoom:   14,
      center: {
        lat: 1.27978,
        lng: 103.864868,
      },

      calloutCoord:      {},
      activeMarker:      {},
      showingInfoWindow: false,
      mapLoaded:         false,
    };
    this.mapRef = createRef();

    this.onMapClick = this.onMapClick.bind(this);
    this.onMarkerDragEnd = this.onMarkerDragEnd.bind(this);
    this.onMarkerDrag = this.onMarkerDrag.bind(this);
    this.onMapRightClick = this.onMapRightClick.bind(this);
    this.onZoomInClicked = this.onZoomInClicked.bind(this);
    this.onZoomOutClicked = this.onZoomOutClicked.bind(this);
    this.removeLastPointClicked = this.removeLastPointClicked.bind(this);
    this.testMarkerInPolygonClicked = this.testMarkerInPolygonClicked.bind(this);
    this.createMapSquare = this.createMapSquare.bind(this);
  }

  componentDidMount() {
    if (this.props.mapCoordinates.length > 0) {
      this.centraliseMapView(this.props.mapCoordinates);
      this.setState({ coords: this.props.mapCoordinates });
    }

    if (this.props.isCalloutCoord) {
      this.setState({ calloutCoord: this.props.calloutCoord });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.calloutCoord !== prevState.calloutCoord) {
      this.setState({
        activeMarker: { position: this.state.calloutCoord },
      });
    }

    if (this.props.mapCoordinates !== prevProps.mapCoordinates) {
      this.setState(
        { coords: this.props.mapCoordinates },
        () => this.centraliseMapView(this.props.mapCoordinates),
      );
    }
  }

  centraliseMapView(data) {
    const arrLat = _.sortBy(_.map(data, (c) => c.lat));
    const arrLng = _.sortBy(_.map(data, (c) => c.lng));
    const highestLat = arrLat[arrLat.length - 1];
    const highestLng = arrLng[arrLng.length - 1];
    console.log(highestLat, highestLng);
    this.setState({
      center: {
        lat: highestLat,
        lng: highestLng,
      },
    });
  }

  createMapSquare(location, meter) {
    const topLeft       = computeOffset(location, meter, 135);
    const topRight      = computeOffset(location, meter, 45);
    const bottomLeft    = computeOffset(location, meter, 225);
    const bottomRight   = computeOffset(location, meter, 315);
    const squareCoords  = [topLeft, topRight, bottomRight, bottomLeft];

    return squareCoords;
  }

  onMapClick(mapProps, map, evt) {
    const lat = evt.latLng.lat();
    const lng = evt.latLng.lng();

    if (!this.props.isCalloutCoord) {
      let { coords } = this.state;

      if (this.props.areaType === AreaType.MRT) {
        const squareCoords = this.createMapSquare({ lat, lng }, 750);
        coords = [];
        coords.push(...squareCoords);
      } else {
        coords.push({ lat, lng });
      }

      this.setState({ coords }, () => {
        this.props.setMapCoor(this.state.coords);
      });
    } else {
      this.setState({
        calloutCoord:      { lat, lng },
        activeMarker:      { position: { lat, lng } },
        showingInfoWindow: true,
      });
      this.props.setCalloutCoord({ lat, lng });
    }
  }

  onMarkerDragEnd(mapProps, map, evt) {
    const lat = evt.latLng.lat();
    const lng = evt.latLng.lng();

    this.props.setCalloutCoord({ lat, lng });
  }

  onMarkerDrag(mapProps, map, evt) {
    const lat = evt.latLng.lat();
    const lng = evt.latLng.lng();

    this.setState({
      calloutCoord: { lat, lng },
      activeMarker: { position: { lat, lng } },
    });
  }

  onMapRightClick(mapProps, map, evt) {
    const lat = evt.latLng.lat();
    const lng = evt.latLng.lng();
    const { coords } = this.state;
    coords.push({ lat, lng });
    this.setState({ coords });
  }

  onZoomInClicked() {
    this.setState((prevState) => ({ zoom: prevState.zoom + 1 }));
  }

  onZoomOutClicked() {
    this.setState((prevState) => ({ zoom: prevState.zoom - 1 }));
  }

  removeLastPointClicked() {
    let { coords } = this.state;

    if (coords && coords.length > 0) {
      if (this.props.areaType === AreaType.MRT) {
        coords = [];
      } else {
        coords.splice(coords.length - 1, 1);
      }

      this.setState({ coords }, () => {
        this.props.setMapCoor(this.state.coords);
      });
    }
  }

  testMarkerInPolygonClicked() {
    const polygonPts = _.map(this.state.coords, (c) => [c.lat, c.lng]);
    const isInside = inside([defaultCoord.lat, defaultCoord.lng], polygonPts);
    console.log('IsInside=', isInside);
  }

  onMarkerClick = (props, marker) => this.setState({
    activeMarker:      marker,
    showingInfoWindow: true,
  });

  onMarkerMounted = (element) => {
    if (!IsEmpty(element)) {
      this.onMarkerClick(element.props, element.marker);
    }
  };

  handleMapIdle = () => {
    this.setState({ mapLoaded: true });
  };

  render() {
    const { Heading } = this.props;
    const coordsPolygonColour = IsEmpty(this.props.colour) ? this.props.defaultColour : this.props.colour;

    return (
      <div className="container-fluid m-0" style={this.props.style}>
        <div style={this.props.buttonContainerStyles}>
          { Heading && <Heading />}
          { !this.props.isCalloutCoord && (
            <div className="d-block" style={{ marginBottom: '13px', ...this.props.buttonSubContainerStyle }}>
              <Button variant="contained" size="small" className="mr-2" color="secondary" onClick={this.onZoomInClicked}>+ Zoom In</Button>
              <Button variant="contained" size="small" className="mr-2" color="secondary" onClick={this.onZoomOutClicked}>- Zoom Out</Button>
              {this.state.coords.length > 0 && (
                <Button variant="contained" size="small" color="secondary" onClick={this.removeLastPointClicked}>
                  { this.props.areaType === AreaType.MRT ? 'Remove Square Points' : 'Remove Last Point' }
                </Button>
              )}
            </div>
          )}
        </div>

        <div className="position-relative w-100" style={{ height: '500px' }}>
          <Map
            ref={(r) => { this.mapRef = r; }}
            google={this.props.google}
            zoom={this.state.zoom}
            zoomControl={false}
            style={{ position: 'absolute', cursor: 'default', top: 0, left: 0, width: '100%', maxWidth: '100%', height: 'auto' }}
            initialCenter={defaultCoord}
            center={this.state.center}
            onClick={this.onMapClick}
            onIdle={this.handleMapIdle}
            onReady={(mapProps, map) => {
              map.setOptions({
                draggableCursor: 'default',
                draggingCursor:  'pointer',
              });
            }}
          >

            {this.props.isCalloutCoord && this.state.mapLoaded && (
              <Marker
                ref={this.onMarkerMounted}
                position={this.state.calloutCoord}
                onClick={this.onMarkerClick}
                draggable
                onDragend={this.onMarkerDragEnd}
                onDrag={this.onMarkerDrag}
              />
            )}

            {this.props.isCalloutCoord && (
              <InfoWindow
                position={{
                  lat: this.state.calloutCoord.lat + 0.00320, // adjusts the offset value to ensure the InfoWindow does not cover the Marker.
                  lng: this.state.calloutCoord.lng,
                }}
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}
                onClose={() => this.setState({ showingInfoWindow: false })}
              >
                <div className="text-center">
                  <p style={{ fontSize: 15, marginBottom: 5, fontWeight: 'bold' }}>{this.props.title}</p>
                  <p style={{ whiteSpace: 'pre-line' }}>{this.props.content}</p>
                </div>
              </InfoWindow>
            )}

            <Polygon
              key={_.uniqueId()} // "unique key" needed, in order to updated polygon
              paths={this.state.coords}
              strokeColor={coordsPolygonColour}
              strokeOpacity={0.30}
              strokeWeight={2}
              fillColor={coordsPolygonColour}
              fillOpacity={0.30}
              onClick={this.onMapClick}
            />
            {
              _.map(this.props.districtsCoords, (coord, index) => (
                <Polygon
                  key={`${coord.length}-${index}`}
                  paths={coord}
                  strokeColor="#3A1078"
                  strokeOpacity={0.30}
                  strokeWeight={2}
                  fillColor="#3A1078"
                  fillOpacity={0.30}
                  onClick={this.onMapClick}
                />
              ))
            }
          </Map>
        </div>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: `${GoogleKey}`,
})(AreaMap);

AreaMap.propTypes = {
  style:                   PropTypes.shape(Object),
  buttonContainerStyles:   PropTypes.shape(Object),
  buttonSubContainerStyle: PropTypes.shape(Object),
  colour:                  PropTypes.string,
  defaultColour:           PropTypes.string,
  areaType:                PropTypes.string,
  districtsCoords:         PropTypes.arrayOf(PropTypes.shape(Object)),
  google:                  PropTypes.shape(Object),
  mapCoordinates:          PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  })),
  Heading:    PropTypes.func,
  setMapCoor: PropTypes.func,

  isCalloutCoord:  PropTypes.bool,
  setCalloutCoord: PropTypes.func,
  calloutCoord:    PropTypes.objectOf(PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  })),
  title:   PropTypes.string,
  content: PropTypes.string,
};

AreaMap.defaultProps = {
  areaType:                '',
  style:                   {},
  buttonContainerStyles:   {},
  buttonSubContainerStyle: {},
  colour:                  '#f00',
  defaultColour:           '#f00',
  districtsCoords:         [],
  google:                  {},
  mapCoordinates:          [],
  Heading:                 null,

  setMapCoor: () => {},

  isCalloutCoord:  false,
  setCalloutCoord: () => {},
  calloutCoord:    {},
  title:           '',
  content:         '',
};
