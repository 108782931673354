import { Button, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const MButton = (props) => {
  const onBtnClick = () => {
    props.onClick();
  };

  return (
    <Button
      className={props.className}
      onClick={onBtnClick}
      style={{ ...props.style }}
      disabled={props.disabled}
    >
      <Typography variant={props.textSize} color="inherit" style={{ color: props.textColour }}>{props.text}</Typography>
    </Button>
  );
};

MButton.propTypes = {
  className:  PropTypes.string,
  onClick:    PropTypes.func.isRequired,
  text:       PropTypes.string,
  disabled:   PropTypes.bool,
  textSize:   PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline']),
  textColour: PropTypes.string,
  style:      PropTypes.shape({}),
};

MButton.defaultProps = {
  text:       '',
  textSize:   'button',
  disabled:   false,
  className:  '',
  textColour: '',
  style:      {},
};

export default MButton;
