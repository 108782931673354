// React Dependencies
import React, { useState, useRef, useEffect, useReducer } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Paper, TextField, Button, Divider, IconButton, Dialog } from '@material-ui/core';
import { Edit as EditIcon, Lock as LockIcon } from '@material-ui/icons';

// Internal Dependencies
import MText from '../../../../components/Common/MText';
import Colours from '../../../../styles/colours';
import PhotoCropper from '../../../../components/Common/photos/PhotoCropper';
import ApiService from '../../../../services/apiService';
import OverLoading from '../../../../components/Loading';
import StampDialog from './StampDialog';
import FloatingColourPicker from '../../../../components/FloatingColourPicker';
import Constants, { Status } from '../../../../common/constants';
import { isHexColour } from '../../../../common/util';
import { IsEmpty } from '../../../../common/checks';
import { titleTextSize, labelTextSize, stampLabelSize } from '../LoyaltyCardStyle';

const defaultImageObject = { file: null, data: null };

const defaultCropperState = {
  cropImage:     defaultImageObject,
  uploadContext: null,
  isCropping:    false,
};

const defaultLoyaltyStampState = {
  stampCount:        0,
  stamps:            [],
  defaultStampImage: defaultImageObject,
  stampsHolderImage: defaultImageObject,
  redeemImage:       defaultImageObject,
  stampTextColour:   Colours.Black.slice(1),
  name:              '',
  terms:             '',
  validityInDays:    null,
  status:            Status.Draft,
  dialog:            {
    id:                      null,
    num:                     null,
    stampImage:              defaultImageObject,
    name:                    '',
    label:                   '',
    linkPageId:              null,
    linkPageName:            '',
    isToBeIssueByLinkPageId: false,
    prize:                   '',
    prizeTerms:              '',
    prizeImage:              defaultImageObject,
    open:                    false,
  },
  ...defaultCropperState,
};

const imageFormats = ['image/jpeg', 'image/jpg', 'image/png'];

const StampSettings = ({ match }) => {
  const { pageId, loyaltyCardId } = match.params;

  const [loyaltyStamps, setLoyaltyStamps] = useReducer((state, action) => (
    { ...state, ...action, dialog: { ...state.dialog, ...action.dialog } }
  ), defaultLoyaltyStampState);

  const fileInputRefs = {
    defaultStampImage: useRef(null),
    stampsHolderImage: useRef(null),
    redeemImage:       useRef(null),
    stampImage:        useRef(null),
    prizeImage:        useRef(null),
  };

  const [loading, setLoading] = useState(false);
  const [stampCountInput, setStampCountInput] = useState(null);
  const [isReduceStampDialogOpen, setIsReduceStampDialogOpen] = useState(false);
  const [stampTextColourPickerOpen, setStampTextColourPickerOpen] = useState(false);
  const [stampTextColourInput, setStampTextColourInput] = useState(defaultLoyaltyStampState.stampTextColour);

  const isDeactivated = loyaltyStamps.status === Status.Deactivated;
  const isDraft = loyaltyStamps.status === Status.Draft;

  const getLoyaltyStamps = async () => {
    try {
      setLoading(true);
      const { loyaltyCard, stamps } = await ApiService.getStampInfos(pageId, loyaltyCardId);
      const displayedStampsCount = loyaltyStamps.stampCount > stamps.length ? loyaltyStamps.stampCount : stamps.length;
      const stampTextColour = (!IsEmpty(loyaltyCard.stampTextColour) && isHexColour(`#${loyaltyCard.stampTextColour}`)) ? loyaltyCard.stampTextColour : loyaltyStamps.stampTextColour;

      setStampTextColourInput(stampTextColour);
      setStampCountInput(displayedStampsCount);
      setLoyaltyStamps({
        dialog:            defaultLoyaltyStampState.dialog,
        status:            loyaltyCard.status,
        defaultStampImage: { data: loyaltyCard.stampImage },
        stampsHolderImage: { data: loyaltyCard.stampsHolderImage },
        redeemImage:       { data: loyaltyCard.redeemStampImage },
        name:              loyaltyCard.name,
        terms:             loyaltyCard.terms,
        validityInDays:    loyaltyCard.validityInDays,
        stampTextColour,
        stamps,
        stampCount:        displayedStampsCount,
      });
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fileValidated = (file) => {
    if (!imageFormats.includes(file.type)) {
      toast.error('Only JPG, JPEG and PNG files are supported.');
      return false;
    }
    if (file.size > Constants.BinaryOneMBSize * 7) {
      toast.error('Maximum file size of 7MB exceeded.');
      return false;
    }
    return true;
  };

  const validateStampCount = (stampCount) => {
    if (stampCount < 0) {
      toast.error('Number of stamps cannot be negative');
      return false;
    }
    if (stampCount > 12) {
      toast.error('Maximum number of 12 stamps exceeded. Please reduce number of stamps.');
      return false;
    }
    return true;
  };

  const openCustomizeDialog = (stamp, num) => {
    const stampInfo = stamp ? {
      id:                      stamp.id,
      stampImage:              { data: stamp.image || null },
      num,
      name:                    stamp.name,
      label:                   stamp.label,
      linkPageId:              stamp.linkPageId,
      linkPageName:            stamp.linkPageName,
      isToBeIssueByLinkPageId: stamp.isToBeIssueByLinkPageId,
      prize:                   stamp.prize,
      prizeTerms:              stamp.prizeTerms,
      prizeImage:              { data: stamp.prizeImage || null },
    } : {};

    setLoyaltyStamps({
      dialog: { ...stampInfo, open: true },
    });
  };

  const onCloseCustomizeDialog = async () => {
    setLoyaltyStamps({ dialog: { open: false } });
  };

  const onCloseCustomizeDialogEnd = () => {
    const { dialog } = loyaltyStamps;
    if (!dialog.open) {
      setLoyaltyStamps({ dialog: defaultLoyaltyStampState.dialog });
    }
  };

  const handleSaveStampCount = async () => {
    try {
      setLoading(true);
      const stampCount = Number(stampCountInput);

      if (validateStampCount(stampCount)) {
        const stamps = await ApiService.updateLoyaltyCardStamps({ pageId, loyaltyCardId }, { stampCount });
        setLoyaltyStamps({ stamps, stampCount: stamps.length });
        toast.success("Loyalty card's stamps were updated");
      }
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCustomizeInput = (e) => {
    setLoyaltyStamps({
      dialog: { [e.target.name]: (e.target.type === 'checkbox' ? e.target.checked : e.target.value) },
    });
  };

  const handleSaveDefaultStampImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const imageUrl = await ApiService.uploadDefaultStampMedia({ pageId, loyaltyCardId }, formData);
      setLoyaltyStamps({ ...defaultCropperState, defaultStampImage: { data: imageUrl } });
      toast.success('Default stamp image has been updated');
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const handleSaveStampsHolderImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const imageUrl = await ApiService.uploadStampsHolderMedia({ pageId, loyaltyCardId }, formData);
      setLoyaltyStamps({ ...defaultCropperState, stampsHolderImage: { data: imageUrl } });
      toast.success('Background image has been updated');
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const handleSaveRedeemStamp = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const imageUrl = await ApiService.uploadRedeemStampMedia({ pageId, loyaltyCardId }, formData);
      setLoyaltyStamps({ ...defaultCropperState, redeemImage: { data: imageUrl } });
      toast.success('Redeemed stamp image has been updated');
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const handleSaveDialogStamp = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const { id: stampInfoId } = loyaltyStamps.dialog;
      const imageUrl = await ApiService.uploadStampInfoMedia({ pageId, loyaltyCardId, stampInfoId }, formData);
      const stamps = loyaltyStamps.stamps.map((stamp) => (stamp.id === stampInfoId ? { ...stamp, image: imageUrl } : stamp));
      setLoyaltyStamps({
        ...defaultCropperState,
        dialog: { stampImage: { data: imageUrl } },
        stamps,
      });
      toast.success(`Stamp's image has been updated`);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const handleSavePrizeImage = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const { id: stampInfoId } = loyaltyStamps.dialog;
      const imageUrl = await ApiService.uploadStampPrizeMedia({ pageId, loyaltyCardId, stampInfoId }, formData);
      const stamps = loyaltyStamps.stamps.map((stamp) => (stamp.id === stampInfoId ? { ...stamp, prizeImage: imageUrl } : stamp));
      setLoyaltyStamps({
        ...defaultCropperState,
        dialog: { prizeImage: { data: imageUrl } },
        stamps,
      });
      toast.success("Stamp's prize image has been updated");
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }
  };

  const handleSave = async (file, uploadContext) => {
    try {
      setLoading(true);
      if (IsEmpty(file)) {
        setLoyaltyStamps({ ...defaultCropperState });
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        switch (uploadContext || loyaltyStamps.uploadContext) {
          case 'stampsHolderImage':
            await handleSaveStampsHolderImage(file);
            break;
          case 'defaultStampImage':
            await handleSaveDefaultStampImage(file);
            break;
          case 'redeemImage':
            await handleSaveRedeemStamp(file);
            break;
          case 'stampImage':
            await handleSaveDialogStamp(file);
            break;
          case 'prizeImage':
            await handleSavePrizeImage(file);
            break;
          default:
            break;
        }
        setLoading(false);
      };
      return true;
    } catch (err) {
      toast.error(err.message);
      console.log('Error at StampSettings.handleSave():', err);
      setLoading(false);
    }
  };

  const handleImageChange = async (e) => {
    const fileInput = e.target;
    if (fileInput.files.length === 0) {
      return;
    }
    const file = fileInput.files[0];
    if (!fileValidated(file)) {
      return;
    }
    if (!['stampsHolderImage'].includes(fileInput.name)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageObj = { file, data: reader.result };
        const cropperTriggered = { isCropping: true, uploadContext: fileInput.name, cropImage: imageObj };
        setLoyaltyStamps({ ...cropperTriggered });

        // clear file input
        fileInputRefs[fileInput.name].current.value = '';
      };
      reader.readAsDataURL(file);
    } else {
      await handleSave(file, fileInput.name);
      fileInputRefs[fileInput.name].current.value = '';
    }
  };

  const closeCropDialog = () => {
    setLoyaltyStamps({
      cropImage:     defaultImageObject,
      isCropping:    false,
      uploadContext: null,
    });
  };

  const handleDialogSave = async () => {
    try {
      setLoading(true);
      const { id, name, label, prize, prizeTerms } = loyaltyStamps.dialog;

      const updatedStamp = await ApiService.updateStampInfo(
        { pageId, loyaltyCardId, stampInfoId: id },
        { name, label, prize, prizeTerms },
      );
      setLoyaltyStamps({
        stamps: loyaltyStamps.stamps.map((stamp) => (stamp.id === updatedStamp.id ? updatedStamp : stamp)),
        dialog: { open: false },
      });

      toast.success('Stamp updated');
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveStampTextColour = async () => {
    try {
      setLoading(true);
      if (stampTextColourInput !== loyaltyStamps.stampTextColour) {
        const { name, terms, validityInDays, status } = loyaltyStamps;
        const loyaltyCard = await ApiService.updateLoyaltyCard(
          pageId,
          loyaltyCardId,
          {
            name,
            terms,
            validityInDays,
            status,
            stampTextColour: isHexColour(`#${stampTextColourInput}`) ? stampTextColourInput : Colours.Black.slice(1),
          },
        );
        setLoyaltyStamps({
          name:            loyaltyCard.name,
          terms:           loyaltyCard.terms,
          validityInDays:  loyaltyCard.validityInDays,
          status:          loyaltyCard.status,
          stampTextColour: loyaltyCard.stampTextColour,
        });
        setStampTextColourInput(loyaltyCard.stampTextColour);
        toast.success('Stamp text colour has been updated', { toastId: `stamp-text-colour-${stampTextColourInput}` });
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLoyaltyStamps();
  }, []);

  return (
    <Paper className="py-3 mb-2 pl-sm-3">
      {loading && <OverLoading />}
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12 col-lg-5">
            <MText size={12} text={`Loyalty Card ID: ${match.params.loyaltyCardId}`} colour={Colours.Gray1} className="mb-2" />
            <MText bold text="Stamp Settings" size={titleTextSize} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-8 col-xl-7 d-flex flex-column flex-lg-row">
            <div className="mb-2 mb-lg-0 mr-2">
              <MText text="Set the number of stamps per card" size={labelTextSize} />
              <MText text="(Max 12)" size={labelTextSize} />
            </div>

            <div className="d-flex align-items-center ml-0 ml-lg-4" style={{ width: 250 }}>
              <TextField
                disabled={!isDraft}
                size="small"
                variant="outlined"
                className="w-50"
                name="stampCount"
                type="number"
                value={stampCountInput || 0}
                onChange={(e) => setStampCountInput((e.target.value && Number(e.target.value) >= 0) ? String(Number(e.target.value)) : 0)}
                InputProps={{
                  style:      { fontSize: labelTextSize },
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
              />
              <Button
                disabled={!isDraft}
                onClick={() => {
                  if (validateStampCount(stampCountInput)) {
                    if (Number(stampCountInput) < loyaltyStamps.stampCount) {
                      setIsReduceStampDialogOpen(true);
                    } else {
                      handleSaveStampCount();
                    }
                  }
                }}
                variant="contained"
                color="secondary"
                className="w-50 ml-2"
              >
                Save
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-11 col-lg-8 col-xl-7 my-4">
            <Divider />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-8 col-lg-6">
            <div className="container-fluid">
              <div className="row">
                <MText text="Details" bold size={labelTextSize} />
              </div>
              <div className="row mb-4">
                <div className="col-6 col-lg-5 pl-0">
                  <MText text="Default stamp image" size={labelTextSize} className="mb-2" />
                  <div className="d-flex">
                    {loyaltyStamps.defaultStampImage.data
                      ? (
                        <img
                          src={loyaltyStamps.defaultStampImage.data}
                          alt="Stamp Placeholder"
                          style={{ width: 60, height: 60 }}
                          className="rounded-circle"
                        />
                      )
                      : <div className="rounded-circle" style={{ backgroundColor: Colours.Gray4, width: 60, height: 60 }} />}
                    <input
                      hidden
                      name="defaultStampImage"
                      accept={imageFormats}
                      type="file"
                      onChange={handleImageChange}
                      ref={fileInputRefs.defaultStampImage}
                    />
                    <div className="pl-1 d-flex justify-content-center align-items-center">
                      {!isDeactivated && (
                        <IconButton onClick={() => fileInputRefs.defaultStampImage.current.click()}>
                          <EditIcon htmlColor={Colours.Black} />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-6 col-lg-5 pl-0">
                  <MText text="Redeemed stamp image" size={labelTextSize} className="mb-2" />
                  <div className="d-flex">
                    {loyaltyStamps.redeemImage.data ? (
                      <img
                        src={loyaltyStamps.redeemImage.data}
                        alt="Stamp Redeem"
                        style={{ width: 60, height: 60 }}
                        className="rounded-circle"
                      />
                    )
                      : <div className="rounded-circle" style={{ backgroundColor: Colours.Gray4, width: 60, height: 60 }} />}
                    <input
                      hidden
                      name="redeemImage"
                      accept={imageFormats}
                      type="file"
                      onChange={handleImageChange}
                      ref={fileInputRefs.redeemImage}
                    />
                    <div className="pl-1 d-flex justify-content-center align-items-center">
                      {!isDeactivated && (
                        <IconButton onClick={() => fileInputRefs.redeemImage.current.click()}>
                          <EditIcon htmlColor={Colours.Black} />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12 pl-0">
                  <MText text="Stamp’s label colour" size={labelTextSize} className="mb-2" />
                </div>
                <div className="col-12 pl-0 d-flex align-items-center">
                  <MText text="#" size={labelTextSize} className="mr-1" />
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    className="mr-2"
                    style={{ width: 100 }}
                    value={stampTextColourInput}
                    InputProps={{ style: { fontSize: labelTextSize, color: Colours.Black } }}
                  />
                  <FloatingColourPicker
                    open={stampTextColourPickerOpen}
                    placement="bottom"
                    colour={`#${stampTextColourInput}`}
                    onChange={(value) => setStampTextColourInput(value.slice(1))}
                    onClose={async () => {
                      setStampTextColourPickerOpen(false);
                      await handleSaveStampTextColour();
                    }}
                  >
                    <Button
                      disabled={isDeactivated}
                      onClick={() => setStampTextColourPickerOpen(!stampTextColourPickerOpen)}
                      style={{
                        height:          '100%',
                        width:           100,
                        border:          `1px solid ${Colours.Gray3}`,
                        backgroundColor: `#${stampTextColourInput}`,
                        borderRadius:    0,
                        cursor:          'pointer',
                      }}
                    />
                  </FloatingColourPicker>
                </div>
              </div>

              {!IsEmpty(loyaltyStamps.stamps)
              && (
                <div className="row mb-3">
                  <div className="col-12 pl-0">
                    <MText text="Customise individual stamp and card background" size={labelTextSize} className="mb-2" />
                  </div>
                  <div className="col-12 pl-0">
                    <div
                      className="position-relative pt-5 px-2"
                      style={{
                        borderRadius:       20,
                        width:              320,
                        backgroundColor:    IsEmpty(loyaltyStamps.stampsHolderImage.data) ? Colours.LightGray1 : null,
                        backgroundImage:    IsEmpty(loyaltyStamps.stampsHolderImage.data) ? null : `url(${loyaltyStamps.stampsHolderImage.data})`,
                        backgroundSize:     'cover',
                        backgroundPosition: 'center top',
                      }}
                    >
                      <div className="container-fluid">
                        <div className="row">

                          {loyaltyStamps.stamps.map((stamp) => (
                            <div key={stamp.id} className="col-4 d-flex flex-column justify-content-start align-items-center mb-4">
                              <IconButton size="small" onClick={() => openCustomizeDialog(stamp, stamp.stampNum)} className="p-0 mb-1">
                                <div
                                  className="rounded-circle d-flex justify-content-center align-items-center position-relative"
                                  style={{ backgroundColor: Colours.Gray4, width: 55, height: 55 }}
                                >
                                  {(stamp.image || loyaltyStamps.defaultStampImage.data) && (
                                    <img
                                      src={stamp.image || loyaltyStamps.defaultStampImage.data}
                                      alt="Stamp"
                                      className="w-100 rounded-circle position-absolute"
                                      style={{ filter: isDeactivated ? null : 'contrast(25%)' }}
                                    />
                                  )}
                                  {!isDeactivated && <EditIcon htmlColor={Colours.Black} className="position-absolute" />}
                                </div>
                              </IconButton>
                              <MText text={stamp.name} colour={`#${stampTextColourInput}`} bold size={stampLabelSize} className="text-center" />
                              <MText text={stamp.label} colour={`#${stampTextColourInput}`} size={stampLabelSize} className="text-center" />
                              <div style={{
                                fontSize:   '10pt',
                                visibility: (stamp && stamp.isToBeIssueByLinkPageId) ? 'visible' : 'hidden',
                                color:      `#${stampTextColourInput}`,
                              }}
                              >
                                <LockIcon fontSize="inherit" color="inherit" />
                              </div>
                            </div>
                          ))}

                        </div>

                        {!isDeactivated && (
                          <div className="row">
                            <div className="col-12 px-0 d-flex align-items-center justify-content-center">
                              <input
                                hidden
                                name="stampsHolderImage"
                                accept={imageFormats}
                                type="file"
                                onChange={handleImageChange}
                                ref={fileInputRefs.stampsHolderImage}
                              />
                              <Button onClick={() => fileInputRefs.stampsHolderImage.current.click()} className="mb-3">
                                {/* filter for background image */}
                                {loyaltyStamps.stampsHolderImage.data && (
                                  <div
                                    className="w-100 h-100 position-absolute"
                                    style={{
                                      top:            0,
                                      left:           0,
                                      opacity:        '70%',
                                      backdropFilter: 'blur(2px) contrast(40%) brightness(140%)',
                                      overflow:       'hidden',
                                      borderRadius:   5,
                                    }}
                                  />
                                )}
                                <div className="w-100 d-flex justify-content-center align-items-center" style={{ zIndex: 1 }}>
                                  <MText text="Set background image" size={11} className="mr-2" />
                                  <EditIcon fontSize="small" />
                                </div>
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!IsEmpty(loyaltyStamps.stamps)
          && (
            <div className="col-12 col-xl-6 pr-xl-2 d-none d-xl-flex flex-column align-items-end">
              <div className="mr-xl-3" style={{ width: 410 }}>
                <div className="mb-2 pl-3 font-weight-bold">
                  <MText text="Preview" bold size={labelTextSize} />
                </div>
                <div
                  className="pt-5 px-4 lc-box-shadow"
                  style={{
                    borderRadius:       20,
                    backgroundColor:    IsEmpty(loyaltyStamps.stampsHolderImage.data) ? Colours.LightGray1 : null,
                    backgroundImage:    IsEmpty(loyaltyStamps.stampsHolderImage.data) ? null : `url(${loyaltyStamps.stampsHolderImage.data})`,
                    backgroundSize:     'cover',
                    backgroundPosition: 'center top',
                  }}
                >
                  <div className="container-fluid">
                    <div className="row">

                      {loyaltyStamps.stamps.map((stamp) => (
                        <div key={stamp.id} className="col-4 d-flex flex-column justify-content-start align-items-center pb-5">
                          <div
                            className="rounded-circle mb-1"
                            style={{ backgroundColor: Colours.Gray4, width: 60, height: 60 }}
                          >
                            {(stamp.image || loyaltyStamps.defaultStampImage.data)
                            && <img src={stamp.image || loyaltyStamps.defaultStampImage.data} alt="stamp" className="w-100 rounded-circle" />}
                          </div>
                          <MText text={stamp.name} bold size={stampLabelSize} className="text-center" colour={`#${stampTextColourInput}`} />
                          <MText text={stamp.label} size={stampLabelSize} className="text-center" colour={`#${stampTextColourInput}`} />
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <StampDialog
        isDeactivated={isDeactivated}
        onCloseDialog={onCloseCustomizeDialog}
        onCloseDialogEnd={onCloseCustomizeDialogEnd}
        loyaltyStamps={loyaltyStamps}
        onUpdateLoyaltyStamps={setLoyaltyStamps}
        handleCustomizeInput={handleCustomizeInput}
        handleDialogSave={handleDialogSave}
        handleImageChange={handleImageChange}
        fileInputRefs={fileInputRefs}
        imageFormats={imageFormats}
      />

      <Dialog
        open={isReduceStampDialogOpen}
        onClose={() => setIsReduceStampDialogOpen(false)}
        PaperProps={{ className: 'p-5 w-100' }}
      >
        <MText text="Reduce stamp confirmation" size={20} bold className="mb-4" />
        <MText text="You have selected to reduce the number of stamps from:" size={15} className="mb-2" />
        <div className="d-flex align-items-center" style={{ marginBottom: '2.2rem' }}>
          <MText text={String(loyaltyStamps.stampCount)} size={24} className="ml-2" bold />
          <MText text="to" size={15} className="ml-4" />
          <MText text={String(stampCountInput)} size={24} className="ml-4" bold />
        </div>
        <MText
          text={`The last ${loyaltyStamps.stampCount - Number(stampCountInput) === 1
            ? `stamp's` : `${loyaltyStamps.stampCount - Number(stampCountInput)} stamps'`
          } images and details will be deleted:`}
          size={15}
          className="mb-4"
        />
        <div className="container-fluid mb-4 px-0">
          <div className="row">
            {loyaltyStamps.stamps.slice(Number(stampCountInput)).map((stamp) => (
              <div key={stamp.id} className="col-4 col-sm-3 col-md-2 d-flex flex-column justify-content-start pb-4">
                <div
                  className="rounded-circle mb-1"
                  style={{ backgroundColor: Colours.Gray4, width: 60, height: 60 }}
                >
                  {(stamp.image || loyaltyStamps.defaultStampImage.data)
                && <img src={stamp.image || loyaltyStamps.defaultStampImage.data} alt="stamp" className="w-100 rounded-circle" />}
                </div>
              </div>
            ))}
          </div>
        </div>
        <MText
          bold
          size={15}
          className="font-italic"
          style={{ marginBottom: '0.75rem' }}
          text="*Once a stamp is deleted, its information cannot be recovered."
        />
        <MText bold text="Proceed?" size={15} className="mb-5" />
        <div className="d-flex align-items-center justify-content-around mx-auto" style={{ width: '80%' }}>
          <Button variant="contained" onClick={() => setIsReduceStampDialogOpen(false)} style={{ width: '40%', backgroundColor: Colours.Gray2 }}>
            <MText text="Cancel" size={13} colour={Colours.White} />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ width: '40%' }}
            onClick={() => {
              handleSaveStampCount();
              setIsReduceStampDialogOpen(false);
            }}
          >
            <MText text="Reduce" size={13} colour={Colours.White} />
          </Button>
        </div>
      </Dialog>

      <Dialog
        fullScreen
        open={loyaltyStamps.isCropping}
        onClose={closeCropDialog}
      >
        <PhotoCropper
          width={4}
          height={3}
          cropPixelWidth={400}
          cropPixelHeight={400}
          outputPixelWidth={900}
          outputPixelHeight={900}
          type="upload"
          label={!['prizeImage'].includes(loyaltyStamps.uploadContext) && 'Logo'}
          src={loyaltyStamps.cropImage.data}
          file={loyaltyStamps.cropImage.file}
          handleSave={handleSave}
          handleClose={closeCropDialog}
          isCropping={loyaltyStamps.isCropping}
          uploading={loading}
        />
      </Dialog>
    </Paper>
  );
};

StampSettings.propTypes = {
  match: PropTypes.shape(Object).isRequired,
};

export default StampSettings;
