// React Dependencies
import React, { useState, useEffect } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { Button, CircularProgress, List, ListItem, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';
import { toast } from 'react-toastify';

// Internal Dependencies
import PageOutletSelect from './PageOutletSelect';
import SearchAddress from '../../../../components/Common/SearchAddress';
import RadioBtnSelect from '../../../../components/Common/RadioBtnSelect';
import ManagePlaceAndLocationTags from './managePlaceAndLocationTags';
import OverLoading from '../../../../components/Loading';

import { Sleep, Trim, ConvertToPascalCase } from '../../../../common/util';
import { IfTrueThrowError, IsEmpty, isValidUrl } from '../../../../common/checks';
import { HalalStatus } from '../../../../common/constants';

import ApiService from '../../../../services/apiService';
import Colours from '../../../../styles/colours';

const LinkPageOrLocation = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [nameEntered, setNameEntered]           = useState('');
  const [isSearchingPages, setIsSearchingPages] = useState(false);
  const [suggestedPages, setSuggestedPages]     = useState([]);
  const [selected, setSelected]                 = useState(null);
  const [isSimilarName, setIsSimilarName]       = useState(false);

  const [isAllOutlets, setIsAllOutlets]       = useState(null);
  const [selectedOutlets, setSelectedOutlets] = useState([]);

  const [rating, setRating]             = useState(null);
  const [isHomeBased, setIsHomeBased]   = useState(false);
  const [validUrl, setValidUrl]         = useState(false);
  const [url, setUrl]                   = useState(null);
  const [phoneNumber, setPhoneNumber]   = useState(null);
  const [validContact, setValidContact] = useState(false);
  const [postalCode, setPostalCode]     = useState(null);
  const [address, setAddress]           = useState(null);
  const [unitNo, setUnitNo]             = useState(null);
  const [locatedIn, setLocatedIn]       = useState(null);
  const [lat, setLat]                   = useState(null);
  const [lng, setLng]                   = useState(null);
  const [tags, setTags]                 = useState([]);
  const [isHalal, setIsHalal]           = useState(HalalStatus.No);

  const [isEdit, setIsEdit]                     = useState(false);
  const [isClicked, setIsClicked]               = useState(false);
  const [enableAddrUpdate, setEnableAddrUpdate] = useState(false);

  useEffect(() => {
    const isEditing = !IsEmpty(props.editData);
    setIsEdit(isEditing);
    setSelected(props.editData);

    if (isEditing) {
      if (!IsEmpty(props.editData.placePageId)) {
        setSelectedOutlets(props.editData.outlets);
        setIsAllOutlets(props.editData.isAllOutlets);
      } else {
        setPostalCode(props.editData.postalCode);
        setAddress(props.editData.address);
        setUnitNo(props.editData.unitNo);
        setLocatedIn(props.editData.locatedIn);
        setLat(props.editData.lat);
        setLng(props.editData.lng);
      }
      if (props.editData.isHomeBased) {
        setUrl(props.editData.url);
        setPhoneNumber(props.editData.phoneNumber);
      }
      setIsHalal(props.editData.halal);
      setIsHomeBased(props.editData.isHomeBased);
      setRating(props.editData.rating);
      setTags(props.editData.tags);
    }
  }, []);

  const onInputNameChanged = async (value) => {
    let pages = [];
    try {
      setSuggestedPages([]);
      setIsSearchingPages(true);
      setNameEntered(value);

      if (!IsEmpty(value)) {
        let result = await ApiService.getPagesSuggest(value);

        // filter out the result from search if placePageId has been selected.
        const extPlaces = _.filter(props.existingPlaces, (ep) => _.has(ep, 'placePageId') && ep.placePageId);
        const mapExtPlaces = _.map(extPlaces, (ep) => ep.placePageId);

        result = _.filter(result, (r) => !mapExtPlaces.includes(r.id));

        // limit max 4 records for display
        if (result.length > 4) {
          result = _.dropRight(result, result.length - 4);
        }
        pages = result;

        // filter to show custom name btn only if theres no exact name within the dataset.
        if (_.includes(pages.map((p) => _.toLower(p.name)), _.toLower(value))) {
          setIsSimilarName(true);
        } else {
          setIsSimilarName(false);
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      await Sleep(300); // to avoid loading indicator from flashing if data is retrieved and displayed fast
      setIsSearchingPages(false);
      setSuggestedPages(pages);
    }
  };

  const selectPage = (pageData) => {
    try {
      setSelected({ ...pageData, placePageId: pageData.id });

      let currentTags = IsEmpty(tags) ? [] : tags.slice();

      // Auto add the page’s halal status
      if (pageData.halal) {
        if (!currentTags.includes(pageData.halal)) {
          currentTags.push(pageData.halal);
        }
        if (!currentTags.includes('halal')) {
          currentTags.push('halal');
        }
        setIsHalal(ConvertToPascalCase(pageData.halal));
        setTags(currentTags);
      } else {
        setIsHalal(HalalStatus.No);
        currentTags = currentTags.filter((x) => x !== 'halal certified' && x !== 'muslim owned' && x !== 'halal');
        setTags(currentTags);
      }

      setIsAllOutlets(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const selectCustomName = (name) => {
    setSelected({ name });
    setIsAllOutlets(null);

    // if there are existing custom locations already added, prefill with the tags of the first existing custom location added.
    let firstSameLocation;
    for (const placeOrLocation of props.existingPlaces) {
      if (IsEmpty(placeOrLocation.placePageId) && placeOrLocation.name === name) {
        if (IsEmpty(firstSameLocation)) {
          firstSameLocation = placeOrLocation;
        } else if (placeOrLocation.createdAt < firstSameLocation.createdAt) {
          firstSameLocation = placeOrLocation;
        }
      }
    }
    if (firstSameLocation && !IsEmpty(firstSameLocation.tags)) {
      setTags(firstSameLocation.tags);
      toast.info('Tags here are auto-filled with tags found in first existing custom location added in this review');
    }
  };

  const updateUrl = (data) => {
    setUrl(data);
    if (isValidUrl(data)) {
      setValidUrl(false);
    } else {
      setValidUrl(true);
    }
  };

  const validatePhoneNumber = (data) => {
    const trimmedNumber = Trim(data, true).replace(/[A-Za-z]+/g, '');
    setPhoneNumber(trimmedNumber);
    if (!IsEmpty(trimmedNumber) && trimmedNumber.length < 8) {
      setValidContact(true);
    } else {
      setValidContact(false);
    }
  };

  const validateData = (selectedData) => {
    IfTrueThrowError(selectedData === {} || IsEmpty(selectedData), 'Selected data is empty');
    if (IsEmpty(selectedData.placePageId) && !isHomeBased) {
      IfTrueThrowError(
        IsEmpty(selectedData.postalCode) || IsEmpty(selectedData.address),
        'Postal code and address must be provided for custom location',
      );
    }
    IfTrueThrowError(isHomeBased && !IsEmpty(updateUrl) && validUrl, 'Link must start with http:// or https://');
    IfTrueThrowError(isHomeBased && !IsEmpty(phoneNumber) && validContact, 'Phone number should be at least 8 digit in length');
    IfTrueThrowError(IsEmpty(isHalal), 'Halal Status is required for location');
  };

  const onAddClick = async () => {
    try {
      setIsLoading(true);
      // handle where 'selected' is a Page or a location
      let data = {};
      if ('placePageId' in selected) {
        data = {
          placePageId: selected.id,
          name:        selected.name,
          isAllOutlets,
          outlets:     isAllOutlets ? [] : selectedOutlets,
          rating:      Trim(rating),
          halal:       isHalal,
        };
      } else {
        data = {
          name:   selected.name,
          isHomeBased,
          url,
          phoneNumber,
          postalCode,
          locatedIn,
          address,
          unitNo,
          lat,
          lng,
          rating: Trim(rating),
          halal:  isHalal,
          tempId: Math.round(Math.random() * 1000), // create a temp id to differentiate records
        };
      }

      data.tags = tags;
      validateData(data);
      setIsClicked(true);

      const addReviewPlaceLocation = await ApiService.addReviewPlaceLocation(props.reviewId, data);
      if ('placePageId' in addReviewPlaceLocation) {
        addReviewPlaceLocation.outlets = data.outlets;
      }
      addReviewPlaceLocation.tags = data.tags;

      // update parent com
      props.onAdd(addReviewPlaceLocation);
      toast.success(`Review Place/Location Added`, { autoClose: 6000 });
    } catch (error) {
      toast.error(error.message);
      setIsClicked(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateClick = async () => {
    try {
      setIsLoading(true);
      const updatedData = ('placePageId' in selected)
        ? {
          ...selected,
          isAllOutlets,
          outlets: isAllOutlets ? [] : selectedOutlets,
          rating:  Trim(rating),
          halal:   isHalal,
        } : {
          ...selected,
          isHomeBased,
          url:         isHomeBased ? url : null,
          phoneNumber: isHomeBased ? phoneNumber : null,
          postalCode,
          locatedIn,
          address,
          unitNo,
          lat,
          lng,
          rating:      Trim(rating),
          halal:       isHalal,
        };

      updatedData.tags = tags;
      validateData(updatedData);
      setIsClicked(true);

      await ApiService.updateReviewPlaceLocation(selected.id, props.reviewId, updatedData);
      props.onUpdate(updatedData);
      toast.success(`Review Place/Location Updated.`, { autoClose: 6000 });
    } catch (error) {
      toast.error(error.message);
      setIsClicked(false);
    } finally {
      setIsLoading(false);
    }
  };

  const inputNameDebounced = _.debounce(onInputNameChanged, 500);

  const onChangeHalalTag = (halal) => {
    let currentTags = tags;

    if (halal === HalalStatus.No) {
      setIsHalal(HalalStatus.No);
      currentTags = currentTags.filter((x) => x !== 'halal certified' && x !== 'muslim owned' && x !== 'halal');
    } else if (halal === HalalStatus.MuslimOwned) {
      setIsHalal(HalalStatus.MuslimOwned);
      currentTags = tags.filter((x) => x !== 'halal certified');
      currentTags.push('muslim owned');
    } else {
      setIsHalal(HalalStatus.HalalCertified);
      currentTags = tags.filter((x) => x !== 'muslim owned');
      currentTags.push('halal certified');
    }

    if ((halal === HalalStatus.MuslimOwned || halal === HalalStatus.HalalCertified) && !currentTags.includes('halal')) {
      currentTags.push('halal');
    }

    setTags(currentTags);
  };

  return (
    <div className="container-fluid py-3">
      { isLoading && <OverLoading backgroundColor="rgba(0,0,0,0.5)" /> }
      <div className="row">
        <div className="col-12 row">
          <div className="col-12">
            <Typography
              variant="h6"
              color="inherit"
              style={{ color: isEdit ? Colours.Gray1 : Colours.Black, fontWeight: 'bold', fontSize: 16 }}
            >
              Step 1: Enter the name of the page / shop / retailer
            </Typography>
          </div>

          <div className="col-12 col-md-8 row d-flex flex-row align-items-center">
            <div className="col-12 col-md-12">
              <TextField
                className="mt-2"
                variant="outlined"
                size="small"
                fullWidth
                disabled={isEdit}
                style={{ backgroundColor: isEdit ? Colours.Gray1 : Colours.White }}
                onChange={(e) => inputNameDebounced(e.target.value)}
              />
            </div>
          </div>

          {
            !isEdit && (
              <div className="col-12 row mt-4">
                <div className="col-12 col-lg-7 pl-4 d-flex flex-column">
                  <Typography
                    variant="subtitle1"
                    color="inherit"
                    style={{ color: isEdit ? Colours.Gray1 : Colours.Black, fontSize: 14 }}
                  >
                    Suggested Pages (Click to select)
                  </Typography>

                  {
                    IsEmpty(nameEntered) && (
                      <div className="mt-2 ml-2 px-4 py-2" style={{ backgroundColor: Colours.Gray1, borderRadius: '5px' }}>
                        <Typography
                          className=""
                          variant="body1"
                          color="inherit"
                          style={{ color: Colours.White, fontSize: 14 }}
                        >
                          Page suggestions will shown here
                        </Typography>
                      </div>
                    )
                  }

                  {
                    isSearchingPages && (<CircularProgress className="m-3" color="secondary" size="2rem" />)
                  }

                  {
                    !IsEmpty(suggestedPages) && (
                      <List className="mt-2" style={{ border: 'solid', borderWidth: '1px', borderRadius: '6px', borderColor: Colours.Gray1 }}>
                        {
                        _.map(suggestedPages, (p) => (
                          <ListItem key={p.name} button onClick={() => selectPage(p)}>
                            <Typography variant="body1" style={{ fontSize: 14 }}>{p.name}</Typography>
                          </ListItem>
                        ))
                        }
                      </List>
                    )
                  }

                  {
                    !IsEmpty(nameEntered) && IsEmpty(suggestedPages) && !isSearchingPages && (
                      <Typography className="mt-4 ml-2" variant="body1" style={{ fontSize: 14 }}>There is no page that matches the name entered</Typography>
                    )
                  }
                </div>

                {
                  !IsEmpty(nameEntered) && !isSearchingPages && !isSimilarName && (
                    <div className="col-12 col-lg-5 d-flex flex-column align-items-center">
                      <div className="px-2 mt-4">
                        <Typography variant="h6" style={{ color: Colours.Gray1, fontSize: 16 }}>OR</Typography>
                      </div>
                      <div className="mx-3 mt-3">
                        <Button variant="contained" size="large" onClick={() => selectCustomName(nameEntered)}>
                          <Typography style={{ fontSize: 16 }}>
                            <>
                              Proceed with custom name
                              <br />
                              '
                              <span style={{ fontWeight: 'bold' }}>
                                {nameEntered}
                              </span>
                              '
                            </>
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>

        {
          selected && (
            <div className="col-12 mt-4 py-2 px-3" style={{ backgroundColor: Colours.Primary, borderRadius: '5px' }}>
              <Typography variant="h6" color="inherit" style={{ color: Colours.White, fontSize: 16 }}>Selected:</Typography>
              <Typography className="ml-3" variant="h6" color="inherit" style={{ color: Colours.White, fontWeight: 'bold', fontSize: 16 }}>{selected.name}</Typography>
            </div>
          )
        }

        {
          !IsEmpty(selected) && IsEmpty(selected.placePageId) && (
            <div className="ml-2 col-12 mt-4">
              <Typography variant="h6" style={{ fontSize: 16 }}>Is Home Based Business?</Typography>
              <RadioBtnSelect
                className="ml-2"
                value={isHomeBased ? 'Yes' : 'No'}
                onChange={(value) => setIsHomeBased(value === 'Yes')}
                options={[
                  { value: 'No' },
                  { value: 'Yes' },
                ]}
              />
            </div>
          )
        }
        <div className="my-4 col-12" style={{ height: '1px', backgroundColor: Colours.Gray1 }} />
        <div className="col-12 d-flex flex-row">
          <Typography variant="h6" color="inherit" style={{ fontWeight: 'bold', color: IsEmpty(selected) ? Colours.Gray1 : Colours.Black, fontSize: 16 }}>Step 2:</Typography>
          {
            !IsEmpty(selected) && (
              <Typography className="ml-3" variant="h6" style={{ fontWeight: 'bold', fontSize: 16 }}>
                {
                  isEdit ? (
                    !IsEmpty(selected.placePageId) ? 'Applicable Outlet(s)' : 'Address'
                  ) : (
                    !IsEmpty(selected.id) ? 'Select Applicable Outlet(s)' : 'Enter Address'
                  )
                }
              </Typography>
            )
          }
          {
            (isEdit && IsEmpty(selected.placePageId) && !enableAddrUpdate) && (
              <Button className="ml-4 px-5" color="default" variant="contained" size="small" onClick={() => setEnableAddrUpdate(true)}>Edit</Button>
            )
          }
        </div>

        {
          !IsEmpty(selected) && (
            <>
              {
                !IsEmpty(selected.placePageId) ? (
                  <div className="col-12 mt-4 d-flex flex-column">
                    <div className="ml-2">
                      <Typography variant="h6" style={{ fontSize: 16 }}>
                        Review is for all
                        {' '}
                        {selected.name}
                        {'\'s '}
                        Outlets
                      </Typography>
                      <RadioBtnSelect
                        className="ml-2"
                        value={isAllOutlets ? 'Yes' : 'No'}
                        onChange={(value) => setIsAllOutlets(value === 'Yes')}
                        options={[
                          { value: 'Yes' },
                          { value: 'No' },
                        ]}
                      />
                    </div>
                    {
                      !isAllOutlets && (
                        <div className="mt-3 col-12">
                          <PageOutletSelect
                            pageId={selected.placePageId}
                            selected={selectedOutlets || []}
                            onChange={(outlets) => setSelectedOutlets(outlets)}
                          />
                        </div>
                      )
                    }
                  </div>
                ) : (
                  (isEdit && !enableAddrUpdate) ? (
                    <div className="col-12 mt-4 d-flex flex-column pl-4">
                      <div className="d-flex flex-row">
                        <Typography variant="subtitle1" style={{ minWidth: '100px', fontSize: 14 }}>Postal Code:</Typography>
                        <Typography variant="subtitle1">{postalCode}</Typography>
                      </div>
                      {
                        locatedIn && (
                          <div className="d-flex flex-row">
                            <Typography variant="subtitle1" style={{ minWidth: '100px', fontSize: 14 }}>Located In:</Typography>
                            <Typography variant="subtitle1">{locatedIn}</Typography>
                          </div>
                        )
                      }
                      <div className="d-flex flex-row">
                        <Typography variant="subtitle1" style={{ minWidth: '100px', fontSize: 14 }}>Address:</Typography>
                        <Typography variant="subtitle1">{address}</Typography>
                      </div>

                      <div className="d-flex flex-row">
                        <Typography variant="subtitle1" style={{ minWidth: '100px', fontSize: 14 }}>Unit No:</Typography>
                        <Typography variant="subtitle1">{unitNo}</Typography>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 mt-3 d-flex flex-column pl-3">
                      <SearchAddress
                        onChange={
                          (addr) => {
                            setPostalCode(IsEmpty(addr) ? null : addr.postalCode);
                            setLocatedIn(IsEmpty(addr) ? null : addr.name);
                            setAddress(IsEmpty(addr) ? null : addr.address);
                            setUnitNo(IsEmpty(addr) ? null : addr.unitNo);
                            setLat(IsEmpty(addr) ? null : addr.lat);
                            setLng(IsEmpty(addr) ? null : addr.lng);
                          }
                        }
                      />
                    </div>
                  )
                )
              }
              <div className="col-12 mt-4 d-flex flex-column">
                <Typography variant="h6" style={{ fontSize: 16 }}>Rating</Typography>
                <TextField
                  className="mt-2"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={rating || ''}
                  onChange={(e) => { setRating(e.target.value); }} //set a new rating
                />
              </div>
            </>
          )
        }

        {
          !IsEmpty(selected) && (
            <>
              <div className="my-4 col-12" style={{ height: '1px', backgroundColor: Colours.Gray1 }} />
              <div className="col-12 d-flex flex-column">
                <Typography variant="h6" color="inherit" style={{ fontWeight: 'bold', color: IsEmpty(selected) ? Colours.Gray1 : Colours.Black }}>Step 3:</Typography>
                <div className="form-group ml-4 mt-2">
                  <Typography
                    variant="h6"
                    color="inherit"
                    style={{ color: Colours.Gray1, fontWeight: 'bold' }}
                  >
                    Is Place/Location Halal?
                  </Typography>
                  <RadioBtnSelect
                    value={isHalal}
                    className="ml-2"
                    options={[
                      { value: HalalStatus.No, label: HalalStatus.No },
                      { value: HalalStatus.HalalCertified, label: HalalStatus.HalalCertified },
                      { value: HalalStatus.MuslimOwned, label: HalalStatus.MuslimOwned },
                    ]}
                    onChange={(halal) => onChangeHalalTag(halal)}
                  />
                </div>
                <ManagePlaceAndLocationTags
                  halal={isHalal}
                  locationName={selected.name}
                  reviewTags={props.reviewTags}
                  existingTags={IsEmpty(tags) ? [] : tags}
                  onTagsChange={(updatedTags) => setTags(updatedTags)}
                />
              </div>
            </>
          )
        }

        {
          isHomeBased && (
            <>
              <div className="my-4 col-12" style={{ height: '1px', backgroundColor: Colours.Gray1 }} />
              <div className="col-12 d-flex flex-column">
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Step 4: Contact Info</Typography>
                <div className="row mt-3">
                  <div className="col-12 flex-row align-items-center">
                    <Typography variant="subtitle1">Website URL: &nbsp;</Typography>
                    {
                      validUrl
                        ? (
                          <Typography variant="subtitle1" style={{ color: 'red' }}>
                            Invalid url
                            <br />
                            Must start with http:// or https://
                          </Typography>
                        )
                        : null
                    }
                  </div>
                  <div className="col-12 d-flex flex-row align-items-center">
                    <TextField
                      className="mt-2"
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      value={url}
                      onChange={(e) => updateUrl(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 flex-row align-items-center">
                    <Typography variant="subtitle1">Phone Number: &nbsp;</Typography>
                    {
                      validContact && (
                        <Typography variant="subtitle1" style={{ color: 'red' }}>
                          Phone number should be at least 8 digit in length
                        </Typography>
                      )
                    }
                  </div>
                  <div className="col-4 d-flex flex-row align-items-center">
                    <TextField
                      className="mt-2"
                      variant="outlined"
                      size="small"
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => validatePhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>
          )
        }

        <div className="my-4 col-12" style={{ height: '1px', backgroundColor: Colours.Gray1 }} />

        <div className="col-12 d-flex flex-row justify-content-end">
          <Button
            className="px-4"
            variant="contained"
            onClick={() => props.onCancel()}
          >
            <Typography variant="body1">Cancel</Typography>
          </Button>

          <Button
            className="ml-4 mr-3 px-5"
            variant="contained"
            color="secondary"
            disabled={IsEmpty(selected) || isClicked}
            onClick={isEdit ? onUpdateClick : onAddClick}
          >
            <Typography variant="body1">{isEdit ? 'Update' : 'Add'}</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

LinkPageOrLocation.propTypes = {
  existingPlaces: PropTypes.arrayOf(PropTypes.shape({})),
  editData:       PropTypes.shape({
    isAllOutlets: PropTypes.bool,
    outlets:      PropTypes.arrayOf(PropTypes.shape({})),
    placePageId:  PropTypes.number,
    postalCode:   PropTypes.string,
    address:      PropTypes.string,
    unitNo:       PropTypes.string,
    locatedIn:    PropTypes.string,
    lat:          PropTypes.number,
    lng:          PropTypes.number,
    rating:       PropTypes.string,
    isHomeBased:  PropTypes.bool,
    url:          PropTypes.string,
    phoneNumber:  PropTypes.string,
    tags:         PropTypes.arrayOf(PropTypes.string),
    halal:        PropTypes.arrayOf(PropTypes.string),
  }),
  onAdd:      PropTypes.func,
  onCancel:   PropTypes.func,
  onUpdate:   PropTypes.func,
  reviewTags: PropTypes.arrayOf(PropTypes.string),
  reviewId:   PropTypes.number,
};

LinkPageOrLocation.defaultProps = {
  existingPlaces: [],
  editData:       null,
  onAdd:          () => { },
  onCancel:       () => { },
  onUpdate:       () => {},
  reviewTags:     [],
  reviewId:       null,
};

export default LinkPageOrLocation;
