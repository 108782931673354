import React, { useEffect, useState } from 'react';
import { Paper, Typography, Dialog, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Colours from '../../../../styles/colours';
import { WindowSize } from '../../../../common/constants';
import PostsListing from './PostsListing';
import SelectedPostAnalytics from './SelectedPostAnalytics';
import { IsEmpty } from '../../../../common/util';

const _paperRadius = 14;

const PostsAnalytics = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedPostAnalytics, setSelectedPostAnalytics] = useState(null);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);

      if (props.windowSize === WindowSize.Mobile) {
       setOpenModal(true);
      }
    }
  }, [selectedPostAnalytics]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPostAnalytics();
  };

  return (
    <Paper className="d-flex flex-column pt-4 pb-5" style={{ borderRadius: _paperRadius }}>
      <div className="row">
        <div className="col-12 col-lg-4 pl-4">
          <Typography className="px-4" variant="subtitle1" color="inherit" style={{ color: Colours.Gray }}>Post Analytics</Typography>
          <PostsListing setData={(data) => setSelectedPostAnalytics(data)} refresh={() => setRefresh(true)} pageId={props.pageId} />
        </div>
        {
          (props.windowSize === WindowSize.Desktop) && (
            <div className="col-12 col-lg-8 d-block d-md-block pt-md-4 pt-lg-0 px-md-5">
              {
                !IsEmpty(selectedPostAnalytics) ? (
                  <>
                    <Typography className="mb-2" variant="subtitle1" style={{ color: Colours.Gray }}>
                      Analytics of selected post:
                    </Typography>

                    {!refresh && <SelectedPostAnalytics post={selectedPostAnalytics} />}
                  </>
                ) : (
                  <div className="mt-3 mt-md-5 mt-lg-3">
                    <div className="d-flex flex-column align-items-center">
                      <Typography variant="h6" color="inherit" style={{ color: Colours.Gray2 }}>Select post to view its analytics</Typography>
                      <img
                        src={require('../../../../assets/images/analytics.png')}
                        alt="analytics"
                        width="50%"
                      />
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
      </div>

      {(props.windowSize === WindowSize.Mobile) && (
        <div className="row d-flex flex-row mt-4 px-4">
          <Dialog
            open={openModal}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent style={{
              position:      'relative',
              overflow:      'hidden',
              height:        '100vh',
              verticalAlign: 'middle',
            }}
            >
              <div
                className="position-fixed rounded-circle"
                style={{
                  background: Colours.Gray3,
                  top:        15,
                  right:      15,
                  zIndex:     1,
                }}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => handleCloseModal()}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div style={{
                display:   'block',
                width:     '100%',
                height:    '90vh',
                margin:    '0 auto',
                overflowX: 'hidden',
              }}
              >
                { !refresh && <SelectedPostAnalytics post={selectedPostAnalytics} /> }
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </Paper>
  );
};

PostsAnalytics.propTypes = {
  pageId:     PropTypes.number.isRequired,
  windowSize: PropTypes.string.isRequired,
};

PostsAnalytics.defaultProps = {
};

const mapStateToProps = (state) => ({
  windowSize: state.layout.windowSize,
});

export default connect(mapStateToProps, null)(PostsAnalytics);
