import Constants, { WindowSize } from '../common/constants';

const { ReduxAction } = Constants;

const logoutModalToggle = (dispatch) => {
  dispatch({
    type: ReduxAction.LogoutModalToggle,
  });
};

const sideBarHide = (dispatch) => {
  dispatch({
    type: ReduxAction.SideBarHide,
  });
};

const sideBarShow = (dispatch) => {
  dispatch({
    type: ReduxAction.SideBarShow,
  });
};

const sideBarToggle = (dispatch) => {
  dispatch({
    type: ReduxAction.SideBarToggle,
  });
};

const slidingPanel = (dispatch, slidingPanelData) => {
  dispatch({
    type:    ReduxAction.SlidingPanel,
    payload: slidingPanelData,
  });
};

const slidingPanelMobile = (dispatch, slidingPanelData) => {
  dispatch({
    type:    ReduxAction.SlidingPanelMobile,
    payload: slidingPanelData,
  });
};

const windowSizeChanged = (dispatch, windowSize = WindowSize.Desktop) => {
  dispatch({
    type:    ReduxAction.WindowSizeChanged,
    payload: windowSize,
  });
};

const loyaltyProgramSideBarShow = (dispatch) => {
  dispatch({
    type: ReduxAction.LoyaltyProgramSideBarShow,
  });
};

const loyaltyProgramSideBarHide = (dispatch) => {
  dispatch({
    type: ReduxAction.LoyaltyProgramSideBarHide,
  });
};

const layoutAction = {
  logoutModalToggle,
  sideBarHide,
  sideBarShow,
  sideBarToggle,
  slidingPanel,
  slidingPanelMobile,
  windowSizeChanged,
  loyaltyProgramSideBarShow,
  loyaltyProgramSideBarHide,
};

export default layoutAction;
