import { IsEmpty } from '../common/checks';
import Constants from '../common/constants';

const { ReduxAction } = Constants;

const initialState = {
  selected: {
    /*id:          '1',
    title:       'Test Title',
    description: `Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's
    standard dummy text ever since the 1500s, when an unknown
    printer took a galley of type and scrambled it to make a
    type specimen book. It has survived not only five centuries,
    but also the leap into electronic typesetting, remaining
    essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum
    passages, and more recently with desktop publishing software
    like Aldus PageMaker including versions of Lorem Ipsum.`,
    promoTerms: `Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's
    standard dummy text ever since the 1500s, when an unknown
    printer took a galley of type and scrambled it to make a
    type specimen book. It has survived not only five centuries,
    but also the leap into electronic typesetting, remaining
    essentially unchanged. It was popularised in the 1960s
    with the release of Letraset sheets containing Lorem Ipsum
    passages, and more recently with desktop publishing software
    like Aldus PageMaker including versions of Lorem Ipsum.`,
    photos: [],*/
  },
  list: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ReduxAction.PostSelected:
      return {
        ...state,
        selected: IsEmpty(payload)
          ? {}
          : payload,
      };

    case ReduxAction.PostDataUpdate:
      return {
        ...state,
        selected: {
          ...state.selected,
          /**
           * 'payload' to contain the fields we are updating.
           * This allows us to update specific info
           *
           * E.g
           * payload = {
           *    title: '',
           * }
           *
           */
          ...payload,
        },
      };

    default:
      return state;
  }
}
