import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import MButton from '../../../components/MButton';
import {  Error, StaffType, Status } from '../../../common/constants';
import ApiService  from '../../../services/apiService';
import StyledRadio  from '../../../components/StyledRadio';
import Colours from '../../../styles/colours';
import ChangePlanModal from './ChangePlanModal';

function AccessTable({
  currentStaffList,
  pageSelected,
  setCurrentStaffList,
  isLoading,
  disabled,
  statusFilter,
  onStatusFilterChanged,
  hasReachedStaffLimit,
}) {
  const [openDeleteOrDeactivate, setOpenDeleteOrDeactivate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openReactivation, setOpenReactivation] = useState(false);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [staffType, setStaffType] = useState(null);
  const [id, setId] = useState(null);
  const [email, setEmail] = useState('');
  const [isDelete, setIsDelete] = useState(true);

  return (
    <div className="p-2">
      {/* Delete or deactivate Modal */}
      <Dialog
        fullWidth
        size="sm"
        open={openDeleteOrDeactivate}
        onClose={() => setOpenDeleteOrDeactivate(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isDelete ? 'Delete' : 'Deactivate'}
          {' '}
          Staff
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="inherit" style={{ color: Colours.Gray1 }}>
            {`${isDelete ? 'Delete' : 'Deactivate'} Staff with ID=${id} and email=`}
            <span style={{ color: Colours.Black, fontWeight: 'bolder' }}>{email}</span>
            ?
          </Typography>
        </DialogContent>
        <DialogActions className="mb-2">
          <MButton
            className="px-4"
            onClick={() => {
              ApiService.deleteOrDeactivateBusinessStaff(id, pageSelected.id)
                .then(async () => {
                  const data = await ApiService.getBusinessStaff({ pageId: pageSelected.id });
                  setCurrentStaffList(data);
                  toast.success(`Staff ${isDelete ? 'Deleted' : 'Deactivated'}`);
                  setOpenDeleteOrDeactivate(false);
                })
                .catch((error) => {
                  const errMessage = error.message ? error.message : Error.UnexpectedError;
                  toast.error(errMessage);
                });
            }}
            color="inherit"
            style={{ color: Colours.Gray1 }}
            text="Confirm"
          />
          <MButton className="px-4 mx-1" onClick={() => setOpenDeleteOrDeactivate(false)} textColour={Colours.Primary} text="Cancel" />
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        size="sm"
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update Staff</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="stafftype"
              row
              name="Staff Type"
              value={staffType}
              onChange={(e) => setStaffType(e.target.value)}
            >
              <FormControlLabel color="primary" value={StaffType.Admin} control={<StyledRadio size="small" />} label={StaffType.Admin} />
              <FormControlLabel value={StaffType.Contributor} control={<StyledRadio size="small" />} label={StaffType.Contributor} />
              <FormControlLabel value={StaffType.Observer} control={<StyledRadio size="small" />} label={StaffType.Observer} />
              <FormControlLabel value={StaffType.Finance} control={<StyledRadio size="small" />} label={StaffType.Finance} />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions className="mb-2">
          <MButton
            className="px-2"
            onClick={() => {
              ApiService.updateBusinessStaff(id, { type: staffType }, pageSelected.id)
                .then(async () => {
                  const data = await ApiService.getBusinessStaff({ pageId: pageSelected.id });
                  setCurrentStaffList(data);
                  toast.success('Staff Updated');
                  setOpenEdit(false);
                })
                .catch((error) => {
                  const errMessage = error.message ? error.message : Error.UnexpectedError;
                  toast.error(errMessage);
                });
            }}
            color="default"
            text="Confirm"
          />

          <MButton className="px-2 mx-3" onClick={() => setOpenEdit(false)} textColour={Colours.Primary} text="Cancel" />
        </DialogActions>
      </Dialog>

      {/* Reactivation Modal */}
      <Dialog
        fullWidth
        size="sm"
        open={openReactivation}
        onClose={() => setOpenReactivation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reactivate Staff</DialogTitle>
        <DialogContent>
          Are you sure you want to reactivate this business staff? Reactivating will allow them to access all the permissions associated with their staff type
          <div
            style={{
              display:             'grid',
              gridTemplateColumns: 'auto 2px 1fr ',
              columnGap:           '4px',
              rowGap:              '4px',
            }}
            className="mt-2"
          >
            <span className="font-weight-bold">Email</span>
            <span>
              :
            </span>
            <span>
              {email}
            </span>

            <span className="font-weight-bold">Staff Type</span>
            <span>
              :
            </span>
            <span>
              {staffType}
            </span>
          </div>
        </DialogContent>
        <DialogActions className="mb-2">
          <MButton
            className="px-2"
            onClick={() => {
              ApiService.updateBusinessStaff(id, { status: Status.Activated }, pageSelected.id)
                .then(async () => {
                  const data = await ApiService.getBusinessStaff({ pageId: pageSelected.id });
                  setCurrentStaffList(data);
                  toast.success('Staff Activated');
                  setOpenReactivation(false);
                })
                .catch((error) => {
                  const errMessage = error.message ? error.message : Error.UnexpectedError;
                  toast.error(errMessage);
                });
            }}
            color="default"
            text="Reactivate"
          />
          <MButton className="px-2 mx-3" onClick={() => setOpenReactivation(false)} textColour={Colours.Primary} text="Cancel" />
        </DialogActions>
      </Dialog>

      {/* Plans Dialog */}
      <ChangePlanModal
        onClose={() => setOpenPlanModal(false)}
        open={openPlanModal}
        pageSelected={pageSelected}
      />

      <MaterialTable
        title=""
        isLoading={isLoading}
        columns={[
          { title: 'Email', field: 'inviteEmail', editable: 'never' },
          {
            title:  'Staff Type',
            field:  'type',
            lookup: {
              Contributor: StaffType.Contributor,
              Observer:    StaffType.Observer,
              Admin:       StaffType.Admin,
              Finance:     StaffType.Finance,
            },
          },
          {
            title:    'Invite Status',
            field:    'inviteStatus',
            editable: 'never',
          },
          {
            title:           'Status',
            field:           'status',
            filterComponent: () => (
              <Select
                className="w-100"
                defaultValue={statusFilter}
                variant="standard"
                onChange={(e) => {
                  onStatusFilterChanged(e.target.value);
                }}
              >
                <MenuItem key={0} value="All">All</MenuItem>
                <MenuItem key={1} value={Status.Activated}>{Status.Activated}</MenuItem>
                <MenuItem key={2} value={Status.Deactivated}>{Status.Deactivated}</MenuItem>
                <MenuItem key={3} value={Status.Pending}>{Status.Pending}</MenuItem>
              </Select>
            ),
          },
        ]}
        data={currentStaffList}
        actions={[
          (rowData) => ({
            icon:    'delete',
            hidden:  rowData.status === Status.Deactivated,
            tooltip: rowData.status === Status.Pending ? 'Delete Staff' : 'Deactivate Staff',
            onClick: () => {
              setId(rowData.id);
              setEmail(rowData.inviteEmail);
              if (rowData.status === Status.Pending) {
                setIsDelete(true);
              } else if (rowData.status === Status.Activated) {
                setIsDelete(false);
              }

              setOpenDeleteOrDeactivate(true);
            },
            disabled: rowData.isSelf || rowData.status === Status.Deactivated || disabled,
          }),
          (rowData) => ({
            icon:    'edit',
            hidden:  rowData.status === Status.Deactivated,
            tooltip: 'Edit Staff',
            onClick: () => {
              setId(rowData.id);
              setEmail(rowData.inviteEmail);
              setStaffType(rowData.type);
              setOpenEdit(true);
            },
            disabled: rowData.isSelf || rowData.status === Status.Deactivated || disabled,
          }),
          (rowData) => ({
            icon:    'toggle_on',
            hidden:  rowData.status !== Status.Deactivated,
            tooltip: 'Reactivate Staff',
            onClick: () => {
              if (!hasReachedStaffLimit) {
                setId(rowData.id);
                setEmail(rowData.inviteEmail);
                setStaffType(rowData.type);
                setOpenReactivation(true);
              } else {
                setOpenPlanModal(true);
              }
            },
            disabled: rowData.isSelf || rowData.status !== Status.Deactivated || disabled,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          filtering:          true,
        }}
      />
    </div>
  );
}

AccessTable.defaultProps = {
  isLoading: false,
};

AccessTable.propTypes = {
  pageSelected: PropTypes.shape({
    id:        PropTypes.number.isRequired,
    staffType: PropTypes.string.isRequired,
    name:      PropTypes.string.isRequired,
    plan:      PropTypes.string.isRequired,
  }).isRequired,
  setCurrentStaffList:   PropTypes.func.isRequired,
  currentStaffList:      PropTypes.instanceOf(Array).isRequired,
  isLoading:             PropTypes.bool,
  disabled:              PropTypes.bool.isRequired,
  hasReachedStaffLimit:  PropTypes.bool.isRequired,
  statusFilter:          PropTypes.oneOf(['', Status.Activated, Status.Deactivated, Status.Pending]).isRequired,
  onStatusFilterChanged: PropTypes.func.isRequired,
};

export default AccessTable;
