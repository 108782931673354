import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import _ from 'lodash';
import Colours from '../../../../styles/colours';
import postAction from '../../../../actions/post';
import { IsEmpty } from '../../../../common/util';
import Constants from '../../../../common/constants';

const MealsAvailableTagMobile = (props) => {
  const [selected, setSelected] = useState([]);
  const optionList = Constants.MealTags;
  const [notApplicable, setNotApplicable] = useState(false);
  let tempArr = [];

  useEffect(() => {
    if (!IsEmpty(props.post.mealsAvailable) && props.post.mealsAvailable[0] === Constants.NotApplicable) {
      setNotApplicable(true);
    }
  }, [props.post]);

  useEffect(() => {
    _.map(props.tags, (tag) => {
      if (optionList.includes(tag)) {
        tempArr.push(tag);
      }
    });
    setSelected(props.tags);
  }, [props.tags]);

  const onChange = (tag) => {
    if (!IsEmpty(tag)) {
      let skipTag = true;

      if (!selected.includes(tag)) {
        tempArr = _.concat(selected, tag);
        props.dispatchPostUpdate({ flagMealsAvailNA: !IsEmpty(tempArr), userTags: props.tags.concat(tag), mealsAvailable: _.compact(_.filter(props.tags.concat(tag), (v) => (optionList.includes(v) ? v : null))) });
      } else {
        tempArr = _.without(selected, tag);
        const trimmedUserTags = _.without(props.tags, tag);
        tempArr.concat(trimmedUserTags);
        const filterMealsAvailOnly = _.compact(_.filter(trimmedUserTags, (v) => (optionList.includes(v) ? v : null)));

        if (IsEmpty(filterMealsAvailOnly)) {
          skipTag = false;
        }

        props.dispatchPostUpdate({ flagMealsAvailNA: !IsEmpty(filterMealsAvailOnly), userTags: trimmedUserTags, mealsAvailable: filterMealsAvailOnly });
      }

      props.onSkipMealsTag(skipTag);
      setNotApplicable(false);
      setSelected(tempArr);
    }
  };

  const clearTags = () => {
    setNotApplicable(!notApplicable);

    let skipTag = false;
    let mealsAvailTag = [];
    if (!notApplicable) {
      skipTag = true;
      mealsAvailTag = [Constants.NotApplicable];
    }

    props.onSkipMealsTag(skipTag);
    props.dispatchPostUpdate({ flagMealsAvailNA: !notApplicable, userTags: _.compact(_.filter(props.tags, (v) => (!optionList.includes(v) ? v : null))), mealsAvailable: mealsAvailTag });
  };

  return (
    <>
      {
        _.map(optionList, (tag) => (
          <Button
            disabled={props.disabled}
            key={tag}
            className="mr-2 mb-2"
            variant="outlined"
            color="inherit"
            style={{
              backgroundColor: selected.includes(tag) ? (props.disabled ? Colours.Gray2 : Colours.Primary) : Colours.White,
              color:           selected.includes(tag) ? Colours.White : Colours.Gray,
            }}
            onClick={() => {
              if (tag === Constants.NotApplicable) {
                clearTags();
              } else {
                onChange(tag);
              }
            }}
          >
            <Typography variant="body2">{_.capitalize(tag)}</Typography>
          </Button>
        ))
      }

      <Button
        disabled={props.disabled}
        className="mr-2 mb-2"
        variant="outlined"
        color="inherit"
        style={{
          backgroundColor: notApplicable ? (props.disabled ? Colours.Gray2 : Colours.Primary) : Colours.White,
          color:           notApplicable ? Colours.White : Colours.Gray,
        }}
        onClick={() => clearTags()}
      >
        <Typography variant="body2">{ Constants.NotApplicable }</Typography>
      </Button>
    </>
  );
};
MealsAvailableTagMobile.propTypes = {
  disabled: PropTypes.bool,
  post:     PropTypes.shape({
    mealsAvailable: PropTypes.arrayOf(PropTypes.string),
    status:         PropTypes.string,
  }),
  tags:               PropTypes.arrayOf(PropTypes.string),
  onSkipMealsTag:     PropTypes.func.isRequired,
  dispatchPostUpdate: PropTypes.func.isRequired,
};

MealsAvailableTagMobile.defaultProps = {
  disabled: false,
  post:     { },
  tags:     [],
};

const mapStateToProps = (state) => ({
  post: state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => postAction.postDataUpdate(dispatch, post),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealsAvailableTagMobile);
